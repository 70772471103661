import styled from "styled-components";
import {
  SpaceProps,
  space,
  textAlign,
  TextAlignProps,
  typography,
  TypographyProps,
  position,
  PositionProps,
} from "styled-system";

const Label = styled.label<
  SpaceProps &
    TextAlignProps &
    TypographyProps &
    PositionProps & {
      $transition?: string;
    }
>`
  font-family: BauPro;
  text-transform: uppercase;
  line-height: 200%;
  color: ${(props) => props.theme.colors.thirdText};
  transition: ${(props) => props.$transition ?? undefined};
  ${space};
  ${textAlign};
  ${typography};
  ${position};
`;

export default Label;
