import styled from "styled-components";
import {
  WidthProps,
  HeightProps,
  compose,
  variant,
  OpacityProps,
  width,
  height,
} from "styled-system";
import Image from "../primitives/Image";

export interface CardHomeSliderProps
  extends HeightProps,
    WidthProps,
    OpacityProps {
  variant?: "left" | "right" | "center";
  description: string;
  uri: string;
}

const Container = styled.div<CardHomeSliderProps>`
  /*   width: ${(props) => props.width || "58vw"};
  height: ${(props) => props.height || "92vw"}; */
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease;
  opacity: ${(props) => props.opacity};
  aspect-ratio: 9 / 16;
  position: absolute;
  ${width};
  ${height};

  &::after {
    content: "${(props) => props.description}";
    position: absolute;
    bottom: 1vh;
    transform: translate(-50%, -35%)
      ${(props) =>
        props.variant === "left"
          ? "rotate(7deg)"
          : props.variant === "center"
          ? "rotate(0deg)"
          : "rotate(-7deg)"};
    color: ${(props) => props.theme.colors.primaryText};
    width: min-content;
    text-align: left;
    text-transform: uppercase;
    font-family: "BauProBold";
    font-size: ${["5.2vw"]};
    font-weight: bold;
    text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.75);
    line-height: 108%;
  }

  ${() =>
    compose(
      variant({
        variants: {
          left: {
            transform: "rotate(-7deg)",
          },
          right: {
            transform: "rotate(7deg)",
          },
        },
      })
    )}
`;


const CardHomeSlider = ({ ...props }: CardHomeSliderProps): JSX.Element => {
  return (
    <Container {...props}>
      <Image
        src={props.uri}
        minHeight={["100%"]}
        minWidth={["100%"]}
        style={{ filter: "lightgrey(50%)" }}
      />
    </Container>
  );
};

export default CardHomeSlider;
