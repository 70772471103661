import eNtranslations from "../locales/en/translations"
import iTtranslations from "../locales/it/translations"


const resources = {
    EN: {
       translation:eNtranslations
    },
    IT: {
        translation:iTtranslations
    }
} 

export default resources;