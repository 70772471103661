import Search from "components/molecules/Search";
import Flex from "components/primitives/Flex";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import imageDefault from "../assets/img/image-community-default.svg";
import Image from "components/primitives/Image";
import Txt from "components/primitives/Txt";
import Button from "components/primitives/Button";
import { PAGE } from "utils/const/pagePath";
import { useNavigate } from "react-router-dom";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CommunitySearchRequest } from "redux/features/community/CommunityTypes";
import { FollowButton } from "components/molecules/FollowButton";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import useIntersection from "utils/hooks/useIntersection";
import { communityActions } from "redux/features/community/communitySlice";
import Section from "components/primitives/Section";
import Lottie from "lottie-light-react";
import loader from "assets/lotties/loader.json";
import Box from "components/primitives/Box";
import { ErrorComponent } from "components/molecules";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { H1, H6, debug1 } from "theme/theme.styled";
import { CustomThemeContext } from "context/ThemeContext";
import bratz from '/images/community-bratz.png'

export function CommunityPage() {
  const { customTheme } = useContext(CustomThemeContext);
  //hook assignment
  const bottom = useRef<HTMLDivElement | null>(null);
  const inViewport = useIntersection(bottom, "10px");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = customTheme.colors;
  const { register, watch } = useForm<{ search: string }>();
  const { t } = useTranslation();
  //context data
  const { isMobile } = useContext(AppGeneralContext);
  //selectors

  const communityUserList = useAppSelector((state) => state.community.CommunityUserList.data?.Data);
  const pager = useAppSelector((state) => state.community.CommunityUserList.data?.Pager);
  const { isLoading: isFetchingList, errors } = useAppSelector((state) => state.community.CommunityUserList);

  //useState data
  const [searchParams, setSearchParams] = useState<CommunitySearchRequest | undefined>();

  //first Call
  useEffect(() => {
    dispatch(communityActions.getCommunityUserList({ PageNum: 1, PageSize: 10 }));
    setSearchParams({ PageNum: 1, PageSize: 10 });
  }, []);

  //handler Function
  const handleSearch = (searchText: string) => {
    const req: CommunitySearchRequest = {
      SearchText: searchText,
      PageNum: 1,
      PageSize: 10,
      isFiltering: true,
    };

    dispatch(communityActions.getCommunityUserList(req));
    setSearchParams({ ...req, SearchText: undefined });
  };

  const handleNavigate = (idUser: string) => {
    //TODO: Incorporate the path /:id for receiving the idUser
    navigate(`${PAGE.profilePreview.menuPath}${idUser}`);
  };

  //Infinite Scroll
  useEffect(() => {
    if (inViewport?.isVisible) {
      if (!pager?.TotalPages || pager?.PageNum >= pager?.TotalPages) {
        return;
      }
      let newPage = pager?.PageNum + 1;
      dispatch(communityActions.getCommunityUserList({ ...searchParams, PageNum: newPage, isFiltering: false }));
      setSearchParams((prev) => ({ ...prev, PageNum: newPage }));
    }
  }, [inViewport.isVisible]);

  //set searchText
  useEffect(() => {
    if (watch("search") === "") {
      return;
    }
    setSearchParams((prev) => ({ ...prev, SearchText: watch("search") }));
  }, [watch("search")]);

  const reloadCommunityPage = () => {
    dispatch(communityActions.getCommunityUserList({ PageNum: 1, PageSize: 10 }));
    setSearchParams({ PageNum: 1, PageSize: 10 });
  };

  return (
    <Flex marginTop={isMobile ? 100 : 3} width={[1]} maxWidth={theme.maxWidth[0]} flexDirection={["column"]} alignItems={["center"]} padding={["2em", "3em", "5em"]} marginBottom={"5em"}>
      <Flex flexDirection={"column"} justifyContent={"start"} width={"100%"}>
        <Txt variant={"bold"} fontSize={["40px", "90px", "100px"]} lineHeight={1.2} width={"100%"} textAlign={"start"}>
          {t(i18Enum.Community_ComingSoon_Desc1)}
        </Txt>
        <Txt variant={"bold"} fontSize={["40px", "90px", "100px"]} lineHeight={1.2} width={"100%"} textAlign={"start"}>
          {t(i18Enum.Community_ComingSoon_Desc2)}
        </Txt>

        <Txt variant={"medium"} fontSize={[4, "20px"]} width={"100%"} textAlign={"start"} $textTransform='inherit' marginTop={3}>
          {t(i18Enum.Community_ComingSoon_Desc3)}
        </Txt>

        <Flex justifyContent={"center"}>
          <Image src={bratz} objectFit='cover' width={["100%", "100%", "80%"]} margin={5} />
        </Flex>

        <Flex justifyContent={"center"}>
          <Button width={["60%", "50%", "30%"]} variant='primary' padding={["10px", "10px 20px"]} borderRadius={"50px"} onClick={() => navigate(PAGE.register.menuPath)}>
            <Txt width={"90%"} $cursor='pointer'>
              {t(i18Enum.Community_ComingSoon_Button)}
            </Txt>
          </Button>
        </Flex>
      </Flex>

      {/* TODO: riattivare nella v2
      <Flex marginTop={isMobile ? 100 : 3} width={[1]} maxWidth={theme.maxWidth[0]} flexDirection={["column"]} alignItems={["center", "left"]} padding={"0 1.5em"} marginBottom={"10em"}>
       <Search
        width={[1, "500px"]}
        placeholder={t(i18Enum.Community_Label_SearchUser)}
        short={true}
        isfilter={false}
        isFocused={true}
        control={register("search")}
        value={watch("search")}
        isOpen={false}
      />
      <Box width={"min-content"} padding={"30px 0"}>
        <Button variant={"success"} fontSize={[3]} fontWeight={500} width={["236px"]} height={["43px"]} onClick={() => handleSearch(watch().search)}>
          {t(i18Enum.Common_Search)}
        </Button>
      </Box>
      {errors !== undefined && errors !== "" ? (
        <ErrorComponent errors={errors} onClickHandler={reloadCommunityPage} />
      ) : (
        <Flex as={"ul"} width={[1, "500px"]} $gap={1} flexDirection={["column"]} marginTop={"2em"}>
          {!communityUserList?.length && !isFetchingList && t(i18Enum.Community_Search_Error)}
          {communityUserList?.map((user) => (
            <Flex as='li' key={user.Id} width={[1]} alignItems={"center"} $gap={2}>
              <Image src={user.ImageUrl ? user.ImageUrl : imageDefault} width={54} $aspectRatio='1' borderRadius={"50%"} style={{ cursor: "pointer" }} onClick={() => handleNavigate(user.Id)} />
              <Flex flexDirection={"column"} alignItems={"start"} flex={1} onClick={() => handleNavigate(user.Id)}>
                <Txt variant='medium' fontSize={[2]} $cursor='pointer'>
                  {`${user.GivenName} ${user.FamilyName}`}
                </Txt>
                <Txt variant='medium' fontSize={[2]} color={theme.colors.primaryText} $textTransform='inherit' $cursor='pointer'>
                  {user.Username}
                </Txt>
              </Flex>
              <FollowButton isFollowed={user.IsFollowing} id={user.Id} />
            </Flex>
          ))}
        </Flex>
      )}
      <Section
        ref={bottom}
        width={["50%", "20%"]}
        maxWidth={[null, theme.maxWidth[0]]}
        padding={["5% 0"]}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"start"}
        position={"relative"}
      >
        {isFetchingList && <Lottie animationData={loader} autoPlay />}
      </Section> 
      </Flex>*/}
    </Flex>
  );
}
