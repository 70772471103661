import ArrowSvg from "components/atoms/ArrowSvg";
import Flex from "components/primitives/Flex";
import Section from "components/primitives/Section";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import outfits from "api/mock/outfits.json";
import Box from "components/primitives/Box";
// import { CartCardBody } from 'components/atoms/CartCardBody'
// import Button from 'components/primitives/Button'
import Marquee from "react-fast-marquee";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
// import { CardType } from 'api/types/responses/cardsRequest'
// import { toastType } from 'utils'
import { ErrorNoItems } from "components/molecules/ErrorNoItems";
import { PAGE } from "utils/const/pagePath";
import { Cart } from "./Cart";
import { VariantDTO } from "redux/features/product/ProductsTypes";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

//TODO verificare adattamento pagina
export const BuyOutfit = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { isMobile, asideParams, /*addToast,*/ setAsideIsOpen, openAsideMenu } =
    useContext(AppGeneralContext);
  const color = customTheme.colors;
  const maxW = customTheme.maxWidth;
  const { id } = useParams();
  const { t } = useTranslation();
  const outfit = useMemo(
    () =>
      asideParams
        ? outfits.data.find((x) => x.id === asideParams)
        : outfits.data.find((x) => x.id === id),
    [id]
  );
  const [outfitInCart, setOutfitInCart] = useState<VariantDTO[]>([]);
  //riabilitare con array giusto
  //  const [outfitInCart, setOutfitInCart] = useState<CardType[]>(outfit?.products ?? [])
  const [counter, setCounter] = useState<number>(5);

  const total = useMemo(() => {
    let total = 0;
    for (let index = 0; index < outfitInCart.length; index++) {
      const add = outfitInCart[index].discountedPrice
        ? outfitInCart[index].discountedPrice
        : outfitInCart[index].price;
      total = total + (add ?? 0);
    }
    return total.toFixed(2);
  }, [outfitInCart]);

  const navigate = useNavigate();

  // function deleteProduct(id: string) {
  //     let newOutfitInCart = outfitInCart.filter(x => x.Id !== id)
  //     let itemRemoved = outfitInCart.find(x => x.Id === id)
  //     setOutfitInCart(newOutfitInCart)
  //     addToast(toastType.warning, t(i18Enum.Message_ItemRemoved));
  // }

  function addToCart() {
    //TODO collegare carrello
    if (isMobile) {
      navigate(PAGE.cart.menuPath);
    }
    openAsideMenu<undefined>(<Cart />, undefined);
  }

  useEffect(() => {
    if (outfitInCart.length !== 0) {
      return;
    }

    if (counter > 0) {
      setTimeout(() => {
        setCounter((x) => x - 1);
      }, 1000);
      return;
    }

    if (isMobile) {
      navigate(-1);
    } else {
      setAsideIsOpen(false);
    }
  }, [counter, outfitInCart.length]);

  return (
    <Section
      paddingTop={["80px", "80px", 0]}
      width={[1]}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"start"}
    >
      <Flex
        padding={["0 5%", "0 5%"]}
        marginBottom={[4]}
        maxWidth={maxW[0]}
        paddingTop={4}
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Flex width={[null, 1]} onClick={() => navigate(-1)}>
          <ArrowSvg width={[40, 30]} />{" "}
          {!isMobile && (
            <Txt paddingLeft={10} variant="link" color={color.primaryBase}>
              {t(i18Enum.Common_Back)}
            </Txt>
          )}
        </Flex>
        <Txt
          width={[1]}
          textAlign={"center"}
          paddingLeft={10}
          variant="light"
          color={color.thirdBase}
        >
          {i18Enum.Product_SelectSize}
        </Txt>
        <Box width={[null, 1]} />
      </Flex>
      {isMobile && (
        <Marquee direction="left" loop={0} style={{ width: "100%" }}>
          <Txt as={"h2"} width={[1]} color={color.primaryBase}>
            {outfit?.title.repeat(10)}
          </Txt>
        </Marquee>
      )}
      <Flex
        padding={["0 5%", "1em 5%"]}
        minHeight={"60vh"}
        maxWidth={maxW[0]}
        width={[1]}
        flexDirection={["column", "row"]}
        flexWrap={"wrap"}
        justifyContent={["start", "center"]}
      >
        {outfitInCart.length === 0 ? (
          <ErrorNoItems
            message={t(i18Enum.Cart_NothingInYourCart)}
            countDownText={t(i18Enum.Cart_Checkout_Label)}
            arrayLenght={outfitInCart.length}
            hasCountDown
          />
        ) : (
          outfitInCart?.map((prod, index) => {
            //TODO gestire caso in cui è stata selezionata la size e in cui al click sul salva  una delle size nn è selezionata
            // let sizeSelected = undefined;
            // let validation = undefined;
            return null;
            // <CartCardBody
            //     variant={
            //         sizeSelected && validation ? "success" :
            //             sizeSelected == undefined || validation == undefined ? "default" :
            //                 "error"
            //     }
            //     key={prod.Id} margin={["10px 0 ", 1]}
            //     width={["100%"]}
            //     deleteItem={() => deleteProduct(prod.Id??'')}
            //     background={prod?.Images?.[0].Url??''}>
            //     <Flex flexDirection={"column"} alignItems={"start"} padding={[4]}>
            //         <Txt textAlign={"start"} fontFamily={"BauPro"} fontSize={[3]}>{''}</Txt>
            //         <Txt textAlign={"start"} fontFamily={"BauProMedium"} fontSize={[2]}>{prod.Section}</Txt>
            //         <Flex width={[1]} >
            //             <Txt $textDecoration={prod.DiscountPrice ? "line-through" : " none"} color={prod.DiscountPrice ? color.thirdBase : color.primaryBase}>{prod.Price}€</Txt>
            //             {prod.DiscountPrice && <Txt paddingLeft={[2]} color={color.primaryBase}>{prod.DiscountPrice}€</Txt>}
            //         </Flex>
            //         <Txt paddingTop={[3]} fontFamily={"BauPro"} fontSize={[3]}>SELECT YOUR SIZE</Txt>
            //         <Flex flexWrap={"wrap"} minWidth={[null, "200px"]}>
            //             {prod.Size?.map((x, i) => {
            //                 //TODO gestire caso in cui è stata selezionata la size
            //                 let selectedIndex = 0
            //                 return (
            //                     <Button
            //                         width={"auto"}
            //                         fontSize={[2, 3]} variant={selectedIndex === i ? 'optionSuccess' : 'optionGray'}
            //                         padding={["3px 10px", "5px 15px"]}
            //                         marginTop={[2]}
            //                         marginRight={[2]}
            //                         key={x + Math.random()}
            //                         onClick={() => { selectedIndex = i }}
            //                     >{x}</Button>
            //                 )
            //             })}
            //         </Flex>
            //     </Flex>
            // </CartCardBody>
          })
        )}
      </Flex>
      {!isMobile && <Box height={"130px"}></Box>}
      <MobileNavigationBottom
        text={t(i18Enum.Common_Next)}
        variant="cart"
        description={`${t(i18Enum.Common_Total)}: ${total}€`}
        buttonAction={() => addToCart()}
      />
    </Section>
  );
};
