import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomThemeContext } from "context/ThemeContext";
import Section from "components/primitives/Section";
import Box from "components/primitives/Box";
import ArrowSvg from "components/atoms/ArrowSvg";
import Txt from "components/primitives/Txt";
import Flex from "components/primitives/Flex";
import {
  MultiColorKeyGradient,
  VariantColor,
  colorsKeyEnum,
  priceNum,
} from "utils";
import SelectColor from "components/molecules/SelectColor";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import { CartCardBody } from "components/atoms/CartCardBody";
import { ErrorNoItems } from "components/molecules/ErrorNoItems";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CartShipping } from "./CartShipping";
import { PAGE } from "utils/const/pagePath";
import { useAppSelector } from "redux/app/hooks";
import { useDispatch } from "react-redux";
import { cartActions } from "redux/features/cart/cartSlice";
import { UpdateBasketRequest } from "api/types/requests/cartRequest";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import React from "react";
import BouncingDot from "components/atoms/BouncingDot";
// import GridCard, { CardView } from "components/organisms/GridCard";
import Tooltip from "components/primitives/Tooltip";
// import { filterItems } from "utils/functions/filterItems";

export const Cart = () => {
  //context data
  const { customTheme } = useContext(CustomThemeContext);
  const { isMobile, openAsideMenu, setAsideIsOpen } =
    useContext(AppGeneralContext);
  const { t } = useTranslation();
  //hook assignment
  const colors = customTheme.colors;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //selector
  const userId = useAppSelector((state) => state.user.user.data?.id);
  const { isLoading, data: cartData } = useAppSelector(
    (state) => state.cart.cart
  );
  const shops = useAppSelector(
    (state) => state.cart.cart.data?.ShoppingBasket?.shops
  );
  // const totalPrice = useAppSelector(
  //   (state) => state.cart.cart.data?.ShoppingBasket?.TotalPrice
  // );
  const processingProducts = useAppSelector(
    (state) => state.cart.cart.data?.ShoppingBasket?.processingProducts
  );
  // const similarProducts = useAppSelector(
  //   (state) => state.cart.cart.data?.RelatedProducts
  // );
  const shoppingBasketShops = useAppSelector(
    (state) => state.cart.cart.data?.ShoppingBasket?.shops
  );

  //TODO: reenable
  // useEffect(() => {
  //   if (!similarProducts) {
  //     return;
  //   }
  //   const viewItemList = similarProducts?.map((product) => {
  //     return {
  //       item_id: product.id,
  //       item_name: product.name,
  //       item_brand: product.shop.name,
  //       // index: product.Order,
  //       // item_variant: product.Variants?.[0].Sku,
  //     };
  //   });

  //   window.dataLayer.push({ ecommerce: null });
  //   window.dataLayer.push({
  //     event: "view_item_list",
  //     ecommerce: {
  //       items: viewItemList,
  //     },
  //   });

  //   console.log({ viewItems: viewItemList });
  // }, []);

  // useEffect(() => {
  //   if (!shoppingBasketShops || !totalPrice) {
  //     return;
  //   }

  //   const viewCartItems = shoppingBasketShops
  //     ?.map((shop) =>
  //       shop.Variants.map((product) => {
  //         return {
  //           item_brand: shop.Name,
  //           item_id: product.Id,
  //           item_name: product.ProductName,
  //           price: product.DiscountedPrice ?? product.Price,
  //           quantity: product.Quantity,
  //         };
  //       })
  //     )
  //     .flat();

  //   window.dataLayer.push({ ecommerce: null });
  //   window.dataLayer.push({
  //     event: "view_cart",
  //     ecommerce: {
  //       currency: "EUR",
  //       value: totalPrice,
  //       items: viewCartItems,
  //     },
  //   });

  //   console.log({ viewCart: viewCartItems });
  // }, [shoppingBasketShops, totalPrice]);

  //booleanValue

  const [confirmMode, setConfirmMode] = useState<null | string>(null);
  const userLogged = useMemo(() => {
    return !!userId;
  }, [userId]);

  //handle functions
  const handleQuantityChange = (
    productBasketId: string,
    newQuantity: number,
    isQuantityIncreased: boolean,
    productId: string
  ) => {
    if (newQuantity === 0) {
      setConfirmMode((prev) =>
        productBasketId === prev ? null : productBasketId
      );
    } else {
      const req: UpdateBasketRequest = {
        shoppingBasketItemId: productBasketId,
        quantity: newQuantity,
      };
      dispatch(cartActions.updateShoppingBasketAction(req));
    }

    // TODO: reeenable
    // const items = filterItems(
    //   shoppingBasketShops,
    //   newQuantity,
    //   isQuantityIncreased
    // );
    // if (isQuantityIncreased) {
    //   const addToCartItem = items?.filter((item) => item.item_id === productId);

    //   window.dataLayer.push({ ecommerce: null });
    //   window.dataLayer.push({
    //     event: "add_to_cart",
    //     ecommerce: {
    //       currency: "EUR",
    //       value: addToCartItem?.[0].price,
    //       items: addToCartItem,
    //     },
    //   });

    //   console.log({ add: addToCartItem }, addToCartItem?.[0].price);
    // } else {
    //   const removeFromCartItem = items?.filter(
    //     (item) => item.item_id === productId
    //   );

    //   window.dataLayer.push({ ecommerce: null });
    //   window.dataLayer.push({
    //     event: "remove_from_cart",
    //     ecommerce: {
    //       currency: "EUR",
    //       value: removeFromCartItem?.[0].price,
    //       items: removeFromCartItem,
    //     },
    //   });
    //   console.log({ remove: removeFromCartItem });
    // }
  };

  const removeProductsFromCart = (
    productBasketId: string,
    productId: string
  ) => {
    dispatch(cartActions.deleteItemAction(productBasketId));
    //TODO: reenable
    // const items = filterItems(shoppingBasketShops);
    // const removeFromCartItem = items?.filter(
    //   (item) => item.item_id === productId
    // );

    // window.dataLayer.push({ ecommerce: null });
    // window.dataLayer.push({
    //   event: "remove_from_cart",
    //   ecommerce: {
    //     currency: "EUR",
    //     value: removeFromCartItem?.[0].price,
    //     items: removeFromCartItem,
    //   },
    // });
    // console.log({ remove: removeFromCartItem });
  };

  const getShopsTotalPrice = () => {
    const arrayPrice: number[] = [];
    shoppingBasketShops?.map((shop) => {
      arrayPrice.push(parseFloat(shop.totalDiscountedPrice));
      return arrayPrice;
    });
    const sum = arrayPrice.reduce((partialSum, a) => partialSum + a, 0);
    return sum;
  };

  //navigation Function
  const handleCloseAside = () => {
    if (isMobile) {
      navigate(-1);
    }

    setAsideIsOpen(false);
    return;
  };

  function next() {
    if (!shops || shops.length === 0) {
      return;
    }

    if (!userLogged) {
      setAsideIsOpen(false);
      navigate(PAGE.login.menuPath);
      return;
    }
    // cookies.remove("shoppingBasketId");

    // dispatch(cartActions.getShoppingBasketAction());

    if (isMobile) {
      navigate(PAGE.shipping.menuPath);
    }
    openAsideMenu<undefined>(<CartShipping />, undefined);
    return;
  }

  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"start"}
        marginBottom={"5%"}
        minHeight={[0, null]}
        padding={["12% 0", "5% 0", "0"]}
        width={[1]}
      >
        <Box width={[1]}>
          <Section
            flexDirection={"column"}
            justifyContent={"center"}
            padding={"2em 5%"}
            width={"100%"}
          >
            <ArrowSvg
              widthCustom="s"
              position={"absolute"}
              left={[3]}
              handleClick={() => handleCloseAside()}
            />
            <Txt as={"h3"} variant="bold" color={colors.primaryBase}>
              {t(i18Enum.Cart_Checkout_Title)}
            </Txt>
          </Section>
          <Section
            flexDirection={"column"}
            padding={"2em 5%"}
            alignItems={"center"}
            width={[1]}
          >
            {!shops || shops.length === 0 ? (
              <ErrorNoItems
                message={t(i18Enum.Cart_NothingInYourCart)}
                arrayLenght={0}
                hasCountDown={true}
                countDownText={t(i18Enum.Cart_Checkout_Label)}
              />
            ) : (
              shops.map((shop, index) => {
                return (
                  <React.Fragment key={shop.id + index}>
                    <Txt
                      as={"h3"}
                      width={"100%"}
                      variant="medium"
                      padding={"10px"}
                      textAlign={"left"}
                      color={colors.thirdText}
                      fontSize={[3]}
                    >
                      {`${t(i18Enum.Cart_Shop_Title)}: ${shop.name}`}
                    </Txt>
                    {shop?.items?.map((basketItem) => {
                      const canDecrease = basketItem.quantity > 0;
                      return (
                        <CartCardBody
                          key={basketItem.productVariantId}
                          variant="default"
                          background={
                            basketItem.productVariant?.images?.[0]?.bucketUrl ??
                            ""
                          }
                          isLoading={isLoading}
                          quantity={basketItem.quantity}
                          confirmMessage={confirmMode === basketItem.id}
                          setConfirmMode={setConfirmMode}
                          deleteItem={() =>
                            removeProductsFromCart(
                              basketItem.id ?? "",
                              basketItem?.productVariantId
                            )
                          }
                        >
                          <Flex
                            key={basketItem.productVariantId}
                            $gap={1}
                            textAlign={"start"}
                            justifyContent={"space-between"}
                            flexDirection={"column"}
                            width={1}
                            padding={["0% 5%", "5% 5% 4%"]}
                          >
                            <Txt
                              as={"h3"}
                              width={"90%"}
                              variant="medium"
                              color={colors.thirdText}
                              fontSize={[3]}
                            >
                              {basketItem?.productVariant?.product?.name}
                            </Txt>
                            <Flex width={1} $gap={0.5} alignItems={"center"}>
                              <Txt
                                variant={
                                  basketItem?.productVariant?.discountedPrice &&
                                  basketItem?.productVariant?.discountedPrice >
                                    0
                                    ? "crossed"
                                    : "medium"
                                }
                                color={colors.thirdText}
                                fontSize={[2]}
                                marginRight={[2]}
                              >
                                {basketItem?.productVariant?.price &&
                                  `${priceNum(
                                    basketItem?.productVariant?.price ?? 0
                                  )}€`}
                              </Txt>

                              {basketItem?.productVariant?.discountedPrice &&
                                basketItem?.productVariant?.discountedPrice >
                                  0 && (
                                  <Txt
                                    variant="medium"
                                    color={colors.primaryText}
                                    fontSize={[2, 4]}
                                  >
                                    {`${priceNum(
                                      basketItem?.productVariant
                                        ?.discountedPrice
                                    )}€`}
                                  </Txt>
                                )}
                            </Flex>
                            {basketItem.productVariant?.options &&
                              basketItem.productVariant?.options.map((tag) => {
                                if (tag.type === "COLOR") {
                                  // if (!tag.Tags?.[0]) {
                                  //   return;
                                  // }
                                  return (
                                    <Tooltip
                                      content={tag.label ?? ""}
                                      key={tag.id}
                                    >
                                      <SelectColor
                                        isBlack={
                                          tag.label
                                            ? tag.label === "Black"
                                            : false
                                        }
                                        backgroundImage={
                                          tag.label ===
                                          colorsKeyEnum.Multicolour
                                            ? MultiColorKeyGradient
                                            : "unset"
                                        }
                                        background={VariantColor(
                                          tag?.label ?? ""
                                        )}
                                        selected={true}
                                      />
                                    </Tooltip>
                                  );
                                } else if (tag.type === "SIZE") {
                                  return (
                                    <Txt
                                      key={tag.id}
                                      variant="light"
                                      color={colors.thirdText}
                                      fontSize={[2]}
                                    >
                                      {tag.label ?? ""}
                                    </Txt>
                                  );
                                }
                              })}

                            <Flex justifyContent={"end"}>
                              <Flex
                                justifyContent={"center"}
                                alignItems={"center"}
                                backgroundColor={
                                  canDecrease ? colors.grayBase : colors.gray12
                                }
                                width={"40px"}
                                height={"40px"}
                              >
                                <Txt
                                  fontSize={"30px"}
                                  onClick={() =>
                                    canDecrease &&
                                    handleQuantityChange(
                                      basketItem.id,
                                      basketItem.quantity - 1,
                                      false,
                                      basketItem?.productVariantId
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  width={"100%"}
                                  textAlign={"center"}
                                  color={
                                    canDecrease
                                      ? colors.thirdText
                                      : colors.thirdTextContrast
                                  }
                                >
                                  -
                                </Txt>
                              </Flex>
                              <Flex
                                justifyContent={"center"}
                                alignItems={"center"}
                                backgroundColor={colors.grayBgActive}
                                width={"50px"}
                                height={"40px"}
                              >
                                {isLoading &&
                                processingProducts?.includes(basketItem.id) ? (
                                  <Flex
                                    width={[1]}
                                    height={["35px"]}
                                    justifyContent={"center"}
                                    alignItems={"end"}
                                  >
                                    <BouncingDot color={colors.thirdBase} />
                                  </Flex>
                                ) : (
                                  <Txt
                                    fontSize={"20px"}
                                    color={colors.thirdText}
                                  >
                                    {basketItem.quantity}
                                  </Txt>
                                )}
                              </Flex>
                              <Flex
                                justifyContent={"center"}
                                alignItems={"center"}
                                backgroundColor={colors.grayBase}
                                width={"40px"}
                                height={"40px"}
                              >
                                <Txt
                                  fontSize={"30px"}
                                  onClick={() =>
                                    handleQuantityChange(
                                      basketItem.id,
                                      basketItem.quantity + 1,
                                      true,
                                      basketItem?.productVariantId
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                  width={"100%"}
                                  textAlign={"center"}
                                  color={colors.thirdText}
                                >
                                  +
                                </Txt>
                              </Flex>
                            </Flex>
                          </Flex>
                        </CartCardBody>
                      );
                    })}
                  </React.Fragment>
                );
              })
            )}
          </Section>
          {/* TODO: reenable */}
          {/* {shops?.length !== 0 && similarProducts?.length !== 0 && (
            <Section
              flexDirection={"column"}
              backgroundColor={colors.secondaryBase}
              padding={"5%"}
            >
              <Txt
                textAlign={"start"}
                variant="light"
                color={colors.thirdText}
                fontSize={[2]}
              >
                {t(i18Enum.Product_YouMayAlsoLike)}
              </Txt>

              <Flex
                width={[1]}
                justifyContent={"center"}
                marginLeft={[0, "5%"]}
              >
                <GridCard
                  products={similarProducts}
                  viewType={CardView.cardMobile}
                />
              </Flex>
            </Section>
          )} */}
        </Box>
        <MobileNavigationBottom
          variant="cart"
          disabled={!shops || shops.length === 0}
          cartShippingInfo={`*${t(i18Enum.Cart_Checkout_ShippingNotIncluded)}`}
          text={t(i18Enum.Cart_Checkout_Button)}
          description={`${t(i18Enum.Common_Total)}: ${priceNum(
            getShopsTotalPrice()
          )}€`}
          buttonAction={() => next()}
          isLoading={isLoading}
        />
      </Flex>
    </>
  );
};
