import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Flex from "../components/primitives/Flex";
import Box from "../components/primitives/Box";
import Txt from "../components/primitives/Txt";
import { ToggleWithLabels } from "../components/molecules/ToggleWithLabels";
import { DashedRoundSvg } from "../components/atoms/DashedRoundSvg";
import { MobileNavigationBottom } from "../components/atoms/MobileNavigationBottom";
import { EyeSlashSvg } from "../components/atoms/EyeSlashSvg";
import { EyeSvg } from "../components/atoms/EyeSvg";
import Section from "../components/primitives/Section";
import ArrowSvg from "../components/atoms/ArrowSvg";
import { CustomThemeContext } from "../context/ThemeContext";
import Button from "../components/primitives/Button";
import SelectField from "../components/molecules/SelectField";
import TextareaField from "../components/molecules/TextareaField";
import { AddColor } from "../components/molecules/AddColor";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import { AppGeneralContext } from "context/AppGeneralContext";
// import UploadImage from "components/molecules/UploadImage";
// import { OptionType } from "components/primitives/Select";
import { Avatar } from "components/atoms/Avatar";
import { WardrobeItemType } from "utils/generalTypes/wardrobe";
import { CategoriesTag } from "utils/generalTypes/category";
// import { getFilterTagsCall } from "api/categoriesCall/filterCall";
import React from "react";
import { PAGE } from "utils/const/pagePath";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";


const initialState: WardrobeItemType = {
  imageUrl: "",
  nameProduct: "",
  description: "",
  categories: [],
  colors: [],
  isVisible: true
}

export const WardrobeAddWear = () => {
  const navigate = useNavigate();
  const { isMobile, asideParams , setAsideIsOpen, filterList: categoriesTag} = useContext(AppGeneralContext);
  const state = useParams()
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const data: WardrobeItemType | undefined = useMemo(() => isMobile ? state : asideParams, [isMobile])
  const [form, setForm] = useState<WardrobeItemType>(data ?? initialState);
  const [chooseImage, setChooseImage] = useState(false);
  const [newImage, setNewImage] = useState<string | null>();
  const [categories, setCategories] = useState<CategoriesTag[]>([]);
  const genderOptions = useMemo(() => categories.filter(x => x.parentKey === null), [categories])
  // const categoryOptions = useMemo(() => categories.filter(x => x.parentKey !== null), [categories])
  const [categoryLevels, setCategoryLevels] = useState<CategoriesTag[][]>([]);
  const { t } = useTranslation();
 
  function save (){
    //TODO salvatggio
    setAsideIsOpen(false)
  }

  useEffect(() => {
    if(!isMobile){
      navigate(PAGE.wardrobe.menuPath)
    }
  }, [])


  //creo il primo livello di categorie
  function CreateCategoriesFirstLevel(genderKey: number) {
      const firstLevelCategories = categories.filter(x => x.parentKey === genderKey)
      setCategoryLevels([[...firstLevelCategories]])
  }
  //creo i sottolivelli
  function CreateCategoriesLevel(optionKey: number){
        const newLevel = categories.filter(x => x.parentKey === optionKey)
        if(newLevel.length === 0){
          return
        }
        setCategoryLevels(x => [...x, newLevel])
  }



  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };





  const handleChangeGender = (option: CategoriesTag) => {
    setForm({ ...form, gender: option, categories: [option] });
    CreateCategoriesFirstLevel(option.key)
  };

  const handleChangeCategory = (option: CategoriesTag, index: number) => {
    if(form.categories?.[index + 1] && Object.values(form.categories?.[index + 1]).length !== 0 ){
       const deleteCategories = form.categories?.splice(index + 1 , form.categories.length)
       const newCategories = [...deleteCategories]
       setForm( {...form, categories: newCategories});
       const newCategoryLevels =  categoryLevels.splice(index - 1 , categoryLevels.length)
       setCategoryLevels(newCategoryLevels)
    }

    setForm({ ...form, categories: form.categories ? [...form.categories, option] : [option] });
    CreateCategoriesLevel(option.key)
  };

  // const handleUpload = (img: string) => {
  //   setChooseImage(false);

  //   if (img) {
  //     setNewImage(img);
  //   }
  // };

  const showSelects = useMemo(()=>{
   return (
    categoryLevels.map((x, i) => {

      if (form.categories?.[i]?.key === undefined) {
        return <></>
      }

      const value = form.categories?.[i + 1]
      return (
      <SelectField<CategoriesTag>
      handleBlur={()=>{}/**da implementare con formik */}
          key={i}
          id='category'
          placeholder='category'
          option={x}
          value={value ? value : null}
          labelExtractor={(x) => x.value}
          valueExtractor={(x) => x.key.toString()}
          handleChange={(option) => handleChangeCategory(option, i)}
        />
        )
    }))

  },[categoryLevels, form.categories])

  return (
    <>
      <Box margin={["10% 0", "0"]} height={"100%"}>
        <Section flexDirection={"column"}>
          {!chooseImage ? (
            <>
              {isMobile && (
                <Section padding={"2em 1em"} $gap={2}>
                  <ArrowSvg widthCustom='s' top={["10vh"]} handleClick={() => navigate(-1)} />
                  <Txt as={"h3"} variant='light' color={colors.thirdText}>
                    {!form?.id ? "Add" : "Edit"} wear
                  </Txt>
                </Section>
              )}

              <Section flexDirection={"column"} alignItems={"center"} $gap={3} marginBottom={"3em"}>
                <ToggleWithLabels
                  firstLabel='PRIVATE'
                  firstIcon={<EyeSlashSvg />}
                  secondIcon={<EyeSvg variant='white' />}
                  secondLabel='PUBLIC'
                  handleCheck={(value) => console.log("toggle with labels", value)}
                />

                <Flex flexDirection={"column"} maxWidth={"150px"} $gap={1.5}>
                  {form || newImage ? <Avatar imageUrl={newImage ? newImage : form?.imageUrl} /> : <DashedRoundSvg callback={() => console.log("add wear!")} />}

                  <Button type='button' variant='primary' padding={["12px 20px 9px"]} fontSize={[3, 2]} width={"100%"} onClick={() => setChooseImage(true)}>
                    Choose image
                  </Button>
                </Flex>
              </Section>

              <Section justifyContent={"center"}>
                <Flex flexDirection={"column"} minWidth={"300px"} width={["90%", "60%"]} $gap={3}>
                  <InputFieldWardrobe spaceProp={{ width: [1] }} id='nameProduct' name='nameProduct' placeholder='title' label={"title"} value={form?.nameProduct} onChange={(e) => handleChange(e)} />

                  <SelectField<CategoriesTag>
                    handleBlur={() => {} /**da implementare con formik */}
                    id='gender'
                    placeholder='gender'
                    option={genderOptions}
                    value={form.gender}
                    labelExtractor={(x) => x.value}
                    valueExtractor={(x) => x.key.toString()}
                    handleChange={(option) => handleChangeGender(option)}
                  />

                  {showSelects}

                  <TextareaField
                    variant='description'
                    spaceProp={{ width: [1] }}
                    id='description'
                    name='description'
                    placeholder='description'
                    label={"description"}
                    value={form?.description}
                    onChange={(e) => handleChange(e)}
                  />

                  <AddColor id='colors' label='colors' selectedColors={form?.colors?.length ? form?.colors : undefined} />
                </Flex>
              </Section>
            </>
          ) : (
            <Section justifyContent={"center"}>
              {/* TODO verificare i problemi */}
              {/* <UploadImage  onClose={() => handleUpload()} /> */}
            </Section>
          )}
        </Section>
      </Box>

      {!chooseImage && <MobileNavigationBottom text={t(i18Enum.Common_Save)} description={form.nameProduct ? form.nameProduct : "new dress"} buttonAction={() => save()} />}
    </>
  );
};
