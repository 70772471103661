import { PropsWithChildren, useContext, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import CrossSvg from "components/atoms/CrossSvg";
import Flex from "components/primitives/Flex";
import { AppGeneralContext } from "context/AppGeneralContext";

import useOutsideClick from "utils/hooks/useOutsideClick";

const Sidebar: React.FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { asideIsOpen, setAsideIsOpen, asideChildren, closeAndResetAside } =
    useContext(AppGeneralContext);

  const sidebarVariants = {
    open: { opacity: 1, x: 0, transition: { type: "tween" } },
    closed: { opacity: 0, x: "-100%", transition: { type: "tween" } },
  };

  const handleClose = () => {
    setAsideIsOpen((x) => !x);
    closeAndResetAside();
  };

  useOutsideClick(
    ref,
    () => {
      setAsideIsOpen((x) => !x);
      closeAndResetAside();
    },
    asideIsOpen
  );

  return (
    <AnimatePresence>
      {asideIsOpen && (
        <Flex
          zIndex={99}
          position={"absolute"}
          top={0}
          left={0}
          backgroundColor={"rgba(0, 0, 0, 0.5)"}
          width={"100%"}
          height={"100vh"}
        >
          <motion.div
            id="aside"
            style={{
              backgroundColor: "#1d1d1d",
              width: "700px",
              height: "calc(100vh - 80px)",
              position: "fixed",
              top: "80px",
              left: "0",
              zIndex: "100",
              overflowY: "auto",
              overflowX: "hidden",
              boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.7)",
            }}
            initial="closed"
            animate="open"
            exit="closed"
            variants={sidebarVariants}
          >
            <Flex
              position={"relative"}
              height={"100%"}
              width={1}
              flexDirection={"column"}
              alignItems={"center"}
              ref={ref}
            >
              <Flex
                position={"relative"}
                width={1}
                padding={3}
                justifyContent={"right"}
              >
                <CrossSvg handleClick={handleClose} />
              </Flex>
              {children}
              {asideChildren}
            </Flex>
          </motion.div>
        </Flex>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;
