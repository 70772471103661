import { AppGeneralContext } from "context/AppGeneralContext";

import { useContext } from "react";
import { useAppSelector } from "redux/app/hooks";
import styled, { keyframes } from "styled-components";
import {
  HeightProps,
  WidthProps,
  space,
  SpaceProps,
  PositionProps,
  position,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  BorderProps,
  TypographyProps,
} from "styled-system";
import { toastType } from "utils";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { useDispatch } from "react-redux";
import { UserActions } from "redux/features/user/userSlice";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

interface SVGProps extends WidthProps, HeightProps, SpaceProps, PositionProps {
  state: boolean;
  handleClick: () => void;
  $transform?: string;
  productId?: string;
}

const SVGCont = styled.div<
  ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    PositionProps &
    BorderProps &
    TypographyProps & { $gap?: number } & { $aspectRatio?: string } & {
      $transition?: string;
    } & { $backgroundImage?: string } & { $blur?: boolean }
>`
  width: ${(props) => props.width || "23px"};
  height: ${(props) => props.height || "20px"};
  cursor: pointer;
  ${position}
  ${space}
`;

const StyledSVG = styled.svg<SVGProps>`
  cursor: pointer;
  width: ${(props) => props.width || "23px"};
  height: ${(props) => props.height || "20px"};
`;

export const pulse = keyframes`
from{
  opacity: 1;
}
to {
    opacity: 0.1;
  }
`;
const AnimatedSVGPath = styled.path<SVGProps>`
  fill: ${(props) =>
    props.state
      ? props.theme.colors.primaryBase
      : props.theme.colors.thirdBase};
  stroke: black;
  stroke-width: 2;
  transform: ${({ $transform }) => $transform || "rotate(-3deg)"};
  transition: fill 0.3s ease-out;
  animation: ${pulse} 0.8s infinite alternate;
  animation-delay: 0.1s;
`;
const SVGPath = styled.path<SVGProps>`
  fill: ${(props) =>
    props.state
      ? props.theme.colors.primaryBase
      : props.theme.colors.thirdBase};
  stroke: black;
  stroke-width: 2;
  transform: ${({ $transform }) => $transform || "rotate(-3deg)"};
  transition: fill 0.3s ease-out;
`;

export const WishListHeartSvg = ({ ...props }: SVGProps) => {
  const { data: user } = useAppSelector((state) => state.user.user);
  const { addToast } = useContext(AppGeneralContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleWishlisted = async (productId: string | undefined) => {
    if (!user || user.id === "") {
      navigate(PAGE.login.menuPath);
      return;
    }

    if (!productId) {
      addToast(toastType.warning, t(i18Enum.ErrorPage500_ErrorLabel));
      return;
    }

    if (props.state) {
      dispatch(UserActions.removeProductWishListAction(productId));
    } else {
      dispatch(UserActions.addProductWishListAction(productId));
    }
    //   dispatch(getWishListAction());

    props.handleClick();
  };

  return (
    <SVGCont
      {...props}
      border={"1px solid red"}
      onClick={() => {
        handleWishlisted(props?.productId);
      }}
    >
      <StyledSVG
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23 20"
        {...props}
      >
        <SVGPath
          {...props}
          d="M10.5536 4.34851L11.334 5.80452L12.2622 4.43802C13.5377 2.56027 15.8618 1.93931 17.8903 2.46834C19.8805 2.98741 21.4129 4.55903 21.2853 6.99365C21.2025 8.57357 20.1383 10.3147 18.1778 12.3675C16.442 14.1851 14.1574 16.0955 11.621 18.2166C11.3271 18.4623 11.0298 18.7109 10.7296 18.9626L10.7282 18.9637C10.7113 18.9779 10.6824 18.9906 10.643 18.9885C10.6035 18.9865 10.5762 18.9709 10.5608 18.955L10.5596 18.9537C10.2886 18.6733 10.02 18.3962 9.75446 18.122C7.46056 15.7544 5.3876 13.6149 3.84888 11.6232C2.11057 9.37314 1.22999 7.52569 1.31273 5.94693C1.43992 3.52015 3.12592 2.10863 5.15727 1.79447C7.22441 1.47479 9.47093 2.32847 10.5536 4.34851Z"
        />
      </StyledSVG>
    </SVGCont>
  );
};
