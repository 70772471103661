import styled from "styled-components";
import {
  ColorProps,
  SpaceProps,
  WidthProps,
  HeightProps,
  compose,
  space,
  variant,
  width,
  color,
  height,
  typography,
  layout,
  border,
  fontSize,
  position,
  TypographyProps,
} from "styled-system";

export interface InputProps {
  variant?: "search" | "primary" | "color" | "checkboxText";
  private?: "text" | "date";
}

const Input = styled.input<
  InputProps & ColorProps & SpaceProps & WidthProps & HeightProps & TypographyProps
>`
  border-radius: 50px;
  border: none;
  margin: 0;
  padding: 0;

  &::placeholder {
    text-transform: uppercase;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:disabled:hover {
    opacity: 0.5;
    cursor: default;
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: inherit !important;
  }

  ${(props) =>
    compose(
      variant({
        variants: {
          primary: {
            fontFamily: "BauPro",
            textTransform: "uppercase",
            backgroundColor: props.theme.colors.grayBase,
            color: props.theme.colors.thirdTextContrast,
            lineHeight: "normal",
          },
          search: {
            fontFamily: "BauPro",
            textTransform: "uppercase",
            backgroundColor: "transparent",
            color: props.theme.colors.primaryText,
            fontSize: [2],
            lineHeight: "267%",
            padding: 0,
          },
          color: {
            width: "36px",
            height: "36px",
            border: "none",
            cursor: "pointer",
            "&::-webkit-color-swatch-wrapper": { padding: 0 },
            "&::-webkit-color-swatch": {
              border: `4px solid ${props.theme.colors.primaryBase}`,
              borderRadius: "100%",
            },
          },
          checkboxText: {
            width: "100%",
            height: "26px",
            backgroundColor:"transparent",
            borderColor: props.theme.colors.thirdLine,
            borderStyle: "solid",
            borderWidth: "0 0 1px 0",
            borderRadius: 0,
            cursor: "pointer",
            fontFamily:"BauPro",
            fontSize:"14px",
            color:props.theme.colors.thirdText,
            
          },
        },
      })
    )}
  ${width};
  ${height};
  ${space};
  ${color};
  ${typography};
  ${layout};
  ${border};
  ${fontSize};
  ${position};
`;

export default Input;
