import styled from "styled-components";
import { HeightProps, WidthProps, space, SpaceProps, PositionProps, position, height, width, opacity, OpacityProps } from "styled-system";

interface SVGProps extends WidthProps, HeightProps, SpaceProps, PositionProps, OpacityProps {
  disabled?: boolean;
  colorText?: string;
  handleClick?: () => void;
  text?: string;
}

//customWidth:CSS.Property.Width

/*
in svgprop --> textWidth:

*/
const StyledSVG = styled.svg<SVGProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &rect,
  &path {
    stroke-width: 0px;
  }

  ${space};
  ${position};
  ${height};
  ${width};
  ${opacity}
`;

const SVGPath = styled.path``;

const SVGRect = styled.rect`
  fill: lime;
`;

interface SVGTextProps {
  colorText: string;
}
const SVGText = styled.text<SVGTextProps>`
  fill: ${(props) => props.colorText || props.theme.colors.primaryBase};
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  width: 100%;
`;

function AboutUsSvg({ text, colorText, ...props }: SVGProps) {
  return (
  
      <StyledSVG
        onClick={
          props.disabled
            ? () => {}
            : () => {
                props.handleClick?.();
              }
        }
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 173.37 55.04'
        opacity={props.disabled ? 0 : 1}
        {...props}
     
      >
        <SVGRect width='173.37' height='34.78' />
        <SVGPath d='m35.33,29.72h-4.34V10.87l-6.89,18.85h-2.98l-6.72-18.85v18.85h-4.07V4.78h6.29l6.23,17.16,6.36-17.16h6.13v24.94Z' />
        <SVGPath d='m51.07,4.35c7.55,0,12.49,5.4,12.49,12.78s-4.3,13.02-12.35,13.02-12.59-5.4-12.59-12.95c0-7.15,4.87-12.85,12.45-12.85m-7.58,12.82c0,5.53,2.68,8.84,7.62,8.84s7.58-2.75,7.58-8.91c0-5.2-2.72-8.68-7.62-8.68s-7.58,3.58-7.58,8.74Z' />
        <SVGPath d='m78.2,4.35c7.55,0,12.49,5.4,12.49,12.78s-4.3,13.02-12.35,13.02-12.59-5.4-12.59-12.95c0-7.15,4.87-12.85,12.45-12.85Zm-7.58,12.82c0,5.53,2.68,8.84,7.62,8.84s7.58-2.75,7.58-8.91c0-5.2-2.72-8.68-7.62-8.68s-7.58,3.58-7.58,8.74Z' />
        <SVGPath d='m105.18,4.35c7.55,0,12.49,5.4,12.49,12.78s-4.31,13.02-12.35,13.02-12.58-5.4-12.58-12.95c0-7.15,4.87-12.85,12.45-12.85m-7.58,12.82c0,5.53,2.68,8.84,7.62,8.84s7.58-2.75,7.58-8.91c0-5.2-2.72-8.68-7.62-8.68s-7.58,3.58-7.58,8.74Z' />
        <SVGPath d='m149.63,25.61h15.33v4.1h-19.87V4.78h19.24v4.1h-14.71v6.1h12.49v4.01h-12.49v6.62Z' />
        <SVGPath d='m140.52,24.09c.13,3.08.53,4.57,1.19,5.5v.13h-4.9c-.5-.8-.8-2.05-1.03-5.07-.27-3.54-1.13-5.2-4.87-5.2h-5.43v10.27h-4.54V4.78h11.66c4.74,0,8.68,2.09,8.68,7.05,0,3.58-1.79,5.33-4.5,6.1,2.25.86,3.58,2.82,3.74,6.16m-8.08-8.54c2.92,0,4.31-.93,4.31-3.41,0-2.35-1.82-3.21-3.94-3.21h-7.32v6.62h6.95Z' />
        <SVGText colorText={colorText ?? "#fff"} x={"50%"} y='95%' dominantBaseline='middle' textAnchor='middle' width='173.37' height='55.04'>
          {text}
        </SVGText>
      </StyledSVG>

  );
}

export default AboutUsSvg;
