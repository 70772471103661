import { PropsWithChildren } from "react";
import Flex from "./Flex";
import Txt from "./Txt";
import { H6 } from "theme/theme.styled";

interface ICartInfoWrapper {
  text?: string;
  price?: string | number;
  colorText?: string;
  colorPrice?: string;
}

export const CartInfoWrapper = ({ text, price, colorText, colorPrice }: PropsWithChildren<ICartInfoWrapper>) => {
  return (
    <Flex flexDirection={"column"} $gap={1}>
      <Txt textAlign={"start"} as={"h6"} variant='medium' color={colorText} fontSize={H6}>
        {text}
      </Txt>
      <Txt textAlign={"start"} as={"h6"} variant='light' color={colorPrice} fontSize={H6}>
        {`${price}${text !== "Status" ? "€" : ""}`}
      </Txt>
    </Flex>
  );
};
