import { SpaceProps, WidthProps } from "styled-system";
import Flex from "../primitives/Flex";
import SelectField from "components/molecules/SelectField";
import CalendarField from "components/molecules/CalendarField";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import Box from "components/primitives/Box";
import { OptionNumberType } from "components/primitives/Select";
import { useContext } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import Button from "components/primitives/Button";
import Txt from "components/primitives/Txt";
import * as Yup from "yup";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import { useFormik } from "formik";
import Form from "components/primitives/Form";
import { GenderEnum } from "utils";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export interface FormStepLoginFirst {
  firstName: string;
  lastName: string;
  birthdate?: Date;
  gender?: string;
}

type FormStepFirstSchemaObject = {
  [key in keyof FormStepLoginFirst]: Yup.Schema<any>;
};

const initialFormStepLoginFirst: FormStepLoginFirst = {
  firstName: "",
  lastName: "",
  birthdate: undefined,
  gender: undefined,
};

interface StepLoginFirstFormProps extends WidthProps, SpaceProps {
  onSubmit: (value: FormStepLoginFirst) => void;
  isPrivate: boolean | undefined;
  data?: FormStepLoginFirst | null;
}

function StepLoginFirstForm({
  onSubmit,
  isPrivate,
  data,
  ...props
}: StepLoginFirstFormProps) {
  const { t } = useTranslation();
  const {
    isMobile,
    filterList: categoriesTag,
    genderFilter,
  } = useContext(AppGeneralContext);

  const formStepLoginFirstSchema =
    Yup.object().shape<FormStepFirstSchemaObject>({
      firstName: Yup.string().required(
        t(i18Enum.Validation_Client_Field_Required)
      ),
      lastName: Yup.string().required(
        t(i18Enum.Validation_Client_Field_Required)
      ),
      birthdate: Yup.date().nullable(),
      gender: Yup.string().required(
        t(i18Enum.Validation_Client_Field_Required)
      ),
    });

  const formik = useFormik<FormStepLoginFirst>({
    initialValues: data ? data : initialFormStepLoginFirst,
    validationSchema: formStepLoginFirstSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      onSubmit(values);

      setSubmitting(false);
    },
  });

  return (
    <>
      <Form
        padding={" 5%"}
        width={[1]}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        {...props}
        onSubmit={formik.handleSubmit}
      >
        <Flex
          width={[1, 1, "500px"]}
          padding={["0 5%", "0 0"]}
          flexDirection={"column"}
          maxWidth={"500px"}
          marginBottom={50}
        >
          <InputFieldWardrobe
            {...formik.getFieldProps("firstName")}
            name={"firstName"}
            id={"firstName"}
            autoComplete={"given-name"}
            placeholder={t(i18Enum.User_UserProfile_Label_GivenName)}
            label={"firstName"}
            spaceProp={{ width: [1], marginTop: [4], padding: ["10px 0"] }}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched("firstName", true)}
            value={formik.values.firstName}
            error={
              formik.errors.firstName ? formik.errors.firstName : undefined
            }
          />
          <InputFieldWardrobe
            {...formik.getFieldProps("lastName")}
            name={"lastName"}
            id={"lastName"}
            autoComplete={"family-name"}
            placeholder={t(i18Enum.User_UserProfile_Label_FamilyName)}
            label={"lastName"}
            spaceProp={{ width: [1], marginTop: [3], padding: ["10px 0"] }}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched("lastName", true)}
            value={formik.values.lastName}
            error={formik.errors.lastName ? formik.errors.lastName : undefined}
          />
          <CalendarField
            id="birthdate"
            paddingTop={2}
            paddingBottom={2}
            onBlur={() => formik.setFieldTouched("birthdate", true)}
            placeholder={t(i18Enum.User_UserProfile_Label_BirthDate)}
            isPrivate={isPrivate}
            width={[1]}
            // value={formik.values?.date ? new Date(formik.values.date) : today}
            value={
              formik.values.birthdate ? new Date(formik.values.birthdate) : null
            }
            onChange={(date?: Date) =>
              formik.setFieldValue("birthdate", date?.toISOString())
            }
          />
          {formik.errors.birthdate && (
            <Txt
              padding={"13px 0 0 18px"}
              variant="error"
              fontSize={[3]}
              textAlign={"left"}
            >
              {String(formik.errors.birthdate)}
            </Txt>
          )}
          <SelectField<OptionNumberType>
            zIndex={[0, 0, 1]}
            width={[1]}
            marginTop={"1.2em"}
            id="gender"
            isPrivate={isPrivate}
            placeholder={t(i18Enum.User_UserProfile_Label_Gender)}
            option={genderFilter}
            value={genderFilter.find((x) => x.value === formik.values.gender)}
            handleChange={(opt) => formik.setFieldValue("gender", opt.value)}
            handleBlur={() => {
              formik.setFieldTouched("gender", true);
            }}
            labelExtractor={(x) => x.label}
            valueExtractor={(x) => GenderEnum[x.value]}
          />
          {formik.errors.gender && (
            <Txt
              padding={"13px 0 0 18px"}
              variant="error"
              fontSize={[3]}
              textAlign={"left"}
            >
              {formik.errors.gender}
            </Txt>
          )}
        </Flex>
        {!isMobile && (
          <Flex
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
            maxWidth={"500px"}
          >
            <Txt variant="light" fontSize={[2]}>
              {t(i18Enum.User_UserProfile_Section_Personal)}
            </Txt>
            <Button
              type="submit"
              variant="primary"
              padding={"10px 20px"}
              disabled={!formik.isValid}
            >
              {t(i18Enum.Common_Next)}
            </Button>
          </Flex>
        )}
        <Box marginBottom={5} />
      </Form>
      {isMobile && (
        <MobileNavigationBottom
          text={t(i18Enum.Common_Next)}
          description={t(i18Enum.User_UserProfile_Section_Personal)}
          disabled={!formik.isValid}
          buttonAction={formik.handleSubmit}
        />
      )}
    </>
  );
}

export default StepLoginFirstForm;
