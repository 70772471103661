import { Fragment, PropsWithChildren, useContext } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { CartCardBody } from "components/atoms/CartCardBody";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import SelectColor from "./SelectColor";
import { priceNum } from "utils/functions/stringPrice";
import { H6 } from "theme/theme.styled";
import { CartInfoWrapper } from "components/primitives/CartInfoWrapper";
import { getOrderStatusLabel } from "./Order";
import { OrderDTO, ShippingDTO } from "redux/features/user/UserTypes";
import { MultiColorKeyGradient, VariantColor, colorsKeyEnum } from "utils";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import Tooltip from "components/primitives/Tooltip";
import { OrderStatus } from "utils/graphql/generated/graphql";
interface IOrderSentCard {
  items?: ShippingDTO[];
  order?: OrderDTO;
  variants: "order" | "cart";
}

export const OrderSentCard = ({
  items,
  order,
  variants,
}: PropsWithChildren<IOrderSentCard>) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { t } = useTranslation();

  return (
    <>
      {items &&
        items?.map((orderItem) => (
          <Fragment key={orderItem.id}>
            <CartCardBody
              // opacity={
              //   orderItem.Status === StatusOrder.Deleted ||
              //   orderItem.Status === StatusOrder.Canceled
              //     ? 0.3
              //     : 1
              // }
              variant="orders"
              background={
                orderItem?.productVariant?.images?.[0].bucketUrl ?? ""
              }
              quantity={orderItem.quantity}
              height={"200px"}
            >
              <Flex
                flexDirection={"column"}
                justifyContent={"flex-start"}
                paddingBottom={[3]}
                width={"80%"}
              >
                <Txt
                  textAlign={"start"}
                  as={"h6"}
                  variant="medium"
                  color={colors.thirdText}
                  fontSize={H6}
                  margin={"0 0 5% 0"}
                >
                  {orderItem.productVariant?.product?.name}
                </Txt>

                <Flex $gap={0.5} margin={"0 0 10% 0"}>
                  <Txt
                    variant={
                      orderItem?.productVariant?.discountedPrice &&
                      orderItem?.productVariant?.discountedPrice > 0
                        ? "crossed"
                        : "medium"
                    }
                    color={colors.thirdText}
                    fontSize={[2]}
                  >
                    {`${priceNum(orderItem?.productVariant?.price ?? 0)}€`}
                  </Txt>

                  {orderItem?.productVariant?.discountedPrice &&
                    orderItem?.productVariant?.discountedPrice > 0 && (
                      <Txt
                        variant="medium"
                        color={colors.primaryText}
                        fontSize={[2]}
                      >
                        {`${priceNum(
                          orderItem.productVariant?.discountedPrice
                        )}€`}
                      </Txt>
                    )}
                </Flex>
                <Flex $gap={1} alignItems={"center"} minHeight={"50px"}>
                  {orderItem?.productVariant?.options &&
                    orderItem.productVariant.options.map((option) => {
                      if (
                        option.type == "COLOR" &&
                        VariantColor(option.label)
                      ) {
                        return (
                          <Tooltip content={option.label ?? ""} key={option.id}>
                            <SelectColor
                              isBlack={option.label === "Black"}
                              backgroundImage={
                                option.label === colorsKeyEnum.Multicolour
                                  ? MultiColorKeyGradient
                                  : "unset"
                              }
                              background={VariantColor(option.label ?? "")}
                              selected={true}
                            />
                          </Tooltip>
                        );
                      } else {
                        return (
                          <Txt
                            key={option.id}
                            variant="light"
                            color={colors.thirdText}
                            fontSize={[2]}
                          >
                            {option.label}
                          </Txt>
                        );
                      }
                    })}
                </Flex>
              </Flex>
            </CartCardBody>
          </Fragment>
        ))}
      <Flex
        justifyContent={"flex-end"}
        padding={"5%"}
        borderBottom={`1px solid ${colors.thirdBase}`}
        $gap={2}
      >
        {variants === "cart" && (
          <CartInfoWrapper
            text={t(i18Enum.Cart_Checkout_Title)}
            price={priceNum(order?.total)}
            colorText={colors.thirdText}
            colorPrice={colors.thirdText}
          />
        )}
        <CartInfoWrapper
          text={t(i18Enum.Orders_Order_Shipping)}
          price={
            order?.itemsTotal && order?.total
              ? priceNum(order?.total - order?.itemsTotal)
              : 0
          }
          colorText={colors.thirdText}
          colorPrice={colors.thirdText}
        />
        <CartInfoWrapper
          text={t(i18Enum.Common_Total)}
          price={order?.total}
          colorText={colors.primaryText}
          colorPrice={colors.primaryText}
        />
        {/* //TODO  gestire lo status Order  */}
        {variants === "order" && (
          <CartInfoWrapper
            text={t(i18Enum.Orders_Order_Status)}
            price={getOrderStatusLabel(order?.status)}
            colorText={colors.thirdText}
            colorPrice={
              order?.status === OrderStatus.Cancelled
                ? colors.warningBase
                : order?.status === OrderStatus.Accepted
                ? colors.primaryBase
                : colors.thirdText
            }
          />
        )}
      </Flex>
    </>
  );
};
