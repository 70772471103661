import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MODAL, ModalStateType, ModalType } from "./ModalTypes";


const modalInitialState:ModalStateType = {
   
    isOpen: false,
    type: ModalType.message,
    title: "",
    message: "",
    child:undefined,
    callback:undefined
    
}

export const modalSlice = createSlice({
    name:MODAL,
    initialState:modalInitialState,
    reducers:{
        openModal:(state:ModalStateType, {payload:modal}:PayloadAction<ModalStateType>)=>{
            state.isOpen= modal.isOpen;
            state.callback = modal.callback;
            state.message = modal.message;
            state.type = modal.type;
            state.child = modal.child
            state.title = modal.title
        },
        closeModal:(state:ModalStateType)=>{
            state.isOpen = false;
            state.callback=undefined;
            state.message='';
            state.type=ModalType.message;
            state.title='';
            state.child=undefined

        }
    }
})





//you can use the first in your component like 'const dispatch = useAppDispatch() --> ...onclick ={()=>dispatch(ModalActions.closeModal())}
export const ModalActions = modalSlice.actions
// or this one with dispatch(closeModal)
export const {openModal,closeModal} = modalSlice.actions

//note that dispatch not suggest you wich action you need... so.. i found easier to use the first solution..


export default modalSlice.reducer;