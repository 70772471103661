import styled from "styled-components";
import {
  ColorProps,
  flexbox,
  LayoutProps,
  SpaceProps,
  layout,
  space,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  color,
  variant,
  compose,
} from "styled-system";



export interface OutifWrapperProps {
  variant?:
    | "first"
    | "second"
    | "third"
    | "fourth"
    | "open"
    | "close"
}

const OutfitCardWrapper = styled.div<
OutifWrapperProps &
  ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    PositionProps &
    BorderProps & { $gap?: number } & { $aspectRatio?: string } & {
      $transition?: string;} & {background?:string}  & {transform?:string} & {hasShadow:boolean}
>`
  display: flex;
  aspect-ratio: ${(props) => props.$aspectRatio ?? undefined};
  gap: ${(props) => props.$gap}em;
  transition: ${(props) => props.$transition ?? undefined};
  background: ${(props) => props.background ?? undefined};
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  overflow:hidden;
  transform:${(props) => props.transform ?? undefined};
  ${space}
  ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
    ${() =>
      compose(
        variant({
          variants: {
            first: {
              clipPath: "polygon(100% 0%, 100% 70%, 0% 100%, 0% 30%)",
            },
            second: {
              clipPath:"polygon(100% 0%, 100% 100%, 0% 70%, 0% 30%)",
            },
            third: {
              clipPath:"polygon(100% 30%, 100% 70%, 0% 100%, 0% 0%)",
            },
            fourth: {
              clipPath:"polygon(100% 0%, 100% 70%, 0% 100%, 0% 30%)",
            },
            open: {
              clipPath:"polygon(100% 0%, 100% 85%, 0% 100%, 0% 15%)",
            },
            close: {
              clipPath:"polygon(100% 0%, 100% 70%, 0% 100%, 0% 30%)",
            },
          },
        })
      )};
`;
export default OutfitCardWrapper;
