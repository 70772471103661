export type RegisterStateType = {
    register: {
        isSuccess:boolean|null
        isLoading: boolean;
        errors: string;
        data: string
    }
}

export const REGISTER = 'register';
export type REGISTER = typeof REGISTER

export const GET_REGISTER_ACTION = `${REGISTER}/getRegisterAction`
export type GET_REGISTER_ACTION = typeof GET_REGISTER_ACTION

export const GET_VERIFY_USER_ACTION = `${REGISTER}/getVerifyUserAction`;
export type GET_VERIFY_USER_ACTION = typeof GET_VERIFY_USER_ACTION;