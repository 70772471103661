import { Outlet } from "react-router-dom"
import Flex from "../components/primitives/Flex"

export const PublicPage = () => {
  return (
    <Flex  flexDirection={'column'} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
      <Outlet />
    </Flex>
  )
}
