export type jwtToken = {
  primarysid: string;
  role: string;
  nbf: number;
  exp: number;
  iat: number;
};

const AUTH_LOCAL_STORAGE_KEY = "authorization";

const getAuth = (): string | null | undefined => {
  if (!localStorage) {
    return;
  }

  const accessToken: string | null = localStorage.getItem(
    AUTH_LOCAL_STORAGE_KEY
  );

  return accessToken;
};

const setAuth = (accessToken: string) => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, accessToken);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
