import { useContext, useEffect, useMemo, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { priceNum /*, toastType*/ } from "utils";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import { AddressCard, addressType } from "components/molecules/AddressCard";
import { H6 } from "theme/theme.styled";
import Section from "components/primitives/Section";
import { OrderSentCard } from "components/molecules/OrderSentCard";
import { useLocation, useNavigate } from "react-router-dom";
import { AppGeneralContext } from "context/AppGeneralContext";
import Box from "components/primitives/Box";
// import Button from "components/primitives/Button";
import ArrowSvg from "components/atoms/ArrowSvg";
import { ErrorNoItems } from "components/molecules/ErrorNoItems";
import {
  OrderDTO /*, UserShippingAddress*/,
} from "redux/features/user/UserTypes";

import { format } from "date-fns";
import CardWrapperWhite from "components/primitives/CardWrapperWhite";
import { DotsLoader } from "components/molecules/DotsLoader";
import Accordion from "components/molecules/Accordion";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { OrderStatus } from "utils/graphql/generated/graphql";

export const OrdersParentDetailsPage = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { isMobile, asideParams } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const navigate = useNavigate();
  const orderDetail: OrderDTO | null = useLocation().state;
  const [routeParams, setRouteParams] = useState<OrderDTO | null>(orderDetail);
  // const dispatch = useDispatch();
  const [SACardIsLoading, setSACardIsLoading] = useState<boolean>(false);
  const [BACardIsLoading, setBACardIsLoading] = useState<boolean>(false);
  // const [selectedId, setSelectedId]= useState<string>()
  const order: OrderDTO | null = useMemo(
    () => (isMobile ? routeParams : asideParams),
    [isMobile, routeParams, asideParams]
  );
  const { t } = useTranslation();

  useEffect(() => {
    setRouteParams(orderDetail);
  }, [orderDetail, orderDetail?.status]);

  return (
    <Flex width={[1]} flexDirection={"column"}>
      {isMobile && (
        <Section
          padding={["100px 5% 20px 5%"]}
          width={[1]}
          justifyContent={"space-between"}
        >
          <Flex flexDirection={"row"} onClick={() => navigate(-1)}>
            <ArrowSvg width={[40, 30]} />{" "}
            {!isMobile && (
              <Txt paddingLeft={10} variant="link" color={colors.primaryBase}>
                {t(i18Enum.Common_Back)}
              </Txt>
            )}
          </Flex>
        </Section>
      )}
      <Section
        padding={["10px 5%"]}
        width={[1]}
        justifyContent={"space-between"}
      >
        <Txt fontSize={H6} as={"h6"} fontFamily={"BauProMedium"}>
          {t(i18Enum.Orders_Order_OrderDate)}
        </Txt>
        <Txt
          fontSize={H6}
          as={"h6"}
          fontFamily={"BauPro"}
          fontWeight={"300"}
          color={colors.primaryBase}
        >
          {`${
            order?.createdAt ? format(new Date(order?.createdAt), "PPp") : ""
          }`}
        </Txt>
      </Section>
      <Section
        padding={["10px 5%"]}
        width={[1]}
        flexDirection={"column"}
        alignItems={"start"}
      >
        <Txt padding={"10px 0"} fontFamily={"BauPro"}>
          {t(i18Enum.Orders_Order_ShippingAddress)}
        </Txt>
        {order?.shippingAddress && (
          <>
            {SACardIsLoading ? (
              <CardWrapperWhite
                style={{ borderColor: colors.thirdBase }}
                minHeight={"70px"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <DotsLoader />
              </CardWrapperWhite>
            ) : (
              <AddressCard
                type={
                  order.status === OrderStatus.Pending
                    ? addressType.detail
                    : addressType.disabled
                }
                name={order?.shippingAddress.name ?? ""}
                street={order?.shippingAddress.street ?? ""}
                streetNumber={order?.shippingAddress.streetNumber ?? ""}
                zipCode={order?.shippingAddress.zipCode ?? ""}
                city={order?.shippingAddress.city ?? ""}
                countryId={order?.shippingAddress.countryId ?? 110}
                isDefault={order?.shippingAddress.isDefault ?? false}
                isBilling={!!order?.shippingAddress.isBilling}
                handleEdit={() => {}}
              />
            )}
          </>
        )}
      </Section>

      <Section
        padding={["10px 5%"]}
        width={[1]}
        flexDirection={"column"}
        alignItems={"start"}
      >
        <Txt padding={"10px 0"} fontFamily={"BauPro"}>
          {t(i18Enum.Orders_Order_BillingAddress)}
        </Txt>
        {order?.billingAddress && (
          <>
            {BACardIsLoading ? (
              <CardWrapperWhite
                style={{ borderColor: colors.thirdBase }}
                minHeight={"70px"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <DotsLoader />
              </CardWrapperWhite>
            ) : (
              <AddressCard
                type={
                  order.status === OrderStatus.Pending
                    ? addressType.detail
                    : addressType.disabled
                }
                name={order.billingAddress.name ?? ""}
                street={order.billingAddress.street ?? ""}
                streetNumber={order.billingAddress.streetNumber ?? ""}
                zipCode={order.billingAddress.zipCode ?? ""}
                city={order.billingAddress.city ?? ""}
                countryId={order.billingAddress.countryId ?? 110}
                isDefault={!!order.billingAddress.isDefault}
                isBilling={!!order.billingAddress.isBilling}
                handleEdit={() => {}}
              />
            )}
          </>
        )}
      </Section>
      {order?.orders && order?.orders.length !== 0 && (
        <Section
          padding={["10px 5%"]}
          width={[1]}
          flexDirection={"column"}
          alignItems={"start"}
        >
          <Txt padding={"10px 0"} fontFamily={"BauPro"}>
            {t(i18Enum.Orders_Title)}
          </Txt>
          {order.orders.map((orderChild, index) => {
            return (
              <Accordion
                key={orderChild.Id + "_" + index}
                width={[1]}
                variant={"card"}
                title={`${t(i18Enum.Orders_Order_Number)} ${orderChild.number}`}
                fullHeight
              >
                <Flex>
                  {orderChild?.items && orderChild.items.length !== 0 ? (
                    <Box width={[1]} paddingTop={"20px"} height={"100%"}>
                      <OrderSentCard
                        items={orderChild?.items}
                        order={orderChild}
                        variants={"order"}
                      />
                    </Box>
                  ) : (
                    <ErrorNoItems
                      message={t(i18Enum.Orders_Order_ErrorNoItems)}
                      countDownText={t(i18Enum.Orders_Order_OrderDetail)}
                      arrayLenght={orderChild?.items?.length}
                      hasCountDown
                    />
                  )}
                </Flex>
              </Accordion>
            );
          })}
        </Section>
      )}
      <Section
        padding={["10px 5%"]}
        width={[1]}
        flexDirection={"column"}
        alignItems={"start"}
      ></Section>
      <Section padding={["0 5% 25% 5%"]} width={[1]} flexDirection={"column"}>
        {/* <Flex justifyContent={"flex-end"} padding={"20px 0"} alignItems={"center"} width={[1]} borderBottom={`1px solid ${colors.thirdBase}`} $gap={2}>
          <Txt textAlign={"start"} as={"h6"} variant='medium' color={colors.thirdText} fontSize={H6}>
            Promo
          </Txt>
          <Txt textAlign={"start"} as={"h6"} variant='light' color={colors.primaryText} fontSize={H6}>
            {`${priceNum(order?.)}€`}
          </Txt>
        </Flex> */}
        {/* TODO: modificare con lo stato dell'ordine che arriva da back-end */}
        <Flex
          justifyContent={"flex-end"}
          padding={"20px 0"}
          borderBottom={"1px solid white"}
        ></Flex>
        <Flex
          justifyContent={"flex-end"}
          alignItems={"center"}
          width={[1]}
          padding={"20px 0"}
          borderBottom={`1px solid ${colors.thirdBase}`}
          $gap={2}
        >
          <Txt
            textAlign={"start"}
            as={"h6"}
            variant="medium"
            color={colors.thirdText}
            fontSize={H6}
          >
            {t(i18Enum.Common_Total)}
          </Txt>

          <Txt
            textAlign={"start"}
            as={"h6"}
            variant="light"
            color={colors.primaryText}
            fontSize={H6}
          >
            {`${priceNum(order?.total)}€`}
          </Txt>
        </Flex>
      </Section>
    </Flex>
  );
};
