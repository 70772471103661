import { useContext, useEffect, useState } from "react";
import { SpaceProps, WidthProps } from "styled-system";
import { CustomThemeContext } from "../../context/ThemeContext";
import { capitalize } from "../../utils";

import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import Button from "../primitives/Button";
import Checkbox from "../molecules/Checkbox";
import * as Yup from "yup";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import Form from "components/primitives/Form";
import { useFormik } from "formik";
import YupPassword from "yup-password";
import { AppGeneralContext } from "context/AppGeneralContext";
import { useAppSelector } from "redux/app/hooks";
import { RegisterRequest } from "api/types/requests/registerRequest";
import { PAGE } from "utils/const/pagePath";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { AUTH_API } from "api/graphql/requests";

YupPassword(Yup);

export interface FormRegister {
  email: string;
  firstName: string;
  lastName: string;
  isMarektingPolicyAgree: boolean;
  isPrivacyPolicyAgree: boolean;
  isProfilingPolicyAgree: boolean;
  isTermsOfUseAgree: boolean;
}

type RegisterFormSchemaObject = {
  [key in keyof FormRegister]: Yup.Schema<any>;
};
interface FormField {
  id: keyof Pick<FormRegister, "email" | "firstName" | "lastName">;
  placeholder: string;
  isPrivate?: boolean;
  width: number[];
}
interface CheckboxData {
  id: keyof Pick<
    FormRegister,
    | "isMarektingPolicyAgree"
    | "isPrivacyPolicyAgree"
    | "isProfilingPolicyAgree"
    | "isTermsOfUseAgree"
  >;
  label: string;
  linkLabel?: string;
  onClick?: () => void;
}

const registerFormInitialValues: FormRegister = {
  email: "",
  firstName: "",
  lastName: "",
  isMarektingPolicyAgree: false,
  isPrivacyPolicyAgree: false,
  isProfilingPolicyAgree: false,
  isTermsOfUseAgree: false,
};

interface RegisterFormProps extends WidthProps, SpaceProps {}

function RegisterForm({ ...props }: RegisterFormProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const { setIsLoading, setUserId } = useContext(AppGeneralContext);
  const colors = customTheme.colors;

  const [isValid, setIsValid] = useState(false);
  const {
    isLoading: isFetchingRegister,
    isSuccess,
    data,
  } = useAppSelector((state) => state.register.register);
  const { t } = useTranslation();

  const formBillingSchema = Yup.object().shape<RegisterFormSchemaObject>({
    email: Yup.string()
      .matches(
        /^[\w\-.]+@([\w-]+\.)+[a-zA-Z]{2,10}$/i,
        t(i18Enum.Validation_Client_Field_Email)
      )
      .required(t(i18Enum.Validation_Client_Field_Required)),
    firstName: Yup.string(),
    lastName: Yup.string(),
    isMarektingPolicyAgree: Yup.bool(),
    isProfilingPolicyAgree: Yup.bool(),
    isPrivacyPolicyAgree: Yup.boolean()
      .required()
      .oneOf([true], t(i18Enum.Validation_Client_Field_Required)),
    isTermsOfUseAgree: Yup.boolean()
      .required()
      .oneOf([true], t(i18Enum.Validation_Client_Field_Required)),
  });

  const formFields: FormField[] = [
    {
      id: "firstName",
      placeholder: t(i18Enum.User_UserProfile_Label_GivenName),
      width: [1, 1, 1, 0.49],
    },
    {
      id: "lastName",
      placeholder: t(i18Enum.User_UserProfile_Label_FamilyName),
      width: [1, 1, 1, 0.49],
    },
    {
      id: "email",
      placeholder: t(i18Enum.User_UserProfile_Label_Email),
      width: [1, 1, 1],
    },
  ];

  const checkboxesData: CheckboxData[] = [
    {
      id: "isTermsOfUseAgree",
      label: t(i18Enum.IsTermsOfUseAgree),
      linkLabel: t(i18Enum.Footer_TermsAndConditions),
      onClick: () => {
        window.open(
          PAGE.termsAndConditions.menuPath,
          "_blank",
          "noopener,noreferrer"
        );
      },
    },
    {
      id: "isPrivacyPolicyAgree",
      label: t(i18Enum.IsPrivacyPolicyAgree),
      linkLabel: t(i18Enum.Footer_PrivacyPolicy),
      onClick: () => {
        window.open(
          PAGE.privacyPolicy.menuPath,
          "_blank",
          "noopener,noreferrer"
        );
      },
    },
    {
      id: "isMarektingPolicyAgree",
      label: t(i18Enum.IsMarektingPolicyAgree),
    },
    {
      id: "isProfilingPolicyAgree",
      label: t(i18Enum.IsProfilingPolicyAgree),
    },
  ];
  useEffect(() => {
    setIsLoading(isFetchingRegister);
  }, [isFetchingRegister]);

  useEffect(() => {
    setUserId(data);
  }, [data]);

  const formik = useFormik<FormRegister>({
    enableReinitialize: true,
    initialValues: registerFormInitialValues,
    validationSchema: formBillingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const newReq: RegisterRequest = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        isMarektingPolicyAgree: values.isMarektingPolicyAgree,
        isPrivacyPolicyAgree: values.isPrivacyPolicyAgree,
        isProfilingPolicyAgree: values.isPrivacyPolicyAgree,
        isTermsOfUseAgree: values.isTermsOfUseAgree,
      };
      // dispatch(RegisterActions.getRegisterAction(newReq));

      const publicDomain = "http://localhost:5173";
      const signupRequest = new URLSearchParams({
        client_id: "local",
        redirect_uri: `${publicDomain}`,
        response_type: "token",
        provider: "code",
        tenant: "mooore",
        email: newReq.email || "",
        newsletter: newReq.isMarektingPolicyAgree ? "true" : "false",
        first_name: newReq.firstName || "",
        last_name: newReq.lastName || "",
      });

      location.href = `${AUTH_API}/code/authorize?${signupRequest}`;

      if (!isSuccess) {
        setSubmitting(false);
        return;
      }
      setUserId(data);
      setSubmitting(false);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (
      formik.isValid &&
      Object.keys(formik.touched).length > 0 &&
      Object.keys(formik.errors).length === 0
    ) {
      setIsValid(true);
      return;
    }
    setIsValid(false);
  }, [formik.isValid, formik.touched, formik.errors]);

  return (
    <Form
      as="form"
      flexDirection={"column"}
      onSubmit={formik.handleSubmit}
      alignItems={"end"}
      {...props}
    >
      <Txt
        as="legend"
        width={[1]}
        fontSize={[4]}
        variant="light"
        textAlign={"left"}
      >
        {t(i18Enum.Login_CreateAccount)}
      </Txt>
      <Flex flexWrap={"wrap"} width={[1]} justifyContent={"space-between"}>
        {formFields.map((field) => {
          const fieldName = field.id;
          const autoComplete =
            fieldName === "email"
              ? "email"
              : fieldName === "firstName"
              ? "given-name"
              : fieldName === "lastName"
              ? "family-name"
              : "new-password";
          const error =
            formik.errors[fieldName] && formik.touched[fieldName]
              ? String(formik.errors[fieldName])
              : undefined;
          return (
            <InputFieldWardrobe
              {...formik.getFieldProps(fieldName)}
              key={fieldName}
              spaceProp={{
                width: field.width,
                marginTop: [2],
                padding: ["10px 0"],
              }}
              id={fieldName}
              type={fieldName === "email" ? "email" : "text"}
              autoComplete={autoComplete}
              isPrivate={field.isPrivate}
              placeholder={field.placeholder}
              value={formik.values[fieldName]}
              name={fieldName}
              label={field.placeholder}
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched(fieldName, true)}
              error={error}
            />
          );
        })}
      </Flex>

      {checkboxesData.map((checkbox, index) => {
        const fieldName = checkbox.id;
        const error =
          formik.errors[fieldName] &&
          formik.values[fieldName] === false &&
          formik.touched[fieldName]
            ? String(formik.errors[fieldName])
            : undefined;

        return (
          <Checkbox
            {...formik.getFieldProps(fieldName)}
            key={checkbox.id}
            spaceProp={{ width: [1], marginTop: index === 0 ? "38px" : "19px" }}
            id={checkbox.id}
            name={fieldName}
            checked={formik.values[fieldName]}
            onChange={formik.handleChange}
            onClick={() => formik.setFieldTouched(fieldName, true)}
            error={error}
            children={
              <Txt variant="light" fontSize={[1]} $textTransform="inherit">
                {capitalize(checkbox.label)}
                {checkbox.linkLabel && (
                  <Txt
                    paddingLeft={[1]}
                    variant="linkLight"
                    color={colors.primaryBg}
                    onClick={checkbox.onClick}
                  >
                    {capitalize(checkbox.linkLabel)}
                  </Txt>
                )}
              </Txt>
            }
          />
        );
      })}
      {/* <Checkbox
        key={'IsTermsOfUseAgree'}
        spaceProp={{ width: [1], marginTop: "38px"}}
        id={'IsTermsOfUseAgree'}
        name={'IsTermsOfUseAgree'}
        checked={formik.values['IsTermsOfUseAgree']}
        onChange={(e) => { console.log(e), formik.handleChange(e); formik.setFieldTouched('IsTermsOfUseAgree', true) }}
        error={(formik.errors['IsTermsOfUseAgree'] && formik.touched['IsTermsOfUseAgree']) ? String(formik.errors['IsTermsOfUseAgree']) : undefined}
      >
      </Checkbox> */}
      <Button
        variant="register"
        type="submit"
        marginTop={[4]}
        disabled={!isValid}
      >
        {t(i18Enum.Login_RegisterButton)}
      </Button>
    </Form>
  );
}

export default RegisterForm;
