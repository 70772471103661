import { useEffect } from "react";
import { SpaceProps, WidthProps } from "styled-system";
import * as Yup from "yup";
import Flex from "../primitives/Flex";
import TextareaField from "components/molecules/TextareaField";
import { useFormik } from "formik";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";

import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export interface ProfileInfo {
  firstName?: string;
  lastName?: string;
  username?: string;
  bio?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  tikTokUrl?: string;
  xUrl?: string;
  youTubeUrl?: string;
  pinterestUrl?: string;
}

const initialProfileInfo: ProfileInfo = {
  firstName: "",
  lastName: "",
  username: "",
  bio: "",
  facebookUrl: "",
  instagramUrl: "",
  tikTokUrl: "",
  xUrl: "",
  youTubeUrl: "",
  pinterestUrl: "",
};

type FormField = {
  id: keyof ProfileInfo;
  placeholder: string;
};

type ProfileInfoSchemaObject = {
  [key in keyof ProfileInfo]: Yup.Schema<any>;
};
interface ProfileInfoFormProps extends WidthProps, SpaceProps {
  onSubmit: (value: ProfileInfo) => void;
  onFieldChange: () => void;
  isFormValid: (value: boolean) => void;
  isPrivate: boolean | undefined;
  data: ProfileInfo;
}

function ProfileInformationForm({
  onSubmit,
  onFieldChange,
  isFormValid,
  isPrivate,
  data,
  ...props
}: ProfileInfoFormProps) {
  const { t } = useTranslation();

  const formPersonalInfoSchema = Yup.object().shape<ProfileInfoSchemaObject>({
    firstName: Yup.string().required(
      t(i18Enum.Validation_Client_Field_Required)
    ),
    lastName: Yup.string().required(
      t(i18Enum.Validation_Client_Field_Required)
    ),
    username: Yup.string().required(
      t(i18Enum.Validation_Client_Field_Required)
    ),
    bio: Yup.string().nullable(),
    facebookUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    instagramUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    tikTokUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    xUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    youTubeUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
    pinterestUrl: Yup.string().url(t(i18Enum.Error_Links)).nullable(),
  });

  const formFields: FormField[] = [
    {
      id: "firstName",
      placeholder: t(i18Enum.User_UserProfile_Label_GivenName),
    },
    {
      id: "lastName",
      placeholder: t(i18Enum.User_UserProfile_Label_FamilyName),
    },
    { id: "username", placeholder: t(i18Enum.User_UserProfile_Label_Username) },
    {
      id: "bio",
      placeholder: t(i18Enum.User_UserProfile_Label_ShortDescription),
    },
    {
      id: "facebookUrl",
      placeholder: t(i18Enum.User_UserProfile_Label_Facebook),
    },
    {
      id: "instagramUrl",
      placeholder: t(i18Enum.User_UserProfile_Label_Instagram),
    },
    { id: "tikTokUrl", placeholder: t(i18Enum.User_UserProfile_Label_Tiktok) },
    {
      id: "xUrl",
      placeholder: t(i18Enum.User_UserProfile_Label_Twitter),
    },
    {
      id: "youTubeUrl",
      placeholder: t(i18Enum.User_UserProfile_Label_Youtube),
    },
    {
      id: "pinterestUrl",
      placeholder: t(i18Enum.User_UserProfile_Label_Pinterest),
    },
  ];

  const formik = useFormik<ProfileInfo>({
    initialValues: data ? data : initialProfileInfo,
    validationSchema: formPersonalInfoSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.firstName && values.lastName && values.username) {
        onSubmit(values);
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    isFormValid(formik.isValid);
  }, [formik.isValid]);

  useEffect(() => {
    formik.handleSubmit();
  }, [
    formik.values.facebookUrl,
    formik.values.lastName,
    formik.values.firstName,
    formik.values.instagramUrl,
    formik.values.pinterestUrl,
    formik.values.bio,
    formik.values.tikTokUrl,
    formik.values.xUrl,
    formik.values.username,
    formik.values.youTubeUrl,
  ]);

  return (
    <Flex
      as="form"
      width={[1]}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      $gap={0.5}
      {...props}
    >
      <Flex
        width={[1, 1, "500px"]}
        flexDirection={"column"}
        maxWidth={"500px"}
        marginBottom={[3]}
      >
        {formFields.map((field) =>
          field.id === "bio" ? (
            <TextareaField
              {...formik.getFieldProps(field.id)}
              key={field.id}
              name="bio"
              spaceProp={{ width: [1], marginTop: 26, fontSize: "10px" }}
              variant="description"
              id={field.id}
              placeholder={field.placeholder}
              isPrivate={isPrivate}
              value={formik.values[field.id]}
              onChange={(e) => {
                formik.handleChange(e);
                onFieldChange();
              }}
              onBlur={() => formik.setFieldTouched(field.id, true)}
              error={
                formik.errors[field.id] ? formik.errors[field.id] : undefined
              }
            />
          ) : (
            <InputFieldWardrobe
              {...formik.getFieldProps(field.id)}
              key={field.id}
              name={field.id}
              id={field.id}
              placeholder={field.placeholder}
              label={field.id}
              autoComplete={
                field.id === "firstName"
                  ? "given-name"
                  : field.id === "lastName"
                  ? "family-name"
                  : "off"
              }
              spaceProp={{ width: [1], marginTop: [4], padding: ["10px 0"] }}
              onChange={(e) => {
                formik.handleChange(e);
                onFieldChange();
              }}
              onBlur={() => formik.setFieldTouched(field.id, true)}
              value={formik.values[field.id]}
              error={
                formik.errors[field.id] ? formik.errors[field.id] : undefined
              }
            />
          )
        )}
      </Flex>
    </Flex>
  );
}

export default ProfileInformationForm;
