import { useContext, useEffect, useState } from "react";
import Button from "../primitives/Button";
import CartSvg from "../atoms/CartSvg";
import Txt from "../primitives/Txt";
import { CustomThemeContext } from "../../context/ThemeContext";
import { ProductProps } from ".";
import SelectColor from "../molecules/SelectColor";
import Accordion from "../molecules/Accordion";
import {
  MultiColorKeyGradient,
  VariantColor,
  colorsKeyEnum,
  removeDuplicates,
} from "../../utils";
import SelectSize from "../molecules/SelectSize";
import Flex from "../primitives/Flex";
import Skeleton from "../atoms/Skeleton";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { DotsLoader } from "components/molecules/DotsLoader";
import { RenderHTML } from "components/primitives/RenderHTML";
import Tooltip from "components/primitives/Tooltip";

export const ProductDetailDesktop = ({
  cartIsUpdating,
  isBuyNow,
  product,
  selected,
  handleSelection,
  handleAddToCart,
  isLoading,
  productVariants,
  productToBasket,
}: ProductProps) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const [openedAccordion, setOpenedAccordion] = useState<string>("");
  const [selectedColorId, setSelectedColorId] = useState<string | undefined>(
    undefined
  );
  const { t } = useTranslation();
  const accordionList = [
    {
      id: "info",
      title: t(i18Enum.Product_Label_Details),
      description: product?.details,
      defaultOpen: false,
    },
    {
      id: "material",
      title: t(i18Enum.Product_Label_MaterialAndCare),
      description: product?.composition,
      defaultOpen: false,
    },
    {
      id: "shipping",
      title: t(i18Enum.Product_Label_ShippingInfo),
      description: t(i18Enum.Product_ShippingInfo),
      defaultOpen: false,
    },
  ];

  useEffect(() => {
    const viewItem = [
      {
        item_id: selected?.id,
        item_name: product?.name,
        item_variant: selected?.sku,
      },
    ];

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "EUR",
        value: selected?.discountedPrice
          ? selected?.discountedPrice
          : selected?.price,
        items: viewItem,
      },
    });
    console.log({ viewItem: viewItem });
  }, [selected]);

  useEffect(() => {
    if (selected) {
      setSelectedColorId(
        selected?.options?.filter((option) => option.type == "COLOR")?.[0]?.id
      );
    }
  }, [selected]);

  useEffect(() => {
    if (!productVariants?.length) {
      handleSelection(selected !== null ? selected : undefined);
    }
  }, []);

  const showColorSection =
    productVariants?.some(
      (variant) =>
        variant?.options?.filter((option) => option.type == "COLOR")?.length > 0
    ) ?? false;

  const productColors = removeDuplicates(
    productVariants
      ?.map((variant) => {
        return variant.options
          ?.filter((option) => option.type == "COLOR")
          .flat();
      })
      .flat() || []
  );

  return (
    <Flex
      $gap={1}
      height={"100%"}
      flexDirection={["column", "row", "column"]}
      paddingTop={["0px", "20px", "0px"]}
      justifyContent={["space-between"]}
    >
      <Flex
        flexDirection={"column"}
        padding={"1em"}
        border={"1px solid white"}
        width={["100%", "50%", "100%"]}
      >
        <Flex p={"1px 5px"} flexDirection={"column"}>
          {/* color */}
          <Flex flexDirection={"column"}>
            {showColorSection ? (
              <>
                <Txt
                  width={[1]}
                  fontSize={[1]}
                  color={colors.thirdBase}
                  textAlign={"left"}
                  variant="light"
                  mb={1}
                >
                  {t(i18Enum.Product_SelectColor)}
                </Txt>
                <Flex
                  mb={3}
                  $gap={1}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                  width={"100%"}
                >
                  {!isLoading ? (
                    productColors.map((color) => {
                      return (
                        <Tooltip content={color.label ?? ""} key={color?.id}>
                          <SelectColor
                            isBlack={false}
                            background={
                              color?.label === colorsKeyEnum.Multicolour
                                ? MultiColorKeyGradient
                                : VariantColor(color?.label ?? "")
                            }
                            selected={selectedColorId === color?.id}
                            onClick={() => {
                              //Se questo colore non è gia selezionato lo imposto
                              if (!(selectedColorId === color?.id)) {
                                const variantIdx = productVariants?.findIndex(
                                  (variant) => {
                                    let found = false;
                                    variant.options
                                      .filter(
                                        (option) => option.type == "COLOR"
                                      )
                                      .forEach((option) => {
                                        if (option.id === color.id) {
                                          found = true;
                                        }
                                      });
                                    return found;
                                  }
                                );
                                if (
                                  typeof variantIdx !== "undefined" &&
                                  variantIdx >= 0 &&
                                  productVariants
                                ) {
                                  const variant = productVariants[variantIdx];
                                  handleSelection(variant);
                                  setSelectedColorId(color.id);
                                }
                              }
                            }}
                          />
                        </Tooltip>
                      );
                    })
                  ) : (
                    <Skeleton width={"100%"} height={"20px"} />
                  )}
                </Flex>
              </>
            ) : null}
          </Flex>
          {/*sizes  */}
          {isLoading ? (
            <Skeleton height={"80px"} width={"100%"} />
          ) : (
            <SelectSize
              variant="xl"
              title={t(i18Enum.Product_SelectSize)}
              productVariants={productVariants ?? []}
              selected={selected}
              productToBasket={(product) => productToBasket(product)}
            />
          )}
          {/* infoBuyNow */}
          <Txt
            mt={2}
            color={colors.primaryText}
            fontSize={[2]}
            variant="light"
            paddingBottom={"10px"}
            style={{ textTransform: "inherit" }}
          >
            {/* {capitalize(product. ?? "")} */}
          </Txt>
        </Flex>
        {/* Checkout flow */}
        {/* <Button
          variant="addToCart"
          padding={"13px 0"}
          onClick={() => handleAddToCart?.(false)}
        >
          <CartSvg width={"1.1428em"} height={"1.4285em"} />

          <Txt
            fontFamily={"BauProMedium"}
            fontWeight={500}
            fontSize={[3]}
            marginLeft={"10px"}
          >
            {cartIsUpdating && !isBuyNow ? (
              <DotsLoader customHeight="23px" />
            ) : (
              t(i18Enum.Product_ProductCard_Button_AddToCart)
            )}
          </Txt>
        </Button> */}
        {/* BUY NOW button */}
        {/* <Button
          variant="buyNow"
          padding={"13px 0"}
          onClick={() => handleAddToCart?.(true)}
        >
          <CartSvg width={"1.1428em"} height={"1.4285em"} />
          <Txt
            fontFamily={"BauProMedium"}
            fontWeight={500}
            fontSize={[3]}
            marginLeft={"10px"}
          >
            {cartIsUpdating && isBuyNow ? (
              <DotsLoader customHeight="23px" colors={colors.secondaryBase} />
            ) : (
              t(i18Enum.Product_Button_BuyNow)
            )}
          </Txt>
        </Button> */}
      </Flex>
      {/* ACCORDION */}
      <Flex flexDirection={"column"} width={["100%", "50%", "100%"]}>
        {accordionList.map((accordion) => (
          <Accordion
            onOpenHandler={() => setOpenedAccordion(accordion.id)}
            key={accordion.id}
            variant={"card"}
            title={accordion.title}
            defaultOpen={accordion.id === openedAccordion}
          >
            {accordion.id === "shipping" ? (
              <RenderHTML
                variant="unordered"
                html={accordion.description ?? ""}
                fontSize={"12px"}
              />
            ) : (
              <Txt
                as="p"
                variant="light"
                color={colors.thirdBase}
                fontSize={[2]}
                margin="0"
                style={{ textTransform: "inherit" }}
              >
                {accordion.description ?? ""}
              </Txt>
            )}
          </Accordion>
        ))}
        {/* <Accordion onOpenHandler={() => setOpenedAccordion("info")} variant={"card"} title={"info"} defaultOpen={openedAccordion === "info"}>
          <Txt as='p' variant='light' color={colors.thirdBase} fontSize={[2]} style={{ textTransform: "inherit" }} margin='0'>
            {capitalize(product?.info ?? "")}
          </Txt>
        </Accordion>
        <Accordion onOpenHandler={() => setOpenedAccordion("material")} variant={"card"} title={"material"} defaultOpen={openedAccordion === "material"}>
          <Txt as='p' variant='light' color={colors.thirdBase} fontSize={[2]} style={{ textTransform: "inherit" }} margin='0'>
            {capitalize(product?.material ?? "")}
          </Txt>
        </Accordion>
        <Accordion onOpenHandler={() => setOpenedAccordion("shipping")} variant={"card"} title={"shipping"} defaultOpen={openedAccordion === "shipping"}>
          <Txt as='p' variant='light' color={colors.thirdBase} fontSize={[2]} style={{ textTransform: "inherit" }} margin='0'>
            {capitalize(product?.shipping ?? "")}
          </Txt>
        </Accordion> */}
      </Flex>
    </Flex>
  );
};
