import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import arrow from "../../assets/icons/arrow.svg";
import { SpaceProps, TypographyProps, WidthProps, compose, space, typography, variant, width } from "styled-system";
import Txt from "../primitives/Txt";

type variants = "footer" | "card";
interface ItemProps extends WidthProps, SpaceProps {
  variant: variants;
  colapse?: boolean;
  fullHeight?: boolean;
}
const ListItem = styled.ul<ItemProps>`
  padding: 0;
  margin: 0;
  overflow: "hidden";
  ${(props) =>
    compose(
      variant({
        variants: {
          footer: {
            backgroundColor: "transparent",
            margin: "0px 0 20px",
          },
          card: {
            backgroundColor: "transparent",
            border: "1px solid",
            padding: "13px 14px 16px 13px",
            boxSizing: "border-box",
            borderColor: props.theme.colors.thirdBase,
          },
        },
      })
    )}

  li:first-child {
    overflow: hidden;
    line-height: inherit;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: start;
    align-items: ${(props) => (props.colapse ? "unset" : "center")};

    ${(props) =>
      compose(
        variant({
          variants: {
            footer: {
              fontFamily: "BauProMedium",
              height: 28,
              padding: "2px 0 !important",
              boxSizing: "border-box",
              color: props.theme.colors.primaryBase,
            },
            card: {
              fontFamily: "BauPro",
              color: props.theme.colors.thirdBase,
              justifyContent: "space-between",
            },
          },
        })
      )}
    ${typography}
  }
  li:nth-child(2) {
    max-height: ${(props) => (props.colapse && props.fullHeight ? "100%" : props.colapse && !props.fullHeight ? "320px" : "0px")};
    transition: none;
    padding-top: ${(props) => (props.colapse ? "5px" : "0px")};
    text-align: left;
  }
  ${space};
  ${width};
`;

const Item = styled.li<ItemProps>`
  overflow: hidden;
  transition: none;
  display: flex;
  flex-direction: column;
`;

export const ArrowIcon = styled.i<ItemProps>`
  ${(props) =>
    compose(
      variant({
        variants: {
          footer: {
            margin: "0 0 0 15px !important",
            transition: "0.3s transform",
            transform: props.colapse ? "rotate(-180deg)" : "rotate(0deg)",
            filter: "invert(67%) sepia(85%) saturate(404%) hue-rotate(66deg) brightness(90%) contrast(103%)",
            backgroundImage: `url(${arrow})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: 10,
            height: 9,
          },
          card: {
            lineHeight: "0.5em",
            paddingLeft: 20,
            fontFamily: "BauPro",
            fontSize: "1.5em",
            fontStyle: "normal",
            display: "flex",
            alignItems: "center",
            height: "auto",
            "&::after": {
              content: props.colapse ? `"-"` : `"+"`,
              color: props.theme.colors.thirdBase,
              display: "block",
              transition: "all 1s linear",
            },
          },
        },
      })
    )}
`;

interface AccordionProps {
  variant: variants;
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  onOpenHandler?: () => void;
  fullHeight?: boolean;
}

const Accordion: React.FC<AccordionProps & WidthProps & SpaceProps & TypographyProps> = ({ variant, title, onOpenHandler, children, defaultOpen = false, fullHeight = false, ...props }) => {
  const [show, setShow] = useState<boolean>(defaultOpen);

  useEffect(() => {
    setShow(defaultOpen);
  }, [defaultOpen]);

  const handleShow = useCallback((): void => {
    setShow(!show);
  }, [show]);

  return (
    <ListItem variant={variant} colapse={show} fullHeight={fullHeight} {...props}>
      <Item
        variant={variant}
        onClick={() => {
          handleShow();
          onOpenHandler ? onOpenHandler() : null;
        }}
      >
        <Txt $cursor='pointer' overflow={"hidden"} textAlign={"start"}>
          {title}
        </Txt>

        {variant !== "footer" && <ArrowIcon variant={variant} colapse={show} />}
      </Item>
      {show && (
        <Item colapse={show} variant={variant}>
          {children}
        </Item>
      )}
    </ListItem>
  );
};

export default Accordion;

/* 
example
<Accordion
  variant="card"
  title="shipping"
  width={"50%"}
  >
   <Txt
     fontSize={"12px"}
     fontFamily={"BauPro"}
    lineHeight={"18px"}
    textAlign={"left"}
    >
     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
     eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
     minim veniam, quis nostrud exercitation ullamco laboris nisi ut
     aliquip ex ea commodo consequat.
   </Txt>
</Accordion>
*/
