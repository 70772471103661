import styled from "styled-components";
import {
  ColorProps,
  LayoutProps,
  SpaceProps,
  WidthProps,
  layout,
  space,
  position,
  PositionProps,
  border,
  BorderProps,
  color,
  textAlign,
  TextAlignProps,
  background,
  BackgroundProps
} from "styled-system";

const Box = styled.div<BackgroundProps&TextAlignProps&BorderProps&LayoutProps & ColorProps & WidthProps & SpaceProps&PositionProps &   { $aspectRatio?: string }>`
  ${space}
  ${layout}
  ${position}
  ${border}
  ${color}
  ${textAlign}
  ${background}
  aspect-ratio: ${(props) => props.$aspectRatio ?? undefined};
`;
export default Box;
