import styled from "styled-components";
import Box from "../primitives/Box";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import Flex from "components/primitives/Flex";
import { SpaceProps } from "styled-system";

export interface ValueRangeSlider {
  minValue: number;
  maxValue: number;
}

interface SliderProps extends SpaceProps {
  rangeMax: number;
  handleValues: (value: ValueRangeSlider) => void;
}

const SlaiderSingle = ({ rangeMax, handleValues, ...props }: SliderProps) => {
  const colors = useContext(CustomThemeContext).customTheme.colors;
  const [minRange, setMinRange] = useState(1);
  const [maxRange, setMaxRange] = useState(rangeMax);
  const gap = rangeMax * 0.15;

  useEffect(() => {
    handleValues({ minValue: minRange, maxValue: maxRange });
  }, [minRange, maxRange]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newMinRange = minRange;
    let newMaxRange = maxRange;

    if (name === "range-min") {
      newMinRange = parseInt(value);
      if (newMinRange < 0) {
        newMinRange = 0;
      }
      if (newMinRange > maxRange) {
        newMinRange = maxRange - gap;
      }
    } else {
      newMaxRange = parseInt(value);
      if (newMaxRange < minRange) {
        newMaxRange = minRange + gap;
      }
    }

    setMinRange(newMinRange);
    setMaxRange(newMaxRange);
  };

  const progressStyle = {
    left: `${(minRange / rangeMax) * 100}%`,
    right: `${100 - (maxRange / rangeMax) * 100}%`,
  };

  return (
    <Flex alignItems={"center"} {...props}>
      <Box
        height={16}
        width={24}
        border={`1px solid ${colors.thirdBase}`}
        position={"relative"}
      />
      <Box position={"relative"} width={[1]}>
        <Box
          id="range-slider"
          position="relative"
          height={3}
          borderRadius={"5px"}
          backgroundColor={colors.thirdBase}
          style={{ outline: "none" }}
          top="1px"
          margin="10px"
        >
          <Box
            id="progress"
            position="absolute"
            borderRadius="5px"
            height={"100%"}
            backgroundColor={colors.primaryBase}
            style={progressStyle}
          />
          <InputRange
            type="range"
            min={0}
            max={rangeMax}
            value={minRange}
            onChange={handleInputChange}
            name="range-min"
          />
        </Box>
      </Box>
      <Box
        marginLeft={2}
        width={37}
        height={25}
        border={`1px solid ${colors.thirdBase}`}
        position={"relative"}
      />
    </Flex>
  );
};

export const InputRange = styled.input`
  position: absolute;
  top: -13px;
  left: -5px;
  width: calc(100% + 13px);
  pointer-events: none;
  background: none;
  outline: none;

  &::-webkit-slider-thumb {
    pointer-events: auto;
    -webkit-appearance: none;
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 2px solid ${({ theme }) => theme.colors.secondaryBg};
    background: ${(props) => props.theme.colors.thirdBg};
  }
  &::-moz-range-thumb {
    pointer-events: auto;
    -webkit-appearance: none;
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 2px solid ${({ theme }) => theme.colors.secondaryBg};
    background: ${(props) => props.theme.colors.thirdBg};
  }
  &::-ms-thumb {
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 2px solid ${({ theme }) => theme.colors.secondaryBg};
    background: ${(props) => props.theme.colors.thirdBg};
  }
  -webkit-appearance: none;
`;

export default SlaiderSingle;
