import { FC, ReactNode, useContext, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { ArrowIcon } from "components/molecules";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import styled from "styled-components";

const Body = styled.div<{ lines: number }>`
  -webkit-box-orient: vertical;
  height: calc((12px * ${(props) => props.lines} * 1.618));
  max-height: 150px;
  line-height: 1.618;
  text-align: justify;
  transition: height 0.3s linear;

  &.open {
    height: auto;
    overflow: scroll;
  }

  &.closed {
    -webkit-line-clamp: ${(props) => props.lines};
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0;
`;

interface IReadMore {
  lines: number;
  children: ReactNode;
}

enum ReadMoreEnum {
  false = "READ MOOORE",
  true = "",
}

export const ReadMoreWrapper: FC<IReadMore> = ({ lines, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [readMoreText, setReadMoreText] = useState(ReadMoreEnum["false"]);
  const [className, setClassName] = useState("closed");
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;

  const handleClick = () => {
    const nextIsOpen = !isOpen;
    setIsOpen(nextIsOpen);
    setClassName(nextIsOpen ? "open" : "closed");
    setReadMoreText(ReadMoreEnum[`${nextIsOpen}`]);
  };

  return (
    <>
      <Flex alignItems={"flex-end"}>
        <Body lines={lines} className={`${className}`}>
          {typeof children === "string" ? <Txt $textTransform='inherit'>{children}</Txt> : children}
        </Body>
        {!isOpen && <Txt>...</Txt>}
      </Flex>
      <Footer>
        <Flex alignItems={"center"} onClick={handleClick} style={{ cursor: "pointer" }}>
          <Txt variant='medium' color={colors.primaryText} $cursor='pointer'>
            {readMoreText}
          </Txt>
          <ArrowIcon variant={"footer"} colapse={isOpen} />
        </Flex>
      </Footer>
    </>
  );
};

// import { FC, PropsWithChildren, ReactNode, useContext, useMemo, useState } from "react";
// import { CustomThemeContext } from "context/ThemeContext";
// import { ArrowIcon } from "components/molecules";
// import Flex from "components/primitives/Flex";
// import Txt from "components/primitives/Txt";
// import styled from "styled-components";

// const Body = styled.div<{ lines: number }>`
//   -webkit-box-orient: vertical;
//   height: calc((12px * ${(props) => props.lines} * 1.618));
//   max-height: 150px;
//   line-height: 1.618;
//   text-align: justify;
//   transition: height 0.3s linear;

//   &.open {
//     height: auto;
//     overflow: scroll;
//   }

//   &.closed {
//     -webkit-line-clamp: ${(props) => props.lines};
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }
// `;

// const Footer = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   padding: 0.5rem 0;
// `;

// interface IReadMore extends PropsWithChildren {
//   lines: number;
//   description:string
// }

// export const ReadMoreWrapper: FC<IReadMore> = ({ lines, children,description }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const { customTheme } = useContext(CustomThemeContext);
//   const colors = customTheme.colors;

//   const handleClick = () => {
//     setIsOpen(x => !x)
//   };
//   const textIsTooLong = useMemo(() => {
//     console.log(description.length)
//     if (description.length >= 200) {
//       return true
//     } else {
//       return false
//     }
//   }, [description])

//   return (
//     <>
//       <Flex alignItems={"flex-end"}>
//         <Body lines={lines} className={isOpen ? 'open' : 'closed'}>
//       <Txt $textTransform='inherit'>{description}</Txt>
//         </Body>
//         {(textIsTooLong && !isOpen) && <Txt>...</Txt>}
//       </Flex>
//       {textIsTooLong &&
//         <Footer>
//           <Flex alignItems={"center"} onClick={handleClick} style={{ cursor: "pointer" }}>
//             <Txt variant='medium' color={colors.primaryText} $cursor='pointer'>
//               {!isOpen && 'READ MOOORE'}
//             </Txt>
//             <ArrowIcon variant={"footer"} colapse={isOpen} />
//           </Flex>
//         </Footer>}
//     </>
//   );
// };
