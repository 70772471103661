import React, { HTMLAttributes } from "react";
import styled, { keyframes } from "styled-components";
import {
  SpaceProps,
  LayoutProps,
  BorderProps,
  space,
  color,
  layout,
  border,
  width,
  height,
  compose,
  variant,
  ColorProps,
} from "styled-system";
import { LazyLoadImage } from "react-lazy-load-image-component";

const skeletonKeyframe = keyframes`
  0% { background-position: 50% 0%; }
  50% { background-position: 50% 100%; }
  100% { background-position: 50% 0%; }
`;

interface ImageProps
  extends SpaceProps,
    LayoutProps,
    BorderProps,
    ColorProps,
    Omit<HTMLAttributes<HTMLImageElement>, "color"> {
  src: string | undefined;
  alt?: string;
  variant?: "loaded" | "loading";
  objectFit?: "cover" | "contain" | "fill" | "none" | "scale-down";
  objectPosition?:
    | "bottom"
    | "center"
    | "left"
    | "right"
    | "top"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right";
  aspectRatio?: string;
}

const StyledImage = styled(LazyLoadImage)<ImageProps>`
  ${space}
  ${color}
  ${layout}
  ${border}
  ${width}
  ${height}
  object-fit: ${(props) => props.objectFit || "cover"};
  animation: ${skeletonKeyframe} 2s ease-in-out alternate infinite;
  object-position: ${(props) => props.objectPosition || "center"};
  aspect-ratio: ${(props) => props.aspectRatio ?? undefined};
  ${(props) =>
    compose(
      variant({
        variants: {
          loaded: {
            filter: "blur(0px)",
            transition: "filter 0.5s linear",
          },
          loading: {
            filter: "blur(10px)",
            clipPath: "inset(0)",
          },
        },
      })
    )}
`;

const Image = ({ ...props }: ImageProps & { $aspectRatio?: string }) => {
  //TODO: add back variant to manage loading state
  return <StyledImage {...props} />;
};

export default Image;
