import Flex from "components/primitives/Flex";
import Section from "components/primitives/Section";
import { RenderHTML } from "components/primitives/RenderHTML";
import { CustomThemeContext } from "context/ThemeContext";
import { useContext } from "react";

interface IInfoTemplate {
  htmlData: string;
  variant?: "unordered" | undefined;
}

export const InfoTemplate = ({ htmlData, variant }: IInfoTemplate) => {
      const { customTheme } = useContext(CustomThemeContext);
      const maxW = customTheme.maxWidth;
  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"center"} maxWidth={maxW}>
      <Section minHeight={"200px"} padding={["18% 5% 5%", "2% 5% 0"]} width={[1]} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} $gap={2}>
        <RenderHTML html={htmlData} variant={variant} />
      </Section>
    </Flex>
  );
};
