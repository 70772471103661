import { GeneralLoader } from "components/molecules/GeneralLoader";
import Flex from "components/primitives/Flex";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const PendingPaymentPage = () => {
  const { url } = useParams();

  useEffect(() => {
    if (url) {
      //   if (isMobile) {
      //     window.location.replace(url);
      //     return;
      //   }
      //   window.open(url, "_blank", "noopener,noreferrer");
      //   window.close();
      // window.location.replace(url);

      console.log("url", url);
    }
  }, [url]);

  return (
    <Flex width={[1]} height={"100vh"}>
      <GeneralLoader />
    </Flex>
  );
};
