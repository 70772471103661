import { put, race, select, take, takeLatest, call } from "redux-saga/effects";
import {
  UserType,
  GET_USER,
  UserPreference,
  UserShippingAddress,
  CREATE_SHIPPING_ADDRESS,
  UPDATE_USER_SHIPPING_ADDRESS,
  SET_DEFAULT_USER_SHIPPING_ADDRESS,
  SEND_USER_PREFERENCE,
  EDIT_USER,
  DELETE_USER,
  SWITCH_PRIVATE_ACCOUNT,
  CREATE_BILLING_ADDRESS,
  UPDATE_USER_BILLING_ADDRESS,
  SET_DEFAULT_USER_BILLING_ADDRESS,
  GET_WHISLIST,
  GET_ORDER_PAGE,
  OrderType,
  DELETE_ORDER,
  DELETE_ADDRESS,
  ListOrdersResponse,
  REFRESH_STRIPE_URL,
  REMOVE_AUTH,
  ADD_AUTH,
  REMOVE_PROD_WISHLIST,
  ADD_PROD_WISHLIST,
} from "./UserTypes";
import {
  getUserErrorAction,
  getUserSuccessAction,
  manageUserShippingAddressErrorAction,
  manageUserShippingAddressSuccessAction,
  sendUserPreferenceErrorAction,
  sendUserPreferenceSuccessAction,
  manageUserBillingAddressSuccessAction,
  manageUserBillingAddressErrorAction,
  editUserErrorAction,
  deleteUserActionError,
  deleteUserActionSuccess,
  switchPrivateAccountErrorAction,
  getWishListErrorAction,
  getWishListSuccessAction,
  getOrdersErrorAction,
  getOrdersSuccessAction,
  userSlice,
  deleteOrderErrorAction,
  getOrdersAction,
  deleteAddressErrorAction,
  refreshStripeUrlErrorAction,
  userResetDataAction,
  removeAuthAction,
} from "./userSlice";
import {
  ApiSimpleResponse,
  BaseResponse,
  CoreResponse,
} from "../../../api/types/responses";
import {
  createShippingAddressCall,
  updateShippingAddressCall,
  deleteAddressCall,
  setDefaultAddressCall,
  postUserPreferenceCall,
  getUserCall,
  postEditUserCall,
  deleteUserCall,
  switchPrivateAccountCall,
  createBillingAddressCall,
  updateBillingAddressCall,
  deleteOrderByIdCall,
  addProductWishlistCall,
  removeProductWishlistCall,
} from "../../../api/userCalls/userCalls";
import { PayloadAction } from "@reduxjs/toolkit";
import { BaseIdRequest } from "api/types/requests";
import { toast } from "react-toastify";
import { RootState } from "redux/app/store";
import {
  CreateUpdateBillingAddressRequest,
  CreateUpdateShippingAddressRequest,
} from "api/types/requests/shippingAddressRequest";

import { stripeCall } from "api/cartCalls/basketCall";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";
import {
  toastErrorStyle,
  toastSuccessStyle,
  toastWarningStyle,
} from "utils/const/const";
import {
  AddProductWishlistMutation,
  CheckoutSessionUiMode,
  CreateCheckoutSessionMutation,
  CurrentUserQuery,
  RemoveProductFromWishlistMutation,
  UpdateUserMutation,
} from "utils/graphql/generated/graphql";
import { removeAuth, setAuth } from "api/authHelpers";
import { cookies } from "utils/cookies";
import { getShoppingBasketNoBasketAction } from "../cart/cartSlice";
import { isDev } from "utils";

//This refers to getUserAction dispatch, check userSlice
function* getUserSaga() {
  const response: ApiSimpleResponse<CurrentUserQuery> = yield getUserCall();

  if (
    !response.isSuccess ||
    !response.response ||
    !response.response?.currentUser
  ) {
    yield put(
      getUserErrorAction(
        response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)
      )
    );
    yield put(removeAuthAction());
    return;
  }
  const data = response.response?.currentUser;

  yield put(getUserSuccessAction(data));
}

function* editUserSaga({ payload }: PayloadAction<UserType>) {
  const res: ApiSimpleResponse<BaseResponse<UserType>> = yield postEditUserCall(
    payload
  );
  if (!res.isSuccess || !res.response?.Data) {
    yield put(editUserErrorAction(res.error ?? ""));
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    return;
  }
  yield put(getUserSuccessAction(res.response.Data));
  toast.success(t(i18Enum.User_UserProfile_Updated), toastSuccessStyle);
}

function* deleteUserSaga() {
  const res: ApiSimpleResponse<CoreResponse> = yield deleteUserCall();
  if (!res.isSuccess) {
    yield put(deleteUserActionError(res.error ?? ""));
    toast.error(`${t(i18Enum.Error_Occurred)}`, toastErrorStyle);
    return;
  }
  yield put(deleteUserActionSuccess());
  yield put(removeAuthAction());

  toast.error(t(i18Enum.User_UserProfile_Deleted), toastErrorStyle);
}

//TODO: rename into updateuserpreferencesaga and unify it
function* sendUserPreferenceSaga() {
  const preferences: UserPreference | undefined = yield select(
    (state: RootState) => {
      console.log({ state });
      return state.user.userPreference.data;
    }
  );
  //Getting userId from redux
  const userId: string = yield select((state: RootState) => {
    return state.user.user.data?.id;
  });

  if (!preferences) {
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    return;
  }

  const res: ApiSimpleResponse<UpdateUserMutation> =
    yield postUserPreferenceCall(userId, preferences);

  if (!res.isSuccess || !res.response?.updateUser) {
    yield put(sendUserPreferenceErrorAction(res.error ?? ""));
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    return;
  }

  yield call(refreshUserData);

  toast.success(
    t(i18Enum.User_UserProfile_PreferencesSaved),
    toastSuccessStyle
  );
}

function* switchPrivateAccountSaga() {
  const res: ApiSimpleResponse<BaseResponse<UserType>> =
    yield switchPrivateAccountCall();
  if (!res.isSuccess || !res.response?.Data) {
    yield put(switchPrivateAccountErrorAction(res.error ?? ""));
    toast.error(`${t(i18Enum.Error_Occurred)}`, toastErrorStyle);
    return;
  }
  yield put(getUserSuccessAction(res.response.Data));
  if (res.response.Data.isPrivate) {
    toast.warning(
      t(i18Enum.User_UserProfile_VisibilityMessage_Private),
      toastWarningStyle
    );
  } else {
    toast.success(
      t(i18Enum.User_UserProfile_VisibilityMessage_Public),
      toastSuccessStyle
    );
  }
}
/*SHIPPING ADDRESS SAGAS*/
function* createShippingAddressSaga({
  payload,
}: PayloadAction<CreateUpdateShippingAddressRequest>) {
  const response: ApiSimpleResponse<BaseResponse<UserShippingAddress>> =
    yield createShippingAddressCall(payload);

  if (!response.isSuccess || !response.response) {
    yield put(
      manageUserShippingAddressErrorAction(
        response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)
      )
    );
    return;
  }
  toast.success(
    t(i18Enum.User_Shipping_ShippingAddress_Create),
    toastSuccessStyle
  );
  yield put(
    manageUserShippingAddressSuccessAction(response.response?.createUserAddress)
  );
  yield call(refreshUserData);
}

function* updateShippingAddressSaga({
  payload,
}: PayloadAction<BaseIdRequest<CreateUpdateShippingAddressRequest>>) {
  const response: ApiSimpleResponse<UserShippingAddress> =
    yield updateShippingAddressCall(payload.id, payload.params);
  if (!response.isSuccess || !response.response) {
    yield put(
      manageUserShippingAddressErrorAction(
        response.error ?? t(i18Enum.Error_Occurred)
      )
    );

    return;
  }
  toast.success(
    t(i18Enum.User_Shipping_ShippingAddress_Updated),
    toastSuccessStyle
  );
  yield call(refreshUserData);
}

function* setDefaultShippingAddressSaga({
  payload: shippingAddressId,
}: PayloadAction<string>) {
  const response: ApiSimpleResponse = yield setDefaultAddressCall(
    shippingAddressId
  );
  if (!response.isSuccess || !response.response) {
    yield put(
      manageUserShippingAddressErrorAction(
        response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)
      )
    );
    return;
  }
  yield call(refreshUserData);
}

/*BILLING ADDRESS SAGAS*/
function* createBillingAddressSaga({
  payload,
}: PayloadAction<CreateUpdateBillingAddressRequest>) {
  const response: ApiSimpleResponse<BaseResponse<UserShippingAddress>> =
    yield createBillingAddressCall(payload);
  if (!response.isSuccess || !response.response) {
    yield put(manageUserBillingAddressErrorAction(response.error ?? ""));
    return;
  }
  toast.success(
    t(i18Enum.User_Shipping_BillingAddress_Create),
    toastSuccessStyle
  );
  yield put(
    manageUserBillingAddressSuccessAction(response.response?.createUserAddress)
  );
  yield call(refreshUserData);
}

function* updateBillingAddressSaga({
  payload,
}: PayloadAction<BaseIdRequest<CreateUpdateBillingAddressRequest>>) {
  const response: ApiSimpleResponse<UserShippingAddress> =
    yield updateBillingAddressCall(payload.id, payload.params);
  if (!response.isSuccess || !response.response) {
    yield put(
      manageUserBillingAddressErrorAction(
        response.error ?? t(i18Enum.Error_Occurred)
      )
    );

    return;
  }
  toast.success(
    t(i18Enum.User_Shipping_BillingAddress_Updated),
    toastSuccessStyle
  );
  yield put(getOrdersAction());
}

function* setDefaultBillingAddressSaga({
  payload: billingAddressId,
}: PayloadAction<string>) {
  const response: ApiSimpleResponse = yield setDefaultAddressCall(
    billingAddressId
  );
  if (!response.isSuccess || !response.response) {
    yield put(
      manageUserBillingAddressErrorAction(
        response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)
      )
    );
    return;
  }
  yield call(refreshUserData);
}

function* deleteAddressSaga({
  payload: { isBilling, addressId },
}: PayloadAction<{ isBilling: boolean; addressId: string }>) {
  const response: ApiSimpleResponse<BaseResponse<UserShippingAddress[]>> =
    yield deleteAddressCall(addressId);

  if (
    !response.isSuccess ||
    !response.response ||
    !response.response?.deleteUserAddress
  ) {
    toast.error(`${t(i18Enum.Error_Occurred)}`, toastErrorStyle);
    yield put(
      deleteAddressErrorAction({
        isBilling: isBilling,
        error: response.response?.ErrorMessage ?? t(i18Enum.Error_Occurred),
      })
    );
    return;
  }

  if (isBilling) {
    yield put(getOrdersAction());
  }
  yield call(refreshUserData);
}

function* refreshUserData() {
  const userResponse: ApiSimpleResponse<CurrentUserQuery> = yield getUserCall();

  const userData = userResponse.response?.currentUser as UserType;
  if (userData) {
    yield put(getUserSuccessAction(userData));
    yield put(sendUserPreferenceSuccessAction(userData));
  }
}

function* addProductWishlist({ payload: productId }: PayloadAction<string>) {
  const res: ApiSimpleResponse<AddProductWishlistMutation> =
    yield addProductWishlistCall(productId);
  if (!res.isSuccess || !res.response?.addProductToWishlist) {
    yield put(
      getWishListErrorAction(res.error ?? t(i18Enum.ErrorPage500_ErrorLabel))
    );
    toast.error(`${t(i18Enum.Error_Occurred)}`, toastErrorStyle);
    return;
  }

  toast.success("Prodotto aggiunto alla wishlist", toastSuccessStyle);

  yield put(getWishListSuccessAction());
}

function* removeProductWishlist({ payload: productId }: PayloadAction<string>) {
  const res: ApiSimpleResponse<RemoveProductFromWishlistMutation> =
    yield removeProductWishlistCall(productId);
  if (!res.isSuccess || !res.response?.removeProductFromWishlist) {
    yield put(
      getWishListErrorAction(res.error ?? t(i18Enum.ErrorPage500_ErrorLabel))
    );
    toast.error(`${t(i18Enum.Error_Occurred)}`, toastErrorStyle);
    return;
  }
  yield put(getWishListSuccessAction());
}

function* getWishListSaga() {
  yield call(refreshUserData);
  yield put(getWishListSuccessAction());
}

function* getOrdersSaga() {
  yield call(refreshUserData);
  yield put(getOrdersSuccessAction());
}

function* refreshStripeUrlSaga({
  payload,
}: PayloadAction<{ id: string; action: (url: string) => void }>) {
  if (payload.id === "") {
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    return;
  }
  const stripe: ApiSimpleResponse<CreateCheckoutSessionMutation> =
    yield stripeCall({
      uiMode: CheckoutSessionUiMode.Hosted,
      successUrl: isDev()
        ? "http://localhost:5173/payment/success"
        : "https://mooore.com/payment/success",
      cancelUrl: isDev()
        ? "http://localhost:5173/payment/error"
        : "https://mooore.com/payment/error",
      session: {
        orderId: payload.id,
      },
    });
  if (!stripe.isSuccess || !stripe.response?.createCheckoutSession) {
    toast.error(stripe.response?.ErrorMessage, toastErrorStyle);
    yield put(
      refreshStripeUrlErrorAction(
        stripe.response?.ErrorMessage ?? t(i18Enum.Error_Occurred)
      )
    );
    return;
  }

  payload.action(stripe.response.createCheckoutSession.url!);
  yield call(getOrdersSaga);
}

function* deleteOrderSaga({ payload: id }: PayloadAction<string>) {
  const res: ApiSimpleResponse<BaseResponse<OrderType[]>> =
    yield deleteOrderByIdCall(id);
  if (!res.isSuccess || !res.response?.Data) {
    yield put(deleteOrderErrorAction(res.error ?? t(i18Enum.Error_Occurred)));
    toast.error(`${t(i18Enum.Error_Occurred)}`, toastErrorStyle);
    return;
  }
  yield call(getOrdersSaga);
}

function* removeAuthSaga() {
  yield removeAuth();
  yield cookies.remove("shoppingBasketId");
  yield put(getShoppingBasketNoBasketAction());
  yield put(userResetDataAction());
}

function* addAuthSaga({ payload }: PayloadAction<{ token: string }>) {
  yield setAuth(payload.token);
  yield cookies.remove("shoppingBasketId");
  yield put(getShoppingBasketNoBasketAction());
  yield put(userResetDataAction());
}

// Generator function
export function* watchGetUser() {
  //This refers to getUserAction, check userSlice
  yield takeLatest(GET_USER, getUserSaga),
    yield takeLatest(EDIT_USER, editUserSaga);
  yield takeLatest(SEND_USER_PREFERENCE, sendUserPreferenceSaga);
  yield takeLatest(REMOVE_AUTH, removeAuthSaga);
  yield takeLatest(ADD_AUTH, addAuthSaga);
  yield takeLatest(SWITCH_PRIVATE_ACCOUNT, switchPrivateAccountSaga);
  yield takeLatest(CREATE_SHIPPING_ADDRESS, createShippingAddressSaga);
  yield takeLatest(UPDATE_USER_SHIPPING_ADDRESS, updateShippingAddressSaga);
  yield takeLatest(GET_ORDER_PAGE, getOrdersSaga);
  yield takeLatest(DELETE_ADDRESS, deleteAddressSaga);
  yield takeLatest(CREATE_BILLING_ADDRESS, createBillingAddressSaga);
  yield takeLatest(UPDATE_USER_BILLING_ADDRESS, updateBillingAddressSaga);
  yield takeLatest(
    SET_DEFAULT_USER_BILLING_ADDRESS,
    setDefaultBillingAddressSaga
  );
  yield takeLatest(
    SET_DEFAULT_USER_SHIPPING_ADDRESS,
    setDefaultShippingAddressSaga
  );
  yield takeLatest(GET_WHISLIST, getWishListSaga);
  yield takeLatest(ADD_PROD_WISHLIST, addProductWishlist);
  yield takeLatest(REMOVE_PROD_WISHLIST, removeProductWishlist);
  yield takeLatest(DELETE_ORDER, deleteOrderSaga);
  yield takeLatest(DELETE_USER, deleteUserSaga);
  yield takeLatest(REFRESH_STRIPE_URL, refreshStripeUrlSaga);
}
