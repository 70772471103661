import StepperHeader from "components/molecules/StepperHeader";
import StepThirdForm, {
  ThirdStepForm,
} from "components/organisms/StepThirdForm";
import { Step } from "components/organisms";
import Flex from "components/primitives/Flex";
import { UserPreference } from "redux/features/user/UserTypes";
import { useAppDispatch } from "redux/app/hooks";
import { putUserPreferenceAction } from "redux/features/user/userSlice";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

interface StepperThirdPageProps {
  handleCurrentStep: (step: Step) => void;
}

export function StepperThirdPage({ handleCurrentStep }: StepperThirdPageProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onSubmit = async (value: ThirdStepForm) => {
    const req: Partial<UserPreference> = {
      favoriteColors: value.colors,
      favoriteVibes: value.vibes,
      favoriteSports: value.sports,
      favoriteHobbies: value.hobbies,
    };

    dispatch(putUserPreferenceAction(req));
    handleCurrentStep(Step.fourth);
  };

  return (
    <>
      <Flex
        flexDirection={"column"}
        padding={["28px 5%"]}
        alignItems={"center"}
        $gap={1.5}
        width={[1, 1, "900px"]}
      >
        <StepperHeader
          title={t(i18Enum.User_UserProfile_AboutYou)}
          backToStep={() => handleCurrentStep(Step.second)}
        />
      </Flex>
      <StepThirdForm onSubmit={onSubmit} />
    </>
  );
}
