import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Section from "components/primitives/Section";
import Box from "components/primitives/Box";
import Flex from "components/primitives/Flex";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import Txt from "components/primitives/Txt";
import { H3, H5, H6 } from "theme/theme.styled";
import { CustomThemeContext } from "context/ThemeContext";
import { AffiliateFormType } from "utils/generalTypes/affiliate";
import Toggle from "components/primitives/Toggle";
import Button from "components/primitives/Button";
import { AppGeneralContext } from "context/AppGeneralContext";
import Grid from "components/primitives/Grid";
import { HiddenCheckbox, StyledCheckbox } from "components/molecules/Checkbox";
import SelectField from "components/molecules/SelectField";
import { OptionType } from "components/primitives/Select";
import { PAGE } from "utils/const/pagePath";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "components/primitives/Form";
import { affiliateProgramCall } from "api/contentCalls/affiliateCall";
import { toastType } from "utils/generalTypes/enum";
import { DotsLoader } from "components/molecules/DotsLoader";

const initialState: AffiliateFormType = {
  Name: "",
  Message: "",
  VATNumber: "",
  TaxIdCode: "",
  Address: "",
  Phone: "",
  MobilePhone: "",
  Email: "",
  WebSiteUrl: "",
  EcommerceBool: false,
  EcommerceUrl: "",
  Sector: "",
};

interface FormField {
  id: keyof Pick<
    AffiliateFormType,
    | "Name"
    | "VATNumber"
    | "TaxIdCode"
    | "Address"
    | "Phone"
    | "MobilePhone"
    | "Email"
    | "WebSiteUrl"
    | "EcommerceUrl"
  >;
  placeholder: string;
  value: string;
}

export const Affiliate = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { isMobile, addToast } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const maxW = customTheme.maxWidth;
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [checked, setChecked] = useState<null | number>(null);
  const [selectedSector, setSelectedSector] = useState<OptionType>();
  const [hasPolicy, setHasPolicy] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formSchema = Yup.object().shape({
    Name: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
    VATNumber: Yup.string().required(
      t(i18Enum.Validation_Client_Field_Required)
    ),
    TaxIdCode: Yup.string().required(
      t(i18Enum.Validation_Client_Field_Required)
    ),
    Address: Yup.string(),
    Phone: Yup.string().matches(
      phoneRegExp,
      t(i18Enum.Validation_Client_Field_Phone)
    ),
    MobilePhone: Yup.string()
      .matches(phoneRegExp, t(i18Enum.Validation_Client_Field_Phone))
      .required(t(i18Enum.Validation_Client_Field_Required)),
    Email: Yup.string()
      .matches(
        /^[\w\-.]+@([\w-]+\.)+[a-zA-Z]{2,10}$/i,
        t(i18Enum.Validation_Client_Field_Email)
      )
      .required(t(i18Enum.Validation_Client_Field_Required)),
    WebSiteUrl: Yup.string().required(
      t(i18Enum.Validation_Client_Field_Required)
    ),
    EcommerceBool: Yup.boolean().required(
      t(i18Enum.Validation_Client_Field_Required)
    ),
    EcommerceUrl: Yup.string(),
    Sector: Yup.string(),
  });

  const onChangeCheckbox = (index: number) => {
    setChecked((prev) => (index === prev ? null : index));
    formik.setFieldValue("EcommerceBool", index === 0 ? true : false);
  };

  const handleChangeSelect = (option: OptionType) => {
    setSelectedSector(option);
    formik.setFieldValue("Sector", option.label);
  };

  const formik = useFormik<AffiliateFormType>({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: formSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      const req = {
        name: values.Name,
        message: "",
        VATNumber: values.VATNumber,
        TaxIdCode: values.TaxIdCode,
        Address: values.Address,
        Phone: values.Phone,
        MobilePhone: values.MobilePhone,
        Email: values.Email,
        WebSiteUrl: values.WebSiteUrl,
        EcommerceBool: values.EcommerceBool,
        EcommerceUrl: values.EcommerceUrl,
        Sector: values.Sector,
      };
      const resp = await affiliateProgramCall(req);

      if (!resp.ok) {
        addToast(toastType.error, t(i18Enum.ErrorPage500_ErrorLabel));
      }
      setSubmitting(false);
      setLoading(false);
      formik.resetForm();
    },
  });

  const checkboxOptions = [
    {
      id: "77a672a4-990a-11ee-b9d1-0242ac120002",
      label: t(i18Enum.Affiliate_Option_Yes),
      value: true,
    },
    {
      id: "7d5268a2-990a-11ee-b9d1-0242ac120002",
      label: t(i18Enum.Affiliate_Option_No),
      value: false,
    },
  ];

  const affiliateFormFields: FormField[] = [
    {
      id: "Name",
      placeholder: t(i18Enum.Affiliate_Label_Name),
      value: formik.values.Name,
    },
    {
      id: "VATNumber",
      placeholder: t(i18Enum.Affiliate_Label_VATNumber),
      value: formik.values.VATNumber,
    },
    {
      id: "TaxIdCode",
      placeholder: t(i18Enum.Affiliate_Label_TaxIdCode),
      value: formik.values.TaxIdCode,
    },
    {
      id: "Address",
      placeholder: t(i18Enum.Affiliate_Label_Address),
      value: formik.values.Address,
    },
    {
      id: "Phone",
      placeholder: t(i18Enum.Affiliate_Label_Phone),
      value: formik.values.Phone,
    },
    {
      id: "MobilePhone",
      placeholder: t(i18Enum.Affiliate_Label_MobilePhone),
      value: formik.values.MobilePhone,
    },
    {
      id: "Email",
      placeholder: t(i18Enum.Affiliate_Label_Email),
      value: formik.values.Email,
    },
    {
      id: "WebSiteUrl",
      placeholder: t(i18Enum.Affiliate_Label_WebSiteUrl),
      value: formik.values.WebSiteUrl,
    },
    {
      id: "EcommerceUrl",
      placeholder: t(i18Enum.Affiliate_Label_EcommerceUrl),
      value: formik.values.EcommerceUrl,
    },
  ];

  const selectFields = [
    {
      label: "Apparel",
      value: "apparel",
    },
    {
      label: "Shoes",
      value: "shoes",
    },
    {
      label: "Accessories",
      value: "accessories",
    },
    {
      label: "Jewellery",
      value: "jewellery",
    },
    {
      label: "Cosmetics",
      value: "cosmetics",
    },
  ];

  useEffect(() => {
    if (
      formik.isValid &&
      Object.keys(formik.touched).length > 0 &&
      Object.keys(formik.errors).length === 0 &&
      hasPolicy
    ) {
      setIsValid(true);
      return;
    }
    setIsValid(false);
  }, [formik.isValid, formik.touched, formik.errors, hasPolicy]);

  return (
    <Box margin={["15% 0", "0"]} height={"100%"} width={"100%"} maxWidth={maxW}>
      <Form as="form" onSubmit={formik.handleSubmit}>
        <Section
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={"5%"}
          $gap={2}
        >
          <Txt
            width={"100%"}
            textAlign={"start"}
            fontSize={H3}
            variant="light"
            color={colors.primaryText}
          >
            {t(i18Enum.Affiliate_Title)}
          </Txt>
          <Grid
            gridTemplateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"}
            minWidth={"300px"}
            width={"100%"}
            $gap={3}
          >
            {affiliateFormFields.slice(0, 4).map((field) => {
              const error =
                formik.errors[field.id] && formik.touched[field.id]
                  ? String(formik.errors[field.id])
                  : undefined;
              const autoComplete =
                field.id === "Email"
                  ? "email"
                  : field.id === "Name"
                  ? "given-name"
                  : field.id === "MobilePhone"
                  ? "tel"
                  : field.id === "Address"
                  ? "street-address"
                  : "off";
              return (
                <InputFieldWardrobe
                  {...formik.getFieldProps(field.id)}
                  spaceProp={{ width: [1] }}
                  key={field.id}
                  id={field.id}
                  name={field.id}
                  placeholder={field.placeholder}
                  autoComplete={autoComplete}
                  label={field.placeholder}
                  value={formik.values[field.id]}
                  onChange={formik.handleChange}
                  onBlur={() => formik.setFieldTouched(field.id, true)}
                  error={error}
                />
              );
            })}
          </Grid>

          <Txt
            width={"100%"}
            textAlign={"start"}
            fontSize={H5}
            variant="light"
            color={colors.thirdText}
          >
            {t(i18Enum.Affiliate_Contacts)}
          </Txt>

          <Grid
            gridTemplateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"}
            minWidth={"300px"}
            width={"100%"}
            $gap={3}
          >
            {affiliateFormFields.slice(4, 8).map((field) => {
              const error =
                formik.errors[field.id] && formik.touched[field.id]
                  ? String(formik.errors[field.id])
                  : undefined;
              return (
                <InputFieldWardrobe
                  {...formik.getFieldProps(field.id)}
                  spaceProp={{ width: [1] }}
                  key={field.id}
                  id={field.id}
                  name={field.id}
                  placeholder={field.placeholder}
                  label={field.placeholder}
                  value={formik.values[field.id]}
                  onChange={formik.handleChange}
                  onBlur={() => formik.setFieldTouched(field.id, true)}
                  error={error}
                />
              );
            })}
          </Grid>

          <Flex width={"100%"} flexDirection={"column"}>
            <Txt
              width={"100%"}
              textAlign={"start"}
              fontSize={H6}
              variant="light"
              color={colors.thirdText}
            >
              {t(i18Enum.Affiliate_HasECommerce)}
            </Txt>

            <Flex justifyContent={"start"} margin={["5%", "2%"]} $gap={2}>
              {checkboxOptions.map((option, index) => {
                return (
                  <Flex key={option.id} maxWidth={"80%"} $gap={0.5}>
                    <HiddenCheckbox
                      type="checkbox"
                      checked={index === checked}
                      id={option.id}
                      name={option.label}
                      index={option.value}
                      onChange={() => onChangeCheckbox(index)}
                    />
                    <StyledCheckbox
                      height={14}
                      htmlFor={option.id}
                      checked={index === checked}
                    />
                    <Txt
                      textAlign={"start"}
                      variant="light"
                      color={colors.thirdText}
                      fontSize={[2]}
                    >
                      {option.label}
                    </Txt>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>

          <Flex
            flexDirection={"column"}
            minWidth={"300px"}
            width={"100%"}
            $gap={3}
          >
            <InputFieldWardrobe
              {...formik.getFieldProps(affiliateFormFields[8].id)}
              spaceProp={{ width: [1] }}
              id={affiliateFormFields[8].id}
              name={affiliateFormFields[8].id}
              placeholder={affiliateFormFields[8].placeholder}
              label={affiliateFormFields[8].placeholder}
              value={formik.values.EcommerceUrl}
              onChange={formik.handleChange}
              onBlur={() => formik.setFieldTouched("EcommerceUrl", true)}
              error={formik.errors.EcommerceUrl}
            />

            <SelectField<OptionType>
              marginBottom={30}
              width={[1]}
              id="Sector"
              placeholder={t(i18Enum.Affiliate_Label_Sector)}
              option={selectFields}
              value={selectedSector}
              handleBlur={() => {}}
              handleChange={(option) => handleChangeSelect(option)}
              labelExtractor={(x) => x.label}
              valueExtractor={(x) => x.value}
            />
          </Flex>

          <Flex
            minWidth={"300px"}
            width={"100%"}
            justifyContent={"start"}
            alignItems={"center"}
            $gap={2}
          >
            <Toggle
              checked={hasPolicy}
              handleChange={(value) => setHasPolicy(value)}
            />
            <Flex width={"80%"}>
              <Txt textAlign={"justify"} variant="light" fontSize={1}>
                {t(i18Enum.Affiliate_Desc1)}{" "}
                <Txt
                  variant="link"
                  color={colors.primaryText}
                  onClick={() => navigate(PAGE.affiliatePolicy.menuPath)}
                >
                  {t(i18Enum.Affiliate_Desc2)}
                </Txt>{" "}
                {t(i18Enum.Affiliate_Desc3)}
                <Txt
                  variant="link"
                  color={colors.primaryText}
                  onClick={() => navigate(PAGE.privacyPolicy.menuPath)}
                >
                  {t(i18Enum.Affiliate_Desc4)}
                </Txt>{" "}
                {t(i18Enum.Affiliate_Desc5)}{" "}
                <Txt
                  variant="link"
                  color={colors.primaryText}
                  onClick={() => navigate(PAGE.cookiePolicy.menuPath)}
                >
                  {t(i18Enum.Affiliate_Desc6)}
                </Txt>
              </Txt>
            </Flex>
          </Flex>
          <Flex width={"100%"} justifyContent={"start"}>
            <Button
              variant="login"
              widthCustom="m"
              type="submit"
              disabled={!hasPolicy}
            >
              {loading ? (
                <DotsLoader
                  customHeight="22px"
                  colors={customTheme.colors.secondaryBase}
                />
              ) : (
                t(i18Enum.Affiliate_Button_Send)
              )}
            </Button>
          </Flex>
        </Section>
      </Form>
    </Box>
  );
};
