import { getShopByUsernameQuery, getShopsQuery } from "api/graphql/requests";
import { queryClient } from "App";

export const getBrandByShopIdCall = async (shopCode: string) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: [`brandByCode_${shopCode}`],
      queryFn: () => getShopByUsernameQuery(shopCode),
    });

    return {
      response: { shop: data?.shop },
      isSuccess: true,
      error: false,
    };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const getBrandsListCall = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["brandsList"],
      queryFn: () => getShopsQuery(),
    });

    const slicedShops = data?.shops;
    return {
      response: { shops: slicedShops },
      isSuccess: true,
      error: false,
    };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};
