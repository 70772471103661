import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import Section from "components/primitives/Section";
import Txt from "components/primitives/Txt";
import Flex from "components/primitives/Flex";
import Image from "components/primitives/Image";
import { priceNum } from "utils";
import { HiddenCheckbox, StyledCheckbox } from "components/molecules/Checkbox";
import {
  BasketItem,
  BasketShop,
  ShippingOption,
} from "redux/features/cart/CartType";
import { UpdateShippingRequest } from "api/types/requests/cartRequest";
import { Link, useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { AppGeneralContext } from "context/AppGeneralContext";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux/app/hooks";

interface IShippingCard {
  id: string;
  shopItems?: BasketItem[];
  shop: BasketShop;
  shippingOptionSelect?: ShippingOption;
  shippingOptionsList: ShippingOption[];
  handleShopTotal: (_: UpdateShippingRequest) => void;
}

export const ShippingCard = ({
  id,
  shopItems,
  shop,
  shippingOptionSelect,
  shippingOptionsList,
  handleShopTotal,
}: PropsWithChildren<IShippingCard>) => {
  const { customTheme } = useContext(CustomThemeContext);
  const { setAsideIsOpen } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>(
    shippingOptionSelect?.id
  );
  const [price, setPrice] = useState<number | undefined>(
    parseFloat(shippingOptionSelect ? shippingOptionSelect.price : "0")
  );

  const navigate = useNavigate();
  const { t } = useTranslation();
  const isBuyNow = useAppSelector((state) => state.cart.cart.data?.isBuyNow);

  const shopPrice = parseFloat(shop.totalDiscountedPrice);

  const recalcShippinhCosts = () => {
    let isFreeShipping = false;

    shippingOptionsList?.forEach((option) => {
      isFreeShipping =
        option.freeShipping &&
        (shopPrice ?? 0) >= parseFloat(option.freeShippingMinAmount!);
      return isFreeShipping;
    });

    const req: UpdateShippingRequest = {
      ShippingOptionId: selectedOptionId ?? "",
      ShopId: shop?.id ?? "",
      price: isFreeShipping
        ? shopPrice ?? 0
        : (parseFloat(price) ?? 0) + (shopPrice ?? 0),
    };
    handleShopTotal(req);
  };

  useEffect(() => {
    recalcShippinhCosts();
  }, [selectedOptionId, price, shippingOptionsList]);

  function continueShopping(shopId: string) {
    setAsideIsOpen(false);
    navigate(`${PAGE.brand.menuPath}/${shop?.name}`, {
      state: { id: shopId },
    });
  }

  function checkShipping(par: UpdateShippingRequest) {
    setSelectedOptionId(par.ShippingOptionId);
    setPrice(par.price);
  }

  return (
    <>
      {shop?.id && shopItems?.length && (
        <Section
          key={shop.id}
          flexDirection={"column"}
          $gap={1}
          padding={["0", "0 10%"]}
          marginBottom={"10%"}
          width={[1]}
        >
          <Txt
            textAlign={"start"}
            as={"h3"}
            variant="medium"
            color={colors.thirdText}
            fontSize={[3]}
            width={[1]}
            margin={"0 0 0 0%"}
          >
            {shop?.name}
          </Txt>

          <Flex flexDirection={"column"}>
            <Flex flexDirection={"row"} width={"100%"} flexWrap={"wrap"}>
              {shopItems.map((item) => (
                <Flex
                  key={item.productVariantId}
                  flexGrow={1}
                  maxWidth={["calc(100% / 7)", "calc(100% / 10)"]}
                  $aspectRatio="1/1"
                  border={`1px ${colors.thirdBase}`}
                  borderStyle={"solid solid none"}
                >
                  <Image
                    width={"100%"}
                    src={item.productVariant?.images?.[0]?.bucketUrl}
                  />
                </Flex>
              ))}
            </Flex>

            <Section width={"100%"} border={`1px solid ${colors.thirdBase}`}>
              <Flex
                width={"100%"}
                flexDirection={"column"}
                padding={4}
                $gap={1.5}
              >
                {shippingOptionsList &&
                  shippingOptionsList?.map((option, idx) => {
                    const isLastItem =
                      shippingOptionsList &&
                      shippingOptionsList?.length - 1 === idx;

                    const isFreeShipping =
                      option.freeShipping &&
                      shopPrice >= parseFloat(option.freeShippingMinAmount!);

                    const checkboxId = "check" + id + "_" + option.id;

                    return (
                      <div key={`${shop.id}_${option.id}`}>
                        <Flex justifyContent={"space-between"}>
                          <Flex maxWidth={"80%"} $gap={0.5}>
                            <HiddenCheckbox
                              type="checkbox"
                              checked={selectedOptionId === option.id}
                              id={checkboxId}
                              name={option.name}
                              value={selectedOptionId}
                              onChange={() =>
                                checkShipping({
                                  ShippingOptionId: option.id,
                                  ShopId: id,
                                  price: isFreeShipping
                                    ? 0
                                    : parseFloat(option.price),
                                })
                              }
                            />
                            <StyledCheckbox
                              height={14}
                              htmlFor={checkboxId}
                              checked={selectedOptionId === option.id}
                            />

                            <Txt
                              textAlign={"start"}
                              variant="light"
                              color={colors.thirdText}
                              fontSize={[2]}
                            >
                              {option.name}
                            </Txt>
                          </Flex>
                          <Flex>
                            <Txt
                              variant={isFreeShipping ? "crossed" : "medium"}
                              color={colors.thirdText}
                              fontSize={[2]}
                            >
                              {option?.price &&
                                `${priceNum(option?.price ?? 0)}€`}
                            </Txt>

                            {isFreeShipping && (
                              <Txt
                                variant="medium"
                                color={colors.primaryText}
                                fontSize={[2]}
                                marginLeft={"5px"}
                              >
                                {`${priceNum(0)}€`}
                              </Txt>
                            )}
                          </Flex>
                        </Flex>
                        {isLastItem && (
                          <>
                            {!isFreeShipping && (
                              <Txt
                                textAlign={"start"}
                                $textTransform="inherit"
                                variant={isBuyNow ? "light" : "linkLight"}
                                color={colors.primaryText}
                                fontSize={[2]}
                                onClick={() =>
                                  isBuyNow ? null : continueShopping(shop?.id)
                                }
                              >
                                {t(i18Enum.Cart_Shipping_FreeShipping_Desc)}
                              </Txt>
                            )}
                            <Flex
                              flexDirection={["column", "row"]}
                              justifyContent={["start", "space-between"]}
                              $gap={1}
                            >
                              <Flex justifyContent={"start"} $gap={0.2}>
                                <Txt
                                  textAlign={"start"}
                                  variant="light"
                                  $textTransform="inherited"
                                  color={colors.primaryText}
                                  fontSize={[2]}
                                >
                                  {selectedOptionId
                                    ? t(i18Enum.Cart_Shipping_TotalWithShipping)
                                    : t(i18Enum.Cart_Shipping_Total)}
                                  :
                                </Txt>
                                <Txt
                                  textAlign={"start"}
                                  variant="medium"
                                  color={colors.primaryText}
                                  fontSize={[2]}
                                >
                                  {isFreeShipping ? (
                                    <>{priceNum(shopPrice)}€</>
                                  ) : (
                                    <>{priceNum((price ?? 0) + shopPrice)}€</>
                                  )}
                                </Txt>
                              </Flex>
                              <Link
                                target="_blank"
                                to={PAGE.shippings.menuPath}
                              >
                                <Txt
                                  textAlign={["start", "end"]}
                                  variant="linkLight"
                                  color={colors.thirdText}
                                  fontSize={[2]}
                                >
                                  {t(i18Enum.Cart_Shipping_Terms)}
                                </Txt>
                              </Link>
                            </Flex>
                          </>
                        )}
                      </div>
                    );
                  })}
              </Flex>
            </Section>
          </Flex>
        </Section>
      )}
    </>
  );
};
