import Search from "components/molecules/Search";
import GridCard, { CardView } from "components/organisms/GridCard";
import Flex from "components/primitives/Flex";
import Section from "components/primitives/Section";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { useContext, useEffect, useRef, useState } from "react";

import { H6 } from "theme/theme.styled";
import Grid from "assets/icons/grid.svg?react";
import Column from "assets/icons/column.svg?react";
import useIntersection from "utils/hooks/useIntersection";
// import { CardType } from "api/types/responses/cardsRequest";
import Lottie from "lottie-light-react";
import loader from "assets/lotties/loader.json";
import SearchPage from "./SearchPage";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { ProductActions } from "redux/features/product/productSlice";
import { ProductsListRequest } from "api/types/requests/productRequest";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

// type Filter = {
//   id: string;
//   label: string;
// };

// interface CardList {
//   pageNum: number;
//   pageSize: number;
//   orderBy: string;
//   orderDirection: string;
//   data: CardType[];
// }

const ShopFiltered = () => {
  //ref
  const bottom = useRef<HTMLDivElement | null>(null);
  //hook assignment
  const inViewport = useIntersection(bottom, "500px");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //context data
  const { isMobile, openAsideMenu, setShopParamsBackup } =
    useContext(AppGeneralContext);
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const maxW = customTheme.maxWidth;
  //redux selectors
  const { isLoading: isFetchingProducts, errors } = useAppSelector(
    (state) => state.product.productList
  );
  const productList = useAppSelector(
    (state) => state.product.productList.data?.Data
  );
  const pager = useAppSelector(
    (state) => state.product.productList.data?.Pager
  );
  //location Params
  const params: ProductsListRequest = useLocation().state;
  //useState data
  const [searchParams, setSearchParams] = useState<
    ProductsListRequest | undefined
  >(params);
  const [cardView, setCardView] = useState<CardView>(CardView.card);
  const { t } = useTranslation();

  useEffect(() => {
    if (!productList) {
      return;
    }
    //TODO: reenable
    // const viewItemList = productList?.map((product) => {
    //   return {
    //     item_id: product.id,
    //     item_name: product.name,
    //     item_brand: product.shop.name,
    //     // index: product.Order,
    //     item_variant: product.variants?.[0].sku,
    //   };
    // });
    // window.dataLayer.push({ ecommerce: null });
    // window.dataLayer.push({
    //   event: "view_item_list",
    //   ecommerce: {
    //     items: viewItemList,
    //   },
    // });
    // console.log({ viewItems: viewItemList });
  }, [productList]);

  //gestione infinite scroll
  useEffect(() => {
    if (inViewport?.isVisible) {
      // if (!pager?.TotalPages || pager?.PageNum >= pager?.TotalPages) {
      //   return;
      // }
      const newPage = pager?.PageNum + 1;
      dispatch(
        ProductActions.searchProductAction({
          ...searchParams,
          PageNum: newPage,
        })
      );
      setSearchParams((prev) => ({ ...prev, PageNum: newPage }));
    }
  }, [inViewport.isVisible]);

  useEffect(() => {
    setShopParamsBackup(undefined);
  }, []);

  function openFilter() {
    if (isMobile) {
      navigate(PAGE.shopFilter.menuPath);
    }
    openAsideMenu<undefined>(<SearchPage />, undefined);

    {
      !isMobile ? navigate(PAGE.shopList.menuPath) : null;
    }
  }

  useEffect(() => {
    setSearchParams((prev) => ({ ...prev, Name: params.Name }));
  }, [params.Name]);

  return (
    <Flex
      width={[1]}
      padding={["5% 0", "0,0"]}
      flexDirection={"column"}
      alignItems={"center"}
      mt={isMobile ? 5 : "unset"}
    >
      <Section
        minHeight={"100px"}
        maxWidth={[maxW[0]]}
        padding={"0 5%"}
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Flex width={[1]}>
          <Search
            short
            width={[1]}
            handleClick={() => openFilter()}
            value={params?.Name ?? ""}
          />
        </Flex>
      </Section>
      {/* TODO v2 <Section width={[1]} flexDirection={"column"}  alignItems={"center"} padding={["5% 5%", "2% 5%"]} position={"relative"} backgroundColor={colors.gray12} >
                <Flex width={[1]} maxWidth={[maxW[0]]} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Txt color={colors.primaryBase} as={"h6"} fontFamily={"BauPro"} fontWeight={"400"} fontSize={H6}> POPULAR FILTER</Txt>
                    <Txt variant='linkDanger' color={colors.red10} as={"span"} fontFamily={"BauPro"} fontWeight={"400"} fontSize={[2]} onClick={resetFilter}>reset</Txt>
                </Flex>
                
               <Flex width={["100%"]} paddingTop={3} maxWidth={[maxW[0]]} flexDirection={"row"} justifyContent={["spaceBetween", "start"]} flexWrap={"wrap"} alignItems={"center"} $transition='all 2s'>
                    {popularFilter.map((filter, index) => {
                        let isPromo: boolean = filter.label.includes("PROMO")
                        let isSelcted =  filterSelected?.includes(filter.id)
                        return <Button noHover width={100} key={filter.id} padding="10px 20px" margin={"5px 0 5px 5px"} variant={"option"} onClick={() => GoToFilter(filter.id)} 
                        style={isPromo && !isSelcted ? { boxShadow: colors.yellowShadow } : {}} 
                        color={isPromo && !isSelcted ? colors.secondaryBase : !isSelcted ? colors.thirdBase :  null  } 
                        backgroundColor={isPromo && !isSelcted ? colors.yellow : isSelcted  ? colors.primaryBase : colors.secondaryBase}>{filter.label}</Button>
                    })}
                    <Button noHover padding="10px 20px"  margin={"5px 0 5px 5px"} variant={"option"}
                        onClick={() => GoToFilter(undefined)}>VIEW ALL</Button>
                </Flex>
            </Section> */}
      {!isFetchingProducts && !productList?.length ? (
        <Txt
          as={"h6"}
          $textTransform={"default"}
          fontFamily={"BauPro"}
          fontWeight={"500"}
          fontSize={H6}
        >
          {searchParams?.Name
            ? `${t(i18Enum.Shopping_Page_NoResultsFor)} ${searchParams.Name}`
            : t(i18Enum.Shopping_Page_NoResults)}
        </Txt>
      ) : (
        <>
          <Section
            width={[1]}
            maxWidth={[null, maxW[0]]}
            padding={["3% 5%", "2% 5%", "2% 5%", "2% 0"]}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"start"}
            position={"relative"}
          >
            <Flex
              paddingBottom={["3%", "2%"]}
              maxWidth={[maxW[0]]}
              width={[1]}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Txt
                as={"h6"}
                $textTransform={"default"}
                fontFamily={"BauPro"}
                fontWeight={"500"}
                fontSize={H6}
              >
                {searchParams?.Name
                  ? `${t(i18Enum.Shopping_Page_ResultsFor)} ${
                      searchParams.Name
                    }`
                  : t(i18Enum.Shopping_Page_Results)}
              </Txt>
              <Flex
                flexDirection={"row"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <Column
                  stroke={
                    cardView === CardView.images
                      ? colors.primaryBase
                      : colors.thirdBase
                  }
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() => {
                    setCardView((x) =>
                      x === CardView.images ? CardView.card : CardView.images
                    );
                  }}
                />
                <Grid
                  stroke={
                    cardView === CardView.card
                      ? colors.primaryBase
                      : colors.thirdBase
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCardView((x) =>
                      x === CardView.card ? CardView.images : CardView.card
                    );
                  }}
                />
              </Flex>
            </Flex>
          </Section>
          <Section
            width={[1]}
            maxWidth={[null, maxW[0]]}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"start"}
            position={"relative"}
          >
            <GridCard products={productList} viewType={cardView} />
          </Section>
        </>
      )}
      <Section
        ref={bottom}
        width={["50%", "20%"]}
        maxWidth={[null, maxW[0]]}
        padding={["5% 0"]}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"start"}
        position={"relative"}
      >
        {isFetchingProducts && <Lottie animationData={loader} autoPlay />}
      </Section>
    </Flex>
  );
};
export default ShopFiltered;
