import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  OrderRequest,
  UpdateBasketRequest,
} from "./../../../api/types/requests/cartRequest";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  UPDATE_CART_BASKET,
  ADD_ITEM_BASKET,
  DELETE_BASKET_ITEM,
  GET_SHOPPING_BASKET,
  SET_SHIPPING_OPTION,
  CREATE_ORDER,
  BUY_NOW_ITEM,
  BUY_NOW_CANCEL,
  ShoppingBasket,
} from "./CartType";
import { ApiSimpleResponse } from "api/types/responses";
import {
  addItemToShoppingBasketCall,
  createOrderCall,
  removeFromShoppingBasketCall,
  getShoppingBasketCall,
  updateShoppingBasketCall,
  stripeCall,
} from "api/cartCalls/basketCall";
import {
  addItemErrorAction,
  addItemSuccessAction,
  createOrderErrorAction,
  createOrderSuccessAction,
  getShoppingBasketErrorAction,
  getShoppingBasketSuccessAction,
  updateShoppingBasketErrorAction,
  updateShoppingBasketSuccessAction,
} from "./cartSlice";

import { toast } from "react-toastify";
import { SelectedProductVariant } from "utils/generalTypes/Frontend";
import { RootState } from "redux/app/store";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";
import { toastErrorStyle, toastSuccessStyle } from "utils/const/const";
// import { filterItems } from "utils/functions/filterItems";
import {
  AddToShoppingBasketMutation,
  CheckoutSessionUiMode,
  CreateCheckoutSessionMutation,
  CreateOrderMutation,
  RemoveFromShoppingBasketMutation,
  ShoppingBasketQuery,
  UpdateShoppingBasketItemMutation,
} from "utils/graphql/generated/graphql";
import { isDev } from "utils";

// const getItemsFromCart = (state: CartStateType) => state.cart.data?.ShoppingBasket?.Shops;
// const isBuyNowSelector = (state: CartStateType) => state.cart.data?.isBuyNow;
// const processingProducts = (state: RootState) =>
//   state.cart.cart.data?.ShoppingBasket?.processingProducts;

function* buyNowSaga({
  payload,
}: PayloadAction<{ item: SelectedProductVariant; action: () => void }>) {
  const res: ApiSimpleResponse<AddToShoppingBasketMutation> =
    yield addItemToShoppingBasketCall([payload.item.id]);

  if (!res.isSuccess || !res.response?.addToShoppingBasket) {
    yield put(addItemErrorAction(t(i18Enum.Error_Occurred)));
    toast.error(res.error, toastErrorStyle);
    return;
  }
  yield put(addItemSuccessAction());
  yield call(GetShoppingBasketSaga);
  payload.action();
}

function* AddItemToBasketSaga({
  payload,
}: PayloadAction<SelectedProductVariant>) {
  const res: ApiSimpleResponse<AddToShoppingBasketMutation> =
    yield addItemToShoppingBasketCall([payload.id]);

  if (!res.isSuccess || !res.response?.addToShoppingBasket) {
    yield put(addItemErrorAction(t(i18Enum.Error_Occurred)));
    toast.error(t(i18Enum.Error_MissingSize), toastErrorStyle);
    return;
  }

  yield put(addItemSuccessAction());
  yield call(GetShoppingBasketSaga);

  //TODO: reenable
  // const items = filterItems(res.response.Data.ShoppingBasket.Shops);
  // const addToCartItem = items?.filter((item) => item.item_id === payload.id);
  // window.dataLayer.push({ ecommerce: null });
  // window.dataLayer.push({
  //   event: "add_to_cart",
  //   ecommerce: {
  //     currency: "EUR",
  //     value: addToCartItem?.[0].price,
  //     items: addToCartItem,
  //   },
  // });
  // console.log(addToCartItem);
  toast.success(
    t(i18Enum.Product_ProductCard_AddItemSuccess),
    toastSuccessStyle
  );
}

function* UpdateBasketSaga({ payload }: PayloadAction<UpdateBasketRequest>) {
  const res: ApiSimpleResponse<UpdateShoppingBasketItemMutation> =
    yield updateShoppingBasketCall(payload.shoppingBasketItemId!, {
      quantity: payload.quantity,
    });

  if (!res.isSuccess || !res.response?.updateShoppingBasketItem) {
    yield put(updateShoppingBasketErrorAction(t(i18Enum.Error_Occurred)));
    return;
  }

  yield put(updateShoppingBasketSuccessAction());
  yield call(GetShoppingBasketSaga);
}

function* DeleteItemSaga({ payload }: PayloadAction<string>) {
  const res: ApiSimpleResponse<RemoveFromShoppingBasketMutation> =
    yield removeFromShoppingBasketCall(payload);
  if (!res.isSuccess || !res.response?.removeFromShoppingBasket) {
    yield put(updateShoppingBasketErrorAction(t(i18Enum.Error_Occurred)));
    return;
  }
  yield put(updateShoppingBasketSuccessAction());
  yield call(GetShoppingBasketSaga);
}

function* GetShoppingBasketSaga() {
  const res: ApiSimpleResponse<ShoppingBasketQuery> =
    yield getShoppingBasketCall();
  if (!res.isSuccess || !res.response?.shoppingBasket) {
    yield put(getShoppingBasketErrorAction(t(i18Enum.Error_Occurred)));
    return;
  }
  yield put(
    getShoppingBasketSuccessAction(
      res.response.shoppingBasket as ShoppingBasket
    )
  );
}

function* SetShippingOptionSaga({
  payload,
}: PayloadAction<{ req: UpdateBasketRequest[]; action: () => void }>) {
  let hasError = false;

  for (const singleRequest of payload.req) {
    try {
      const res: ApiSimpleResponse<UpdateShoppingBasketItemMutation> =
        yield updateShoppingBasketCall(singleRequest.shoppingBasketItemId, {
          shippingOptionId: singleRequest.shippingOptionId,
          quantity: singleRequest.quantity,
        });

      if (!res.isSuccess || !res.response?.updateShoppingBasketItem) {
        hasError = true;
        break;
      }
    } catch (error) {
      console.error("Error in SetShippingOptionSaga: ", error);
      hasError = true;
      break;
    }
  }

  if (hasError) {
    yield put(getShoppingBasketErrorAction(t(i18Enum.Error_Occurred)));
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    return;
  }

  yield put(updateShoppingBasketSuccessAction());
  yield call(GetShoppingBasketSaga);
  payload.action();
}

function* CreateOrderSaga({
  payload,
}: PayloadAction<{ req: OrderRequest; action: (par: string) => void }>) {
  const isBuyNow: boolean | undefined = yield select(
    (state: RootState) => state.cart.cart.data?.isBuyNow
  );

  const res: ApiSimpleResponse<CreateOrderMutation> = yield createOrderCall(
    payload.req
  );
  if (!res.isSuccess || !res.response?.createOrder) {
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    yield put(createOrderErrorAction(t(i18Enum.Error_Occurred)));
    return;
  }

  const stripe: ApiSimpleResponse<CreateCheckoutSessionMutation> =
    yield stripeCall({
      uiMode: CheckoutSessionUiMode.Hosted,
      successUrl: isDev()
        ? "http://localhost:5173/payment/success"
        : "https://mooore.com/payment/success",
      cancelUrl: isDev()
        ? "http://localhost:5173/payment/error"
        : "https://mooore.com/payment/error",
      session: {
        orderId: res.response.createOrder.id,
      },
    });

  if (!stripe.isSuccess || !stripe.response?.createCheckoutSession) {
    toast.error(t(i18Enum.Error_Occurred), toastErrorStyle);
    yield put(createOrderErrorAction(t(i18Enum.Error_Occurred)));
    return;
  }

  if (isBuyNow) {
    yield put(createOrderSuccessAction());
    payload.action(stripe.response.createCheckoutSession.url!);
    return;
  } else {
    yield put(createOrderSuccessAction());
    payload.action(stripe.response.createCheckoutSession.url!);
  }
}

function* BuyNowDeleteSaga() {
  // const buyNowId: string | undefined = yield cookies.get("buyNowBasketId");
  // if (!!buyNowId) {
  //   yield cookies.remove("buyNowBasketId");
  // }
  yield call(GetShoppingBasketSaga);
}

export function* watchCart() {
  yield takeLatest(UPDATE_CART_BASKET, UpdateBasketSaga);

  //Check
  yield takeLatest(ADD_ITEM_BASKET, AddItemToBasketSaga);
  yield takeLatest(BUY_NOW_ITEM, buyNowSaga);
  //

  yield takeLatest(DELETE_BASKET_ITEM, DeleteItemSaga);
  yield takeLatest(GET_SHOPPING_BASKET, GetShoppingBasketSaga);
  yield takeLatest(SET_SHIPPING_OPTION, SetShippingOptionSaga);
  yield takeLatest(CREATE_ORDER, CreateOrderSaga);
  yield takeLatest(BUY_NOW_CANCEL, BuyNowDeleteSaga);
}
