import ArrowSvg from "components/atoms/ArrowSvg";
import { OutfitMarquee } from "components/organisms/OutfitMarquee";
import Flex from "components/primitives/Flex";
import Section from "components/primitives/Section";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GridCard from "components/organisms/GridCard";
import { CardType } from "api/types/responses/cardsRequest";
import { OutfitList } from "components/organisms/OutfitList";

// import { BuyOutfit } from "./BuyOutfit";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { OutfitsActions } from "redux/features/outfits/outfitsSlice";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { ProductType, VariantDTO } from "redux/features/product/ProductsTypes";

export type Outfit = {
  id: string;
  title: string;
  description: string;
  coverImage: string;
  products: CardType[];
};

export const OutFitPage = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { isMobile, setIsLoading } = useContext(AppGeneralContext);
  const color = customTheme.colors;
  const maxW = customTheme.maxWidth;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data: outfits, isLoading: isFetchingOutfitsList } = useAppSelector(
    (state) => state.outfits.outfitsList
  );
  const selectedOutfit = useAppSelector(
    (state) => state.outfits.selectedOutfit.data
  );

  const getProductsFromVariants = (variants: VariantDTO[]): ProductType[] => {
    const products: Record<string, ProductType> = {};

    variants?.forEach((variant) => {
      products[variant.product.handle] = {
        id: variant.product.id,
        name: variant.product.name,
        shop: variant.product.shop,
        handle: variant.product.handle,
      };
      if (!products[variant.product.handle].variants) {
        products[variant.product.handle].variants = [];
      }
      products[variant.product.handle].variants?.push(variant);
    });

    return Object.values(products);
  };

  useEffect(() => {
    if (!selectedOutfit) {
      return;
    }
    const viewItemList = selectedOutfit?.variants?.map((variantItem) => {
      return {
        item_id: variantItem.id,
        item_name: variantItem.product.name,
        item_brand: variantItem.product.shop.name,
        // index: product?.Order,
        item_variant: variantItem.sku,
      };
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_id: selectedOutfit?.id,
        item_list_name: selectedOutfit?.name,
        items: viewItemList,
      },
    });
  }, [selectedOutfit]);

  useEffect(() => {
    if (!outfits && !isFetchingOutfitsList) {
      dispatch(OutfitsActions.getOutfitsListAction());
    }
  }, []);

  useEffect(() => {
    setIsLoading(isFetchingOutfitsList);
  }, [isFetchingOutfitsList]);

  // function BuyAll() {
  //   if (!selectedOutfit) {
  //     return;
  //   }
  //   if (isMobile) {
  //     navigate(`${PAGE.buyOutfit.menuPath}/${selectedOutfit.Id}`);
  //   }
  //   openAsideMenu<string>(<BuyOutfit />, selectedOutfit.Id);
  // }

  if (isFetchingOutfitsList) {
    return <></>;
  }
  return (
    <>
      <Section height={["80px", 0]} position={"relative"} />
      <Flex
        paddingTop={4}
        top={[60]}
        left={"5%"}
        zIndex={2}
        flexDirection={"row"}
        position={"absolute"}
        onClick={() => navigate(-1)}
      >
        <ArrowSvg width={[40, 30]} />{" "}
        {!isMobile && (
          <Txt paddingLeft={10} variant="link" color={color.primaryBase}>
            {t(i18Enum.Common_Back)}
          </Txt>
        )}
      </Flex>
      <OutfitMarquee
        variants={selectedOutfit?.variants}
        description={selectedOutfit?.description ?? ""}
        title={selectedOutfit?.name ?? ""}
      />
      <Section
        width={[1]}
        maxWidth={[null, maxW[0]]}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Txt $textTransform="inherit" padding={["50px 0 ", "5% 0"]}>
          {t(i18Enum.Outfit_SelectedOutfit_Title)}
        </Txt>
        <GridCard
          products={getProductsFromVariants(selectedOutfit?.variants)}
        />
        {/* <Button variant='primary' margin={"30px 0"} fontWeight={"600"} padding={"10px 20px"} onClick={BuyAll}>
          ADD ALL TO CART
        </Button> */}
      </Section>
      <OutfitList
        outfits={outfits ?? []}
        selectedOutfit={selectedOutfit}
        setSelectedOutfit={(outfit) =>
          dispatch(OutfitsActions.setSelectedOutfitAction(outfit))
        }
      />
    </>
  );
};
