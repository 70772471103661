import { SpaceProps, WidthProps } from "styled-system";

import Flex from "../primitives/Flex";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import Box from "components/primitives/Box";
import { useContext } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import Txt from "components/primitives/Txt";
import Button from "components/primitives/Button";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from "components/primitives/Form";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
export interface FormStepLoginSecond {
  height?: number;
  weight?: number;
}

const initialSecondStep: FormStepLoginSecond = {
  height: undefined,
  weight: undefined,
};

// interface FormField {
//   id: keyof FormStepLoginSecond;
//   placeholder: string;
//   validation: (value: string) => messageResponse;
// }

interface StepLoginSecondForm extends WidthProps, SpaceProps {
  onSubmit: (value: FormStepLoginSecond) => void;
  data?: FormStepLoginSecond | null;
}

type FormStepSecondSchemaObject = {
  [key in keyof FormStepLoginSecond]: Yup.Schema<any>;
};

const formStepLoginSecondSchema =
  Yup.object().shape<FormStepSecondSchemaObject>({
    height: Yup.number().nullable(),
    weight: Yup.number().nullable(),
  });

function StepLoginSecondForm({
  onSubmit,
  data,
  ...props
}: StepLoginSecondForm) {
  const { isMobile } = useContext(AppGeneralContext);
  const { t } = useTranslation();

  const getNullableValue = (value: any) => {
    if (value === "") {
      return undefined;
    } else {
      return parseFloat(value);
    }
  };

  const formik = useFormik<FormStepLoginSecond>({
    initialValues: data ? data : initialSecondStep,
    validationSchema: formStepLoginSecondSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      onSubmit({
        height: getNullableValue(values.height),
        weight: getNullableValue(values.weight),
      });
      setSubmitting(false);
    },
  });

  return (
    <>
      <Form
        padding={["28px 5%"]}
        flex={1}
        width={[1]}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        onSubmit={formik.handleSubmit}
        {...props}
      >
        <Flex
          width={[1, 1, "500px"]}
          flexDirection={"column"}
          maxWidth={"500px"}
          marginBottom={50}
        >
          <InputFieldWardrobe
            {...formik.getFieldProps("height")}
            name={"height"}
            type="number"
            id={"height"}
            min={1}
            placeholder={t(i18Enum.OnboardingQuiz_StepUserDetails_Label_Height)}
            label={"Height"}
            spaceProp={{ width: [1], marginTop: [4], padding: ["10px 0"] }}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched("Height", true)}
            value={formik.values.height}
            error={formik.errors.height ? formik.errors.height : undefined}
          />
          <InputFieldWardrobe
            {...formik.getFieldProps("weight")}
            name={"weight"}
            id={"weight"}
            min={1}
            type="number"
            placeholder={t(i18Enum.OnboardingQuiz_StepUserDetails_Label_Weight)}
            label={"Weight"}
            spaceProp={{ width: [1], marginTop: [4], padding: ["10px 0"] }}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched("weight", true)}
            value={formik.values.weight}
            error={formik.errors.weight ? formik.errors.weight : undefined}
          />
        </Flex>
        {!isMobile && (
          <Flex
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
            maxWidth={"500px"}
          >
            <Txt variant="light" fontSize={[2]}>
              {t(i18Enum.User_UserProfile_Section_Personal)}
            </Txt>
            <Button
              type="submit"
              variant="primary"
              padding={"10px 20px"}
              disabled={!formik.isValid}
            >
              {t(i18Enum.Common_Next)}
            </Button>
          </Flex>
        )}
        <Box marginBottom={5} />
      </Form>

      {isMobile && (
        <MobileNavigationBottom
          text={t(i18Enum.Common_Next)}
          description={t(i18Enum.User_UserProfile_Section_Personal)}
          disabled={!formik.isValid}
          maxHeight={["auto", "auto", 110]}
          buttonAction={formik.handleSubmit}
        />
      )}
    </>
  );
}

export default StepLoginSecondForm;
