import { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import {
  HeightProps,
  PositionProps,
  WidthProps,
  height,
  top,
  width,
} from "styled-system";
import ArrowSvg from "../atoms/ArrowSvg";
import Flex from "../primitives/Flex";
import { AppGeneralContext } from "context/AppGeneralContext";
import {
  sliderAnimation,
  transformsDesktop,
  transformsMobile,
} from "./SliderHomepage";
import { useNavigate } from "react-router-dom";
import { HomepageSliderDTO } from "utils/generalTypes/homepageSlider";

const filterGray = "grayscale(100%) brightness(120%)";

interface CarrouselProps {
  images: HomepageSliderDTO[];
}
interface ImageWrapperProps extends WidthProps, HeightProps, PositionProps {
  isSelected: boolean;
  description: string;
  fontSize?: string;
  lineHeight?: string;
  textWidth?: string;
}

const afterStyles = (props: any) => css`
  height: auto;
  position: absolute;
  bottom: 3vh;
  left: 2vh;
  color: ${props.theme.colors.thirdBase};
  font-family: "BauProMedium";
  text-transform: uppercase;
  text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.75);
  transition: all 1s ease;
  text-align: left;
  word-wrap: break-word;
  filter: ${props.isSelected ? "opacity(1)" : "opacity(0)"};
  &:hover {
    filter: ${props.isSelected ? "none" : "none"};
  }
`;

const Image1Wrapper = styled.div<ImageWrapperProps>`
  transition: transform 0.4s ease;
  background-color: transparent;
  flex-shrink: 0;
  transform: rotate(8deg);
  cursor: pointer;
  border-radius: 16px;
  overflow: hidden;
  ${width};
  ${height};
  filter: ${({ isSelected }) => !isSelected && filterGray};
  &::after {
    content: "${({ description }) => description}";
    font-size: ${(props) => props.fontSize};
    line-height: ${(props) => props.lineHeight};
    width: ${(props) => props.textWidth};
    ${afterStyles}
  }
  &:hover {
    filter: ${({ isSelected }) => !isSelected && "none"};
  }
`;
const Image2Wrapper = styled.div<ImageWrapperProps>`
  transition: transform 0.4s ease;
  position: absolute;
  background-color: transparent;
  flex-shrink: 0;
  transform: rotate(-8deg);
  border-radius: 16px;
  overflow: hidden;
  left: ${["10vmin"]};
  cursor: pointer;
  ${width};
  ${height};
  filter: ${({ isSelected }) => !isSelected && filterGray};
  &::after {
    content: "${({ description }) => description}";
    font-size: ${(props) => props.fontSize};
    line-height: ${(props) => props.lineHeight};
    width: ${(props) => props.textWidth};
    ${afterStyles}
  }
  &:hover {
    filter: ${({ isSelected }) => !isSelected && "none"};
  }
`;
const Image3Wrapper = styled.div<ImageWrapperProps>`
  transition: transform 0.4s ease;
  position: absolute;
  background-color: blue;
  flex-shrink: 0;
  border-radius: 16px;
  left: ${["35vmin"]};
  overflow: hidden;
  cursor: pointer;
  ${width};
  ${height};
  ${top}
  filter: ${({ isSelected }) => !isSelected && filterGray};
  z-index: 2;
  &::after {
    content: "${({ description }) => description}";
    font-size: ${(props) => props.fontSize};
    line-height: ${(props) => props.lineHeight};
    width: ${(props) => props.textWidth};
    ${afterStyles}
  }
  &:hover {
    filter: ${({ isSelected }) => !isSelected && "none"};
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: ${["100%"]};
  max-width: ${["100%"]};
  height: ${["100%"]};
  min-height: ${["100%"]};
  transition: all 0.3s ease;
`;

const SliderHomepageDesktop = ({ images }: CarrouselProps): JSX.Element => {
  const [position, setPosition] = useState(0);
  const [numberSlide, setNumberSlide] = useState(0);
  const { isMobile } = useContext(AppGeneralContext);
  const cards: HomepageSliderDTO[] = useMemo(() => images, [images]);
  const totalCards = useMemo(() => cards.length, [cards]);
  const initialIndexVisible = [0, 1, 2];
  const navigate = useNavigate();
  const [ordered, setOrdered] = useState<number[]>(initialIndexVisible);
  const [cardsOrder, setCardsOrder] = useState<number | undefined>(undefined);
  const transforms: Array<sliderAnimation> = useMemo(
    () => (isMobile ? transformsMobile : transformsDesktop),
    [isMobile]
  );
  const cardRendered: HomepageSliderDTO[] = useMemo(() => {
    const newCards: HomepageSliderDTO[] = [];
    ordered.forEach((card) => newCards.push(cards[card]));
    return newCards;
  }, [ordered]);

  const changeCardOrdered = (
    ordered: number[],
    increment: 1 | -1,
    order?: number
  ) => {
    // togli indice più grande o più piccolo
    const minIndex = Math.min.apply(Math, ordered);
    const minIndexPosition = ordered.indexOf(minIndex);
    const maxIndex = Math.max.apply(Math, ordered);
    const maxIndexPosition = ordered.indexOf(maxIndex);
    let list: number[] = [...ordered];

    list.splice(increment === 1 ? minIndexPosition : maxIndexPosition, 1);

    // aggiungi nuovo indice
    const newValue =
      ((increment === 1 ? Math.max.apply(Math, list) : minIndex) +
        increment +
        totalCards) %
      totalCards;
    // let orderVale = order !== undefined ? (increment === 1 ? order : order + 1) : undefined;
    list.splice(order === undefined ? 0 : order, 0, newValue);

    if (numberSlide === 1 && increment === -1) {
      list = initialIndexVisible;
      setCardsOrder(undefined);
    }
    //TODO: exception da gestire 3 card
    if (numberSlide === totalCards) {
      list = [0, 1, 2];
      return;
    }
    //TODO: exception da gestire
    // if (numberSlide === totalCards - 1 && increment === -1) {
    //   list = [0, 4, 5]
    // }
    // if (numberSlide === totalCards - 2) {
    //   list = increment === 1 ? [0, 1, 5] : [3, 4, 5]
    // }

    if (order && order >= totalCards * 2) {
      //TODO: exception da gestire
      list = [0, 1, 2];
      return;
    }
    return list;
  };

  const calcCardsOrder = (increment: 1 | -1) => {
    let order = cardsOrder;
    if (cardsOrder === undefined) {
      if (increment === 1) {
        order = 0;
      } else {
        order = 2;
      }
    } else {
      if (increment === 1) {
        order = (cardsOrder + increment + 3) % 3;
      } else {
        order = (cardsOrder + increment + 3) % 3;
      }
    }

    if (numberSlide === 1 && increment === -1) {
      order === undefined;
    }

    if (numberSlide === cards.length - 1 && increment === 1) {
      order === undefined;
    }
    setCardsOrder(order);
    return order;
  };

  const fnPositions = (action: "asc" | "desc") => {
    //condizioni di uscita
    if (numberSlide === 0 && action === "desc") {
      return;
    }

    if (numberSlide === cards.length - 1 && action === "asc") {
      return;
    }
    // increment and decrement
    const increment = action === "asc" ? 1 : -1;
    // increment index of cardOrder to change
    const order = calcCardsOrder(increment);
    setNumberSlide((x) => x + increment);
    setPosition((x) => (x + increment + 6) % 6);

    setTimeout(() => {
      setPosition((x) => (x + increment + 6) % 6);
      const newOrder = changeCardOrdered(ordered, increment, order);
      //TO DONgestire più card
      setOrdered([0, 1, 2]);
    }, 400);
  };

  useEffect(() => {
    generateTransitionArray();
  }, [images]);

  //TEST
  function generateTransitionArray() {
    const positions: number[][] = [];
    const arrayTotalIndex: number[] = Array.from(Array(totalCards).keys());

    if (totalCards <= 3) {
      positions.push(arrayTotalIndex);
      return;
    }

    // const indexPosition0: number[] = arrayTotalIndex.filter((x) => x % 3 === 0);
    // const indexPosition1: number[] = arrayTotalIndex.filter((x) => x % 3 === 1);
    // const indexPosition2: number[] = arrayTotalIndex.filter((x) => x % 3 === 2);

    // const indexChanged: number = 0;
  }

  const handleNavigation = (url?: string): void => {
    if (!url) {
      return;
    }

    if (!url.includes("mooore.com")) {
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      const pathFromUrl = url.split("mooore.com")[1];
      navigate(pathFromUrl);
    }
  };

  const shuffleCards = (clickedIndex: number) => {
    const displacement = clickedIndex - numberSlide;
    const times = Math.abs(displacement);
    const direction = displacement < 0 ? "desc" : "asc";
    for (let i = 0; i < times; i++) {
      fnPositions(direction);
    }
  };

  return (
    <Flex
      width={"100%"}
      height={"100%"}
      marginTop={["30%", "20%", "15%", "15%"]}
      marginBottom={[]}
    >
      <ArrowSvg
        top={["45%", "50%"]}
        $transition={"opacity 0.5s ease"}
        position={"absolute"}
        disabled={numberSlide === 0}
        zIndex={20}
        left={["5vw"]}
        handleClick={() => fnPositions("desc")}
      />

      <Flex
        height={["60vh", "100%"]}
        position={"relative"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {cardRendered.map((card, index) => {
          if (index === 0) {
            return (
              <Image1Wrapper
                key={index}
                style={transforms[position].imageFirst}
                fontSize={isMobile ? "40px" : "60px"}
                lineHeight={isMobile ? "40px" : "60px"}
                textWidth={isMobile ? "150px" : "195px"}
                isSelected={position === 0}
                description={card.label}
                width={["50%", "30%"]}
                onClick={() => {
                  if (index === numberSlide) {
                    handleNavigation(card.redirectUrl);
                    return;
                  } else {
                    shuffleCards(index);
                  }
                }}
              >
                <Image src={card.image.bucketUrl} alt="Imagen 1" />
              </Image1Wrapper>
            );
          } else if (index === 1) {
            return (
              <Image2Wrapper
                key={index}
                style={transforms[position].imageSecond}
                fontSize={isMobile ? "40px" : "60px"}
                lineHeight={isMobile ? "40px" : "60px"}
                textWidth={isMobile ? "150px" : "195px"}
                isSelected={position === 2}
                description={card.label}
                width={["50%", "30%"]}
                height={["90%", "75%"]}
                onClick={() => {
                  if (index === numberSlide) {
                    handleNavigation(card.redirectUrl);
                    return;
                  } else {
                    shuffleCards(index);
                  }
                }}
              >
                <Image src={card.image.bucketUrl} alt="Imagen 2" />
              </Image2Wrapper>
            );
          } else if (index === 2) {
            return (
              <Image3Wrapper
                key={index}
                style={transforms[position].imageThird}
                fontSize={isMobile ? "40px" : "60px"}
                lineHeight={isMobile ? "40px" : "60px"}
                textWidth={isMobile ? "120px" : "195px"}
                isSelected={position === 4}
                description={card.label}
                width={["50%", "25%"]}
                onClick={() => {
                  if (index === numberSlide) {
                    handleNavigation(card.redirectUrl);
                    return;
                  } else {
                    shuffleCards(index);
                  }
                }}
              >
                <Image src={card.image.bucketUrl} alt="Imagen 3" />
              </Image3Wrapper>
            );
          }
        })}
      </Flex>
      <ArrowSvg
        top={["45%", "50%"]}
        $transition={"opacity 0.5s ease"}
        disabled={numberSlide >= cards.length - 1}
        position={"absolute"}
        zIndex={20}
        $transform={"rotate(180deg)"}
        right={["5vw"]}
        handleClick={() => fnPositions("asc")}
      />
    </Flex>
  );
};

export default SliderHomepageDesktop;
