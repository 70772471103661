import ArrowSvg from "../components/atoms/ArrowSvg";
import Flex from "../components/primitives/Flex";
import LoginForm from "../components/organisms/LoginForm";
import Txt from "../components/primitives/Txt";
import Button from "../components/primitives/Button";
import { useContext, useEffect } from "react";
import { CustomThemeContext } from "../context/ThemeContext";
import BackgroundVideo from "../components/molecules/VideoBackgroud";
import Box from "../components/primitives/Box";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "redux/app/hooks";
import { AppGeneralContext } from "context/AppGeneralContext";
import { PAGE } from "utils/const/pagePath";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { CartShipping } from "./CartShipping";

export const LoginPage = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { /* setIsLoading,*/ isMobile, openAsideMenu } =
    useContext(AppGeneralContext);
  const color = customTheme.colors;
  const navigate = useNavigate();

  const isBuyNow = useAppSelector((state) => state.cart.cart.data?.isBuyNow);
  const user = useAppSelector((state) => state.user.user.data);
  const { t } = useTranslation();

  const handleBack = () => {
    navigate(-1);
  };

  const navigateRegister = () => {
    navigate(PAGE.register.menuPath);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!user.favoriteHobbies && !user.favoriteColors) {
      navigate(PAGE.firstLogin.menuPath);
      return;
    }

    if (isBuyNow) {
      if (isMobile) {
        navigate(PAGE.shipping.menuPath);
        return;
      }
      openAsideMenu<undefined>(<CartShipping />, undefined);
    }
    navigate(PAGE.home.menuPath);
  }, [user, isMobile, isBuyNow]);

  return (
    <Box width={[1]} height={["100vh"]} maxWidth={customTheme.maxWidth[0]}>
      <Flex
        width={[1]}
        height={["100%"]}
        maxHeight={["100vh"]}
        maxWidth={customTheme.maxWidth[0]}
        position={"relative"}
        alignItems="center"
        marginTop={["100px", 0]}
        justifyContent={"left"}
      >
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          justifyContent={["normal", "center"]}
          minHeight={"calc(100vh - 200px)"}
          width={[1, 1, 0.5]}
          padding={["40px 28px 40px"]}
          $gap={4}
          style={{ boxSizing: "border-box" }}
        >
          <ArrowSvg handleClick={handleBack} widthCustom="s" />
          <LoginForm width={[1]} />
          <Txt variant="light" fontSize={[3]} color={color.thirdBase}>
            {t(i18Enum.Login_NoAccountCTO)}
          </Txt>
          <Button variant="register" type="button" onClick={navigateRegister}>
            {t(i18Enum.Login_RegisterButton)}
          </Button>
        </Flex>
        {/* <Txt
          variant="light"
          $textTransform="inherit"
          $cursor="pointer"
          fontSize={[2]}
          color={color.thirdBase}
          onClick={continueAsGuest}
        >
          {capitalize("continue as guest")}
        </Txt> */}
      </Flex>
      <BackgroundVideo
        video="first"
        variant="left"
        width={[1, 0.6]}
        height={["100vh"]}
        top={0}
      />
    </Box>
  );
};
