import { useContext, useEffect, useState } from "react";
import { HeightProps, SpaceProps } from "styled-system";
import { CustomThemeContext } from "../../context/ThemeContext";
import Txt from "../primitives/Txt";
import Button from "../primitives/Button";
import Flex from "../primitives/Flex";
import { useTranslation } from "react-i18next";
import {
  ProductOptionType,
  VariantDTO,
} from "redux/features/product/ProductsTypes";
import { SelectedProductVariant } from "utils/generalTypes/Frontend";
import { useAppSelector } from "redux/app/hooks";
import { i18Enum } from "i18n/types/translationType";
import { removeDuplicates } from "utils";
import { reorderSizes } from "utils/functions/filterItems";

interface SelectSizeProps extends SpaceProps, HeightProps {
  variant: "s" | "xl";
  title: string;
  productVariants: VariantDTO[];
  selected?: VariantDTO | null;
  productToBasket: (SelectedProduct: SelectedProductVariant) => void;
}

function SelectSize({ ...props }: SelectSizeProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { t } = useTranslation();
  const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
  const isLoading = useAppSelector((state) => state.cart.cart.isLoading);

  const selectProductColorId = props.selected?.options?.filter(
    (option) => option.type == "COLOR"
  )?.[0]?.id;

  const productOptionsWithColor = props.productVariants
    ?.map((variant) => variant.options)
    ?.filter((options) => {
      let hasColor = false;
      options.forEach((option) => {
        if (option.type == "COLOR" && option.id === selectProductColorId) {
          hasColor = true;
        }
      });
      return hasColor;
    });

  const productSizes = reorderSizes(
    removeDuplicates(
      productOptionsWithColor
        ?.map((options) => {
          return options?.filter((option) => option.type == "SIZE").flat();
        })
        .flat()
    )
  );

  const productSizesIds = props.productVariants.map((variant) => {
    return variant?.options
      ?.filter((item) => item.type == "SIZE")
      .map((variant) => variant.id);
  });

  const isOneSize =
    [...new Set(productSizesIds.flat())]?.length === 1 ||
    [...new Set(productSizesIds.flat())]?.length === 0;

  const retrieveSelection = (
    selectedProd?: VariantDTO | null,
    tagSelected?: ProductOptionType,
    isOneSize?: boolean
  ) => {
    const colorSelected =
      selectedProd?.options?.filter((option) => option.type == "COLOR")?.[0]
        ?.id || undefined;

    if (isOneSize) {
      props.productToBasket({
        id: selectedProd?.id ?? "",
      });
      return;
    }

    if (!selectedProd?.id || !tagSelected) {
      return;
    }
    if (!colorSelected || !tagSelected.id) {
      return;
    }
    setSelectedTagId(tagSelected.id);
    props.productToBasket({
      id: selectedProd.id,
    });
  };

  useEffect(() => {
    if (isOneSize) {
      retrieveSelection(props?.selected, undefined, isOneSize);
    }
  }, [isOneSize, props.selected]);

  useEffect(() => {
    if (!isLoading) {
      resetSelection();
    }
  }, [isLoading]);

  const resetSelection = () => {
    setSelectedTagId(null);
  };

  return (
    <Flex width={[1]} flexDirection={"column"} {...props}>
      <Txt
        width={[1]}
        fontSize={[1]}
        color={colors.thirdBase}
        textAlign={"left"}
        variant="light"
      >
        {props.title}
      </Txt>
      <Flex
        flexDirection={"row"}
        $gap={0.4}
        justifyContent={["flex-start", "flex-start", "flex-start"]}
        flexWrap={"wrap"}
        marginTop={"10px"}
        width={["100%", "100%"]}
      >
        {!isOneSize ? (
          productSizes.map((size, idx) => {
            return (
              size.label && (
                <Button
                  padding={props.variant === "xl" ? "2px 15px" : "2px 30px"}
                  key={size.id! + idx}
                  variant="switcher"
                  selected={size.id === selectedTagId}
                  onClick={() => {
                    //Se questo colore non è gia selezionato lo imposto
                    if (!(selectedTagId === size?.id)) {
                      const variantIdx = props.productVariants?.findIndex(
                        (variant) => {
                          let found = false;
                          variant.options
                            .filter((option) => option.type == "SIZE")
                            .forEach((option) => {
                              if (option.id === size.id) {
                                found = true;
                              }
                            });
                          return found;
                        }
                      );
                      if (
                        typeof variantIdx !== "undefined" &&
                        variantIdx >= 0 &&
                        props.productVariants
                      ) {
                        const variant = props.productVariants[variantIdx];
                        retrieveSelection(variant, size);
                        setSelectedTagId(size.id);
                      }
                    }
                  }}
                  lineHeight={"inherit"}
                >
                  {size.label}
                </Button>
              )
            );
          })
        ) : (
          <Button
            padding={props.variant === "xl" ? "2px 15px" : "2px 30px"}
            variant="switcher"
            selected={isOneSize}
            onClick={() => {
              // retrieveSelection(props?.selected, undefined, true);
            }}
            lineHeight={"inherit"}
          >
            {props.selected?.options?.filter((item) => item.type == "SIZE")
              ?.length === 1
              ? props.selected?.options?.filter(
                  (item) => item.type == "SIZE"
                )?.[0].label
              : t(i18Enum.Common_OneSize)}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default SelectSize;
