import { FC, PropsWithChildren, createContext, useState } from "react";


import { theme } from "../theme/theme.styled";

import { ThemeType } from "../theme/type/styled";


interface CustomThemeContextProps{
    customTheme:ThemeType;
    setCustomTheme:(theme:ThemeType)=>void;
}

const customThemeContextInit:CustomThemeContextProps={
    customTheme:theme,
    setCustomTheme:(_:ThemeType)=>{},
};


export const CustomThemeContext = createContext<CustomThemeContextProps>(customThemeContextInit)

export const CustomThemeProvider:FC<PropsWithChildren> =  ({ children }) => {
    //hook from styled-component to check current theme
   // const themeContext = useContext(ThemeContext)

    const [customTheme,setCustomTheme]= useState<any>(theme)

    const customThemeInitValue:CustomThemeContextProps ={
        customTheme,
        setCustomTheme,
    }


    return(
     <CustomThemeContext.Provider value={customThemeInitValue}>
        {children}
     </CustomThemeContext.Provider>
    )
}