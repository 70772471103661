import { SpaceProps, WidthProps } from "styled-system";
import Flex from "../primitives/Flex";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import Box from "components/primitives/Box";
import Txt from "components/primitives/Txt";
import Grid from "components/primitives/Grid";
import ColorGrid from "components/molecules/ColorGrid";
import { useContext, useEffect } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import Button from "components/primitives/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from "components/primitives/Form";
import Checkbox from "components/primitives/Checkbox";
import { useAppSelector } from "redux/app/hooks";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { getHobbies, getSports, getVibes } from "api/graphql/requests";
import { useQuery } from "@tanstack/react-query";

export interface ThirdStepForm {
  colors?: string[];
  vibes?: string[];
  sports?: string[];
  hobbies?: string[];
}

const initialThirdStep: ThirdStepForm = {
  colors: undefined,
  vibes: undefined,
  sports: undefined,
  hobbies: undefined,
};

export interface FormField {
  id: number;
  label: string;
  value?: boolean;
}

interface StepThirdFormProps extends WidthProps, SpaceProps {
  onSubmit: (value: ThirdStepForm) => void;
}

type FormStepThirdSchemaObject = {
  [key in keyof ThirdStepForm]: Yup.Schema<any>;
};

const formStepLoginThirdSchema = Yup.object().shape<FormStepThirdSchemaObject>({
  colors: Yup.array().of(Yup.string()).nullable(),
  vibes: Yup.array().of(Yup.string()).nullable(),
  sports: Yup.array().of(Yup.string()).nullable(),
  hobbies: Yup.array().of(Yup.string()).nullable(),
});

function StepThirdForm({ onSubmit, ...props }: StepThirdFormProps) {
  const { isMobile } = useContext(AppGeneralContext);
  const { t } = useTranslation();
  const { data: userPreference } = useAppSelector(
    (state) => state.user.userPreference
  );

  const { data: vibes } = useQuery({
    queryKey: ["userVibes"],
    queryFn: () => getVibes(),
  });

  const { data: sports } = useQuery({
    queryKey: ["userSports"],
    queryFn: () => getSports(),
  });

  const { data: hobbies } = useQuery({
    queryKey: ["userHobbies"],
    queryFn: () => getHobbies(),
  });

  useEffect(() => {
    if (userPreference?.favoriteColors) {
      formik.setFieldValue("colors", userPreference?.favoriteColors);
      formik.setFieldTouched("colors", true, true);
      formik.setFieldError("colors", undefined);
    }

    if (userPreference?.favoriteVibes) {
      formik.setFieldValue("vibes", userPreference?.favoriteVibes);
      formik.setFieldTouched("vibes", true, true);
      formik.setFieldError("vibes", undefined);
    }

    if (userPreference?.favoriteSports) {
      formik.setFieldValue("sports", userPreference?.favoriteSports);
      formik.setFieldTouched("sports", true, true);
      formik.setFieldError("sports", undefined);
    }

    if (userPreference?.favoriteHobbies) {
      formik.setFieldValue("hobbies", userPreference?.favoriteHobbies);
      formik.setFieldTouched("hobbies", true, true);
      formik.setFieldError("hobbies", undefined);
    }
  }, [
    userPreference,
    userPreference?.favoriteColors,
    userPreference?.favoriteHobbies,
    userPreference?.favoriteSports,
    userPreference?.favoriteVibes,
  ]);

  const formik = useFormik<ThirdStepForm>({
    initialValues: initialThirdStep,
    validationSchema: formStepLoginThirdSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values);

      setSubmitting(false);
    },
  });

  function addItem(type: "vibes" | "sports" | "hobbies", value: string) {
    const oldArr = formik.values[type] !== undefined ? formik.values[type] : [];
    const newArr = oldArr ? [...oldArr, value] : [value];
    formik.setFieldValue(type, newArr);
  }

  function deleteItem(type: "vibes" | "sports" | "hobbies", value: string) {
    const oldArr = formik.values[type] !== undefined ? formik.values[type] : [];
    const newArr = oldArr?.filter((x) => x !== value);
    formik.setFieldValue(type, newArr);
  }

  const Label = ({ str }: { str: string }): JSX.Element => {
    return (
      <>
        <Txt variant={"light"} fontSize={[3]}>
          {str}
        </Txt>
      </>
    );
  };

  return (
    <>
      <Form
        width={[1]}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        onSubmit={formik.handleSubmit}
        {...props}
      >
        <Flex
          as="fieldset"
          width={[1, 1, "500px"]}
          flexDirection={"column"}
          maxWidth={"500px"}
          padding={0}
          margin={0}
          border={"none"}
        >
          <Txt
            as="legend"
            variant={"medium"}
            fontSize={[3]}
            textAlign={"end"}
            padding={"0 26px 0"}
          >
            {t(i18Enum.OnboardingQuiz_Colors_Header)}
          </Txt>
          <ColorGrid
            values={formik.values.colors}
            handleChange={(values: string[]) =>
              formik.setFieldValue("colors", values)
            }
            onBlur={() => formik.setFieldError("colors", undefined)}
            onFocus={() => formik.setFieldTouched("colors", true)}
          />
        </Flex>

        <Flex
          as="fieldset"
          width={[1, 1, "500px"]}
          flexDirection={"column"}
          maxWidth={"500px"}
          marginTop={[4]}
          padding={"26px"}
          border={"none"}
          position={"relative"}
          $gap={1}
        >
          <Txt as="legend" variant={"medium"} fontSize={[3]} textAlign={"end"}>
            {t(i18Enum.OnboardingQuiz_Vibes_Header)}
          </Txt>
          {vibes?.options &&
            vibes.options.map((field, index) => {
              return (
                <Checkbox
                  key={field.id}
                  id={field.id.toString() + "vibes"}
                  name={field.label}
                  spaceProp={{
                    width: [1],
                    marginTop: index === 0 ? "18px" : "18px",
                  }}
                  checked={
                    formik.values.vibes
                      ? formik.values.vibes.includes(field.id)
                      : false
                  }
                  onChange={(e) => {
                    if (!e.target.checked) {
                      deleteItem("vibes", field.id);
                      return;
                    }
                    addItem("vibes", field.id);
                  }}
                  children={<Label str={field.label} />}
                />
              );
            })}
        </Flex>

        <Grid
          as="fieldset"
          gridTemplateColumns={["repeat(2, 1fr)"]}
          width={[1, 1, "500px"]}
          maxWidth={"500px"}
          padding={"26px"}
          margin={0}
          border={"none"}
          $gap={1}
        >
          <Txt as="legend" variant={"medium"} fontSize={[3]} textAlign={"end"}>
            {t(i18Enum.OnboardingQuiz_Sports_Header)}
          </Txt>
          {sports?.options &&
            sports.options.map((field, index) => (
              <Checkbox
                key={field.id}
                name={field.label}
                id={field.id.toString() + "sports"}
                spaceProp={{
                  width: [1],
                  marginTop: index === 0 ? "18px" : "18px",
                }}
                checked={
                  formik.values.sports
                    ? formik.values.sports.includes(field.id)
                    : false
                }
                onChange={(e) => {
                  if (!e.target.checked) {
                    deleteItem("sports", field.id);
                    return;
                  }
                  addItem("sports", field.id);
                }}
                children={<Label str={field.label} />}
              />
            ))}
        </Grid>

        <Grid
          as="fieldset"
          gridTemplateColumns={["repeat(2, 1fr)"]}
          width={[1, 1, "500px"]}
          maxWidth={"500px"}
          padding={"26px"}
          margin={0}
          border={"none"}
          $gap={1}
        >
          <Txt as="legend" variant={"medium"} fontSize={[3]} textAlign={"end"}>
            {t(i18Enum.OnboardingQuiz_Hobbies_Header)}
          </Txt>
          {hobbies?.options &&
            hobbies.options.map((field, index) => (
              <Checkbox
                key={field.id}
                id={field.id.toString() + "hobbies"}
                spaceProp={{
                  width: [1],
                  marginTop: index === 0 ? "18px" : "18px",
                }}
                checked={
                  formik.values.hobbies
                    ? formik.values.hobbies.includes(field.id)
                    : false
                }
                onChange={(e) => {
                  if (!e.target.checked) {
                    deleteItem("hobbies", field.id);
                    return;
                  }
                  addItem("hobbies", field.id);
                }}
                children={<Label str={field.label} />}
              />
            ))}
        </Grid>

        <Box
          width={[1]}
          marginTop={"auto"}
          height={110}
          zIndex={[1, 1, 0]}
        ></Box>
        {!isMobile && (
          <Flex
            width={[1]}
            justifyContent={"space-between"}
            alignItems={"center"}
            maxWidth={"500px"}
          >
            <Txt variant="light" fontSize={[2]}>
              {t(i18Enum.User_UserProfile_Section_Preferences)}
            </Txt>
            <Button
              type="submit"
              variant="primary"
              padding={"10px 20px"}
              disabled={!formik.isValid}
            >
              {t(i18Enum.Common_Next)}
            </Button>
          </Flex>
        )}
        <Box marginBottom={5} />
      </Form>

      {isMobile && (
        <MobileNavigationBottom
          text={t(i18Enum.Common_Next)}
          description={t(i18Enum.User_UserProfile_Section_Preferences)}
          disabled={!formik.isValid}
          buttonAction={formik.handleSubmit}
        />
      )}
    </>
  );
}

export default StepThirdForm;
