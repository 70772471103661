import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import Toggle from "../primitives/Toggle";
import { CustomThemeContext } from "context/ThemeContext";
import { useContext } from "react";
import { useAppDispatch } from "redux/app/hooks";
import { UserActions, setDefaultUserShippingAddressAction } from "redux/features/user/userSlice";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

interface IAddressCardFooter {
  id?: string;
  isDefault: boolean;
  callback: () => void;
  isPrinted?: boolean;
  isBilling?:boolean
}

export const AddressCardFooter = ({ isBilling,callback, isDefault, id, isPrinted = false }: IAddressCardFooter) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const setAddressAsDefault = () => {
    if (!id) {
      return;
    }
    if(isBilling){
       dispatch(UserActions.setDefaultUserBillingAddressAction(id));
       return
    }
    dispatch(setDefaultUserShippingAddressAction(id));
  };

  return (
    <Flex justifyContent={"space-between"}>
      <Flex alignItems={"center"}>
        {!isDefault ? (
          <>
            <Toggle checked={isDefault} handleChange={setAddressAsDefault} />
            <Txt fontSize={[2]} variant='light' marginLeft={"10px"}>
              {t(i18Enum.User_Shipping_ShippingAddresses_Button_SetDefaultAddress)}
            </Txt>
          </>
        ) : (
          <Txt fontSize={[2]} variant='light'>
            {t(i18Enum.User_Shipping_ShippingAddresses_Label_DefaultAddress)}
          </Txt>
        )}
      </Flex>

      <Flex alignItems={"center"} justifyContent={"flex-end"}>
        <Txt variant='linkLight' color={colors.primaryText} fontSize={[2]} onClick={() => callback()}>
          {isPrinted ? t(i18Enum.Common_Select) : t(i18Enum.Common_Edit)}
        </Txt>
      </Flex>
    </Flex>
  );
};
