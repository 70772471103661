import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'


interface ScrollToTopProps {
    children: ReactElement | ReactNode
    scrollY: number
}

const ScrollToTopWrapper = styled.div`
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
`
export const ScrollToTop = ({ children, scrollY }: ScrollToTopProps) => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > scrollY) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    const showBtn = useMemo(() => {
        if (showTopBtn) {
            return (
                <ScrollToTopWrapper
                    onClick={goToTop}>
                    {children}
                </ScrollToTopWrapper>
            );
        }
        return null
    }, [showTopBtn]);

    return <>{showBtn}</>;

}
