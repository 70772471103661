import { ChangeEvent, FocusEvent, KeyboardEvent, memo, useEffect, useRef } from 'react';

export interface CodeInputFieldProps {
  className?: string;
  index: number;
  value: string;
  hiddenCode: boolean;
  focused: boolean;
  onChange: (inputIndex: number, inputValue: string) => void;
  changeFocus: (pressedKey: string) => void;
}

const CodeInputField = memo(
  ({
    className,
    index,
    value,
    hiddenCode,
    focused,
    onChange,
    changeFocus,
  }: CodeInputFieldProps) => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
      focused && ref.current && ref.current.focus();
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(index, event.target.value);
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (['Backspace', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        changeFocus(event.key);
      }

      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      event.target.setSelectionRange(0, 1);
    };

    return (
      <input
        ref={ref}
        name={`code-input-field-${index}`}
        type={hiddenCode ? 'password' : 'text'}
        className={className || 'size-12 rounded-lg p-2 text-center text-3xl'}
        autoComplete="off"
        pattern="[0-9]*"
        maxLength={1}
        inputMode="tel"
        value={value || ''}
        onChange={handleChange}
        onKeyUp={handleKeyPress}
        onFocus={handleFocus}
      />
    );
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value && prevProps.focused === nextProps.focused;
  }
);

CodeInputField.displayName = 'CodeInputField';

export default CodeInputField;
