import { getOutfitsQuery } from "api/graphql/requests";

import { queryClient } from "App";

export const getOutfitsListCall = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["outfitsList"],
      queryFn: () => getOutfitsQuery(),
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};
