import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OUTFITS, OutfitsStateType } from "./OutfitsTypes";
import { OutfitHomePageDTO } from "api/types/responses/HomePageResponse";
const outfitsInitialState: OutfitsStateType = {
  homePageOutfit: {
    data: null,
    isLoading: false,
    errors: "",
  },
  outfitsList: {
    data: null,
    isLoading: false,
    errors: "",
  },
  selectedOutfit: {
    data: null,
    isLoading: false,
    errors: "",
  },
};

export const outfitSlice = createSlice({
  name: OUTFITS,
  initialState: outfitsInitialState,
  reducers: {
    setOutfitsHomePageAction: (
      state: OutfitsStateType,
      { payload }: PayloadAction<OutfitHomePageDTO>
    ) => {
      state.homePageOutfit.data = payload;
    },
    getOutfitsListAction: (state: OutfitsStateType) => {
      (state.outfitsList.errors = ""), (state.outfitsList.isLoading = true);
    },
    getOutfitsListSuccessAction: (
      state: OutfitsStateType,
      { payload }: PayloadAction<OutfitHomePageDTO[]>
    ) => {
      state.outfitsList.data = payload;
      (state.outfitsList.errors = ""), (state.outfitsList.isLoading = false);
    },
    getOutfitsListErrorAction: (
      state: OutfitsStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      (state.outfitsList.errors = error), (state.outfitsList.isLoading = false);
    },
    setSelectedOutfitAction: (
      state: OutfitsStateType,
      { payload }: PayloadAction<OutfitHomePageDTO>
    ) => {
      state.selectedOutfit.data = payload;
    },
  },
});

export const OutfitsActions = outfitSlice.actions;

export const {
  getOutfitsListAction,
  getOutfitsListErrorAction,
  getOutfitsListSuccessAction,
  setOutfitsHomePageAction,
  setSelectedOutfitAction,
} = outfitSlice.actions;

export default outfitSlice.reducer;
