import { StylesConfig, components, ControlProps, OptionProps } from "react-select";
import { ReactNode, useContext } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import { ThemeType } from "../../theme/type/styled";
import { WidthProps } from "styled-system";

export const theme = (): ThemeType => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { customTheme } = useContext(CustomThemeContext);
  return customTheme;
};

export interface OptionType {
  label: string;
  value: string;
}

export interface DateOptionType {
  label: string;
  value: string | number | Date;
}

export interface OrderOptionType {
  label: string;
  value: string;
  filter: string;
  direction: string;
}

export interface SelectProps extends OptionProps, WidthProps {
  props: ControlProps;
  childrenToggleSee: ReactNode;
}

export const CreateDropdownStyle = <T,>() => {

  return {
    placeholder: (base) => ({
      ...base,
      textAlign: "start",
      color: theme().colors.thirdTextContrast,
      fontFamily: "BauPro",
      fontSize: theme().fontSizes[2],
      textTransform: "uppercase",
      position: "absolute",
      left: -5,
      display: "none",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      // visibility: currentWidth < 768 ? "hidden" : "visible",
      padding: 0,
      color: theme().colors.thirdBase,
      fontSize: "15px",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : null,
      cursor: "pointer",
      transition: "transform 0.1s ease",
      "&:hover": {
        color: theme().colors.primaryBg,
      },
    }),

    container: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      alignItems: "center",
      display: "flex",
      border: "none",
      userSelect: "none",
      "&:hover": {
        border: "none",
      },
      "&:focus-within": {
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      minHeight: "27px",
      maxHeight: "27px",
      boxShadow: "none",
      border: `2px solid ${theme().colors.thirdBase}`,
      width: "100%",
      fontSize: "12px",
      backgroundColor: theme().colors.secondaryBase,
      // borderRadius: state.menuIsOpen ? "10px 10px 0 0" : "10px",
      padding: "0 5px",

      "&:focus-within": {
        outline: "none",
      },
      "&:hover": {
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    }),

    menu: (baseStyles) => ({
      ...baseStyles,
      width: "100%",
      position: "absolute",
      margin: "0",
      padding: "0",
      borderColor: theme().colors.secondaryBase,
      borderRadius: " 0 0 8px 8px",
      borderBottom: `18px solid ${theme().colors.grayBg}`,
      overflow: "hidden",
      zIndex: "10",
    }),

    menuList: (provided) => ({
      ...provided,
      border: "none",
      maxHeight: "166px",
      margin: "0",
      padding: "0",
      overflowY: "scroll",
      borderBottom: `0.2px solid ${theme().colors.thirdTextContrast}`,
    }),

    input: (provided) => ({
      ...provided,
      color: theme().colors.thirdTextContrast,
      fontFamily: "BauPro",
      textTransform: "inherit",
      fontSize: theme().fontSizes[2],
    }),

    valueContainer: (provided) => ({
      ...provided,
      overflow: "visible",
    }),

    option: (provided) => {
      const optionStyle = {
        ...provided,
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        fontFamily: "BauPro",
        textTransform: "inherit",
        fontSize: theme().fontSizes[2],
        height: "30px",
        cursor: "pointer",
        borderTop: "1px solid",
        borderColor: theme().colors.thirdBase,
        backgroundColor: theme().colors.graySolid,
        "&:hover": {},
        "&:active": {
          backgroundColor: theme().colors.graySolid,
        },
      };
      return optionStyle;
    },
    singleValue: (provided, state) => {
      const singleStyle = {
        ...provided,
        textAlign: "left",
        opacity: state.isDisabled ? 0.5 : 1,
        transition: "opacity 300ms",
        color: theme().colors.thirdText,
        fontFamily: "BauPro",
        fontSize: theme().fontSizes[2],
        textTransform: "uppercase",
      };
      return singleStyle;
    },
    multiValueLabel: (provided, state) => {
      const multiLabelStyle = {
        ...provided,
        textAlign: "left",
        opacity: state.isDisabled ? 0.5 : 1,
        transition: "opacity 300ms",
        backgroundColor: theme().colors.grayBase,
        color: theme().colors.thirdBgSubtle,
        fontFamily: "BauPro",
        fontSize: theme().fontSizes[1],
        padding: "2px 5px",
        textTransform: "inherit",
      };
      return multiLabelStyle;
    },
    multiValueRemove: (provided, state) => {
      const multiRemoveStyle = {
        ...provided,
        textAlign: "left",
        opacity: state.isDisabled ? 0.5 : 1,
        transition: "opacity 300ms",

        color: theme().colors.grayBgSubtle,
        fontFamily: "BauPro",
        fontSize: theme().fontSizes[1],
        textTransform: "inherit",
        cursor: "pointer",
      };
      return multiRemoveStyle;
    },

    noOptionsMessage: (provided) => {
      const optionStyle = {
        ...provided,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "BauPro",
        textTransform: "inherit",
        fontSize: theme().fontSizes[2],
        height: "36px",
        borderTop: "1px solid",
        borderColor: theme().colors.thirdBase,
        backgroundColor: theme().colors.graySolid,
        "&:hover": {},
        "&:active": {
          backgroundColor: theme().colors.graySolid,
        },
      };
      return optionStyle;
    },
  } as StylesConfig<T>;
};

export const Control = ({ children, ...props }: ControlProps) => {
  return <components.Control {...props}>{children}</components.Control>;
};
