import { useEffect, useState } from 'react';

import CodeInputField from './CodeInputField';

export type CodeInputProps = {
  className?: string;
  numberOfFields: number;
  hiddenCode: boolean;
  onChange?: ({
    asNumber,
    asString,
    asObject,
  }: {
    asNumber: number;
    asString: string;
    asObject: object;
  }) => void;
};

export default function CodeInput({
  className,
  numberOfFields,
  hiddenCode,
  onChange,
}: CodeInputProps) {
  const [values, setValues] = useState<{ [key: number]: string }>({});
  const [currentField, setCurrentField] = useState<number | null>(null);

  useEffect(() => {
    const fullValue = Object.values(values).join('');
    if (onChange) {
      onChange({
        asNumber: Number(fullValue),
        asString: Object.values(values).join(''),
        asObject: values,
      });
    }
  }, [onChange, values]);

  const handleFieldChange = (inputIndex: number, inputValue: string) => {
    setValues((prevState) => ({
      ...prevState,
      [inputIndex]: inputValue,
    }));
    if (inputValue) setCurrentField(/[0-9]|^$/.test(inputValue) ? inputIndex + 1 : null);
  };

  const changeFocus = (keyCode: string) => {
    if (currentField) {
      if (isNaN(currentField)) return;
      if (keyCode === 'Backspace' && currentField > 0) {
        setCurrentField(currentField - 1);
      }
      if (keyCode === 'ArrowLeft' && currentField > 0) {
        setCurrentField(currentField - 1);
      }
      if (keyCode === 'ArrowRight' && currentField < numberOfFields - 1) {
        setCurrentField(currentField + 1);
      }
    }
  };

  const fields = Array(numberOfFields)
    .fill(0)
    .map((_, index) => (
      <CodeInputField
        key={`code-input-field-${index}`}
        index={index}
        value={values[index]}
        hiddenCode={hiddenCode}
        focused={index === currentField}
        onChange={handleFieldChange}
        changeFocus={changeFocus}
      />
    ));

  return <div className={className}>{fields}</div>;
}
