import { ProductType } from "redux/features/product/ProductsTypes";
import { ProductsListRequest } from "api/types/requests/productRequest";

import { getAriesCall } from "api/baseAriesCall";

import {
  getProducts,
  getProductByCode,
  getProductCategories,
} from "api/graphql/requests";

import { queryClient } from "App";

const PRODUCT_CONTROLLER = "Product";

export const productSearchCall = async (params: ProductsListRequest) => {
  //TODO: nb: prima faceva cosi per qualche motivo da notare "IsFiltering"  a undefined
  //   const req: ProductsListRequest = { ...params, IsFiltering: undefined };
  //   return await postAriesCall<ProductsListRequest, PaginatedResponse<ProductType>>(`${PRODUCT_CONTROLLER}/Search`, req);

  try {
    const data = await queryClient.fetchQuery({
      queryKey: [
        "productsSearch",
        JSON.stringify(params.TagsIds) + JSON.stringify(params.Name),
      ],
      queryFn: () => getProducts(params),
    });

    //TODO: add pagination
    const sliced = data?.products?.slice(0, 200);
    return {
      response: { products: sliced },
      isSuccess: true,
      error: false,
    };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const getProductCategoriesCall = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["productCategories"],
      queryFn: () => getProductCategories(),
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const getSimilarProductCall = async (id: string) =>
  await getAriesCall<ProductType>(`${PRODUCT_CONTROLLER}/${id}/Similar`);

export const getProductFromHandle = async (handle: string) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: [`productsSearchByCode_${handle}`],
      queryFn: () => getProductByCode(handle),
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};
