import {
  getHomeSlidersQuery,
  getFeaturedProductsQuery,
  getOutfitsQuery,
} from "api/graphql/requests";

import { queryClient } from "App";

// export const getHomePageData = async()  =>  await getAriesCall<BaseResponse<HomePageCallResponse>>('Homepage')
export const getHomePageSliders = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["homePageSliders"],
      queryFn: () => getHomeSlidersQuery(),
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const getFeaturedProducts = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["featuredProducts"],
      queryFn: () => getFeaturedProductsQuery(),
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const getHomePageOutfits = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["outfitsList"],
      queryFn: () => getOutfitsQuery(),
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

// dispatch(DomainValuesActions.getCategoryDomainValues())
// dispatch(DomainValuesActions.getSizeCategories())
