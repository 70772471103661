import * as Switch from "@radix-ui/react-switch";
import styled from "styled-components";
import Tooltip from "./Tooltip";

const CustomToogleRoot = styled(Switch.Root)`
  background-color: ${(props) => props.theme.colors.grayBase} !important;
  all: unset;
  width: 42px;
  height: 25px;
  border-radius: 9999px;
  position: relative;
  cursor: pointer;
  &[data-state="checked"] {
    background-color: ${(props) => props.theme.colors.primaryBase} !important;
  }
`;

const CustomToggleThumb = styled(Switch.Thumb)`
  background-color: ${(props) => props.theme.colors.thirdBase} !important;
  display: block;
  width: 21px;
  height: 21px;
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(2px);

  &[data-state="checked"] {
    transform: translateX(19px);
  }
`;

interface ToggleProps {
  handleChange?: (checked: boolean) => void;
  checked?: boolean
  disabled?: boolean
  tooltipText?: string
}

const Toggle = ({ handleChange, checked = false, disabled = false, tooltipText }: ToggleProps) => {
  
  if (!disabled) {

    return (
      <CustomToogleRoot disabled={disabled} onCheckedChange={handleChange} checked={checked}>
        <CustomToggleThumb />
      </CustomToogleRoot>)
  }
  return (
    <Tooltip content={tooltipText}>
      <CustomToogleRoot disabled={disabled} onCheckedChange={handleChange} checked={checked}>
        <CustomToggleThumb />
      </CustomToogleRoot>
    </Tooltip>
  )
};

export default Toggle;
