import styled from "styled-components";
import {
  ColorProps,
  flexbox,
  LayoutProps,
  SpaceProps,
  layout,
  space,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  color,
  compose,
  variant,
} from "styled-system";

 const   SectionCardContainer =styled.div<
 ColorProps &
   FlexboxProps &
   LayoutProps &
   SpaceProps &
   PositionProps &
   BorderProps & { 
     $gap?: number ;
     $aspectRatio?: string;
     $transition?: string;
     variant: "left" |"center"| "right";
   }
>`
 display: flex;
 aspect-ratio: ${(props) => props.$aspectRatio ?? undefined};
 gap: ${(props) => props.$gap ? props.$gap + `em`:undefined};
 background-color: ${(props) => props.theme.colors.grayBg};
 border-radius: 16px;
 transition: transform 2s ;
 ${() =>
    compose(
      variant({
        variants: {
            left: {
                transform:"rotate(-8deg)"
            },
            center: {
                transform:"rotate(0deg)"
            },
            right: {
                transform:"rotate(8deg)"
            }
        }
      }))}

 ${space}
 ${layout}
   ${flexbox}
   ${position}
   ${border}
   ${color}
`;
export default SectionCardContainer;