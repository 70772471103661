import { useContext, useMemo } from "react";
import Card from "../molecules/Card";
import Grid from "../primitives/Grid";

import ProductCardImage from "components/atoms/ProductCardImage";
import { AppGeneralContext } from "context/AppGeneralContext";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { ProductType } from "redux/features/product/ProductsTypes";
// import { useAppDispatch } from "redux/app/hooks";
// import { ProductActions } from "redux/features/product/productSlice";

export enum CardView {
  "images",
  "card",
  "cardMobile",
}
interface GridCardProps {
  products?: ProductType[] | undefined;
  viewType?: CardView;
}
export type IdCard = string | number;

function GridCard({ viewType, products }: GridCardProps) {
  const grid = useMemo(() => {
    if (viewType === CardView.images) {
      return [
        "repeat(4,1fr)",
        "repeat(5,1fr)",
        "repeat(5,1fr)",
        "repeat(5,1fr)",
      ];
    } else if (viewType === CardView.cardMobile) {
      return [
        "repeat(2,1fr)",
        "repeat(2,1fr)",
        "repeat(2,1fr)",
        "repeat(2,1fr)",
      ];
    } else {
      return [
        "repeat(2,1fr)",
        "repeat(3,1fr)",
        "repeat(3,1fr)",
        "repeat(4,1fr)",
      ];
    }
  }, [viewType]);
  const { isMobile, asideIsOpen, setAsideIsOpen } =
    useContext(AppGeneralContext);
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();

  const goToProduct = (product: ProductType) => {
    if (asideIsOpen) {
      setAsideIsOpen(false);
    }

    //Se la variante proviene da outfit, ha anche le options, quindi la passo allo state per preselezionarla
    const productVariant =
      product.variants && product.variants?.[0]?.options?.length > 0
        ? product.variants?.[0]
        : undefined;

    navigate(`${PAGE.product.menuPath}/${product.handle}`, {
      state: {
        id: product.id,
        variant: productVariant,
      },
    });
  };

  return (
    <Grid width={[1]} gridTemplateColumns={grid}>
      {products?.map((product, idx) => {
        if (product.variants?.length == 0) {
          console.error("[GridCard] no variant for product id", product.id);
          return <div className="hidden" key={idx}></div>;
        }
        if (viewType !== CardView.images) {
          return (
            <Card
              product={product}
              onClick={() => goToProduct(product)}
              key={product.id}
            />
          );
        } else {
          return (
            <ProductCardImage
              key={product.id}
              $backgroundImage={product.variants?.[0].images?.[0]?.bucketUrl}
              $animated={!isMobile}
              onClick={() =>
                navigate(`${PAGE.product.menuPath}/${product.handle}`)
              }
            />
          );
        }
      })}
    </Grid>
  );
}

export default GridCard;
