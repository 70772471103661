import { useState } from "react";
import { XLg } from "react-bootstrap-icons";
import CardWrapperWhite from "../primitives/CardWrapperWhite";
import { AddressCardFooter } from "../atoms/AddressCardFooter";
import { ConfirmDialog } from "./ConfirmDialog";
import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import Tooltip from "../primitives/Tooltip";
import { useTheme } from "styled-components";
import { useDispatch } from "react-redux";
import { deleteAddressAction } from "redux/features/user/userSlice";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export enum addressType {
  disabled = "disabled",
  detail = "detail",
  printedAddress = "printedAddress",
  userAddress = "userAddress",
  printedBilling = "printedBilling",
  userBilling = "userBilling",
}

export type BaseAddressFields = {
  id: string;
  callingCodeId?: number;
  city: string;
  country?: string;
  countryId?: number;
  isSelected?: boolean;
  isBilling: boolean;
  isDefault?: boolean;
  name?: string;
  notes?: string;
  phone: string;
  province?: string;
  provinceId?: number;
  region?: string;
  regionId?: number;
  state?: string;
  street: string;
  streetNumber: string;
  zipCode: string;
};

export type IAddressCard = {
  type: addressType;
  handleEdit: () => void;
} & Omit<BaseAddressFields, "phone">;

export const AddressCard = ({
  id: addressId,
  type,
  name: Name,
  street,
  streetNumber,
  zipCode,
  city: City,
  countryId,
  isDefault,
  handleEdit,
  isSelected,
  isBilling,
}: IAddressCard) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const colors = useTheme().colors;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteAddress = (id?: string) => {
    if (!id) {
      return;
    }

    dispatch(
      deleteAddressAction({ isBilling: isBilling, addressId: addressId ?? "" })
    );
    setShowConfirm(false);
  };

  const translationKey = `${i18Enum.DV_Countries}_${countryId}`;

  return (
    <CardWrapperWhite
      style={{ borderColor: isSelected ? colors.primaryText : "white" }}
      opacity={type === addressType.disabled ? 0.3 : 1}
    >
      {!showConfirm ? (
        <Flex flexDirection={"column"} $gap={0.5}>
          <Flex justifyContent={"space-between"}>
            <Flex
              width={
                type === "userAddress" || type === "userBilling"
                  ? "88%"
                  : "100%"
              }
              paddingTop={
                type === "userAddress" || type === "userBilling" ? "5px" : "0"
              }
            >
              <Txt
                textAlign={"left"}
                variant="medium"
                color={isSelected ? colors.primaryText : undefined}
                fontSize={[3]}
              >
                {Name ? Name.toUpperCase() : ""}
              </Txt>
            </Flex>

            {type === "userAddress" || type === "userBilling" ? (
              <Flex>
                <Tooltip
                  content={t(
                    i18Enum.User_Shipping_ShippingAddresses_AlertDelete
                  )}
                >
                  <XLg
                    fontSize={"20px"}
                    cursor={"pointer"}
                    onClick={() => setShowConfirm(true)}
                  />
                </Tooltip>
              </Flex>
            ) : (
              ""
            )}
          </Flex>

          {/* {type === "userBilling" || type === "printedBilling" ? (
             <Flex flexDirection={"column"} $gap={0.5}>
               <Flex justifyContent={"flex-start"}>
                 // TODO: da tradurre 
                 <Txt variant="light" $textTransform="inherit" fontSize={[2]}>CF: {CF}</Txt>
               </Flex>
               <Flex justifyContent={"flex-start"}>
                 // TODO: da tradurre 
                 <Txt variant="light" $textTransform="inherit" fontSize={[2]}>PI: {PI}</Txt>
               </Flex>
             </Flex>
           ) : (
             ""
           )}
    */}
          <Flex alignItems={"center"}>
            <Txt textAlign={"left"} variant="light" fontSize={[2]}>
              {`${street} ${streetNumber} - ${zipCode} ${City}, ${t(
                translationKey,
                { defaultValue: "" }
              )}`}
            </Txt>
          </Flex>

          {type === "userAddress" || type === "userBilling" ? (
            <AddressCardFooter
              isBilling={isBilling}
              id={addressId}
              isDefault={isDefault}
              callback={() => handleEdit()}
            />
          ) : (
            ""
          )}
          {type === "printedAddress" || type === "printedBilling" ? (
            <AddressCardFooter
              isBilling={isBilling}
              isPrinted
              id={addressId}
              isDefault={isDefault}
              callback={() => handleEdit()}
            />
          ) : (
            ""
          )}
        </Flex>
      ) : (
        <ConfirmDialog
          description={t(i18Enum.User_Shipping_ShippingAddresses_AboutDelete)}
          hide={() => setShowConfirm(false)}
          deleteCallback={() => deleteAddress(addressId)}
        />
      )}
    </CardWrapperWhite>
  );
};
