import { IState } from "../SharedStateType";
import { ProductType } from "../product/ProductsTypes";
import { PaginatedResponse } from "api/types/responses";

export type BrandType = {
  id?: string;
  isActive?: boolean;
  description: string;
  name: string;
  profileImage?: {
    bucketUrl: string;
  };
  about: string;
  coverImage?: {
    bucketUrl: string;
  };
  products: ProductType[];
  username: string;
};

export type BrandStateType = {
  brand: IState<BrandType>;
  brandsList: IState<PaginatedResponse<BrandType>>;
};

export const BRAND = "brand";
export type BRAND = typeof BRAND;

export const GET_BRAND_BY_ID = `${BRAND}/getBrandByIdAction`;
export type GET_BRAND_BY_ID = typeof GET_BRAND_BY_ID;
export const GET_BRANDS_LIST = `${BRAND}/getBrandsListAction`;
export type GET_BRANDS_LIST = typeof GET_BRANDS_LIST;
