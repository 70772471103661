import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useEffect, useState } from "react";
import { CartDataType } from "utils/generalTypes/cartItems";


interface CartContextProps {
  totalCartPrice: number;
  setTotalCartPrice: Dispatch<SetStateAction<number>>;
  cartItems?: CartDataType;
  setCartItems: Dispatch<SetStateAction<CartDataType | undefined>>;
}

const cartContextInit: CartContextProps = {
  totalCartPrice: 0,
  setTotalCartPrice: () => {},
  cartItems: undefined,
  setCartItems: () => {},
};

export const CartContext = createContext<CartContextProps>(cartContextInit);

export const CartContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [totalCartPrice, setTotalCartPrice] = useState(0);

  const [cartItems, setCartItems] = useState<CartDataType | undefined>(undefined);
  const [tempTotalCartPrice, setTempTotalCartPrice] = useState(0);



  useEffect(() => {
    let total = 0;
    cartItems?.shopGroupTotals?.forEach((shop) => {
      const shopTotal = shop.total ?? 0;
      const shipping = shop.shippingPrice ?? 0;

      return (total = total + shopTotal + shipping);
    });
    setTempTotalCartPrice(total);
  }, [cartItems?.data, cartItems?.shopGroupTotals]);

  useEffect(() => {
    setTotalCartPrice(tempTotalCartPrice);
  }, [tempTotalCartPrice]);

  const cartContextInitValue: CartContextProps = {
    totalCartPrice,
    setTotalCartPrice,
    cartItems,
    setCartItems,
  };

  return <CartContext.Provider value={cartContextInitValue}>{children}</CartContext.Provider>;
};