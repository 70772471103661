import styled from "styled-components";
import {
  WidthProps,
  HeightProps,
  width,
  height,
  background,
  BackgroundProps,
  BorderColorProps,
} from "styled-system";

interface SelectColorProps {
  selected: boolean;
  isBlack: boolean;
}

const SelectColor = styled.div<
  WidthProps &
    HeightProps &
    BackgroundProps &
    BorderColorProps &
    SelectColorProps
>`
  border-radius: 50%;
  aspect-ratio: 1/1;
  box-sizing: border-box;
  border: 2px solid;
  cursor: pointer;
  width: ${(props) => props.width || "20px"};
  height: ${(props) => props.height || "20px"};
  border-color: ${(props) =>
    props.selected
      ? props.theme.colors.thirdBase
      : props.isBlack
      ? "white"
      : props.theme.colors.primaryBase};
  ${background};
  ${width};
  ${height};
`;
export default SelectColor;
