import styled from "styled-components";
import Box from "../primitives/Box";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";

export interface ValueRangeSlider {
  minValue: number;
  maxValue: number;
}
interface SliderProps {
  rangeMin: number;
  rangeMax: number;
  handleValues: (value: ValueRangeSlider) => void;
}

const Slider = ({ rangeMin, rangeMax, handleValues }: SliderProps) => {
  const colors = useContext(CustomThemeContext).customTheme.colors;
  const [minRange, setMinRange] = useState(rangeMin);
  const [maxRange, setMaxRange] = useState(rangeMax);
  const gap = (rangeMax - rangeMin) * 0.15;

  useEffect(() => {
    handleValues({ minValue: minRange, maxValue: maxRange });
  }, [minRange, maxRange]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newMinRange = minRange;
    let newMaxRange = maxRange;

    if (name === "range-min") {
      newMinRange = parseInt(value);
      if (newMinRange > maxRange) {
        newMinRange = maxRange - gap;
      }
    } else {
      newMaxRange = parseInt(value);
      if (newMaxRange < minRange) {
        newMaxRange = minRange + gap;
      }
    }

    setMinRange(newMinRange);
    setMaxRange(newMaxRange);
  };
  const progressStyle = {
    left: `${(minRange / rangeMax) * 100}%`,
    right: `${100 - (maxRange / rangeMax) * 100}%`,
  };

  return (
    <Box position={"relative"} width={[1]}>
      <Box
        id="range-slider"
        position="relative"
        height={3}
        borderRadius={"5px"}
        backgroundColor={colors.primaryBase}
        style={{ outline: "none" }}
        top="1px"
        margin="10px"
      >
        <Box
          id="progress"
          position="absolute"
          height={"100%"}
          backgroundColor={colors.thirdBase}
          style={progressStyle}
        />
        <Box id="range-input">
          <InputRange
            type="range"
            min={rangeMin}
            max={rangeMax}
            value={minRange}
            onChange={handleInputChange}
            name="range-min"
          />
          <InputRange
            type="range"
            min={rangeMin}
            max={rangeMax}
            value={maxRange}
            onChange={handleInputChange}
            name="range-max"
          />
        </Box>
      </Box>
    </Box>
  );
};

const InputRange = styled.input`
  position: absolute;
  top: -13px;
  left: -13px;
  width: calc(100% + 13px);
  pointer-events: none;
  background: none;
  outline: none;

  &::-webkit-slider-thumb {
    pointer-events: auto;
    -webkit-appearance: none;
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 2px solid ${({ theme }) => theme.colors.secondaryBg};
    background: ${(props) => props.theme.colors.thirdBg};
  }
  &::-moz-range-thumb {
    pointer-events: auto;
    -webkit-appearance: none;
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 2px solid ${({ theme }) => theme.colors.secondaryBg};
    background: ${(props) => props.theme.colors.thirdBg};
  }
  &::-ms-thumb {
    width: 26px;
    height: 26px;
    border-radius: 50px;
    border: 2px solid ${({ theme }) => theme.colors.secondaryBg};
    background: ${(props) => props.theme.colors.thirdBg};
  }
  -webkit-appearance: none;
`;

export default Slider;
