import Txt from 'components/primitives/Txt'
import { CustomThemeContext } from 'context/ThemeContext'
import  { useContext } from 'react'
import { ChevronRight } from 'react-bootstrap-icons'

import styled from "styled-components";
import {
  ColorProps,
  flexbox,
  LayoutProps,
  SpaceProps,
  layout,
  space,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  color
} from "styled-system";

export interface FilterRowProps {
  filterLabel: string,
  hasChild: boolean,
  isSelected?: boolean
  handleFilter: () => void,
}

export const FilterRowWrapper = styled.div<
  ColorProps &
  FlexboxProps &
  LayoutProps &
  SpaceProps &
  PositionProps &
  BorderProps &
  { $selected?: boolean }
>`
  display: flex;
  background-color: ${prop => prop.$selected ? prop.theme.colors.primaryBase : "transparent"};
  & > p{
      color: ${prop => prop.$selected ? prop.theme.colors.secondaryBase : prop.theme.colors.primaryBase};
  };

  &:hover{
    background-color: ${prop => prop.theme.colors.thirdBase};
  }

  &:hover > p{
    color: ${prop => prop.theme.colors.secondaryBase}
  }

  ${space}
  ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
`;




export const FilterRow = ({ filterLabel, hasChild, isSelected, handleFilter }: FilterRowProps) => {
  const color = useContext(CustomThemeContext).customTheme.colors;
  return (
    <FilterRowWrapper
      $selected={isSelected}
      onClick={() => handleFilter()}
      style={{ cursor: "pointer" }}
      flexDirection={"row"}
      padding={"5px 0 "}
      borderTop={"1px solid white"}
      borderBottom={"1px solid white"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={[1]}
    >
      <Txt as={"p"} style={{ cursor: "pointer" }} variant={"light"} color={color.primaryText} textAlign={"left"} fontSize={[3]} paddingLeft={2}>
        {filterLabel}
      </Txt>
      {hasChild && <ChevronRight color={color.primaryText} style={{ marginTop: "2px" }} onClick={handleFilter} />}
    </FilterRowWrapper>
  );
};
