import styled from "styled-components";
import {
  ColorProps,
  flexbox,
  LayoutProps,
  SpaceProps,
  layout,
  space,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  color,
  TypographyProps,
  typography
} from "styled-system";

const Flex = styled.div<
  ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    PositionProps &
    BorderProps & 
    TypographyProps &
    { $gap?: number } & 
    { $aspectRatio?: string } & 
    {$transition?: string;} &
    {$backgroundImage?:string} &
    {$blur?:boolean}
>`
  display: flex;
  aspect-ratio: ${(props) => props.$aspectRatio ?? undefined};
  gap: ${(props) => (props.$gap ? props.$gap + `em` : "unset")};
  transition: ${(props) => props.$transition ?? undefined};
  background-image: ${(props) => props.$backgroundImage ? `url(${props.$backgroundImage})`  : undefined};
  background-repeat: ${(props) => props.$backgroundImage ? "no-repeat" :undefined};
  background-position: ${(props) => props.$backgroundImage ? "center center" :undefined};
  background-size: ${(props) => props.$backgroundImage ? "auto 100% " :undefined};
  filter:  ${(props) => props.$blur ? "blur(10px)" :undefined};;
  &:hover{
    background-size: ${(props) => props.$backgroundImage ? " auto 110% " :undefined};
    transition: ${(props) => props.$transition ?? undefined};
  }

  ${space}
  ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
    ${typography}
`;
export default Flex;
