import { useContext } from "react";
import { useSearchParams } from "react-router-dom";

import Flex from "components/primitives/Flex";

import { CustomThemeContext } from "context/ThemeContext";
import BackgroundVideo from "components/molecules/VideoBackgroud";

import CodeInput from "components/molecules/CodeInput";
import { AUTH_API } from "api/graphql/requests";

export const VerifyUserPage = () => {
  const [params, setParams] = useSearchParams();

  const error = params.get("errror");
  const { customTheme } = useContext(CustomThemeContext);

  const handleChange = (value: { asString: string }) => {
    if (value.asString.length === 6) {
      location.href = `${AUTH_API}/code/callback?code=${value.asString}`;
    }
  };

  return (
    <>
      <Flex
        width={[1]}
        height={["100%"]}
        maxHeight={["100vh"]}
        maxWidth={customTheme.maxWidth[0]}
        position={"relative"}
        alignItems="center"
        marginTop={["100px", 0]}
        justifyContent={"left"}
      >
        <Flex
          flexDirection={"column"}
          alignItems={"middle"}
          justifyContent={["normal", "center"]}
          minHeight={"calc(100vh - 200px)"}
          padding={["40px 28px 40px"]}
          $gap={4}
          style={{ boxSizing: "border-box" }}
          width={["100%", "auto"]}
        >
          <div className="text-center">
            <div className="title px-6 text-accent md:px-0">
              Inserisci il codice ricevuto via email!
            </div>
            <div className="mt-6 w-full">
              <CodeInput
                className="space-x-2 md:space-x-4"
                hiddenCode={false}
                numberOfFields={6}
                onChange={handleChange}
              />

              {error == "invalid_code" && (
                <div className="mt-3 px-6 text-xl text-red-500 md:px-0">
                  Codice non valido, ti invitiamo a riprovare!
                </div>
              )}
              <div></div>
            </div>
          </div>
        </Flex>
      </Flex>
      <BackgroundVideo
        video="first"
        variant={"left"}
        height={"100vh"}
        top={0}
        width={[0.9, 0.9, 0.5]}
      />
    </>
  );
};
