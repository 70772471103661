/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, ChangeEvent } from "react";
import { BorderRadiusProps, SpaceProps, WidthProps } from "styled-system";
import Flex from "../primitives/Flex";
import Box from "../primitives/Box";
import CircleButton from "components/primitives/CircleButton";
import Textarea from "components/primitives/Textarea";
import useAutosizeTextArea from "utils/hooks/useAutosizeTextArea";

interface InputFieldProps extends React.ComponentPropsWithoutRef<"textarea"> {
  spaceProp?: SpaceProps & WidthProps & BorderRadiusProps;
  handleSubmit: (value: string) => void;
}

const CommentField = React.forwardRef<HTMLInputElement, InputFieldProps>(({spaceProp, handleSubmit, ...props }, ref) => {
  const inputRef = ref;
  const [focus, setFocus] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, commentValue);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.target?.value;
    setCommentValue(val);
  };

  const onSubmit = () => {
    handleSubmit(commentValue)
    setCommentValue("")
  }

  return (
    <Flex flexDirection={"column"} alignItems={"start"} {...spaceProp}>
      <Box position={"relative"} width={[1]}>
        <Textarea
          {...props}
          ref={textAreaRef}
          rows={1}
          value={commentValue}
          id='textarea'
          onChange={handleChange}
          onFocus={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
            setFocus(true);
            props.onFocus?.(e);
          }}
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
            setFocus(false);
            props.onBlur?.(e);
          }}
          variant='comment'
          disabled={true}
          width={[1]}
          fontSize={[3]}
          padding={["8px 47px 8px 16px"]}
          placeholder={""}
          autoComplete='off'
          maxLength={380}
        />
        <CircleButton style={{ position: "absolute", top: 0, right: 0, aspectRatio: "1/1", height: "39px" }} variant='disabled'>
          <svg xmlns='http://www.w3.org/2000/svg' width='60%' height='60%' viewBox='0 0 17 17' fill='none'>
            <path
              d='M0 8.5L7.79167 10.6958L12.75 4.95833L6.97283 8.976L3.9185 8.12458L14.7999 2.68458L11.985 13.005L9.32875 10.6399L7.79167 12.7117V12.167L6.375 11.7704V17L9.54337 12.728L12.75 15.5833L17 0L0 8.5Z'
              fill='black'
            />
          </svg>
        </CircleButton>
      </Box>
    </Flex>
  );
});

export default CommentField;
