import { createGlobalStyle } from "styled-components";

//create global theme
const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'BauProMedium';
  src: url("../src/assets/fonts/BauPro-Medium.woff2") format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'BauPro';
  src: url("../src/assets/fonts/BauPro.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'BauProBold';
  src: url("../src/assets/fonts/BauPro-Bold.woff2") format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

  html{
    height: 100%;
    font-family: "BauProMedium" ;
  }

  body {
    height:100%;
    margin: 0;
    padding: 0;
   /*  font-size: 16px; */
    box-sizing: border-box;
    scroll-behavior: smooth;
    background-color:${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.textColor};
    text-align: center;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    
  }
 *::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
  a{
    text-decoration: none;
    color:${(props) => props.theme.colors.primaryText};
    font-weight: bold;
  }
  small {
    font-Size: '80%',
  }

  table {
    text-Indent: 0 ;
    border-Color: inherit ;
    border-Collapse: collapse ;
  }
  img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
    
  }
  
  img, video {
    max-Width: 100%;
    height: auto;
  }
 

  button  {
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
 margin:0;

  }



  ul{
    list-style-type: none;
    margin:0;
    padding:0;
  }


  :focus-visible{
    outline: auto 1px ${(props) => props.theme.colors.primarySolidHover} ;
  }


`;

export default GlobalStyle;
