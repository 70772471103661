import { useCallback, useMemo } from "react";
import { EyeSlashSvg } from "./EyeSlashSvg";
import { EyeSvg } from "./EyeSvg";
import CircleButton from "../primitives/CircleButton";

export interface IVisibleButton {
  handleVisible?: (_: boolean) => void;
  isVisible?: boolean;
}

export const VisibleButton = ({ handleVisible, isVisible }: IVisibleButton) => {
  const internalIsVisible = useMemo(() => isVisible, [isVisible]);

  const handleVisibility = useCallback(() => {
    const newIsVisible = !internalIsVisible;
    handleVisible?.(newIsVisible);
  }, [internalIsVisible, handleVisible]);

  if (internalIsVisible) {
    return (
      <CircleButton variant={"visible"} onClick={handleVisibility}>
        <EyeSvg />
      </CircleButton>
    );
  } else {
    return (
      <CircleButton variant={"hidden"} onClick={handleVisibility}>
        <EyeSlashSvg />
      </CircleButton>
    );
  }
};
