import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/app/store.ts";
import App from "./App.tsx";
import "./index.css";
import { AppGeneralContextProvider } from "./context/AppGeneralContext.tsx";
import { CustomThemeProvider } from "./context/ThemeContext.tsx";
import { StyleSheetManager } from "styled-components";
import { CartContextProvider } from "context/CartContext.tsx";
import isPropValid from "@emotion/is-prop-valid";
import "./i18n/i18nConfig.ts";
import { ModalContextProvider } from "context/ModalContext.tsx";
import { GeneralLoader } from "components/molecules/GeneralLoader.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StyleSheetManager shouldForwardProp={isPropValid}>
    <Suspense fallback={<GeneralLoader />}>
      <CustomThemeProvider>
        <Provider store={store}>
          <AppGeneralContextProvider>
            <ModalContextProvider>
              <CartContextProvider>
                <App />
              </CartContextProvider>
            </ModalContextProvider>
          </AppGeneralContextProvider>
        </Provider>
      </CustomThemeProvider>
    </Suspense>
  </StyleSheetManager>
);
