import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ProductsListRequest } from "api/types/requests/productRequest";

import { toast } from "react-toastify";
import { PRODUCT, ProductStateType, ProductType } from "./ProductsTypes";
import { BaseIdRequest } from "api/types/requests";
import { PaginatedResponse } from "api/types/responses";
import { toastErrorStyle } from "utils/const/const";

const productInitialState: ProductStateType = {
  product: {
    data: null,
    isLoading: false,
    errors: "",
  },
  productList: {
    data: null,
    isLoading: false,
    errors: "",
  },
  similarProducts: {
    data: null,
    isLoading: false,
    errors: "",
  },
  productListByBrand: {
    data: null,
    isLoading: false,
    errors: "",
  },
};

export const productSlice = createSlice({
  name: PRODUCT,
  initialState: productInitialState,
  reducers: {
    setProductAction: (
      state: ProductStateType,
      { payload }: PayloadAction<ProductType>
    ) => {
      state.product.isLoading = true;
      state.product.errors = "";
    },
    setProductSuccessAction: (
      state: ProductStateType,
      { payload: product }: PayloadAction<ProductType>
    ) => {
      state.product.isLoading = false;
      state.product.data = product;
    },
    setProductErrorAction: (
      state: ProductStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.product.isLoading = false;
      state.product.errors = error;
    },
    getProductByIdAction: (
      state: ProductStateType,
      { payload: id }: PayloadAction<string>
    ) => {
      state.product.isLoading = true;
      state.product.errors = "";
    },
    getProductByIdErrorAction: (
      state: ProductStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.product.isLoading = false;
      state.product.errors = error;
    },
    getProductByIdSuccessAction: (
      state: ProductStateType,
      { payload }: PayloadAction<ProductType>
    ) => {
      state.product.isLoading = false;
      state.product.data = payload;
    },

    getProductListByBrandIdAction: (
      state: ProductStateType,
      { payload }: PayloadAction<BaseIdRequest<ProductsListRequest>>
    ) => {
      state.productListByBrand.isLoading = true;
      state.productListByBrand.errors = "";
    },
    getProductListByBrandIdSuccessAction: (
      state: ProductStateType,
      { payload }: PayloadAction<PaginatedResponse<ProductType>>
    ) => {
      state.productListByBrand.isLoading = false;
      state.productListByBrand.errors = "";

      const newItems = payload.Data;

      if (!newItems?.length || !payload.Data) {
        return;
      }
      if (
        !state.productListByBrand.data ||
        !state.productListByBrand.data.Data
      ) {
        state.productListByBrand.data = {
          Pager: payload.Pager,
          Data: payload.Data,
        };
        return;
      }

      const filteredItems = newItems.filter((item) => {
        return !state.productList.data?.Data?.map((x) => x.id).includes(
          item.id
        );
      });
      state.productList.data = {
        Data: [...state.productListByBrand.data.Data, ...filteredItems],
        Pager: payload.Pager,
      };
    },
    getProductListByBrandIdErrorAction: (
      state: ProductStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.productListByBrand.isLoading = false;
      state.productListByBrand.errors = error;
    },
    productListByBrandIdResetData: (state: ProductStateType) => {
      state.productListByBrand.data = {
        Data: [],
        Pager: {
          PageNum: 0,
          PageSize: 0,
          TotalItems: 0,
          TotalPages: 0,
          Links: [],
        },
      };
    },
    searchProductAction: (
      state: ProductStateType,
      { payload }: PayloadAction<ProductsListRequest>
    ) => {
      state.productList.isLoading = true;
      state.productList.errors = "";
    },
    searchProductErrorAction: (
      state: ProductStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.productList.isLoading = false;
      state.productList.errors = error;
      toast.error(error, toastErrorStyle);
    },
    searchProductSuccessAction: (
      state: ProductStateType,
      { payload }: PayloadAction<{ products: ProductType[] }>
    ) => {
      state.productList.isLoading = false;
      state.productList.errors = "";

      if (!payload.products?.length || !payload?.products) {
        return;
      }

      // if (!state.productList.data || !state.productList.data.Data) {
      state.productList.data = {
        Pager: payload.Pager,
        Data: payload.products,
      };
      return;
      // }
      // const filteredItems = newItems.filter((item: any) => {
      //   return !state.productList.data?.Data?.map((x) => x.id).includes(
      //     item.Id
      //   );
      // });
      // state.productList.data = {
      //   Data: [...state.productList.data.Data, ...filteredItems],
      //   Pager: newPayload.Pager,
      // };
    },
    searchProductResetData: (state: ProductStateType) => {
      state.productList.data = {
        Data: [],
        Pager: {
          PageNum: 0,
          PageSize: 0,
          TotalItems: 0,
          TotalPages: 0,
          Links: [],
        },
      };
    },
    getSimilarProductAction: (
      state: ProductStateType,
      { payload: id }: PayloadAction<string>
    ) => {
      state.similarProducts.isLoading = true;
      state.similarProducts.errors = "";
    },
    getSimilarProductsSuccessAction: (
      state: ProductStateType,
      { payload: similar }: PayloadAction<ProductType[]>
    ) => {
      state.similarProducts.isLoading = false;
      state.similarProducts.errors = "";
      state.similarProducts.data = similar;
    },
    getSimilarProductsErrorAction: (
      state: ProductStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.similarProducts.isLoading = false;
      state.similarProducts.errors = error;
    },
  },
});

/* const for easy access in components */
export const ProductActions = productSlice.actions;
/*const for saga/store's  use */
export const {
  searchProductAction,
  searchProductErrorAction,
  searchProductSuccessAction,
  searchProductResetData,
  getSimilarProductAction,
  getSimilarProductsErrorAction,
  getSimilarProductsSuccessAction,
  setProductAction,
  setProductErrorAction,
  setProductSuccessAction,
  getProductListByBrandIdAction,
  getProductListByBrandIdErrorAction,
  getProductListByBrandIdSuccessAction,
  productListByBrandIdResetData,
  getProductByIdAction,
  getProductByIdErrorAction,
  getProductByIdSuccessAction,
} = productSlice.actions;

export default productSlice.reducer;
