import Flex from "../primitives/Flex";
import OutfitCardWrapper from "../primitives/OutfitCardWrapper";
import Txt from "../primitives/Txt";
import { useContext, useEffect, useRef, useState } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import { H6 } from "theme/theme.styled";

export interface OutfitCardProps {
  imageUrl: string;
  bgOpacity?: number;
  shop: string;
  productName: string;
  price: string;
  position: "first" | "second" | "third" | "fourth";
  index: number;
  lastIndex: boolean;
  height: number;
  showOutfit?: () => void;
}

export const OutfitCard = ({ imageUrl, shop, productName, price, position, index, lastIndex, height, showOutfit }: OutfitCardProps): JSX.Element => {
  const { customTheme } = useContext(CustomThemeContext);
  const [translate, setTranslate] = useState<number>(0);
  const color = customTheme.colors;
  const element = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let translate: number = 0;
    if (index !== 0) {
      translate = 30 * index;
    }
    setTranslate(translate);
  }, [index]);

  return (
    <OutfitCardWrapper
      ref={element}
      marginBottom={10}
      variant={position}
      background={`url(${imageUrl})`}
      height={height}
      width={"100%"}
      borderTopLeftRadius={position === "first" ? "100% 38%" : ""}
      borderTopRightRadius={position === "first" ? "5% 10%" : ""}
      borderBottomLeftRadius={lastIndex ? "5% 10%" : ""}
      borderBottomRightRadius={lastIndex ? "100% 40%" : ""}
      transform={`translateY(-${translate}%)`}
      hasShadow={true}
    >
      <OutfitCardWrapper
        variant={position}
        background={
          position === "first" || position === "third"
            ? "linear-gradient(80deg, rgba(0, 0, 0, 0.81) 4.94%, rgba(0, 0, 0, 0.19) 93.94%)"
            : "linear-gradient(75deg, rgba(0, 0, 0, 0.19) 9.03%, rgba(0, 0, 0, 0.81) 91.15%)"
        }
        height={"100%"}
        width={"100%"}
        hasShadow={false}
      >
        <Flex
          flexDirection={"column"}
          alignItems={index % 2 === 0 ? "start" : "end"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
          padding={"0 20px"}
          onClick={() => (showOutfit ? showOutfit() : {})}
          style={{ cursor: "pointer" }}
        >
          <Txt $cursor='pointer' as={"h6"} fontSize={H6} fontWeight={"300"} marginTop={position === "first" ? (1 / 5) * height : null}>
            {" "}
            {shop}
          </Txt>
          <Txt $cursor='pointer' as={"h6"} fontSize={[3]} fontWeight={"600"} textAlign={position === "first" || position === "third" ? 'left' : 'right'}>
            {productName}
          </Txt>
          <Txt $cursor='pointer' as={"h6"} fontSize={H6} fontWeight={"600"} color={color.primaryBase} marginBottom={position === "fourth" ? (1 / 5) * height : null}>
            {" "}
            {price}
          </Txt>
        </Flex>
      </OutfitCardWrapper>
    </OutfitCardWrapper>
  );
};
