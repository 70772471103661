import styled from "styled-components";
import {
  ColorProps,
  SpaceProps,
  WidthProps,
  compose,
  space,
  variant,
  width,
  color,
} from "styled-system";

interface CircleButtonProps {
  variant: "visible" | "hidden" | "disabled";
}

const CircleButton = styled.button<CircleButtonProps & ColorProps & SpaceProps & WidthProps>`
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border: none;
  border-radius: 100%;

  ${(props) =>
    compose(
      variant({
        variants: {
          visible: {
            backgroundColor: props.theme.colors.primaryBase,
            active: props.theme.colors.primaryBgActive,
            "&:hover": {
              backgroundColor: props.theme.colors.primarySolidHover,
            },
          },
          hidden: {
            backgroundColor: props.theme.colors.grayBase,
            active: props.theme.colors.primaryBgActive,
            "&:hover": {
              backgroundColor: props.theme.colors.grayBgSubtle,
            },
          },
          disabled: {
            pointerEvents: "none",
            opacity: 0.4,
          },
        },
      }),
      width,
      space,
      color
    )}
`;
export default CircleButton;
