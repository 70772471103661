import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Section from "components/primitives/Section";
import Flex from "components/primitives/Flex";
import Box from "components/primitives/Box";
import Txt from "components/primitives/Txt";
import ArrowSvg from "components/atoms/ArrowSvg";
import { H6 } from "theme/theme.styled";
import { CartRecap } from "components/atoms/CartRecap";
import { priceNum } from "utils/functions/stringPrice";
import Toggle from "components/primitives/Toggle";
import Image from "components/primitives/Image";
import amex from "../assets/img/payment-amex.svg";
import master from "../assets/img/payment-mastercard.svg";
import pay from "../assets/img/payment-pay.svg";
import visa from "../assets/img/payment-visa.svg";
import stripe from "../assets/img/payment-stripe.svg";
import paypal from "../assets/img/payment-paypal.svg";
import Button from "components/primitives/Button";

import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import { AppGeneralContext } from "context/AppGeneralContext";
import { toastType } from "utils";
import { AddEditBillingForm } from "components/organisms/AddEditBillingForm";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { AddressCard, addressType } from "components/molecules/AddressCard";
import { UserShippingAddress } from "redux/features/user/UserTypes";
import LoadingSpinner from "components/molecules/LoadingSpinner";
import { AddressForm } from "components/organisms/AddressForm";
import { useTheme } from "styled-components";
import { cartActions, createOrderAction } from "redux/features/cart/cartSlice";
import { CartShipping } from "./CartShipping";
import { OrderRequest } from "api/types/requests/cartRequest";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { filterItems } from "utils/functions/filterItems";
import { getOrdersAction } from "redux/features/user/userSlice";

enum PaymentMethod {
  "unselected" = -1,
  "stripe",
  "paypal",
}
type addressParams = {
  addNew: boolean;
  addressSelected: UserShippingAddress | undefined;
};

export const CartCheckout = () => {
  //context data
  const { addToast, isMobile, openAsideMenu, setAsideIsOpen } =
    useContext(AppGeneralContext);
  //hook assignment
  const dispatch = useAppDispatch();
  const colors = useTheme().colors;
  const maxW = useTheme().maxWidth;
  const { t } = useTranslation();
  const navigate = useNavigate();
  //selectors
  const {
    data: shippingAddressList,
    isLoading: isFetchingShippingAddress,
    errors: shippingAddressError,
    isSuccess: shippingSuccess,
  } = useAppSelector((state) => state.user.userShippingAddresses);
  const {
    data: billingAddressList,
    isLoading: isFetchingBillingAddress,
    errors: billingAddressError,
    isSuccess: billingSuccess,
  } = useAppSelector((state) => state.user.userBillingAddresses);
  const {
    data: cart,
    isLoading: isFetchingCart,
    errors: cartErrors,
  } = useAppSelector((state) => state.cart.cart);
  const { data: user } = useAppSelector((state) => state.user.user);
  const shoppingBasket = useAppSelector(
    (state) => state.cart.cart.data?.ShoppingBasket
  );
  const billingSameAsDelivery = useAppSelector(
    (state) =>
      state.cart.cart.data?.cartOrderInfo?.billingAddressSameAsDeliveryAddress
  );
  const defaultShippingAddress = useAppSelector((state) =>
    state.user.userShippingAddresses.data?.find((x) => x.isDefault)
  );
  const defaultBillingAddress = useAppSelector((state) =>
    state.user.userBillingAddresses.data?.find((x) => x.isDefault)
  );
  const lastCreatedBillingAddress = useAppSelector((state) =>
    state.user.userBillingAddresses.data?.find((x) => x.LastCreated)
  );
  const lastCreatedShippingAddress = useAppSelector((state) =>
    state.user.userShippingAddresses.data?.find((x) => x.LastCreated)
  );
  //useState data
  const [disabled, setDisabled] = useState(true);
  const [shippingAddress, setShippingAddress] = useState<addressParams>({
    addNew: false,
    addressSelected: defaultShippingAddress,
  });
  const [billingAddress, setBillingAddress] = useState<addressParams>({
    addNew: false,
    addressSelected: defaultBillingAddress,
  });
  // const [paymentSelcted, setPaymentSelected] = useState<PaymentMethod>(PaymentMethod.unselected)
  const [paymentSelcted, setPaymentSelected] = useState<PaymentMethod>(
    PaymentMethod.stripe
  );
  const payment = [master, visa, amex, pay, paypal];
  const [nameSelected, setNameSelected] = useState<string | undefined>();
  const [nameBillingSelected, setNameBillingSelected] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!shoppingBasket) {
      return;
    }
    const addPaymentInfoItems = filterItems(shoppingBasket?.shops);
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_payment_info",
      ecommerce: {
        currency: "EUR",
        value: shoppingBasket?.total,
        payment_type: "Credit Card",
        items: addPaymentInfoItems,
      },
    });

    console.log({ addPaymentInfo: addPaymentInfoItems });
  }, [shoppingBasket]);

  useEffect(() => {
    if (nameBillingSelected) {
      const address = billingAddressList?.find(
        (x) => x.name === nameBillingSelected
      );
      if (address) {
        setBillingAddress({ addNew: false, addressSelected: address });
      }
    }
  }, [billingAddressList, nameBillingSelected]);

  useEffect(() => {
    if (nameSelected) {
      const address = shippingAddressList?.find((x) => x.name === nameSelected);
      if (address) {
        setShippingAddress({ addNew: false, addressSelected: address });
      }
    }
  }, [shippingAddressList, nameSelected]);

  //setAddress with default address (or undefined if not provided)
  useEffect(() => {
    setBillingAddress((prev) => ({
      ...prev,
      addressSelected: defaultBillingAddress,
    }));
    setShippingAddress((prev) => ({
      ...prev,
      addressSelected: defaultShippingAddress,
    }));
  }, [defaultBillingAddress, defaultShippingAddress]);

  //set address with lastCreated in this page's forms
  useEffect(() => {
    if (billingAddress.addNew && lastCreatedBillingAddress?.id) {
      setBillingAddress((prev) => ({
        ...prev,
        addressSelected: lastCreatedBillingAddress,
      }));
    }
  }, [lastCreatedBillingAddress?.id, billingAddress.addNew]);

  useEffect(() => {
    if (shippingAddress.addNew && lastCreatedShippingAddress?.id) {
      setShippingAddress((prev) => ({
        ...prev,
        addressSelected: lastCreatedShippingAddress,
      }));
    }
  }, [lastCreatedShippingAddress?.id, shippingAddress.addNew]);

  //if creation success
  useEffect(() => {
    if (shippingSuccess) {
      setShippingAddress((prev) => ({ ...prev, addNew: false }));
      return;
    }
  }, [shippingSuccess]);

  useEffect(() => {
    if (billingSuccess) {
      setBillingAddress((prev) => ({ ...prev, addNew: false }));
      return;
    }
  }, [billingSuccess]);

  //buy button handleDisable

  useEffect(() => {
    if (
      !shippingAddress.addressSelected?.id ||
      !billingAddress.addressSelected?.id ||
      paymentSelcted < 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    shippingAddress.addressSelected,
    billingAddress.addressSelected,
    paymentSelcted,
  ]);

  const handleBillingAddressSelection = useCallback(
    (val: boolean) => {
      // setBillingAddress({ addNew: val, addressSelected: undefined });
      // setAddNewBilling(val)
      // setBillingAddressSelected(undefined)
      if (val) {
        dispatch(cartActions.setBillingAddressSameAsDeliveryAddress(true));
      } else {
        dispatch(cartActions.setBillingAddressSameAsDeliveryAddress(false));
      }
    },
    [billingAddress.addressSelected, billingAddressList]
  );

  function buy() {
    //TO DO PAYPAL
    const request: OrderRequest = {
      shippingUserAddress: shippingAddress.addressSelected?.id ?? "",
      billingUserAddress: billingAddress.addressSelected?.id ?? "",
      shoppingBasketId: cart?.ShoppingBasket?.id ?? "",
    };

    dispatch(createOrderAction({ req: request, action: NavigateAfterCall }));
    // resetta il toggle:
    dispatch(cartActions.setBillingAddressSameAsDeliveryAddress(false));
  }

  function NavigateAfterCall(callbackUrl: string) {
    dispatch(getOrdersAction());
    location.href = callbackUrl;
    setAsideIsOpen(false);
  }

  // function savePayment() {
  //   //TODO
  //   addToast(toastType.success, t(i18Enum.Cart_ShippingMethod_Saved));
  // }

  function back() {
    if (isMobile) {
      navigate(-1);
    }
    openAsideMenu<undefined>(<CartShipping />, undefined);
  }

  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        padding={["12% 5%", "10%", "0 10%"]}
        maxWidth={maxW}
        width={"100%"}
      >
        <Box width={"100%"}>
          <Section
            flexDirection={"column"}
            justifyContent={"center"}
            padding={"2em"}
            width={"100%"}
          >
            <ArrowSvg
              widthCustom="s"
              position={"absolute"}
              left={[3]}
              handleClick={back}
            />
          </Section>
        </Box>

        <Section
          width={"100%"}
          flexDirection={"column"}
          alignItems={"center"}
          $gap={1}
        >
          <Txt
            width={["100%"]}
            textAlign={"start"}
            as={"h6"}
            variant="light"
            color={colors.thirdText}
            fontSize={H6}
          >
            {t(i18Enum.Cart_Shipping_ShippingAddress)}
          </Txt>

          {isFetchingShippingAddress && !shippingAddressError && (
            <LoadingSpinner dimension="40px" />
          )}

          {!isFetchingShippingAddress &&
          (!shippingAddressList || shippingAddressList?.length <= 0) ? (
            <Txt
              variant={"medium"}
              fontSize={H6}
              as={"span"}
              $textTransform="default"
            >
              {t(i18Enum.Cart_Shipping_NoAddresses)}
            </Txt>
          ) : (
            shippingAddressList?.map((address) => (
              <AddressCard
                id={address.id}
                key={address.id}
                type={addressType.printedAddress}
                name={address.name ?? ""}
                city={address.city ?? ""}
                street={address.street ?? ""}
                streetNumber={address.streetNumber ?? ""}
                zipCode={address.zipCode ?? ""}
                countryId={address.countryId ?? 110}
                isDefault={!!address.isDefault}
                isBilling={address?.isBilling ?? false}
                handleEdit={() => {
                  setShippingAddress({
                    addNew: false,
                    addressSelected: address,
                  });
                }}
                isSelected={shippingAddress?.addressSelected?.id === address.id}
              />
            ))
          )}
          <Txt
            width={"100%"}
            textAlign={"end"}
            variant="linkLight"
            color={colors.thirdText}
            fontSize={[2]}
            onClick={() =>
              setShippingAddress({ addNew: true, addressSelected: undefined })
            }
          >
            {t(i18Enum.Cart_Shipping_AddNewAddress)}
          </Txt>
          {shippingAddress.addNew && (
            <AddressForm
              isCartForm={true}
              isEdit={false}
              getSelectedName={(name: string) => setNameSelected(name)}
            />
          )}
        </Section>

        {/* <Section flexDirection={"column"} minWidth={"300px"} width={"100%"} $gap={2} margin={"10% 0 5%"}>
          <Txt textAlign={"start"} as={"h6"} variant='light' color={colors.thirdText} fontSize={H6}>
            Do you have some promo code?
          </Txt>

          <PromoField placeholder='promo code' />
        </Section> */}
        <Section
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
          $gap={1.5}
        >
          <Txt
            width={["100%"]}
            textAlign={"start"}
            as={"h6"}
            variant="light"
            color={colors.thirdText}
            fontSize={H6}
          >
            {t(i18Enum.Cart_Shipping_BillingAddress)}
          </Txt>

          {!isFetchingBillingAddress &&
          (!billingAddressList || billingAddressList?.length <= 0) ? (
            <Flex
              flexDirection={"column"}
              alignItems={"center"}
              width={"100%"}
              $gap={1}
              marginBottom={3}
            >
              <Txt
                variant={"medium"}
                fontSize={H6}
                as={"span"}
                $textTransform="default"
              >
                {t(i18Enum.Cart_Shipping_NoAddresses)}
              </Txt>

              <Box
                backgroundColor={"#ff9d00"}
                width={"100%"}
                borderRadius={"10px"}
                padding={"20px"}
                maxWidth={maxW}
              >
                <Txt
                  fontSize={[2]}
                  textAlign={["center"]}
                  color={colors.secondaryText}
                >
                  {t(i18Enum.Cart_Shipping_NoAddresses_Alert)}
                </Txt>

                <Flex
                  flexDirection={["column", "row"]}
                  justifyContent={"center"}
                  alignItems={["center", "end"]}
                  paddingTop={"10px"}
                >
                  <Button
                    variant="secondary"
                    width={"fit-content"}
                    padding={"0.5em 1.2em"}
                    onClick={() =>
                      setBillingAddress({
                        addNew: true,
                        addressSelected: undefined,
                      })
                    }
                    marginRight={["0px", "10px"]}
                    marginBottom={["10px", "0px"]}
                  >
                    {t(i18Enum.Cart_Shipping_AddNewAddress)}
                  </Button>
                </Flex>
              </Box>
            </Flex>
          ) : (
            <>
              {billingAddressList?.map((address) => (
                <AddressCard
                  id={address.id}
                  key={address.id}
                  type={addressType.printedBilling}
                  name={address.name ?? ""}
                  city={address.city ?? ""}
                  street={address.street ?? ""}
                  streetNumber={address.streetNumber ?? ""}
                  zipCode={address.zipCode ?? ""}
                  countryId={address.countryId ?? 110}
                  isDefault={!!address.isDefault}
                  isBilling={address?.isBilling ?? true}
                  handleEdit={() =>
                    setBillingAddress({
                      addNew: false,
                      addressSelected: address,
                    })
                  }
                  isSelected={billingAddress.addressSelected?.id === address.id}
                />
              ))}
              <Txt
                width={"100%"}
                textAlign={"end"}
                variant="linkLight"
                color={colors.thirdText}
                fontSize={[2]}
                onClick={() =>
                  setBillingAddress({
                    addNew: true,
                    addressSelected: undefined,
                  })
                }
              >
                {t(i18Enum.Cart_Shipping_AddNewAddress)}
              </Txt>
            </>
          )}

          {billingAddress.addNew && (
            <>
              <Flex
                width={["100%"]}
                alignItems={"center"}
                $gap={1.5}
                paddingBottom={"20px"}
              >
                <Toggle
                  disabled={!shippingAddress.addressSelected?.id}
                  tooltipText="no shipping address selected"
                  checked={billingSameAsDelivery}
                  handleChange={(value) => {
                    handleBillingAddressSelection(value);
                  }}
                />

                <Flex width={["60%", "100%"]}>
                  <Txt
                    textAlign={"start"}
                    as={"h6"}
                    variant="light"
                    color={colors.thirdText}
                    fontSize={H6}
                  >
                    {t(i18Enum.Cart_Shipping_SameAddress)}
                  </Txt>
                </Flex>
              </Flex>

              <AddEditBillingForm
                variant="shipping"
                isCartForm={true}
                useThisAddress={shippingAddress.addressSelected}
                isEdit={false}
                getSelectedName={(name: string) => setNameBillingSelected(name)}
              />
            </>
          )}

          {isFetchingBillingAddress && !billingAddressError && (
            <LoadingSpinner dimension="40px" />
          )}
        </Section>

        <Section flexDirection={"column"} width={"100%"}>
          {shoppingBasket?.shops &&
            shoppingBasket?.shops.map((shop, index) => {
              return <CartRecap key={index} items={shop.items} shop={shop} />;
            })}

          {/* TODO: aggiornare con il codice promozionale */}
          {/* <Flex justifyContent={"space-between"} alignItems={"center"} borderBottom={`1px solid ${colors.thirdBase}`} padding={"1em 0"}>
            <Txt textAlign={"start"} variant='light' color={colors.primaryText} fontSize={[2]}>
              Promo code
            </Txt>

            <Box>
              <Txt variant='light' color={colors.primaryText} fontSize={[2]} marginRight={"0.5em"}>
                -
              </Txt>
              <Txt variant='light' color={colors.thirdText} fontSize={[2]}>
                {`${priceNum(promoCode)}€`}
              </Txt>
            </Box>
          </Flex> */}

          <Txt
            padding={"1.5em 0"}
            textAlign={"end"}
            as={"h6"}
            variant="medium"
            color={colors.primaryText}
            fontSize={H6}
          >
            {priceNum(shoppingBasket?.total)}€
          </Txt>
        </Section>

        {/* TODO: aggiungere i collegamenti ai pagamenti */}
        <Section flexDirection={"column"} width={"100%"} $gap={1.5}>
          <Txt
            textAlign={"start"}
            as={"h6"}
            variant="light"
            color={colors.thirdText}
            fontSize={H6}
          >
            {t(i18Enum.Cart_Shipping_PayWith)}
          </Txt>

          {/* <Flex
            style={{ cursor: "pointer" }}
            minWidth={"300px"}
            width={"100%"}
            justifyContent={"center"}
            backgroundColor={"#253980"}
            border={paymentSelcted === 1 ? "2px solid #00FF00" : "2px solid #253980"}
            borderRadius={"8px"}
            onClick={() => setPaymentSelected(1)}
          >
            <Image src={paypal} />
          </Flex> */}

          <Flex
            style={{ cursor: "pointer" }}
            minWidth={"300px"}
            width={"100%"}
            justifyContent={"space-between"}
            padding={"3% 5%"}
            backgroundColor={"#635BFF"}
            border={
              paymentSelcted === 0 ? "2px solid #00FF00" : "2px solid #635BFF"
            }
            borderRadius={"8px"}
            onClick={() => setPaymentSelected(0)}
          >
            <Flex width={"90%"} justifyContent={["start"]} $gap={0.5}>
              {payment.map((url, index) => (
                <Image
                  key={index}
                  src={url}
                  alt="payment"
                  maxWidth={["55px", "70px"]}
                  width={["20%"]}
                  height={"auto"}
                />
              ))}
            </Flex>

            <Flex
              $gap={0.5}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Txt
                variant="light"
                color={colors.thirdText}
                $textTransform="inherit"
                fontSize={[1, 3]}
              >
                by
              </Txt>
              <Image minWidth={["50px"]} src={stripe} />
            </Flex>
          </Flex>

          {/* <Txt
            textAlign={"end"}
            variant="linkLight"
            color={colors.thirdText}
            fontSize={[2]}
            onClick={() => savePayment()}
          >
            Save this payment method as your favourite
          </Txt> */}
        </Section>
      </Flex>
      <MobileNavigationBottom
        disabled={disabled}
        variant="cart"
        text={t(i18Enum.Cart_Shipping_OrderNow)}
        isLoading={
          isFetchingCart || (isFetchingBillingAddress && !billingAddressError)
        }
        description={`${t(i18Enum.Common_Total)} ${priceNum(
          shoppingBasket?.total
        )}€`}
        buttonAction={() => {
          disabled
            ? addToast(toastType.warning, t(i18Enum.Message_SelectAddress))
            : buy();
        }}
      />
    </>
  );
};
