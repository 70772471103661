import styled from "styled-components";
import {
  HeightProps,
  WidthProps,
  space,
  SpaceProps,
  PositionProps,
  position,
  display,
  DisplayProps,
} from "styled-system";

interface SVGProps
  extends WidthProps,
    HeightProps,
    SpaceProps,
    PositionProps,
    DisplayProps {
  stroke?: string;
  handleClick?: () => void;
}

export const StyledSVG = styled.svg<SVGProps>`
  cursor: pointer;
  width: ${(props) => props.width || "30px"};
  height: ${(props) => props.height || "32px"};

  ${space}
  ${position}
  ${display}
`;

export const SVGPath = styled.path<SVGProps>`

  stroke: ${(props) =>  props.stroke ? props.stroke : props.theme.colors.thirdBase};
  ${StyledSVG}:hover & {
    stroke:  ${(props) =>props.theme.colors.primaryBase};
    }
  
`;

function CrossSvg({ ...props }: SVGProps) {
  
  return (
    <StyledSVG
      onClick={props.handleClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 32"
   {...props}
    >
      <SVGPath  stroke={props.stroke}  d="M1 1L29.125 31M1 31L29.125 1" />
    </StyledSVG>
  );
}

export default CrossSvg;
