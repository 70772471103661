import { IState } from "../SharedStateType";
import { PaginatedResponse } from "api/types/responses";

export type ProductType = {
  id?: string;
  isPublished?: boolean;
  isCancelled?: boolean;
  LikesCount?: number;
  name?: string;
  wishlistedByUser: boolean;
  shopId?: string;
  AddedDate?: Date;
  description?: string;
  metatagKeywords?: string;
  handle?: string;
  Tags?: Tags[];
  AddedBy?: string;
  composition?: string;
  Delivery?: string;
  details?: string;
  Order?: number;
  shop: ShopDetailInProductDTO;
  variants?: VariantDTO[];
};

export type ShopDetailInProductDTO = {
  id?: string;
  description?: string;
  name?: string;
  Picture?: string;
  about?: string;
  profileImage?: {
    bucketUrl: string;
  };
  coverImage?: {
    bucketUrl: string;
  };
  location?: string;
  username?: string;
};

export type ProductImageDTO = {
  id?: string;
  index?: number;
  bucketUrl?: string;
};

export type ProductOptionType = {
  id: string;
  label: string;
  type: "COLOR" | "SIZE";
  index?: number;
};

export type ProductOptionsType = {
  colors: ProductOptionType[];
  sizes: ProductOptionType[];
};

export type VariantDTO = {
  id?: string;
  product: {
    id: string;
    name: string;
    handle: string;
    shop: {
      name: string;
      username: string;
    };
  };
  options: ProductOptionType[];
  Tags?: Tags[];
  Colors?: Tags[];
  Sizes?: Tags[];
  price?: number;
  discountedPrice?: number;
  LikesCount?: number;
  images?: ProductImageDTO[];
  isPublished?: boolean;
  isFeatured?: boolean;
  AddedDate?: string;
  sku?: string;
};

export type Tags = {
  id?: string;
  ParentId?: string;
  label?: string;
  Icon?: string;
  IsDisabled?: boolean;
  IsOnHeader?: boolean;
  IsExploreByCategories?: boolean;
  IsMultichoice?: boolean;
  Tags: Tags[];
  IsCategory?: boolean;
};

export type ProductStateType = {
  product: IState<ProductType>;
  productList: IState<PaginatedResponse<ProductType>>;
  similarProducts: IState<ProductType[]>;
  productListByBrand: IState<PaginatedResponse<ProductType>>;
};

export const PRODUCT = "product";
export type PRODUCT = typeof PRODUCT;

export const SET_PRODUCT = `${PRODUCT}/setProductAction`;
export type SET_PRODUCT = typeof SET_PRODUCT; // Typescript line

export const SEARCH_PRODUCTS = `${PRODUCT}/searchProductAction`;
export type SEARCH_PRODUCTS = typeof SEARCH_PRODUCTS;

export const GET_SIMILAR_PRODUCT = `${PRODUCT}/getSimilarProductAction`;
export type GET_SIMILAR_PRODUCT = typeof GET_SIMILAR_PRODUCT;

export const GET_PRODUCT_BY_ID = `${PRODUCT}/getProductByIdAction`;
export type GET_PRODUCT_BY_ID = typeof GET_PRODUCT_BY_ID;

export const GET_PRODUCT_LIST_BY_BRAND_ID = `${PRODUCT}/getProductListByBrandIdAction`;
export type GET_PRODUCT_LIST_BY_BRAND_ID = typeof GET_PRODUCT_LIST_BY_BRAND_ID;
