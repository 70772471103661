import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { CustomThemeContext } from "context/ThemeContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { H4 } from "theme/theme.styled";
import i18next from "i18next";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

interface ErrorNoItemsProps {
  arrayLenght?: number;
  message: string;
  countDownText?: string;
  hasCountDown: boolean;
}

export const ErrorNoItems = ({ arrayLenght, message, countDownText, hasCountDown }: ErrorNoItemsProps) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const [counter, setCounter] = useState<number>(5);
  const { setAsideIsOpen, isMobile } = useContext(AppGeneralContext);
  const navigate = useNavigate();
  const currentLanguage = i18next.language;
  const { t } = useTranslation();

  useEffect(() => {
    if (!hasCountDown) {
      return;
    }

    if (arrayLenght !== 0) {
      return;
    }

    if (counter > 0) {
      setTimeout(() => {
        setCounter((x) => x - 1);
      }, 1000);
      return;
    }

    if (isMobile) {
      navigate(-1);
    } else {
      setAsideIsOpen(false);
    }
  }, [counter, arrayLenght]);

  return (
    <Flex flexDirection={"column"} alignItems={"cnter"} minHeight={"100vh"}>
      <Txt as={"h4"} fontSize={H4} width={[1]} color={colors.thirdBase}>
        {message ? message : t(i18Enum.Cart_Checkout_NoItems)}
      </Txt>
      {hasCountDown && (
        <Txt fontSize={[2]} width={[1]} color={colors.thirdBase}>
          {`${countDownText ? countDownText : t(i18Enum.Common_ThePage)} ${t(i18Enum.Error_No_Items_Countdown, { counter })}${
            currentLanguage === "IT" ? (counter === 1 || counter === 0 ? "o" : "i") : counter === 1 || counter === 0 ? "" : "s"
          }`}
        </Txt>
      )}
    </Flex>
  );
};
