import { useContext, useEffect, useState } from "react";
import { SpaceProps, WidthProps } from "styled-system";
import { PlusLg } from "react-bootstrap-icons";
import Box from "../primitives/Box";
import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import { CustomThemeContext } from "../../context/ThemeContext";
import Input from "../primitives/Input";
import CrossSvg from "../atoms/CrossSvg";

interface AddColorProps extends SpaceProps, WidthProps {
  id: string;
  label: string;
  selectedColors?: string[];
}

export const AddColor = ({ id, selectedColors }: AddColorProps) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentColor, setCurrentColor] = useState<string>("#000000");
  const [colorsArray, setColorsArray] = useState<string[]>([]);
  const [deleteMode, setDeleteMode] = useState(false);

  useEffect(() => {
    if (selectedColors) {
      setIsOpen(true)
      setColorsArray(selectedColors);
    }
  }, [selectedColors])

  const handleButtonClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }

    if (isOpen && !deleteMode) {
      getNewColor();
    }
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentColor(event.target.value);
  };

  const getNewColor = () => {
    setColorsArray((prevState) => {
      if (prevState.find((color) => color === currentColor)) {
        return prevState;
      } else {
        return [...prevState, currentColor];
      }
    });
    setCurrentColor("#000000");
  };

  const deleteColor = (color: string) => {
    setColorsArray(colorsArray.filter((value) => value !== color));
  };

  const handleSave = () => {
    setDeleteMode(false);

    if (colorsArray.length === 0) {
      setIsOpen(false);
    }
  };

  return (
    <Box width={[1]}>
      <Flex id={id} flexDirection={"column"} backgroundColor={colors.grayBase} borderRadius={isOpen ? "16px" : "50px"} width={[1]} $transition={"0.5s ease-in-out all"}>
        <Flex justifyContent={"space-between"} alignItems={"center"} padding={["9px 16px"]}>
          <Txt as='p' variant='light' fontSize={3} margin={0} color={colors.thirdBase} textAlign={"end"}>
            Colors
          </Txt>
          <Flex $gap={0.25} alignItems={"center"} style={{ cursor: !deleteMode ? "pointer" : "default" }} onClick={handleButtonClick}>
            <PlusLg fontSize={"20px"} color={colors.primaryBase} />
            <Txt as='p' variant='light' fontSize={3} margin={0} color={colors.primaryBase} style={{ cursor: !deleteMode ? "pointer" : "default" }}>
              Add color
            </Txt>
          </Flex>
        </Flex>

        {isOpen && (
          <Flex flexDirection={"column"} width={"100%"} padding={["0 16px 16px"]}>
            {deleteMode && colorsArray.length > 0 && (
              <Txt as='p' variant='light' $textTransform='inherit' fontSize={3} margin={"0 0 1em 0"} textAlign={"start"}>
                Click on the colors to delete
              </Txt>
            )}

            <Flex $gap={1} flexWrap={"wrap"} maxWidth={"100%"}>
              {colorsArray.map(
                (color, index) =>
                  color !== "" && (
                    <Flex
                      key={index}
                      position={"relative"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      backgroundColor={color}
                      opacity={!deleteMode ? "1" : "0.7"}
                      width={"36px"}
                      height={"36px"}
                      $aspectRatio='1/1'
                      borderRadius={"100%"}
                    >
                      {deleteMode && <CrossSvg width={"50%"} height={"50%"} position={"absolute"} handleClick={() => deleteColor(color)} />}
                    </Flex>
                  )
              )}
              {!deleteMode && <Input variant='color' type='color' value={currentColor} onChange={handleColorChange} />}
            </Flex>
          </Flex>
        )}

        {isOpen && (
          <Flex
            justifyContent={"center"}
            backgroundColor={isOpen ? colors.graySolid : colors.grayBase}
            padding={"6px"}
            borderRadius={"0 0 16px 16px"}
            $transition={"background-color 0.5s ease-in-out"}
          >
            {!deleteMode ? (
              <Txt variant='linkLight' fontSize={[3]} color={"#F00"} onClick={() => setDeleteMode(true)}>
                Delete color
              </Txt>
            ) : (
              <Txt variant='linkLight' fontSize={[3]} color={colors.primaryBase} onClick={handleSave}>
                Save
              </Txt>
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
