export function priceNum(num?: number | string): string {
  let price = num;

  if (price === 0 || !price) {
    return "0";
  }

  if (typeof price == "string") {
    price = parseFloat(price);
  }

  // Redondea el número a dos decimales
  const roundedNumber = price?.toFixed(2) ?? "";

  // Usa toLocaleString para formatear el número según la configuración regional
  const formattedNumber = parseFloat(roundedNumber).toLocaleString("it-IT", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
}
