import { useEffect, useRef } from "react";
import styled from "styled-components";
import { SpaceProps, TypographyProps, PositionProps, space, typography, position, compose, variant } from "styled-system";

interface CustomProps {
  fontSize?: string;
  paddingTop?: string;
  lineHeight?: string;
  width?: string;
}

const CSSParentSelector = styled.div<SpaceProps & TypographyProps & PositionProps & CustomProps & { variant?: "unordered" }>`
  width: ${(props) => props.width || "100%"};
  * {
    text-align: justify;
    text-transform: inherit;
    font-family: "BauPro";
    font-size: ${(props) => props.fontSize || "16px"};
    padding-top: ${(props) => props.paddingTop || "0"};
    line-height: ${(props) => props.lineHeight || "normal"};
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  em {
    font-style: italic;
  }

  strong {
    color: ${(props) => props.theme.colors.primaryBase};
  }

  ul {
    list-style-type: none;
  }

  ul,
  ol {
    padding-left: 1%;
  }

  li {
    padding: 0.5rem 0;
  }

  a:hover {
    color: ${(props) => props.theme.colors.thirdText};
  }

  div {
    padding: 1rem 0;
  }

  div > img {
    width: 60%;
    margin: 0 auto;
  }

  .tableGrid {
    width: 100%;
    margin: 0 auto;
    overflow-x: scroll;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 3fr 1fr;
    word-wrap: break-word;
  }

  .tableGrid > .header {
    background-color: ${(props) => props.theme.colors.primaryBase};
    color: black;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tableGrid > div {
    border: 1px solid ${(props) => props.theme.colors.thirdBase};
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .tableGrid > div > span {
    border-bottom: 1px solid ${(props) => props.theme.colors.thirdBase};
  }

  .tableGrid > div > span,
  .tableGrid > .header {
    font-size: 12px;
    padding: 1rem;
    height: 100%;
    word-break: break-all;
  }

  .tableGrid > div > span:last-child {
    border-bottom: none;
  }

  @media only screen and (max-width: 768px) {
    div > img {
      width: 100%;
    }

    .tableGrid {
      grid-template-columns: 1fr 1fr 1fr 2fr 1fr;
    }

    .tableGrid > div > span,
    .tableGrid > .header {
      font-size: 10px;
      padding: 0.5rem;
    }

    .cookieName {
      font-size: 9px !important;
    }
  }

  ${() =>
    compose(
      variant({
        variants: {
          unordered: {
            ul: {
              listStyleType: "disc",
              listStylePosition: "inside",
              padding: 0,
              margin: 0,
              lineHeight: 1.5,
            },
            li: {
              padding: 0,
              lineHeight: 1.5,
            },
            "& li:first-child": {
              display: "list-item",
              listStyleType: "disc",
              listStylePosition: "inside",
            },
            "& li:nth-child(2)": {
              paddingTop: "0 !important",
            },
          },
        },
      })
    )};
  ${space}
  ${typography}
  ${position}
`;

type RenderHTMLProps = {
  html: string;
  fontSize?: string;
  paddingTop?: string;
  lineHeight?: string;
  width?: string;
  variant?: "unordered";
};

export const RenderHTML = ({ ...props }: RenderHTMLProps) => {
  const selectorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectorRef && selectorRef.current) {
      selectorRef.current.innerHTML = props.html;
    }
  }, [props.html, selectorRef]);

  return <CSSParentSelector {...props} ref={selectorRef} variant={props.variant} />;
};
