import { useContext, useState } from "react";
// import { AppGeneralContext } from "../context/AppGeneralContext";
import { CustomThemeContext } from "../context/ThemeContext";
import Flex from "../components/primitives/Flex";
import Txt from "../components/primitives/Txt";
// import { WardrobeTemplate } from "../components/template/WardrobeTemplate";
// import { parseWardrobeItems, sortCards } from "../utils";
import MenuNavigateProps from "components/organisms/MenuNavigate";
// import { WardrobeItemType } from "utils/generalTypes/wardrobe";
// import { useAppSelector } from "redux/app/hooks";
import { UserType } from "redux/features/user/UserTypes";

export const Wardrobe = () => {
    // const { addToast, isMobile } = useContext(AppGeneralContext);
   
    const { customTheme } = useContext(CustomThemeContext);
    const colors = customTheme.colors;
    const [userData, setUserData] = useState<UserType>();
  // const [cardsData, setCardsData] = useState<WardrobeItemType[][]>([]);
  // const { data:wardrobeItems,isLoading: isFetchingWardrobeItems, errors: wardrobeError } = useAppSelector((state) => state.wardrobe.wardrobe)
//TODO riabilitare successivamente

    //  useEffect(() => {
    //   if (!wardrobeItems?.items)
    //     return
     
    //   const arrFromWardrobeItems:WardrobeItemType[]= wardrobeItems.items.map(parseWardrobeItems)
    //   const cardsArray: WardrobeItemType[][] = [];
    //   sortCards(arrFromWardrobeItems, cardsArray, isMobile ? 4 : 8);
    //   setCardsData(cardsArray);
    //  }, [isMobile, wardrobeItems]);
   

    

  return (
    <Flex padding={["calc(5% + 80px) 0", "0px 0"]} justifyContent={"center"}>
         {userData && (
        <Flex
          key={userData.Id}
          flexDirection={"column"}
          width={"100%"}
          padding={["0 1em", "0 5em"]}
          $gap={1.5}
          maxWidth={950}
        >
         <MenuNavigateProps userData={userData} />
        <Flex marginTop={"1em"} flexDirection={"column"}>
            <Txt
              as={"h3"}
              variant="light"
              color={colors.thirdText}
              textAlign={"left"}
              marginBottom={0}
            >
              Wardrobe
            </Txt>
            <Txt
              as={"p"}
              variant="light"
              fontSize={12}
              color={colors.thirdText}
              style={{textTransform:"none"}}
              textAlign={"left"}
              marginBottom={0}
            >
              Build your wardrobe with your photos and clothes
            </Txt>
            <Txt
              as={"p"}
              fontSize={12}
              variant="light"
              color={colors.thirdText}
              textAlign={"left"}
              style={{textTransform:"none"}}
              marginBottom={0}
            >
             Click on the eye icon 👁️ to activate or deactivate the visibility of the field
            </Txt>
        </Flex>
        <Flex
          flexDirection={["column", "row"]}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          {/* {cardsData.map((cards, index) => {
            return (
              <Flex key={index} flexWrap={"wrap"}>
                <WardrobeTemplate
                  isFirst={index === 0 ? true : false}
                  hasEdit={true}
                  data={cards}
                />
              </Flex>
            );
          })} */}
        </Flex>
        </Flex>
      )}
    </Flex>
  )
}
