import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { SpaceProps, WidthProps } from "styled-system";
import { CustomThemeContext } from "../../context/ThemeContext";
import Input from "../primitives/Input";
// import IconFilter from "../../assets/icons/filter.svg?react";
import SearchSvg from "../atoms/SearchSvg";
import Box from "../primitives/Box";
import Flex from "../primitives/Flex";

interface SearchProps {
  handleClick?: () => void;
  handleFilter?: () => void;
  placeholder?: string;
  short?: boolean;
  isfilter?: boolean;
  isButton?: boolean;
  isFocused?: boolean;
  control?: UseFormRegisterReturn;
  value?: string;
  isOpen?: boolean;
  launchSearch?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  id?: string;
}

const Search: React.FC<SearchProps & WidthProps & SpaceProps> = ({
  handleClick,
  handleFilter,
  placeholder,
  isfilter,
  isButton,
  isFocused,
  short,
  control,
  value,
  isOpen,
  launchSearch,
  onKeyDown,
  id,
  ...props
}) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const [show, setShow] = useState<boolean>(isFocused ? isFocused : false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [onFocusText, setOnFocusText] = useState<boolean>(false);

  useEffect(() => {
    // Establece el foco en el input al renderizar
    if (inputRef.current && !isButton && isOpen !== undefined) {
      inputRef.current.focus();
    }
  }, []);

  const handleShow = useCallback(
    (value: boolean): void => {
      setShow(value);
    },

    [show]
  );

  const handleRef = (event: HTMLInputElement | null) => {
    if (event) inputRef.current = event;
  };

  return (
    <Box {...props} onClick={handleClick}>
      <Box
        padding={0}
        margin={0}
        borderRadius={"22px"}
        backgroundColor={colors.grayBase}
      >
        <Flex
          width={"100%"}
          padding={!short ? ["3px 23px 3px 30px"] : ["0 23px 0 30px"]}
          justifyContent={"space-between"}
          alignItems={"center"}
          $gap={1}
        >
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              cursor: "pointer",
              width: "100%",
            }}
            onClick={handleClick}
          >
            <SearchSvg
              variant="searchable"
              width={!short ? ["18px"] : ["24px"]}
              handleClick={launchSearch}
            />
            <Flex width={"100%"}>
              <Input
                id={id}
                width={"100%"}
                style={{ cursor: isButton ? "pointer" : "text" }}
                placeholder={onFocusText ? "" : placeholder}
                defaultValue={!isButton ? value : ""}
                paddingTop={"0.3em"}
                autoComplete="off"
                variant="search"
                color={isfilter ? colors.thirdText : colors.primaryText}
                fontSize={!short ? [2] : ["16px"]}
                padding={!short ? ["3px 0"] : ["1px 0"]}
                onFocus={() => {
                  handleShow(!isButton ? true : false);
                  setOnFocusText(true);
                }}
                onBlur={() => {
                  handleShow(false);
                  setOnFocusText(false);
                }}
                ref={(e: HTMLInputElement | null) => handleRef(e)}
                onKeyDown={onKeyDown}
                {...control}
              />
            </Flex>
          </label>
          {/* {!short && <IconFilter onClick={handleFilter} style={{ cursor: "pointer" }} fill={colors.primaryBase} />} */}
        </Flex>

        {!short && (
          <Flex
            flexDirection={"column"}
            maxHeight={show ? "400px" : "0px"}
            overflowY={"hidden"}
            style={{ transition: "all 0.3s linear" }}
          >
            {/* <Flex
              width={[1]}
              justifyContent={"space-between"}
              border={`solid ${colors.secondaryBg}`}
              borderWidth={"3px 0"}
              padding={["15px 25px"]}
              marginTop={"16px"}
            >
              <Txt fontSize={[3]} variant="light" color={colors.thirdText}>
                {value ? "ARE YOU LOOKING FOR " : "RECENT RESEARCH"}
              </Txt>
              <Txt
                fontSize={[3]}
                variant="linkLight"
                color={colors.dangerText}
                display={value ? "none" : "block"}
                onClick={()=>{alert("delete ")}}
              >
                {"delete"}
              </Txt>
            </Flex> */}
            {/* TODO gestire map v2 <Flex flexDirection={"column"} padding={"19px 25px"} $gap={0.4}>
              {searchResponse?.data?.map((search, index) => (
                <Grid
                  key={index}
                  gridTemplateAreas={`"icon title" "icon nav"`}
                  gridTemplateRows={"auto auto"}
                  gridTemplateColumns={"auto 1fr"}
                  gridColumnGap={"20px"}
                  alignItems={"center"}
                >
                  <Image src={iconRecent} style={{ gridArea: "icon" }} width={"31px"} />
                  <Txt
                    fontSize={["18"]}
                    width={"fit-content"}
                    style={{ gridArea: "title", textTransform: "inherit" }}
                    variant="light"
                    color={colors.thirdText}
                  >
                    {capitalize(search.title)}
                  </Txt>
                  <Txt
                    fontSize={[0]}
                    style={{ gridArea: "nav", textTransform: "inherit" }}
                    width={"fit-content"}
                    variant="light"
                    color={colors.thirdText}
                  >
                    {capitalize(search.navigation)}
                  </Txt>
                </Grid>
              ))}
            </Flex> */}
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default Search;
