import Flex from "components/primitives/Flex";
import { useCallback, useContext, useMemo, useState } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { CustomThemeContext } from "context/ThemeContext";
import { ValueRangeSlider } from "components/molecules/Slaider";
import { FilterPrice } from "components/molecules";
import { FilterRow } from "components/molecules/filters/FilterRow";
import Button from "components/primitives/Button";
import { AppGeneralContext } from "context/AppGeneralContext";
import Box from "components/primitives/Box";
import { Tags } from "redux/features/product/ProductsTypes";
import {
  MultiColorKeyGradient,
  VariantColor,
  colorsKeyEnum,
  orderByTag,
} from "utils";
import { OrderBy } from "utils/generalTypes/shared";
import CrossSvg from "components/atoms/CrossSvg";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import Tooltip from "components/primitives/Tooltip";

interface FilterProp {
  handleBack: (
    selectedTags?: Tags[],
    orderBy?: OrderBy,
    priceRange?: ValueRangeSlider,
    isPromo?: boolean
  ) => void;
  selectedFilters?: Tags[];
  priceRangeSelected?: ValueRangeSlider;
  selectedOrderBy?: OrderBy;
  isPromoSelected?: boolean;
}

export const NewFilter = ({
  handleBack,
  selectedFilters,
  selectedOrderBy,
  priceRangeSelected,
  isPromoSelected,
}: FilterProp) => {
  //context data
  const { filterList } = useContext(AppGeneralContext);
  const color = useContext(CustomThemeContext).customTheme.colors;
  //useState data
  const [priceRange, setPriceRange] = useState<ValueRangeSlider>(
    priceRangeSelected
      ? {
          maxValue: priceRangeSelected.maxValue,
          minValue: priceRangeSelected.minValue,
        }
      : { maxValue: filterList?.MaxPrice ?? 500, minValue: 0 }
  );
  const [selectedTag, setSelectedTag] = useState<Tags>();
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>(selectedOrderBy);
  const [isPromo, setIsPromo] = useState<boolean>(isPromoSelected ?? false);
  const [selectedTagsObj, setSelectedTagsObj] = useState<Tags[]>(
    selectedFilters ?? []
  );
  const { t } = useTranslation();

  //set filters
  const Maxprice = useMemo(() => {
    if (filterList?.MaxPrice) {
      return filterList?.MaxPrice;
    }
    return 500;
  }, [filterList?.MaxPrice]);

  const tagsFilterList: Tags[] | undefined = useMemo(() => {
    return filterList?.Tags;
  }, [filterList?.Tags?.length]);

  const categoriesFilterList: Tags[] | undefined = useMemo(() => {
    return [
      {
        id: "categories",
        label: "Categories",
        // TODO: riattivare nella v2
        // Label: t(i18Enum.Filters_Label_Categories),
        ParentId: undefined,
        Tags: filterList?.Categories ?? [],
      },
    ] as Tags[];
  }, [filterList?.Categories?.length]);

  //handler function
  const handleRangePrice = (value: ValueRangeSlider) => {
    setPriceRange({ maxValue: value.maxValue, minValue: value.minValue });
    return;
  };

  const handleOrderByFilter = (condition: boolean, tag: Tags) => {
    if (condition) {
      setOrderBy({ OrderBy: { id: tag.id, value: tag.id } });
      setSelectedTag(tag);
    } else {
      setOrderBy((prev) => ({
        ...prev,
        OrderDirection: {
          id: tag.id,
          value: tag.id?.split("-", 1)[0],
          label: tag.label,
        },
      }));
    }
  };
  //utility function

  const addObjFilterValue = useCallback(
    (obj?: Tags) => {
      if (!obj?.id) {
        return;
      }
      const arr: Tags[] = selectedTagsObj ?? [];
      const found = arr.find((el) => el.id === obj.id);
      if (found) {
        const newArr = arr.filter((el) => el.id !== obj.id);
        setSelectedTagsObj(newArr);
        return;
      }
      setSelectedTagsObj([...arr, obj]);
    },
    [selectedTagsObj]
  );

  //JSX render function
  const renderOrderBy = (tag: Tags) => {
    return tag.Tags.map((tag) => {
      return (
        <FilterRow
          filterLabel={tag.label ?? ""}
          hasChild={tag.Tags.length > 0}
          key={tag.id}
          isSelected={
            orderBy?.OrderBy?.id === tag.id ||
            orderBy?.OrderDirection?.id === tag.id
          }
          handleFilter={() => handleOrderByFilter(tag.Tags.length > 0, tag)}
        />
      );
    });
  };

  const renderNextPage = (tag: Tags) => {
    if (tag.label === "Colors") {
      return (
        <Flex flexDirection={"row"}>
          {tag.Tags.map((tag) => (
            <Tooltip content={tag.label ?? ""}>
              <Box
                key={tag.id}
                style={{ cursor: "pointer" }}
                backgroundColor={VariantColor(tag?.label ?? "")}
                backgroundImage={
                  tag.label === colorsKeyEnum.Multicolour
                    ? MultiColorKeyGradient
                    : "unset"
                }
                border={"2px solid"}
                borderColor={
                  tag.id && !!selectedTagsObj?.find((el) => el.id === tag.id)
                    ? color.primaryBase
                    : VariantColor(tag?.label ?? "")
                }
                width={50}
                height={50}
                onClick={() =>
                  tag.Tags.length > 0
                    ? setSelectedTag(tag)
                    : addObjFilterValue(tag)
                }
              />
            </Tooltip>
          ))}
        </Flex>
      );
    }

    if (tag.label === "Categories") {
      return (
        <>
          {tag.Tags.map((tag) => (
            <FilterRow
              filterLabel={tag.label ?? ""}
              hasChild={tag.Tags.length > 0}
              key={tag.id}
              isSelected={!!selectedTagsObj?.find((el) => el.id === tag.id)}
              handleFilter={() =>
                tag.Tags.length > 0
                  ? setSelectedTag(tag)
                  : addObjFilterValue(tag)
              }
            />
          ))}

          <FilterRow
            filterLabel={t(i18Enum.Shopping_Page_Promo)}
            hasChild={false}
            key={"promo-id"}
            isSelected={!!selectedTagsObj?.find((el) => el.id === tag.id)}
            handleFilter={() => setIsPromo(!isPromo)}
          />
        </>
      );
    }

    return tag.Tags.map((tag) => {
      return (
        <FilterRow
          filterLabel={tag.label ?? ""}
          hasChild={tag.Tags.length > 0}
          key={tag.id}
          isSelected={!!selectedTagsObj?.find((el) => el.id === tag.id)}
          handleFilter={() =>
            tag.Tags.length > 0 ? setSelectedTag(tag) : addObjFilterValue(tag)
          }
        />
      );
    });
  };
  //handle back
  const BackToFilters = () => {
    setSelectedTag(undefined);
  };

  return (
    <Flex width={[1]} flexDirection={"column"} padding={["0 5%", "0"]}>
      <Flex padding={"10px 0"} marginBottom={4}>
        <ArrowLeft
          color={color.primaryBase}
          size={25}
          width={"50px"}
          style={{ cursor: "pointer" }}
          onClick={
            selectedTag === undefined
              ? () =>
                  handleBack(
                    selectedTagsObj,
                    orderBy,
                    priceRangeSelected,
                    isPromo
                  )
              : BackToFilters
          }
        />
        <Flex flexDirection={"row"} flexWrap={"wrap"} paddingTop={2}>
          {orderBy?.OrderDirection && (
            <Button ml={2} mt={2} variant="switcherGenre" padding={"10px 20px"}>
              {orderBy.OrderDirection.label}
              <CrossSvg
                marginBottom={1}
                height={"10px"}
                width={"10px"}
                marginLeft={2}
                handleClick={() => setOrderBy(undefined)}
              />
            </Button>
          )}
          {selectedTagsObj?.map((el) => {
            return (
              <Button
                key={el.id}
                ml={2}
                mt={2}
                variant="switcherGenre"
                padding={"10px 20px"}
              >
                {el.label}
                <CrossSvg
                  marginBottom={1}
                  height={"10px"}
                  width={"10px"}
                  marginLeft={2}
                  handleClick={() => addObjFilterValue(el)}
                />
              </Button>
            );
          })}
          {isPromo && (
            <Button ml={2} mt={2} variant="switcherGenre" padding={"10px 20px"}>
              {t(i18Enum.Shopping_Page_Promo)}
              <CrossSvg
                marginBottom={1}
                height={"10px"}
                width={"10px"}
                marginLeft={2}
                handleClick={() => setIsPromo(false)}
              />
            </Button>
          )}
        </Flex>
      </Flex>
      {selectedTag === undefined ? (
        <>
          {categoriesFilterList.map((tag) => {
            return (
              <FilterRow
                filterLabel={tag.label ?? ""}
                hasChild={categoriesFilterList.length > 0}
                key={tag.id}
                isSelected={!!selectedTagsObj?.find((el) => el.id === tag.id)}
                handleFilter={() =>
                  categoriesFilterList.length > 0
                    ? setSelectedTag(tag)
                    : addObjFilterValue(tag)
                }
              />
            );
          })}
          <FilterRow
            filterLabel={orderByTag.label ?? ""}
            hasChild={orderByTag.Tags.length > 0}
            key={orderByTag.id}
            handleFilter={() => setSelectedTag(orderByTag)}
          />
          {tagsFilterList?.map((tag) => {
            return (
              <FilterRow
                filterLabel={tag.label ?? ""}
                hasChild={tag.Tags.length > 0}
                isSelected={!!selectedTagsObj?.find((el) => el.id === tag.id)}
                key={tag.id}
                handleFilter={() =>
                  tag.Tags.length > 0
                    ? setSelectedTag(tag)
                    : addObjFilterValue(tag)
                }
              />
            );
          })}

          <FilterPrice
            range={{ minValue: 0, maxValue: Maxprice }}
            handleRangePrice={handleRangePrice}
            selectedMaxPrice={priceRange.maxValue}
            selectedMinPrice={priceRange.minValue}
          />

          <Flex justifyContent={"center"} padding={"30px 0"}>
            <Button
              variant="primary"
              padding={"10px 20px"}
              onClick={() =>
                handleBack(
                  selectedTagsObj,
                  orderBy,
                  priceRangeSelected,
                  isPromo
                )
              }
            >
              {t(i18Enum.Filters_Button_Filters)}
            </Button>
          </Flex>
        </>
      ) : selectedTag.ParentId === "orderBy" ? (
        renderOrderBy(selectedTag)
      ) : (
        renderNextPage(selectedTag)
      )}
    </Flex>
  );
};
