/* eslint-disable @typescript-eslint/no-explicit-any */
import { SpaceProps, WidthProps } from "styled-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectField from "components/molecules/SelectField";
import { useContext, useEffect, useState } from "react";
import { AppGeneralContext } from "context/AppGeneralContext";
import { IdOptionType, OptionNumberType } from "components/primitives/Select";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import Form from "components/primitives/Form";
import { useNavigate } from "react-router-dom";
import { UserActions } from "redux/features/user/userSlice";
import { UserShippingAddress } from "redux/features/user/UserTypes";
import { toastType } from "utils";
import { useTheme } from "styled-components";
import { CreateUpdateBillingAddressRequest } from "api/types/requests/shippingAddressRequest";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import TextareaField from "components/molecules/TextareaField";
import Box from "components/primitives/Box";
import { PAGE } from "utils/const/pagePath";
import Button from "components/primitives/Button";
import { i18Enum } from "i18n/types/translationType";
import { t } from "i18next";
import { BaseAddressFields } from "components/molecules/AddressCard";
import { useQuery } from "@tanstack/react-query";
import { getProvinces, getRegions } from "api/graphql/requests";

type BillingVariants = "shipping";
type BillingAddressFormFields = {
  CountryKey?: OptionNumberType;
  RegionDto?: IdOptionType | undefined;
  ProvinceDto?: IdOptionType | undefined;
} & Omit<BaseAddressFields, "isBilling" | "isDefault">;

type FormShippingAddressSchemaObject = {
  [key in keyof BillingAddressFormFields]: Yup.Schema<any>;
};

interface AddressFormProps extends WidthProps, SpaceProps {
  useThisAddress?: UserShippingAddress;
  data?: UserShippingAddress;
  variant?: BillingVariants;
  isCartForm?: boolean;
  isEdit: boolean;
}
type FormField = {
  id: keyof BillingAddressFormFields;
  placeholder: string;
  type: "input" | "select";
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const formAddressSchema = Yup.object().shape<FormShippingAddressSchemaObject>({
  id: Yup.string(),
  street: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
  streetNumber: Yup.string(),
  city: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
  notes: Yup.string(),
  name: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
  phone: Yup.string()
    .matches(phoneRegExp, t(i18Enum.Validation_Client_Field_Phone))
    .required(t(i18Enum.Validation_Client_Field_Required)),
  zipCode: Yup.string().required(t(i18Enum.Validation_Client_Field_Required)),
  RegionDto: Yup.object()
    .shape({
      id: Yup.number(),
      label: Yup.string(),
    })
    .required(t(i18Enum.Validation_Client_Field_Required)),
  ProvinceDto: Yup.object()
    .shape({
      id: Yup.number(),
      label: Yup.string(),
    })
    .required(t(i18Enum.Validation_Client_Field_Required)),
});

const formInitialValue: BillingAddressFormFields = {
  id: "",
  street: "",
  streetNumber: "",
  city: "",
  CountryKey: { label: t(i18Enum.DV_Countries_110), value: 110 },
  notes: "",
  name: "",
  phone: "",
  zipCode: "",
  RegionDto: undefined,
  region: "",
  regionId: undefined,
  ProvinceDto: undefined,
  province: "",
  provinceId: undefined,
};
interface AddressFormProps extends WidthProps, SpaceProps {
  useThisAddress?: UserShippingAddress;
  data?: UserShippingAddress;
  variant?: BillingVariants;
  isCartForm?: boolean;
  isEdit: boolean;
  getSelectedName?: (par: string) => void;
}

//TODO: Unify with AddressForm as they are almost identical
export const AddEditBillingForm = ({
  data,
  isEdit,
  isCartForm = false,
  useThisAddress,
  getSelectedName,
  ...props
}: AddressFormProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const colors = useTheme().colors;
  const {
    addToast,
    isMobile,
    setAsideIsOpen,
    closeAndResetAside,
    setIsLoading,
  } = useContext(AppGeneralContext);

  const { data: regionsList, isLoading: isLoadingRegions } = useQuery({
    queryKey: ["regionsList"],
    queryFn: () => getRegions(),
  });

  const { data: provincesList, isLoading: isLoadingProvinces } = useQuery({
    queryKey: ["provincesList"],
    queryFn: () => getProvinces(),
  });

  const cart = useAppSelector((state) => state.cart.cart.data?.cartOrderInfo);
  const { isSuccess, errors, isLoading } = useAppSelector(
    (state) => state.user.userBillingAddresses
  );
  const [formikInitialValue, setFormikInitialValue] =
    useState<BillingAddressFormFields>(
      data
        ? {
            ...data,
            CountryKey: {
              label: t(i18Enum.DV_Countries_110),
              value: data.countryId ?? 110,
            },
            RegionDto: {
              label: data?.region?.label,
              id: data?.regionId || 0,
            },
            ProvinceDto: {
              label: data?.province?.label,
              id: data?.provinceId || 0,
            },
          }
        : formInitialValue
    );

  const defaultNation: OptionNumberType[] = [
    { label: t(i18Enum.DV_Countries_110), value: 110 },
  ];

  const submitRequest = async (
    isEdit: boolean,
    value: BillingAddressFormFields
  ) => {
    const req: CreateUpdateBillingAddressRequest = {
      id: value.id,
      name: value.name ?? "",
      street: value.street ?? "",
      streetNumber: value.streetNumber ?? "",
      city: value.city ?? "",
      countryId: Number(value.CountryKey?.value) ?? 110,
      zipCode: value.zipCode ?? "",
      notes: value.notes ?? "",
      phone: value.phone ?? "",
      regionId: value.RegionDto?.id ? parseInt(value.RegionDto.id) : undefined,
      provinceId: value.RegionDto?.id
        ? parseInt(value.ProvinceDto.id)
        : undefined,
      isBilling: true,
    };

    if (isEdit && value?.id) {
      dispatch(
        UserActions.updateUserBillingAddressAction({
          id: value.id,
          params: req,
        })
      );
      return;
    } else {
      req.id = undefined;
      dispatch(UserActions.createUserBillingAddressAction(req));
      return;
    }
  };

  const formik = useFormik<BillingAddressFormFields>({
    enableReinitialize: true,
    initialValues: formikInitialValue,
    validationSchema: formAddressSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await submitRequest(isEdit, values);
      //error
      if (isSuccess !== null && !isSuccess) {
        setSubmitting(false);
        addToast(toastType.error, errors);
        return;
      }

      if (getSelectedName) {
        getSelectedName(values?.name ?? "");
      }
      //success
      setSubmitting(false);
      if (isMobile) {
        if (isCartForm) {
          navigate(-1);
          //TODO
        } else {
          navigate(PAGE.orders.menuPath);
          formik.resetForm();
          return;
        }
      }
      if (isCartForm) {
        formik.resetForm();
        return;
      }
      closeAndResetAside();
      setAsideIsOpen(false);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (cart?.billingAddressSameAsDeliveryAddress && useThisAddress?.id) {
      //set initial value as default shipping address
      setFormikInitialValue({
        ...useThisAddress,
        CountryKey: { label: t(i18Enum.DV_Countries_110), value: 110 },
      });
      return;
    }
  }, [cart?.billingAddressSameAsDeliveryAddress, useThisAddress]);

  const FormFields: FormField[] = [
    {
      id: "name",
      type: "input",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_Name),
    },
    {
      id: "street",
      type: "input",
      placeholder: t(
        i18Enum.User_Shipping_ManageShippingAddress_Label_AddressLine1
      ),
    },
    {
      id: "streetNumber",
      type: "input",
      placeholder: t(
        i18Enum.User_Shipping_ManageShippingAddress_Label_AddressLine2
      ),
    },
    {
      id: "phone",
      type: "input",
      placeholder: t(
        i18Enum.User_Shipping_ManageShippingAddress_Label_PhoneNumber
      ),
    },
    {
      id: "CountryKey",
      type: "select",
      placeholder: t(
        i18Enum.User_Shipping_ManageShippingAddress_Label_SelectCountry
      ),
    },
    {
      id: "RegionDto",
      type: "select",
      placeholder: "Regione",
    },
    {
      id: "ProvinceDto",
      type: "select",
      placeholder: "Provincia",
    },
    {
      id: "city",
      type: "input",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_City),
    },
    {
      id: "zipCode",
      type: "input",
      placeholder: t(i18Enum.User_Shipping_ManageShippingAddress_Label_ZIPCode),
    },
  ];

  return (
    <Form
      as="form"
      flex={1}
      width={!isMobile && !isCartForm ? "65%" : "100%"}
      flexDirection={"column"}
      onSubmit={formik.handleSubmit}
      alignItems={"end"}
      position={"relative"}
      marginBottom={isMobile ? 140 : 90}
      {...props}
    >
      {FormFields.map((el, i) => {
        const fieldName = el.id as keyof BillingAddressFormFields;
        const placeholder = el.placeholder;
        const value = formik.values[fieldName] as string;
        const error =
          formik.errors[fieldName] && formik.touched[fieldName]
            ? String(formik.errors[fieldName])
            : undefined;
        if (fieldName === "id") {
          return null;
        }
        return el.type === "input" ? (
          <InputFieldWardrobe
            {...formik.getFieldProps(fieldName)}
            key={fieldName}
            name={fieldName}
            spaceProp={{
              width: [1],
              marginTop: i > 0 ? [4] : null,
              padding: ["0 27px"],
            }}
            id={fieldName}
            placeholder={placeholder}
            label={placeholder}
            value={value}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched(fieldName, true)}
            error={error}
          />
        ) : fieldName === "CountryKey" ? (
          <SelectField<OptionNumberType>
            key={fieldName}
            marginTop={30}
            padding={"0 27px"}
            width={[1]}
            id={fieldName}
            placeholder={placeholder}
            option={defaultNation}
            value={{
              value: formik.values.CountryKey?.value ?? 110,
              label: t(i18Enum.DV_Countries_110),
            }}
            handleChange={(opt) => formik.setFieldValue(fieldName, opt)}
            handleBlur={() => formik.setFieldTouched(fieldName, true)}
            labelExtractor={(x) => x.label}
            valueExtractor={(x) => x.value.toString()}
          />
        ) : fieldName === "RegionDto" ? (
          <SelectField<IdOptionType>
            isClearable
            isSearchable
            isLoading={isLoadingRegions}
            key={fieldName}
            marginTop={30}
            padding={"0 27px"}
            width={[1]}
            id={fieldName}
            placeholder={placeholder}
            option={regionsList?.regions ?? []}
            value={formik.values.RegionDto}
            handleChange={(opt) => formik.setFieldValue(fieldName, opt)}
            handleBlur={() => formik.setFieldTouched(fieldName, true)}
            labelExtractor={(x) => x.label}
            valueExtractor={(x) => x.id}
          />
        ) : fieldName === "ProvinceDto" ? (
          <SelectField<IdOptionType>
            isSearchable
            isLoading={isLoadingProvinces}
            key={fieldName}
            marginTop={30}
            padding={"0 27px"}
            width={[1]}
            id={fieldName}
            placeholder={placeholder}
            option={provincesList?.provinces ?? []}
            value={formik.values.ProvinceDto}
            handleChange={(opt) => formik.setFieldValue(fieldName, opt)}
            handleBlur={() => formik.setFieldTouched(fieldName, true)}
            labelExtractor={(x) => x.label}
            valueExtractor={(x) => x.id}
          />
        ) : null;
      })}

      <TextareaField
        {...formik.getFieldProps("notes")}
        id="notes"
        name="notes"
        label={"notes"}
        value={formik.values.notes}
        placeholder={t(i18Enum.User_Shipping_ManageShippingAddress_Label_Note)}
        error={
          formik.errors.notes && formik.touched.notes
            ? String(formik.errors.notes)
            : undefined
        }
        variant="description"
        spaceProp={{ width: "100%", marginTop: [4], padding: "0 27px" }}
        onChange={formik.handleChange}
        onBlur={() => formik.setFieldTouched("Note", true)}
      />

      {!isCartForm ? (
        <Box
          width={[1]}
          marginTop={"auto"}
          height={90}
          zIndex={isMobile ? 1 : 0}
        >
          <MobileNavigationBottom
            text={t(i18Enum.Common_Save)}
            description={
              isEdit
                ? t(
                    i18Enum.User_Shipping_ManageShippingAddress_Label_EditBillingAddress
                  )
                : t(
                    i18Enum.User_Shipping_ManageShippingAddress_Label_AddBillingAddress
                  )
            }
            borderBottom={"50px solid #000"}
            height={90}
            position={isMobile ? "fixed" : "relative"}
            variant="address"
          />
        </Box>
      ) : (
        <Button
          width={"50%"}
          padding={" 10px 0"}
          margin={"10px 5%"}
          textAlign={"end"}
          variant="primary"
          color={colors.secondaryBase}
          type="submit"
        >
          {t(i18Enum.Common_Save)}
        </Button>
      )}
    </Form>
  );
};
