import {
  gray,
  blue,
  red,
  green,
  grayDark,
  blueDark,
  redDark,
  greenDark,
  yellow,
  yellowDark,
  indigoDark,
  indigo,
  slate,
  slateDark,
  whiteA,
  iris,
  irisDark,
} from "@radix-ui/colors";

const defaultSizes = {
  xxs: "30px",
  xs: "50px",
  xsm: "80px",
  sm: "100px",
  md: "200px",
  lg: "300px",
};

const defaultBreackpoints = ["40em", "52em", "64em", "1100px"];




const defaultSpacing = [0, 4, 8, 16, 32, 64, 128, 256];

export const H1 = [ 40,48,56,62]
export const H2 = [ 30,34,38,42]
export const H3 = [ 24,28,32,36]
export const H4 = [ 21,21,25,29]
export const H5 = [ 20,21,23,24]
export const H6 = [ 12,13,15,17]
export const debug1 = {border:"1px solid red"}
export const debug2 = {border:"1px solid blue"}
export const debug3 = {border:"1px solid orange"}

const maxWidth = ["1100px","900px", "1200px"];
const asideMB = [0 ,"200px"]


const defaultFontSize = [10, 11, 12, 14, 16, 24, 32, 42, 64, 96, 128];

// Create your theme
export const theme = {
  name: "light",

  colors: {
    ...gray,
    ...blue,
    ...red,
    ...green,
    ...yellow,
    ...indigo,
    ...slate,
    ...whiteA,
    ...iris,
    //create Aliases

    primaryBase: "#44DC43",
    primaryBgSubtle: "#000",
    primaryBg: "#00FF00",
    primaryBgHover: "#00FF00",
    primaryBgActive: "#44DC43",
    primaryLine: iris.iris6,
    primaryBorder: "#000",
    primaryBorderHover: "#000",
    primarySolid: "#44DC4382",
    primarySolidHover: "#FFF",
    primaryText: "#44DC43",
    primaryTextContrast: "#000",
    primariFilter:
      "invert(67%) sepia(76%) saturate(497%) hue-rotate(65deg) brightness(96%) contrast(87%)",

    secondaryBase: "#000",
    secondaryBgSubtle: "#000",
    secondaryBg: "#000",
    secondaryBgHover: "#000",
    secondaryBgActive: "#000000B2",
    secondaryLine: "#000",
    secondaryBorder: "#000",
    secondaryBorderHover: "#000",
    secondarySolid: "#000",
    secondarySolidHover: "#000",
    secondaryText: "#000",
    secondaryTextContrast: "#000",

    thirdBase: "#FFFFFF",
    thirdBgSubtle: "#FFFFFF",
    thirdBg: "#FFFFFF",
    thirdBgHover: "#FFFFFF",
    thirdBgActive: "#FFFFFF",
    thirdLine: "#FFFFFF",
    thirdBorder: "#FFFFFF",
    thirdBorderHover: "#FFFFFF",
    thirdSolid: "#FFFFFF",
    thirdSolidHover: "#FFFFFF",
    thirdText: "#FFFFFF",
    thirdTextContrast: "#FFFFFF",

    yellow:"#dcff03",
    yellowShadow:"0px 0px 17px 1px #dcff03",

    grayBase: "#3B3C3B",
    grayBgSubtle: "#282828",
    grayBg: "#151515",
    grayBgHover: "#3B3C3B",
    grayBgActive: "#151515",
    grayLine: "#151515",
    grayBorder: "#3B3C3B",
    grayBorderHover: "#282828",
    graySolid: "#282828",
    graySolidHover: "#3B3C3B",
    grayText: "#FFFFFF",
    grayTextContrast: "#151515",

    otherYelowBase: "#DBE11F",
    otherOrangeBase: "#F36F54",
    otherPinkBase: "#ED1A75",
    otherPurpleBase: "#A4509F",

    //Success
    successBase: green.green1,
    successBgSubtle: green.green2,
    successBg: green.green3,
    successBgHover: green.green4,
    successBgActive: green.green5,
    successLine: green.green6,
    successBorder: green.green7,
    successBorderHover: green.green8,
    successSolid: green.green9,
    successSolidHover: green.green10,
    successText: green.green11,
    successTextContrast: green.green12,

    //Warning

    warningBase: yellow.yellow1,
    warningBgSubtle: yellow.yellow2,
    warningBg: yellow.yellow3,
    warningBgHover: yellow.yellow4,
    warningBgActive: yellow.yellow5,
    warningLine: yellow.yellow6,
    warningBorder: yellow.yellow7,
    warningBorderHover: yellow.yellow8,
    warningSolid: yellow.yellow9,
    warningSolidHover: yellow.yellow10,
    warningText: yellow.yellow11,
    warningTextContrast: yellow.yellow12,

    //Danger

    dangerBase: "#FF3300",
    dangerBgSubtle: red.red2,
    dangerBg: "#F00",
    dangerBgHover: red.red4,
    dangerBgActive: red.red5,
    dangerLine: red.red6,
    dangerBorder: red.red7,
    dangerBorderHover: red.red8,
    dangerSolid: red.red9,
    dangerSolidHover: red.red10,
    dangerText: red.red11,
    dangerTextContrast: red.red12,

    //indigo

    indigo1: indigo.indigo1,
    indigo2: indigo.indigo2,
    indigo3: indigo.indigo3,
    indigo4: indigo.indigo4,
    indigo5: indigo.indigo5,
    indigo6: indigo.indigo6,
    indigo7: indigo.indigo7,
    indigo8: indigo.indigo8,
    indigo9: indigo.indigo9,
    indigo10: indigo.indigo10,
    indigo11: indigo.indigo11,
    indigo12: indigo.indigo12,

    //bodyBg Color
    background: "#000",
    textColor: "#FFFFFF",
  },
  //breackpoints
  breakpoints: defaultBreackpoints,
  //font sizes
  fontSizes: defaultFontSize,
  maxWidth:maxWidth,
  asideMB:asideMB,

  // margin and padding
  space: defaultSpacing,

  sizes: defaultSizes,
  /*   fontSizes: {
    sm: "12px",
    md: "16px",
    lg: "18px",
  } ,*/
};

// Create your dark theme

export const darkTheme = {
  name: "dark",
  colors: {
    ...grayDark,
    ...blueDark,
    ...redDark,
    ...greenDark,
    ...yellowDark,
    ...indigoDark,
    ...slateDark,
    ...whiteA,
    ...irisDark,

    //create Aliases

    primaryBase: "#44DC43",
    primaryBgSubtle: "#000",
    primaryBg: "#00FF00",
    primaryBgHover: "#00FF00",
    primaryBgActive: "#44DC43",
    primaryLine: iris.iris6,
    primaryBorder: "#000",
    primaryBorderHover: "#000",
    primarySolid: "#44DC4382",
    primarySolidHover: "#000",
    primaryText: "#44DC43",
    primaryTextContrast: "#000",
    primariFilter:
      "invert(67%) sepia(76%) saturate(497%) hue-rotate(65deg) brightness(96%) contrast(87%)",

    secondaryBase: "#000",
    secondaryBgSubtle: "#000",
    secondaryBg: "#000",
    secondaryBgHover: "#000",
    secondaryBgActive: "#000",
    secondaryLine: "#000",
    secondaryBorder: "#000",
    secondaryBorderHover: "#000",
    secondarySolid: "#000",
    secondarySolidHover: "#000",
    secondaryText: "#000",
    secondaryTextContrast: "#000",

    thirdBase: "#FFFFFF",
    thirdBgSubtle: "#FFFFFF",
    thirdBg: "#FFFFFF",
    thirdBgHover: "#FFFFFF",
    thirdBgActive: "#FFFFFF",
    thirdLine: "#FFFFFF",
    thirdBorder: "#FFFFFF",
    thirdBorderHover: "#FFFFFF",
    thirdSolid: "#FFFFFF",
    thirdSolidHover: "#FFFFFF",
    thirdText: "#FFFFFF",
    thirdTextContrast: "#FFFFFF",

    otherYelowBase: "#DBE11F",
    otherOrangeBase: "#F36F54",
    otherPinkBase: "#ED1A75",
    otherPurpleBase: "#A4509F",

    grayBase: "#3B3C3B",
    grayBgSubtle: "#282828",
    grayBg: "#151515",
    grayBgHover: "#3B3C3B",
    grayBgActive: "#151515",
    grayLine: "#151515",
    grayBorder: "#3B3C3B",
    grayBorderHover: "#282828",
    graySolid: "#282828",
    graySolidHover: "#3B3C3B",
    grayText: "#FFFFFF",
    grayTextContrast: "#151515",

    successBase: greenDark.green1,
    successBgSubtle: greenDark.green2,
    successBg: greenDark.green3,
    successBgHover: greenDark.green4,
    successBgActive: greenDark.green5,
    successLine: greenDark.green6,
    successBorder: greenDark.green7,
    successBorderHover: greenDark.green8,
    successSolid: greenDark.green9,
    successSolidHover: greenDark.green10,
    successText: greenDark.green11,
    successTextContrast: greenDark.green12,

    // repeat for all steps

    warningBase: yellowDark.yellow1,
    warningBgSubtle: yellowDark.yellow2,
    warningBg: yellowDark.yellow3,
    warningBgHover: yellowDark.yellow4,
    warningBgActive: yellowDark.yellow5,
    warningLine: yellowDark.yellow6,
    warningBorder: yellowDark.yellow7,
    warningBorderHover: yellowDark.yellow8,
    warningSolid: yellowDark.yellow9,
    warningSolidHover: yellowDark.yellow10,
    warningText: yellowDark.yellow11,
    warningTextContrast: yellowDark.yellow12,
    // repeat for all steps

    dangerBase: "#FF0000",
    dangerBgSubtle: "#FF0000",
    dangerBg: "#FF0000",
    dangerBgHover: "#FF0000",
    dangerBgActive: "#FF0000",
    dangerLine: "#FF0000",
    dangerBorder: "#FF0000",
    dangerBorderHover: "#FF0000",
    dangerSolid: "#FF0000",
    dangerSolidHover: "#FF0000",
    dangerText: "#FF0000",
    dangerTextContrast: "#FF0000",

    // indigo
    indigo1: indigoDark.indigo1,
    indigo2: indigoDark.indigo2,
    indigo3: indigoDark.indigo3,
    indigo4: indigoDark.indigo4,
    indigo5: indigoDark.indigo5,
    indigo6: indigoDark.indigo6,
    indigo7: indigoDark.indigo7,
    indigo8: indigoDark.indigo8,
    indigo9: indigoDark.indigo9,
    indigo10: indigoDark.indigo10,
    indigo11: indigoDark.indigo11,
    indigo12: indigoDark.indigo12,

    //bodyBg Color
    background: irisDark.iris3,
    textColor: "#000",

    //breackpoints
    breakpoints: defaultBreackpoints,
    //font sizes
    fontSizes: defaultFontSize,
    // margin and padding
    space: defaultSpacing,
    maxWidth:maxWidth,
    asideMB:asideMB,
    sizes: defaultSizes,
    /*   fontSizes: {
    sm: "12px",
    md: "16px",
    lg: "18px",
  },
  } ,*/
    //sizing for button
  },
};

//*********** USAGE EXAMPLES */

// Use the colors in your styles

//   const Button = styled.button`
//     background-color: ${(props) => props.theme.colors.blue4};
//     color: ${(props) => props.theme.colors.blue11};
//     border-color: ${(props) => props.theme.colors.blue7};
//*********************************************************
// You can access to colors also with Aliases:
//
// example:
//     color: ${props => props.theme.colors.blue9};
//     border: 2px solid ${props => props.theme.colors.accentBorder};
//************* *************************************************/
//     &:hover {
//       background-color: ${(props) => props.theme.colors.blue5};
//       border-color: ${(props) => props.theme.colors.blue8};
//     }
//   `;

// Wrap your app with the theme provider and apply your theme to it
//   export default function App() {
//     return (
//       <ThemeProvider theme={theme}>
//         <Button> radix colorz </Button>
//       </ThemeProvider>
//     );
//   }
