import styled from "styled-components";
import {
  MaxHeightProps,
  PaddingProps,
  PositionProps,
  WidthProps,
  bottom,
  maxHeight,
  width,
} from "styled-system";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import {
  MultiColorKeyGradient,
  VariantColor,
  colorsKeyEnum,
  priceNum,
  removeDuplicates,
  useScrollBlock,
} from "../../utils";
import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import Button from "../primitives/Button";
import CartSvg from "../atoms/CartSvg";

import ProductDetailMobilePanel from "./ProductDetailMobilePanel";
import SelectColor from "../molecules/SelectColor";
import CardConfirm from "../molecules/CardConfirm";

import { ProductType, VariantDTO } from "redux/features/product/ProductsTypes";
import useIntersection from "utils/hooks/useIntersection";
import { useScrollDirection } from "utils/hooks/useScrollDirection";
import { SelectedProductVariant } from "utils/generalTypes/Frontend";
import { PAGE } from "utils/const/pagePath";
import { useNavigate } from "react-router-dom";
import { WishListHeartSvg } from "components/atoms";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { DotsLoader } from "components/molecules/DotsLoader";
import Tooltip from "components/primitives/Tooltip";

export interface ProductProps {
  isBuyNow: boolean;
  cartIsUpdating: boolean;
  product?: ProductType | null | undefined;
  handleSelection: (variant?: VariantDTO) => void;
  selected: VariantDTO | undefined | null;
  handleShow: () => void;
  isOpen?: boolean;
  handleAddToCart: (isBuyNow: boolean) => void;
  isLoading: boolean;
  productVariants?: VariantDTO[] | null;
  similarProduct?: ProductType[];
  productToBasket: (selectedProduct: SelectedProductVariant) => void;
  selectedProductToBasket?: SelectedProductVariant | null;
  handleWishlisted?: () => void;
  //just for mock
  wishlisted?: boolean;
}

//TODO: gestire in caso di taglia unica non richiedere conferma taglia
function ProductDetailMobile({
  product,
  handleSelection,
  selected,
  handleShow,
  isOpen,
  productVariants,
  similarProduct,
  handleAddToCart,
  productToBasket,
  selectedProductToBasket,
  handleWishlisted,
  cartIsUpdating,
  isBuyNow,
  //just for mock
  wishlisted,
}: ProductProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const [addToCart, setAddToCart] = useState(false);
  const [dragPosition, setDragPosition] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<number | null>(null);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [selectedColorId, setSelectedColorId] = useState<string | undefined>(
    undefined
  );
  const upSection = useRef<HTMLHRElement | null>(null);
  const cont = useRef<HTMLDivElement | null>(null);
  const inViewUpSection = useIntersection(upSection, "0px");
  const direction = useScrollDirection("cont");
  const navigate = useNavigate();
  const { t } = useTranslation();

  let isOneSize = false;

  if (productVariants) {
    const productSizesIds = productVariants?.map((variant) => {
      return variant?.options
        ?.filter((item) => item.type == "SIZE")
        .map((variant) => variant.id);
    });
    isOneSize =
      [...new Set(productSizesIds.flat())]?.length === 1 ||
      [...new Set(productSizesIds.flat())]?.length === 0;
  }

  useEffect(() => {
    const viewItem = [
      {
        item_id: selected?.id,
        item_name: product?.name,
        item_variant: selected?.sku,
      },
    ];

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "EUR",
        value: selected?.discountedPrice
          ? selected?.discountedPrice
          : selected?.price,
        items: viewItem,
      },
    });
  }, [selected]);

  useEffect(() => {
    if (direction == "up" && inViewUpSection.isVisible) {
      handleShow();
    }
  }, [direction, inViewUpSection]);

  const handlePosition = useCallback(
    (num: number) => setDragPosition(num),
    [dragPosition]
  );

  useEffect(() => {
    if (selected) {
      setSelectedColorId(
        selected?.options?.filter((option) => option.type == "COLOR")?.[0]?.id
      );

      if (isOneSize && selected.id) {
        productToBasket({ id: selected.id });
      }
    }
  }, [selected]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const current = e.currentTarget.scrollTop;

    handleScrollTop(current);
  };

  const handleDragerStart: React.DragEventHandler<HTMLDivElement> = (e) => {
    handlePosition(e.clientY);
  };

  const handleDragOver: React.DragEventHandler<HTMLDivElement> = (e) => {
    let action: "up" | "down" | "";
    const currentY = e.clientY;

    currentY < dragPosition ? (action = "up") : (action = "down");
    if (isOpen && action === "down") {
      handleShow();
    }

    if (!isOpen && action === "up") handleShow();
  };

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    if (isOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
    handlePosition(e.touches[0].clientY);
  };

  const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (e) => {
    let action: "up" | "down" | "";
    const currentY = e.touches[0].clientY;
    currentY < dragPosition ? (action = "up") : (action = "down");
    if (isOpen && action === "down" && !scrollTop) {
      handleScrollTop(null);
      handleShow();
    }
    if (!isOpen && action === "up") handleShow();
  };

  useEffect(() => {
    if (isOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [isOpen]);

  const handleScrollTop = useCallback(
    (value: number | null) => setScrollTop(value),
    [scrollTop]
  );

  const fnAddToCart = (isBuyNow: boolean) => {
    if (!selectedProductToBasket) {
      handleAddToCart(isBuyNow);
      return;
    }
    setAddToCart(true);
    if (isOpen) handleShow();
    handleAddToCart(isBuyNow);
  };

  const productColors = removeDuplicates(
    productVariants
      ?.map((variant) => {
        return variant.options
          ?.filter((option) => option.type == "COLOR")
          .flat();
      })
      .flat() || []
  );

  return (
    <DetailContainer
      id={"cont"}
      onScroll={handleScroll}
      onDragOver={addToCart ? undefined : handleDragOver}
      onDragStart={handleDragerStart}
      onTouchStart={handleTouchStart}
      onTouchMove={addToCart ? undefined : handleTouchMove}
      width={[1]}
      maxHeight={isOpen ? "calc(100vh - 59px)" : "220px"}
      bottom={["0px"]}
      isOpen={isOpen}
      draggable
    >
      <hr
        ref={upSection}
        style={{ borderColor: "transparent", width: "100%" }}
      />
      <Flex flexDirection={"column"} ref={cont}>
        {addToCart ? (
          selected && (
            <CardConfirm
              stateCard={"navigate"}
              isProduct={true}
              padding={[3]}
              justifyContent={"start"}
              handleCancel={() => setAddToCart(false)}
              handleCheckout={() => navigate(PAGE.cart.menuPath)}
            />
          )
        ) : (
          <>
            <Flex
              position={"relative"}
              top={isOpen ? "-20px" : "-60px"}
              $gap={1}
              width={"100%"}
              maxWidth={"100vw"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {productVariants?.length &&
                (productVariants.some(
                  (variant) =>
                    variant.options.filter((item) => item.type == "COLOR")
                      ?.length !== 0
                ) ? (
                  <Txt position={"absolute"} top={"-30px"} fontSize={[2]}>
                    {productColors.length > 1
                      ? `+ ${productColors.length} ${t(
                          i18Enum.Product_Label_Colors
                        )}`
                      : `1 ${t(i18Enum.Product_Label_Color)}`}
                  </Txt>
                ) : null)}

              {productColors.map((color) => {
                return (
                  <Tooltip content={color.label ?? ""} key={color?.id}>
                    <SelectColor
                      isBlack={false}
                      background={VariantColor(color?.label ?? "")}
                      backgroundImage={
                        color?.label === colorsKeyEnum.Multicolour
                          ? MultiColorKeyGradient
                          : "unset"
                      }
                      selected={selectedColorId === color?.id}
                      onClick={() => {
                        //Se questo colore non è gia selezionato lo imposto
                        if (!(selected?.id === color?.id)) {
                          const variantIdx = productVariants?.findIndex(
                            (variant) => {
                              let found = false;
                              variant.options
                                .filter((option) => option.type == "COLOR")
                                .forEach((option) => {
                                  if (option.id === color.id) {
                                    found = true;
                                  }
                                });
                              return found;
                            }
                          );
                          if (
                            typeof variantIdx !== "undefined" &&
                            variantIdx >= 0 &&
                            productVariants
                          ) {
                            const variant = productVariants[variantIdx];
                            handleSelection(variant);
                            setSelectedColorId(color.id);
                          }
                        }
                      }}
                    />
                  </Tooltip>
                );
              })}
            </Flex>
            {/* Checkout flow */}
            {/* <Button
              width={[1]}
              padding={"13px 0"}
              backgroundColor={"transparent"}
              onClick={() => fnAddToCart(false)}
            >
              <CartSvg width={"1.1428em"} height={"1.4285em"} />
              <Txt
                variant="medium"
                color={colors.thirdBase}
                fontSize={[3]}
                marginLeft={"10px"}
                paddingTop={"3px"}
              >
                {cartIsUpdating && !isBuyNow ? (
                  <DotsLoader />
                ) : (
                  t(i18Enum.Product_ProductCard_Button_AddToCart)
                )}
              </Txt>
            </Button> */}
            <Txt
              color={colors.primaryText}
              fontSize={[2]}
              style={{ textTransform: "initial" }}
              paddingBottom={"10px"}
              borderBottom={`1px solid ${colors.thirdBase}`}
            >
              {/* {capitalize(product?.infoAddToCard)} */}
            </Txt>

            <Flex
              height={"auto"}
              width={[1]}
              flexDirection={"column"}
              alignItems="start"
              padding={"15px 10px 0px 10px"}
              position={"relative"}
            >
              <Txt variant="light" color={colors.thirdText} fontSize={[2]}>
                {product?.shop.name}
              </Txt>

              <Txt
                as={"h3"}
                variant="medium"
                color={colors.thirdText}
                fontSize={[3]}
                textAlign={"left"}
                padding={"4px 0 7px"}
              >
                {product?.name}
              </Txt>

              <Flex $gap={0.5}>
                <Txt
                  variant={selected?.discountedPrice ? "crossed" : "medium"}
                  color={colors.thirdText}
                  fontSize={[2]}
                >
                  {selected?.price && `${priceNum(selected?.price ?? 0)}€`}
                </Txt>

                {!!selected?.discountedPrice && (
                  <Txt
                    variant="medium"
                    color={colors.primaryText}
                    fontSize={[2]}
                  >
                    {`${priceNum(selected?.discountedPrice)}€`}
                  </Txt>
                )}
              </Flex>
              <WishListHeartSvg
                productId={product?.id}
                state={!!wishlisted}
                handleClick={() => handleWishlisted?.()}
                position={"absolute"}
                right={2}
              />
            </Flex>
          </>
        )}
      </Flex>
      {isOpen && productVariants && (
        <ProductDetailMobilePanel
          isBuyNow={isBuyNow}
          cartIsUpdating={cartIsUpdating}
          product={product}
          selected={selected}
          handleSelection={handleSelection}
          productVariants={productVariants}
          similarProduct={similarProduct}
          productToBasket={(product) => productToBasket(product)}
          addToCart={(isBuyNow) => fnAddToCart(isBuyNow)}
        />
      )}
    </DetailContainer>
  );
}

export default ProductDetailMobile;

const DetailContainer = styled.div<
  MaxHeightProps &
    PositionProps &
    WidthProps &
    PaddingProps & { isOpen?: boolean }
>`
  height: 100%;
  display: flex;
  z-index: 2;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.secondaryBase};
  position: absolute;
  padding: 0 0px 18px;
  padding-top: ${({ isOpen }) => (isOpen ? "59px" : "0")};
  max-width: 100vw;
  margin: 0 auto;
  overflow-y: ${({ isOpen }) => (isOpen ? "scroll" : "visible")};
  overflow-x: ${({ isOpen }) => (isOpen ? "hidden" : "visible")};
  transition: all 0.2s ease-in-out;
  ${width};
  ${bottom};
  ${maxHeight}
`;
