import {  useContext } from "react";
import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import { VisibleButton } from "../atoms/VisibleButton";
import { getPlural } from "../../utils";
import { Avatar } from "../atoms/Avatar";

import { CustomThemeContext } from "../../context/ThemeContext";

interface IUserAvatarAndFollowers {
  followers: number;
  following: number;
  firstText: string;
  secondText: string;
  hasEdit?: boolean;
  avatar: string | null;
  idComponent?: string | number;
  handleChecked?:(checked:boolean)=>void
  checked?:boolean
}

export const UserAvatarAndFollowers = ({ avatar,checked,handleChecked,followers, following, firstText, secondText, hasEdit/*, idComponent*/ }: IUserAvatarAndFollowers) => {

    const { customTheme } = useContext(CustomThemeContext);
    const colors = customTheme.colors;

//  const mockApiCall = async (isVisible: boolean) => {
//    const response = await visibilityCall({ idComponent, isVisible });
//    if (response) {
//    setIsVisible((prevState) => !prevState);
//    } else {
//      addToast(toastType.error, "Something went wrong!");
//    }
//  };

  return (
    <Flex position={"relative"} alignItems={"center"} width={"100%"} minWidth={"300px"} maxWidth={"900px"} minHeight={"90px"} backgroundColor={"#fff"} borderRadius={"16px"}>
      <Flex alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
        <Flex flexDirection={"column"} justifyContent={"center"} width={"30%"}>
          <Txt as='p' variant='medium' fontSize={[3, 2]} color={colors.secondaryBase} margin={0}>
            {followers}
          </Txt>
          <Txt as='p' variant='light' fontSize={1} color={colors.secondaryBase} margin={0}>
            {getPlural(firstText, followers)}
          </Txt>
        </Flex>

        <Flex flexDirection={"column"} justifyContent={"center"} width={"30%"}>
          <Txt as='p' variant='medium' fontSize={[3, 2]} color={colors.secondaryBase} margin={0}>
            {following}
          </Txt>
          <Txt as='p' variant='light' fontSize={1} color={colors.secondaryBase} margin={0}>
            {secondText}
          </Txt>
        </Flex>
      </Flex>

      <Flex position={"absolute"} justifyContent={"center"} top={"-25%"} width={"100%"} height={"150%"}>
        <Flex position={"relative"} borderRadius={"100%"} height={"100%"} $aspectRatio='1/1'>
          <Avatar imageUrl={avatar} />
          {hasEdit && (
            <Flex position={"absolute"} justifyContent={"center"} alignItems={"center"} right={0} width={"30%"} $aspectRatio='1/1'>
              <VisibleButton handleVisible={handleChecked} isVisible={checked} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
