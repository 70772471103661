import { useContext } from "react";
import { HeightProps, PositionProps, WidthProps } from "styled-system";
import { CustomThemeContext } from "../../context/ThemeContext";

import Flex from "../primitives/Flex";
import SeeSvg from "../atoms/SeeSvg";
import HiddenSvg from "../atoms/HiddenSvg";

interface ViewHideProps extends PositionProps, WidthProps, HeightProps {
  variant: "see" | "hidden";
  handleClick: () => void;
}

function ViewHideButton({ variant, handleClick, ...props }: ViewHideProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  return (
    <Flex
      style={{ aspectRatio: 1 }}
      height={"100%"}
      minHeight={"30px"}
      {...props}
      justifyContent={"center"}
      alignItems={"center"}
      backgroundColor={variant === "see" ? colors.primaryBg : colors.graySolid}
      borderRadius={"50%"}
      onClick={handleClick}
    >
      {variant === "see" && <SeeSvg marginBottom={"2px"} />}
      {variant === "hidden" && <HiddenSvg marginBottom={"2px"} />}
    </Flex>
  );
}

export default ViewHideButton;
