
import { PaginatedResponse } from "api/types/responses";
import { IState } from "../SharedStateType";
import { WardrobeItem } from "utils/generalTypes/wardrobe";



export type WardrobeStateType = {
    wardrobe:IState<PaginatedResponse<WardrobeItem>>
}

export const WARDROBE = 'wardrobe';
export type WARDROBE = typeof WARDROBE

export const GET_WARDROBE = 'getWardrobeAction'
export type GET_WARDROBE = typeof GET_WARDROBE