import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiSimpleResponse, CoreResponse, ErrorResponse } from "./types/responses";
import { StatusCode } from "./statusCode";
import { QueryRequest } from "./types/requests";
import { getAuth } from "./authHelpers";

//TODO aggiungere withCredential
const ariesInstance = axios.create({ baseURL: import.meta.env.VITE_ARIES_BASE_URL });
ariesInstance.interceptors.request.use(async (config) => {
  const auth = getAuth();
  if (auth && auth.Data && config.headers) {
    config.headers.Authorization = `Bearer ${auth.Data}`;
  }
  return config;
});

export const axiosAriesInstance = ariesInstance;

/* IMPORTANTE: per l'utilizzo in v1 in questo progetto, le estensioni a CoreResponse sono state modificate con Object*/

const resolveFunc = <TResponse extends Object>(r: AxiosResponse<TResponse>): ApiSimpleResponse<TResponse> => {
  const response = r.data;
  return { isSuccess: true, response };
};

const rejectFunc = (e: AxiosError<ErrorResponse>) => {
  const response = e.response;
  if (!response || response?.status === StatusCode.UNSUPPORTED_MEDIA_TYPE || response?.status >= StatusCode.INTERNAL_SERVER_ERROR) {
    return { isSuccess: false, error: "An unexpected error occurred, retry later" };
  }

  if (response?.status === StatusCode.NOT_AUTHORIZED) {
    return { isSuccess: false, error: "Not Authorized" };
  }
  if (response?.status === StatusCode.NOT_AUTHORIZED_MORE) {
    return { isSuccess: false, error: "Mooore not Authorized" };
  }
  if (response?.status === StatusCode.NOT_FOUND) {
    return { isSuccess: false, error: "Invalid email or password" };
  }
  if (response?.status === StatusCode.BAD_REQUEST || response?.status === StatusCode.NOT_FOUND) {
    return { isSuccess: false, error: response.data.ErrorMessage };
  }
  if (response?.status === StatusCode.BAD_REQUEST || response?.status === StatusCode.NOT_FOUND) {
    return { isSuccess: false, error: response.data.ErrorMessage };
  }

  const errorDetails = response.data.Details;
  if (!!errorDetails.errors) {
    const [firstError] = Object.keys(errorDetails.errors);

    return { isSuccess: false, error: errorDetails.errors[firstError][0] };
  }
  return { isSuccess: false, error: response.data.Details.detail };
};
/**
 * Creates a post call to the endpoint.
 *
 * @param TRequest - The request type
 * @param TResponse - The expected response type
 * @param endpoint - The endpoint
 * @param request - The body of the request
 * @returns An {@link ApiSimpleResponse} of TResponse
 */
export const postAriesCall = async <TRequest, TResponse extends Object>(endpoint: string, request: TRequest, headers?: QueryRequest): Promise<ApiSimpleResponse<TResponse>> => {
  return axiosAriesInstance
    .post(endpoint, request, { headers })
    .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
    .catch(rejectFunc);
};

/**
 * Creates a put call to the endpoint.
 *
 * @param TRequest - The request type
 * @param TResponse - The expected response type
 * @param endpoint - The endpoint
 * @param request - The body of the request
 * @returns An {@link ApiSimpleResponse} of TResponse
 */
export const putAriesCall = async <TRequest, TResponse extends Object>(endpoint: string, request: TRequest): Promise<ApiSimpleResponse<TResponse>> => {
  return axiosAriesInstance
    .put(endpoint, request)
    .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
    .catch(rejectFunc);
};

/**
 * Creates a patch call to the endpoint.
 *
 * @param TRequest - The request type
 * @param TResponse - The expected response type
 * @param endpoint - The endpoint
 * @param request - The body of the request
 * @returns An {@link ApiSimpleResponse} of TResponse
 */
export const patchAriesCall = async <TRequest, TResponse extends Object>(endpoint: string, request: TRequest): Promise<ApiSimpleResponse<TResponse>> => {
  return axiosAriesInstance
    .patch(endpoint, request)
    .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
    .catch(rejectFunc);
};

/**
 * Creates a delete call to the endpoint.
 *
 * @param TResponse - The expected response type
 * @param endpoint - The endpoint
 * @returns An {@link ApiSimpleResponse} of TResponse
 */
export const deleteAriesCall = async <TResponse extends CoreResponse>(endpoint: string): Promise<ApiSimpleResponse<TResponse>> => {
  return axiosAriesInstance
    .delete(endpoint)
    .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
    .catch(rejectFunc);
};

/**
 * Creates a get call to the endpoint.
 *
 * @param TResponse - The expected response type
 * @param endpoint - The endpoint
 * @returns An {@link ApiSimpleResponse} of TResponse
 */
export const getAriesCall = async <TResponse extends Object, TParams = QueryRequest>(endpoint: string, params?: TParams, headers?: QueryRequest): Promise<ApiSimpleResponse<TResponse>> => {
  return await axiosAriesInstance
    .get(endpoint, { params, headers })
    .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
    .catch(rejectFunc);
};
/**

/**
* Creates a postForm call to the endpoint for uploading file from form.
*
* @param TRequest - The request type
* @param TResponse - The expected response type
* @param endpoint - The endpoint
* @param request - The body of the request
* @returns An {@link ApiSimpleResponse} of TResponse
*/
export const postFormAriesCall = async <TRequest, TResponse extends Object>(endpoint: string, request: TRequest): Promise<ApiSimpleResponse<TResponse>> => {
  return axiosAriesInstance
    .postForm(endpoint, request)
    .then((r: AxiosResponse<TResponse>) => resolveFunc<TResponse>(r))
    .catch(rejectFunc);
};
