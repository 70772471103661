import { put, takeLatest } from "redux-saga/effects";
import { GET_OUTFITS_LIST } from "./OutfitsTypes";
import { ApiSimpleResponse, BaseResponse } from "api/types/responses";
import { OutfitHomePageDTO } from "api/types/responses/HomePageResponse";
import { getOutfitsListCall } from "api/contentCalls/outfitsCall";
import {
  getOutfitsListErrorAction,
  getOutfitsListSuccessAction,
  setSelectedOutfitAction,
} from "./outfitsSlice";

function* getOutfitSaga() {
  const res: ApiSimpleResponse<BaseResponse<OutfitHomePageDTO[]>> =
    yield getOutfitsListCall();
  if (!res.isSuccess || !res.response?.outfits) {
    yield put(getOutfitsListErrorAction(res.error ?? ""));
    return;
  }
  yield put(getOutfitsListSuccessAction(res.response.outfits));
  yield put(setSelectedOutfitAction(res.response.outfits[0]));
}

export function* watchGetOutfits() {
  yield takeLatest(GET_OUTFITS_LIST, getOutfitSaga);
}
