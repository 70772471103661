import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BRAND, BrandStateType, BrandType } from "./BrandTypes";
import { PaginatedResponse } from "api/types/responses";
import { BrandsListRequest } from "api/types/requests/brandRequest";

const brandInitialState: BrandStateType = {
  brand: {
    data: null,
    isLoading: false,
    errors: "",
  },
  brandsList: {
    data: null,
    isLoading: false,
    errors: "",
  },
};

export const brandSlice = createSlice({
  name: BRAND,
  initialState: brandInitialState,
  reducers: {
    getBrandByIdAction: (
      state: BrandStateType,
      { payload }: PayloadAction<string>
    ) => {
      state.brand.isLoading = true;
      state.brand.errors = "";
    },
    getBrandByIdSuccessAction: (
      state: BrandStateType,
      { payload }: PayloadAction<BrandType>
    ) => {
      state.brand.isLoading = false;
      state.brand.errors = "";
      state.brand.data = {
        ...payload,
      };
    },
    manageBrandErrorAction: (
      state: BrandStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.brand.isLoading = false;
      state.brand.errors = error;
    },
    brandResetData: (state: BrandStateType) => {
      state.brand.isLoading = false;
      state.brand.errors = "";
      state.brand.data = null;
    },
    getBrandsListAction: (
      state: BrandStateType,
      { payload }: PayloadAction<BrandsListRequest>
    ) => {
      state.brandsList.errors = "";
      state.brandsList.isLoading = true;
    },
    getBrandsListSuccessAction: (
      state: BrandStateType,
      { payload }: PayloadAction<PaginatedResponse<BrandType>>
    ) => {
      state.brandsList.errors = "";
      state.brandsList.isLoading = false;
      if (!payload?.shops?.length || !payload?.shops) {
        return;
      }
      if (!state.brandsList.data || !state.brandsList.data.Data) {
        state.brandsList.data = {
          Pager: payload.Pager,
          Data: payload.shops,
        };
        return;
      }
      //TODO: manage pagination
      state.brandsList.data = {
        Data: [...state.brandsList.data.Data, ...payload.shops],
        Pager: payload.Pager,
      };
    },
    getBrandsListErrorAction: (
      state: BrandStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.brandsList.errors = error;
      state.brandsList.isLoading = false;
    },
  },
});

export const BrandActions = brandSlice.actions;

export const {
  getBrandByIdAction,
  getBrandByIdSuccessAction,
  manageBrandErrorAction,
  getBrandsListAction,
  getBrandsListSuccessAction,
  getBrandsListErrorAction,
} = brandSlice.actions;

export default brandSlice.reducer;
