import { setBillingAddressCall, setShippingAddressCall } from "../../utils";
import {
  OrderDTO,
  UserPreference,
  UserType,
} from "../../redux/features/user/UserTypes";

import { BaseResponse, PaginatedResponse } from "../types/responses";
import { getAriesCall, patchAriesCall, postAriesCall } from "../baseAriesCall";
import {
  CreateUpdateBillingAddressRequest,
  CreateUpdateShippingAddressRequest,
  UpdateOrderAddressReq,
} from "api/types/requests/shippingAddressRequest";
import {
  CommunitySearchRequest,
  CommunityUser,
} from "redux/features/community/CommunityTypes";

import {
  createUserAddress,
  deleteUserAddress,
  deleteUserAccount,
  getCurrentUserQuery,
  updateUserAddress,
  updateUserPreferencesMutation,
  addProductWishlist,
  removeProductFromWishlist,
  getOrderById,
} from "api/graphql/requests";

import { queryClient } from "App";
import { BaseAddressFields } from "components/molecules/AddressCard";

const USER_PROFILE_CONTROLLER = "User";
const EDIT_USER_CONTROLLER = "User/ProfileEdit";

const SWITCH_PRIVATE = "User/Private";
const ORDERS_PARENTS_CONTROLLER = "Order/Parent";
const DELETE_ORDER_CONTROLLER = "Order/DeleteOrderByCustomer";
const UPDATE_ORDER_ADDRESS = "Order/EditAddressess";

// DON'T USE!
// export const getUserList = async (params: BasePaginatedRequest) =>
//   await getCall<PaginatedResponse<UserType>, BasePaginatedRequest>(`, params);

export const getUserCall = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["currentUser"],
      queryFn: () => getCurrentUserQuery(),
      staleTime: 0,
      gcTime: 0,
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const postEditUserCall = async (req: UserType) =>
  await postAriesCall<UserType, BaseResponse<UserType>>(
    EDIT_USER_CONTROLLER,
    req
  );

export const deleteUserCall = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["deleteUserAcc"],
      queryFn: () => deleteUserAccount(),
      staleTime: 0,
      gcTime: 0,
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const postUserPreferenceCall = async (
  id: string,
  req: UserPreference
) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["updateUserPreferences"],
      queryFn: () => updateUserPreferencesMutation(id, req),
      staleTime: 0,
      gcTime: 0,
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

/************************* USER ACCOUNT  **************************/

export const switchPrivateAccountCall = async () =>
  await patchAriesCall<Object, BaseResponse<UserType>>(SWITCH_PRIVATE, {});

/************************* USER SHIPPING ADDRESS MANAGEMENT **************************/

const createUserAddressCall = async (
  type: "BILLING" | "SHIPPING",
  req: BaseAddressFields
) => {
  if (type == "SHIPPING") {
    setShippingAddressCall(req);
  } else {
    setBillingAddressCall(req);
  }

  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["createUserAddress", type],
      queryFn: () => createUserAddress(req),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};
const updateUserAddressCall = async (
  type: "BILLING" | "SHIPPING",
  id: string,
  req: BaseAddressFields
) => {
  if (type == "SHIPPING") {
    setShippingAddressCall(req);
  } else {
    setBillingAddressCall(req);
  }

  if (req["id"]) delete req["id"];

  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["updateUserAddress", type],
      queryFn: () => updateUserAddress(id, req),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

//shippings
export const createShippingAddressCall = async (
  req: CreateUpdateShippingAddressRequest
) => {
  return await createUserAddressCall("SHIPPING", req);
};
export const updateShippingAddressCall = async (
  shippingAddressId: string,
  req: CreateUpdateShippingAddressRequest
) => {
  return await updateUserAddressCall("SHIPPING", shippingAddressId, req);
};

//billings
export const createBillingAddressCall = async (
  req: CreateUpdateBillingAddressRequest
) => {
  return await createUserAddressCall("BILLING", req);
};

export const updateBillingAddressCall = async (
  billingAddressId: string,
  req: CreateUpdateBillingAddressRequest
) => {
  return await updateUserAddressCall("BILLING", billingAddressId, req);
};

//shared address call
export const deleteAddressCall = async (addressId: string) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["deleteUserAddress", addressId],
      queryFn: () => deleteUserAddress(addressId),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const setDefaultAddressCall = async (addressId: string) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["setDefaultAddress", addressId],
      queryFn: () => updateUserAddress(addressId, { isDefault: true }),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

/************************* ORDERS **************************/

export const getOrderByIdCall = async (id: string) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["orderById", id],
      queryFn: () => getOrderById(id),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const getOrderParentByIdCall = async (id: string) =>
  await getAriesCall<BaseResponse<OrderDTO>>(
    `${ORDERS_PARENTS_CONTROLLER}/${id}`
  );
export const deleteOrderByIdCall = async (id: string) =>
  await patchAriesCall<Object, BaseResponse<OrderDTO>>(
    `${DELETE_ORDER_CONTROLLER}/${id}`,
    {}
  );
export const updateOrderAddressCall = async (req: UpdateOrderAddressReq) =>
  await patchAriesCall<UpdateOrderAddressReq, BaseResponse<OrderDTO>>(
    `${UPDATE_ORDER_ADDRESS}`,
    req
  );

/************************* USER WISHLIST **************************/

export const addProductWishlistCall = async (productId: string) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["addProductWishlist"],
      queryFn: () => addProductWishlist(productId),
      staleTime: 0,
      gcTime: 0,
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const removeProductWishlistCall = async (productId: string) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["removeProductWishlist"],
      queryFn: () => removeProductFromWishlist(productId),
      staleTime: 0,
      gcTime: 0,
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};
export const getUserWishListCall = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["userWishlist"],
      queryFn: () => {},
      staleTime: 0,
      gcTime: 0,
    });
    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

/************************* COMMUNITY **************************/
export const getUserByIDCall = async (id: string) => {
  console.log("getUserByIDCall", id);
  return null;
};
export const getCommunityUserListCall = async (req: CommunitySearchRequest) =>
  await postAriesCall<CommunitySearchRequest, PaginatedResponse<CommunityUser>>(
    `${USER_PROFILE_CONTROLLER}/Community`,
    req
  );
export const toggleFollowingCall = async (id: string) =>
  await patchAriesCall(`${USER_PROFILE_CONTROLLER}/Follow/${id}`, {});
//MOCK REQUEST
// export const getUserInfoCall = async () => {
//   await waitNSeconds(1);
//   return user
//};
