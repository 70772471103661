import { SpaceProps, WidthProps } from "styled-system";

import Button from "../primitives/Button";
import Flex from "../primitives/Flex";
import { Tags } from "redux/features/product/ProductsTypes";

interface SelectFilterProps extends SpaceProps, WidthProps {
  filters: Tags[];
  handleClick: (value: string) => void;
  selected?: string[];
  variant?: "gray";
}

function SelectFilter({
  filters,
  handleClick,
  selected,
  variant,
  ...props
}: SelectFilterProps) {
  return (
    <Flex flexWrap={"wrap"} justifyContent={"right"} $gap={0.6} {...props}>
      {filters?.map((filter) => (
        <Button
          key={filter.id}
          selected={selected?.includes(filter.id ?? "")}
          variant={variant == "gray" ? "switcherGenre" : "switcher"}
          padding={variant != "gray" ? ["3px 16px"] : ["0.5em 1em"]}
          width={"max-content"}
          onClick={() => handleClick(filter.id ?? "")}
        >
          {filter.label}
        </Button>
      ))}
    </Flex>
  );
}

export default SelectFilter;
