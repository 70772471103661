import React, { useEffect, useState } from 'react'

export const useScrollDirection = (id: string) => {
    const [direction, setDirection] = useState<'up' | 'down' | 'block'>('block');

    const [lastScrollTop, setLastScrollTop] = useState(0)
    const [scrollTop, setScrollTop] = useState(0)

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDirection('block');
        }, 200);

        if (lastScrollTop === scrollTop) {
            return;
        }

        if (scrollTop > lastScrollTop) {
            setDirection('down')
        } else if (scrollTop < lastScrollTop) {
            setDirection('up')
        }
        setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);

        return () => {
            clearTimeout(timerId);
        }

    }, [scrollTop, lastScrollTop])


    useEffect(() => {
        const element = id ? document.getElementById(id) : document.documentElement;
        element?.addEventListener("scroll", () => {
            setScrollTop(element?.scrollTop)
        }, false);
        return () => {
            element?.removeEventListener('scroll', () => { })
        }
    }, [id])

    return direction
}
