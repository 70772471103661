import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import rootReducers from "./rootReducer";
import rootSaga from "./rootSaga";
import { BUY_NOW_ITEM, CREATE_ORDER, SET_SHIPPING_OPTION } from "redux/features/cart/CartType";

import { REFRESH_STRIPE_URL } from "redux/features/user/UserTypes";

// ...

//import rootReducer from "redux/reducers";

const ignoredActions = [SET_SHIPPING_OPTION, CREATE_ORDER, BUY_NOW_ITEM, REFRESH_STRIPE_URL];

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducers,
  // enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: { ignoredActions } }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
