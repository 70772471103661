import { useContext } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import loader from 'assets/lotties/loader.json'
import { StateCard } from ".";
import Txt from "../primitives/Txt";
import Box from "../primitives/Box";
import Button from "../primitives/Button";
import CartSvg from "../atoms/CartSvg";
import Flex from "../primitives/Flex";
import BackgroundVideo from "./VideoBackgroud";
import { FlexboxProps, SpaceProps } from "styled-system";
import { useAppSelector } from "redux/app/hooks";
import Lottie from "lottie-light-react";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";

interface CardConfirmProps extends SpaceProps, FlexboxProps {
  handleCancel: () => void;
  handleAddToCard?: () => void;
  handleCheckout: () => void;
  stateCard: StateCard;
  isProduct?: boolean;
}

function CardConfirm({ ...props }: CardConfirmProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  // const UserLng = navigator.language
  // const itShippingInfo=""
  // const enShippingInfo="Shipping times are calculated from the moment the order is accepted by the store and vary depending on the shipping mode chosen. We calculate shipping times considering working days. Saturday and Sunday are usually not in"
  // const shippingInfo = UserLng.includes("it") ? itShippingInfo:enShippingInfo
  const {errors, isLoading}= useAppSelector((state)=>state.cart.cart);
  const { t } = useTranslation();



  return (
    <Flex
      height={["100%"]}
      width={[1]}
      backgroundColor={"transparent"}
      flexDirection={"column"}
      justifyContent='space-between'
      alignItems='start'
      padding={props.stateCard === "navigate" ? ["6vh 7%"] : ["6vh 7% 3vh"]}
      {...props}
    >
      <BackgroundVideo top='0' height={["100%"]} width={[1]} variant='center' video='second' />
      {props.stateCard === "add-to-card" && (
        <>
          <Txt fontSize={[2]} variant='light' color={colors.thirdBase} style={{ textTransform: "inherit" }}>
            {t(i18Enum.Product_ProductCard_ShippingInfo)}
          </Txt>

          <Box width={[1]}>
            <Button width={[1]} variant='cancel' onClick={props.handleCancel}>
              {t(i18Enum.Product_ProductCard_Button_Cancel)}
            </Button>
            <Button width={[1]} height={"34px"} backgroundColor={colors.grayBase} onClick={props.handleAddToCard} marginTop={"7%"}>
              <CartSvg width={"13px"} height={"17px"} />
              <Txt variant='medium' color={colors.thirdBase} fontSize={[2]} marginLeft={"10px"} paddingTop={"3px"}>
                {t(i18Enum.Product_ProductCard_Button_AddToCart)}
              </Txt>
            </Button>
          </Box>
        </>
      )}

      {props.stateCard === "navigate" && (
        <>
          {isLoading ? (
            <Lottie animationData={loader} autoPlay />
          ) : (
            <>
              <Txt fontSize={props.isProduct ? ["18px"] : [3]} marginBottom={props.isProduct ? [2] : [0]} variant='medium' color={colors.thirdBase}>
                {!isLoading && !errors && `✌🏻 ${t(i18Enum.Product_ProductCard_AddItemSuccess)} 🫶🏼`}

                {!isLoading && errors && t(i18Enum.ErrorPage500_ErrorLabel)}
              </Txt>

              <Flex flexDirection={props.isProduct ? "row" : "column"} width={[1]} $gap={props.isProduct ? 1 : 1.5}>
                <Button width={[1]} height={"34px"} backgroundColor={colors.thirdBase} onClick={props.handleCancel}>
                  <Txt variant='medium' color={colors.secondaryText} fontSize={[0]} marginLeft={"10px"}>
                    {t(i18Enum.Cart_ShoppingBag_Button_ContinueShopping)}
                  </Txt>
                </Button>

                {!errors && (
                  <Button
                    width={props.isProduct ? [0.6] : [1]}
                    height={"34px"}
                    variant={"success"}
                    onClick={() => {
                      props.handleCheckout();
                      // props.handleCancel()
                    }}
                  >
                    {t(i18Enum.Cart_ShoppingBag_Button_Checkout)}
                  </Button>
                )}
              </Flex>
            </>
          )}
        </>
      )}
    </Flex>
  );
}

export default CardConfirm;
