import { ProductsListRequest } from "api/types/requests/productRequest";
import request from "graphql-request";
import { graphql } from "../../utils/graphql/generated";
import { AUTH_LOCAL_STORAGE_KEY } from "api/authHelpers";
import {
  CreateCheckoutSessionMutationVariables,
  CreateOrderInput,
  CreateUserAddressInput,
  GetProductsQueryVariables,
  UpdateShoppingBasketItemInput,
  UpdateUserAddressInput,
  UpdateUserInput,
} from "utils/graphql/generated/graphql";

export const GRAPHQL_API = "https://api.store.fabricode.it/graphql";
export const AUTH_API = "https://auth.store.fabricode.it";

const withAuth = () => {
  const sessionToken = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (sessionToken) {
    return { Authorization: `Bearer ${sessionToken}` };
  }
  return undefined;
};

// ********** Products **********

const productsSearchQuery = graphql(/* GraphQL */ `
  query getProducts($categories: [ID!], $search: String) {
    products(
      published: true
      cancelled: false
      categories: $categories
      search: $search
    ) {
      id
      name
      description
      composition
      handle
      wishlistedByUser
      variants {
        sku
        price
        discountedPrice
        isFeatured
        productId
        images {
          bucketUrl
          index
        }
      }
      shop {
        id
        name
        username
      }
    }
  }
`);

const getProductsCategoriesQuery = graphql(/* GraphQL */ `
  query getCategories {
    categories {
      id
      label
    }
  }
`);

const prodctGetByHandle = graphql(/* GraphQL */ `
  query getProduct($handle: String) {
    product(handle: $handle, published: true, cancelled: false) {
      id
      isCancelled
      isPublished
      name
      description
      details
      composition
      wishlistedByUser
      variants(published: true, cancelled: false) {
        id
        sku
        isFeatured
        productId
        price
        discountedPrice
        options {
          id
          type
          label
          index
        }
        images {
          id
          bucketUrl
        }
      }
      shop {
        id
        name
        username
        description
        coverImage {
          bucketUrl
        }
      }
    }
  }
`);

const addProductWishlistMutation = graphql(/* GraphQL */ `
  mutation addProductWishlist($productId: ID!) {
    addProductToWishlist(productId: $productId) {
      addedAt
    }
  }
`);

const removeProductFromWishlistMutation = graphql(/* GraphQL */ `
  mutation removeProductFromWishlist($productId: ID!) {
    removeProductFromWishlist(productId: $productId) {
      addedAt
    }
  }
`);

export const getProductByCode = async (handle: string) => {
  return request(
    GRAPHQL_API,
    prodctGetByHandle,
    { handle: handle },
    withAuth()
  );
};

export const getProducts = async (params: ProductsListRequest) => {
  //TODO: add paginatino passing params

  const tags = params.TagsIds;
  const searchText = params.Name;
  const searchParams: GetProductsQueryVariables = {};

  if (tags && tags?.length > 0) {
    searchParams["categories"] = tags;
  }
  if (searchText) {
    searchParams["search"] = searchText;
  }

  return request(GRAPHQL_API, productsSearchQuery, searchParams, withAuth());
};

export const getProductCategories = async () => {
  return request(GRAPHQL_API, getProductsCategoriesQuery);
};

export const addProductWishlist = async (productId: string) => {
  return request(
    GRAPHQL_API,
    addProductWishlistMutation,
    {
      productId,
    },
    withAuth()
  );
};

export const removeProductFromWishlist = async (productId: string) => {
  return request(
    GRAPHQL_API,
    removeProductFromWishlistMutation,
    {
      productId,
    },
    withAuth()
  );
};

// ********** Shops **********
const getShops = graphql(/* GraphQL */ `
  query getShops {
    shops(active: true) {
      id
      name
      username
      isActive
    }
  }
`);

const getShopByUsername = graphql(/* GraphQL */ `
  query getShopByUsername($username: String) {
    shop(username: $username) {
      id
      name
      username
      about
      description
      isActive
      profileImage {
        bucketUrl
      }
      coverImage {
        bucketUrl
      }
      products(published: true, cancelled: false) {
        id
        name
        handle
        shop {
          name
        }
        variants {
          id
          price
          discountedPrice
          sku
          images {
            id
            bucketUrl
          }
        }
      }
    }
  }
`);

export const getShopByUsernameQuery = async (username: string) => {
  return request(GRAPHQL_API, getShopByUsername, { username });
};
export const getShopsQuery = async () => {
  return request(GRAPHQL_API, getShops, undefined);
};

// ********** HomePage **********

const websiteSliders = graphql(/* GraphQL */ `
  query websiteSliders {
    websiteSliders {
      label
      image {
        bucketUrl
      }
      redirectUrl
      index
    }
  }
`);

const featuredProducts = graphql(/* GraphQL */ `
  query featuredProducts {
    featuredProducts {
      id
      name
      description
      details
      composition
      handle
      wishlistedByUser
      shop {
        name
        username
      }
      variants {
        price
        discountedPrice
        sku
        isFeatured
        isPublished
        options {
          id
          type
          label
        }
        images {
          id
          bucketUrl
        }
      }
      shopId
    }
  }
`);

const getOutfits = graphql(/* GraphQL */ `
  query getOutfits {
    outfits(published: true) {
      id
      name
      description
      isHomepage
      handle
      variants {
        id
        price
        discountedPrice
        sku
        isFeatured
        isPublished
        options {
          id
          type
          label
        }
        product {
          id
          name
          handle
          shop {
            name
            username
          }
        }
        images {
          id
          index
          bucketUrl
        }
      }
    }
  }
`);

export const getHomeSlidersQuery = async () => {
  return request(GRAPHQL_API, websiteSliders, undefined);
};

export const getFeaturedProductsQuery = async () => {
  return request(GRAPHQL_API, featuredProducts, undefined, withAuth());
};

export const getOutfitsQuery = async () => {
  return request(GRAPHQL_API, getOutfits, undefined);
};

// ********** User **********

const getCurrentUser = graphql(/* GraphQL */ `
  query currentUser {
    currentUser {
      id
      role
      email
      gender
      firstName
      lastName
      username
      birthdate
      bio
      isPrivate
      profileImage {
        bucketUrl
      }
      wishlist {
        product {
          id
          name
          handle
          wishlistedByUser
          variants {
            id
            images {
              bucketUrl
            }
          }
        }
      }
      orders {
        id
        status
        stripeCheckoutUrl
        total
        billingAddressId
        number
        orderParent {
          number
        }
        orders {
          id
          shopId
          shop {
            name
          }
          status
          total
          number
        }
      }
      facebookUrl
      instagramUrl
      pinterestUrl
      tikTokUrl
      xUrl
      youTubeUrl
      height
      weight
      favoriteColors
      favoriteHobbies
      favoriteSocials
      favoriteSports
      favoriteStyles
      favoriteTrips
      favoriteVibes
      addresses {
        id
        city
        callingCodeId
        city
        country {
          label
        }
        countryId
        id
        isBilling
        isDefault
        name
        notes
        phone
        province {
          label
        }
        provinceId
        region {
          label
        }
        regionId
        state
        street
        streetNumber
        zipCode
      }
    }
  }
`);

const updateUserMutation = graphql(/* GraphQL */ `
  mutation updateUser($id: ID!, $user: UpdateUserInput!) {
    updateUser(id: $id, user: $user) {
      id
      email
      firstName
      email
      gender
      lastName
      username
      birthdate
      bio
      isPrivate
      facebookUrl
      instagramUrl
      pinterestUrl
      tikTokUrl
      xUrl
      youTubeUrl
      height
      profileImage {
        bucketUrl
      }
    }
  }
`);

const deleteUserAccountQuery = graphql(/* GraphQL */ `
  mutation deleteUser {
    deleteUser {
      id
    }
  }
`);

export const getCurrentUserQuery = async () => {
  return request(GRAPHQL_API, getCurrentUser, undefined, withAuth());
};

export const updateUserPreferencesMutation = async (
  id: string,
  user: UpdateUserInput
) => {
  return request(GRAPHQL_API, updateUserMutation, { id, user }, withAuth());
};

export const deleteUserAccount = async () => {
  return request(GRAPHQL_API, deleteUserAccountQuery, undefined, withAuth());
};

// ********** UserAddress **********

const createUserAddressMutation = graphql(/* GraphQL */ `
  mutation createUserAddress($userAddress: CreateUserAddressInput!) {
    createUserAddress(userAddress: $userAddress) {
      id
    }
  }
`);

const updateUserAddressQuery = graphql(/* GraphQL */ `
  mutation updateUserAddress($id: ID!, $userAddress: UpdateUserAddressInput!) {
    updateUserAddress(id: $id, userAddress: $userAddress) {
      id
    }
  }
`);

const deleteUserAddressQuery = graphql(/* GraphQL */ `
  mutation deleteUserAddress($id: ID!) {
    deleteUserAddress(id: $id) {
      id
    }
  }
`);

export const createUserAddress = async (input: CreateUserAddressInput) => {
  return request(
    GRAPHQL_API,
    createUserAddressMutation,
    { userAddress: input },
    withAuth()
  );
};

export const updateUserAddress = async (
  id: string,
  input: UpdateUserAddressInput
) => {
  return request(
    GRAPHQL_API,
    updateUserAddressQuery,
    {
      id,
      userAddress: input,
    },
    withAuth()
  );
};
export const deleteUserAddress = async (id: string) => {
  return request(
    GRAPHQL_API,
    deleteUserAddressQuery,
    {
      id,
    },
    withAuth()
  );
};

// ********** Shopping Basket **********

const addToShoppingBasketMutation = graphql(/* GraphQL */ `
  mutation addToShoppingBasket($productVariantIds: [ID!]!) {
    addToShoppingBasket(productVariantIds: $productVariantIds) {
      id
    }
  }
`);

const removeFromShoppingBasketMutation = graphql(/* GraphQL */ `
  mutation removeFromShoppingBasket($shoppingBasketItemId: ID!) {
    removeFromShoppingBasket(shoppingBasketItemId: $shoppingBasketItemId) {
      id
    }
  }
`);

const updateShoppingBasketMutation = graphql(/* GraphQL */ `
  mutation updateShoppingBasketItem(
    $id: ID!
    $shoppingBasketItem: UpdateShoppingBasketItemInput!
  ) {
    updateShoppingBasketItem(id: $id, shoppingBasketItem: $shoppingBasketItem) {
      id
    }
  }
`);

const getShoppingBasketQuery = graphql(/* GraphQL */ `
  query shoppingBasket {
    shoppingBasket {
      id
      total
      totalDiscountedPrice
      itemsCount
      shops {
        id
        name
        totalPrice
        totalDiscountedPrice
        shippingOption {
          id
          freeShipping
          freeShippingMinAmount
          name
          price
          daysMax
        }
        shippingOptions {
          id
          freeShipping
          freeShippingMinAmount
          name
          price
          daysMax
        }
        items {
          id
          productVariantId
          quantity
          productVariant {
            id
            price
            discountedPrice
            product {
              name
            }
            options {
              id
              label
              type
            }
            images {
              bucketUrl
            }
          }
        }
        totalPrice
        name
      }
    }
  }
`);

const getShippingOptionsQuery = graphql(/* GraphQL */ `
  query shippingOptions {
    shippingOptions {
      id
      freeShipping
      freeShippingMinAmount
      name
      price
    }
  }
`);

export const addToShoppingBasket = async (variantIds: string[]) => {
  return request(
    GRAPHQL_API,
    addToShoppingBasketMutation,
    {
      productVariantIds: variantIds,
    },
    withAuth()
  );
};

export const removeFromShoppingBasket = async (
  shoppingBasketItemId: string
) => {
  return request(
    GRAPHQL_API,
    removeFromShoppingBasketMutation,
    {
      shoppingBasketItemId,
    },
    withAuth()
  );
};

export const updateShoppingBasket = async (
  id: string,
  shoppingBasketItem: UpdateShoppingBasketItemInput
) => {
  return request(
    GRAPHQL_API,
    updateShoppingBasketMutation,
    {
      id,
      shoppingBasketItem,
    },
    withAuth()
  );
};

export const getShoppingBasket = async () => {
  return request(GRAPHQL_API, getShoppingBasketQuery, undefined, withAuth());
};

export const getShippingOptions = async () => {
  return request(GRAPHQL_API, getShippingOptionsQuery, undefined, withAuth());
};

// ********** Orders  **********

const getOrderByIdQuery = graphql(/* GraphQL */ `
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      number
      orderParent {
        number
      }
      orders {
        number
        status
        totalPrice
        total
        items {
          id
          price
          quantity
          orderId
          productVariant {
            discountedPrice
            price
            options {
              id
              label
              type
            }
            product {
              name
            }
            images {
              bucketUrl
            }
          }
        }
      }
      createdAt
      status
      shop {
        name
      }
      billingAddressId
      shippingAddressId
      shippingAddress {
        name
        street
        streetNumber
        zipCode
        city
        countryId
        isDefault
        isBilling
      }
      billingAddress {
        name
        street
        streetNumber
        zipCode
        city
        countryId
        isDefault
        isBilling
      }
      shopId
      shop {
        name
      }
      status
      stripeCheckoutUrl
      billingAddressId
      number
      items {
        id
        price
        quantity
        orderId
        shop {
          name
        }
        productVariant {
          discountedPrice
          price
          options {
            id
            label
            type
          }
          product {
            name
          }
          images {
            bucketUrl
          }
        }
      }
      totalPrice
      total
    }
  }
`);

const createOrderMutation = graphql(/* GraphQL */ `
  mutation createOrder($order: CreateOrderInput!) {
    createOrder(order: $order) {
      id
    }
  }
`);

const createStripeSessionMutation = graphql(/* GraphQL */ `
  mutation createCheckoutSession(
    $cancelUrl: String
    $returnUrl: String
    $session: CreateCheckoutSessionInput!
    $successUrl: String
    $uiMode: CheckoutSessionUIMode
  ) {
    createCheckoutSession(
      cancelUrl: $cancelUrl
      returnUrl: $returnUrl
      session: $session
      successUrl: $successUrl
      uiMode: $uiMode
    ) {
      id
      url
    }
  }
`);

export const getOrderById = async (id: string) => {
  return request(GRAPHQL_API, getOrderByIdQuery, { id }, withAuth());
};

export const createOrder = async (input: CreateOrderInput) => {
  return request(
    GRAPHQL_API,
    createOrderMutation,
    { order: input },
    withAuth()
  );
};

export const createStripeSession = async (
  input: CreateCheckoutSessionMutationVariables
) => {
  return request(
    GRAPHQL_API,
    createStripeSessionMutation,
    { ...input },
    withAuth()
  );
};

// ********** AFFILIATES **********

// const sendAffiliateEmailMutation = graphql(/* GraphQL */ `
//   query sendAffiliateEmail ($input: SendAffiliateEmailInput) {
//     sendAffiliateEmail(input: $input) {
//       id
//     }
//   }
// `);

// export const sendAffiliateEmail = async (input: SendAffiliateEmailInputVariables) => {
//   return request(GRAPHQL_API, sendAffiliateEmailMutation, {input});
// };

// {
//   "Name": "myname",
//   "Message": "ccc",
//   "VATNumber": "CF55555555",
//   "TaxIdCode": "3333333333",
//   "Address": "via dei tipi",
//   "Phone": "3403333333",
//   "MobilePhone": "333333333",
//   "Email": "email@emil.com",
//   "WebSiteUrl": "http://google.com",
//   "EcommerceBool": true,
//   "EcommerceUrl": "ecommerce name",
//   "Sector": "Shoes"
// }

// ********** OPTIONS  **********

const getColorsQuery = graphql(/* GraphQL */ `
  query getColors {
    options(type: COLOR) {
      id
      type
      label
      value
    }
  }
`);

const getVibesQuery = graphql(/* GraphQL */ `
  query getVibesQuery {
    options(type: VIBE) {
      id
      label
    }
  }
`);

const getSportsQuery = graphql(/* GraphQL */ `
  query getSportsQuery {
    options(type: SPORT) {
      id
      label
    }
  }
`);

const getHobbiesQuery = graphql(/* GraphQL */ `
  query getHobbiesQuery {
    options(type: HOBBY) {
      id
      label
    }
  }
`);

const getSocialsQuery = graphql(/* GraphQL */ `
  query getSocialsQuery {
    options(type: SOCIAL) {
      id
      label
    }
  }
`);

const getRegionsQuery = graphql(/* GraphQL */ `
  query getRegions {
    regions {
      id
      label
    }
  }
`);

const getProvincesQuery = graphql(/* GraphQL */ `
  query getProvinces {
    provinces {
      id
      label
    }
  }
`);

export const getColors = async () => {
  return request(GRAPHQL_API, getColorsQuery, undefined);
};

export const getVibes = async () => {
  return request(GRAPHQL_API, getVibesQuery, undefined);
};

export const getSports = async () => {
  return request(GRAPHQL_API, getSportsQuery, undefined);
};

export const getHobbies = async () => {
  return request(GRAPHQL_API, getHobbiesQuery, undefined);
};

export const getSocials = async () => {
  return request(GRAPHQL_API, getSocialsQuery, undefined);
};

export const getRegions = async () => {
  return request(GRAPHQL_API, getRegionsQuery, undefined);
};

export const getProvinces = async () => {
  return request(GRAPHQL_API, getProvincesQuery, undefined);
};
