import { useContext, useEffect, useMemo, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import MenuNavigateProps from "components/organisms/MenuNavigate";
import AccountInfoForm, {
  FormAccount,
} from "components/organisms/AccountInfoForm";
import { capitalize, toastType } from "utils";
import Toggle from "components/primitives/Toggle";
import ProfileInformationForm, {
  ProfileInfo,
} from "components/organisms/ProfileInformationForm";
// import NotificationsForm, { FormNotification } from "components/organisms/NotificationsForm";
import Button from "components/primitives/Button";
import Box from "components/primitives/Box";
import { useAppSelector } from "redux/app/hooks";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import { useNavigate } from "react-router-dom";
import { PAGE } from "utils/const/pagePath";
import { UserType } from "redux/features/user/UserTypes";
import { useDispatch } from "react-redux";
import { AppGeneralContext } from "context/AppGeneralContext";
import {
  putUserPreferenceAction,
  removeAuthAction,
  sendUserPreferenceAction,
  UserActions,
} from "redux/features/user/userSlice";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export function AccountPage() {
  const { customTheme } = useContext(CustomThemeContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { addToast, setIsLoading, genderFilter } =
    useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const { isLoading: isFectchingUser, data: userData } = useAppSelector(
    (state) => state.user.user
  );
  const [req, setReq] = useState<UserType | undefined>();
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showEditButton, setShowEditButton] = useState<boolean>(false);
  const [isProfileValid, setIsProfileValid] = useState<boolean>(false);
  const updateReq = (updates: Partial<UserType>) => {
    const updatedReq = { ...req, ...updates } as UserType;
    setReq(updatedReq);
  };
  const profileInfo: ProfileInfo = useMemo(() => {
    return {
      firstName: userData?.firstName ?? "",
      lastName: userData?.lastName ?? "",
      username: userData?.username ?? "",
      bio: userData?.bio ?? "",
      facebookUrl: userData?.facebookUrl ?? "",
      instagramUrl: userData?.instagramUrl ?? "",
      tikTokUrl: userData?.tikTokUrl ?? "",
      xUrl: userData?.xUrl ?? "",
      youTubeUrl: userData?.youTubeUrl ?? "",
      pinterestUrl: userData?.pinterestUrl ?? "",
    };
  }, [userData]);
  const [isPrivate, setIsPrivate] = useState<boolean>();

  const dispatch = useDispatch();

  if (userData === undefined) {
    dispatch(removeAuthAction());
  }

  function getEditAccountInfo(values: FormAccount) {
    updateReq(values);
  }

  function getProfileInfo(values: ProfileInfo) {
    updateReq(values);
  }

  // function getNotificationStatus(values: FormNotification) {
  //   updateReq(values);
  // }

  function SendEdit(req: Partial<UserType> | undefined) {
    if (!req?.username || req.username === "") {
      addToast(toastType.warning, t(i18Enum.Error_MissingUsername));
      return;
    }

    // dispatch(UserActions.editUserAction(req));
    dispatch(putUserPreferenceAction(req));
    dispatch(sendUserPreferenceAction());
    setShowEditButton(false);
  }

  useEffect(() => {
    setIsLoading(isFectchingUser);
    setIsPrivate(userData?.isPrivate);
  }, [isFectchingUser]);

  const handleInputChange = () => {
    setShowEditButton(true);
  };

  const questionnaire = () => {
    navigate(PAGE.firstLogin.menuPath);
  };

  const handleShowDelete = () => {
    setShowDelete(true);
  };
  const cancelDelete = () => {
    setShowDelete(false);
  };

  const handleDelete = () => {
    dispatch(UserActions.deleteUserAction());
    navigate(PAGE.home.menuPath);
  };

  const handleIsPrivate = (checked: boolean) => {
    setIsPrivate(checked);
    updateReq({ isPrivate: checked });
    handleInputChange();
  };

  const paragraph = t(i18Enum.User_UserProfile_Desc_Privacy);
  const legendIsPrivate = t(i18Enum.User_UserProfile_Desc_PrivacyLegend);

  return (
    <Flex
      position={"relative"}
      padding={["5% 0", "5% 0", "0"]}
      width={[1]}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      {userData?.id && (
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          padding={["0 1em", "0 5em"]}
          maxWidth={["none", 1100]}
          width={[1]}
        >
          <Box width={1} maxWidth={["none", 1100]}>
            <MenuNavigateProps userData={userData} />
          </Box>
          <Flex
            key={userData.id}
            flexDirection={"column"}
            width={[1]}
            padding={["0 1em", "0 1em", 0]}
            $gap={1}
            maxWidth={["none", 500]}
          >
            <Txt
              as={"h3"}
              marginTop={"3em"}
              variant="light"
              fontSize={[3]}
              color={colors.thirdText}
              textAlign={"left"}
              marginBottom={-3}
            >
              {t(i18Enum.User_UserProfile_MyAccount)}
            </Txt>

            <AccountInfoForm
              data={{
                birthdate: userData.birthdate
                  ? new Date(userData.birthdate)
                  : undefined,
                gender: userData.gender ?? undefined,
              }}
              optionGenders={genderFilter}
              onSubmit={getEditAccountInfo}
              onFieldChange={handleInputChange}
            />

            <Txt
              as={"h3"}
              marginTop={"1em"}
              variant="light"
              fontSize={[3]}
              color={colors.thirdText}
              textAlign={"left"}
              marginBottom={0}
            >
              {t(i18Enum.User_UserProfile_Section_Profile)}
            </Txt>

            <Txt
              as={"p"}
              marginTop={-2}
              variant="light"
              fontSize={[3, 4]}
              $textTransform="inherit"
              color={colors.thirdText}
              textAlign={"left"}
              marginBottom={0}
            >
              {capitalize(paragraph)}
            </Txt>

            <Flex alignItems={"center"} width={[1]}>
              <Toggle checked={isPrivate} handleChange={handleIsPrivate} />
              <Txt
                fontSize={[3]}
                lineHeight={"12px"}
                marginLeft={[3]}
                variant="light"
              >
                {isPrivate
                  ? t(i18Enum.Common_AccountPublic)
                  : t(i18Enum.Common_AccountPrivate)}
              </Txt>
            </Flex>

            <Txt
              width={[1]}
              variant="light"
              $textTransform="inherit"
              textAlign={"left"}
              opacity={!isPrivate ? 0 : 1}
              maxHeight={!isPrivate ? 10 : 100}
              fontSize={[2, 3, 4]}
              style={{ transition: "all 0.5s ease-out" }}
            >
              {capitalize(legendIsPrivate)}
            </Txt>

            <ProfileInformationForm
              onSubmit={getProfileInfo}
              onFieldChange={handleInputChange}
              isFormValid={(value) => setIsProfileValid(value)}
              data={profileInfo}
              isPrivate={isPrivate ? true : undefined}
            />

            {/* <Txt as={"h3"} marginTop={0} variant='light' fontSize={[3]} color={colors.thirdText} textAlign={"left"} marginBottom={0}>
              {t(i18Enum.User_NotificationSettings_Title)}
            </Txt>

            <NotificationsForm
              onSubmit={getNotificationStatus}
              data={{
                NotificationWishlistOnStockEnabled: userData.NotificationWishlistOnStockEnabled ?? false,
                NotificationOrderShippedEnabled: userData.NotificationOrderShippedEnabled ?? false,
                NotificationFollowingBirthdayEnabled: userData.NotificationFollowingBirthdayEnabled ?? false,
              }}
            /> */}

            <Txt
              as={"h3"}
              marginTop={0}
              variant="light"
              fontSize={[3]}
              color={colors.thirdText}
              textAlign={"left"}
              marginBottom={0}
            >
              {t(i18Enum.User_UserProfile_Section_Preferences)}
            </Txt>

            <Txt
              as={"a"}
              marginTop={"0"}
              variant="linkLight"
              fontSize={[3]}
              color={colors.primaryText}
              textAlign={"left"}
              marginBottom={0}
              onClick={questionnaire}
            >
              {t(i18Enum.User_UserProfile_Repeat_Quiz)}
            </Txt>
            {showDelete ? (
              <Box
                backgroundColor={"#FF3300"}
                width={"100%"}
                marginBottom={"100px"}
                borderRadius={"16px"}
                padding={"16px"}
              >
                <Txt
                  as="h3"
                  textAlign={["center"]}
                  color="black"
                  marginTop={"5px"}
                >
                  ⚠️
                </Txt>
                <Txt as="h5" textAlign={["center"]} color="black">
                  {t(i18Enum.User_Deactivate_Title_AreYouSure)}
                </Txt>
                <Flex
                  flexDirection={["column", "row"]}
                  justifyContent={"center"}
                  alignItems={["center", "end"]}
                  padding={"20px 0 10px 0"}
                >
                  <Button
                    variant="secondary"
                    width={"fit-content"}
                    padding={"0.5em 1.2em"}
                    onClick={cancelDelete}
                    marginRight={["0px", "10px"]}
                    marginBottom={["10px", "0px"]}
                  >
                    {t(i18Enum.User_Deactivate_Skip)}
                  </Button>
                  <Button
                    variant="warning"
                    width={"fit-content"}
                    padding={"0.5em 1.2em"}
                    onClick={handleDelete}
                  >
                    {t(i18Enum.User_Deactivate_DeactivateMyAccount)}
                  </Button>
                </Flex>
              </Box>
            ) : (
              <Button
                variant="danger"
                width={"fit-content"}
                padding={"0.5em 1.2em"}
                margin={"20px 0 100px"}
                onClick={handleShowDelete}
              >
                {t(i18Enum.User_Deactivate_DeactivateMyAccount)}
              </Button>
            )}
          </Flex>

          {(showEditButton || !req?.username) && (
            <MobileNavigationBottom
              text={t(i18Enum.Common_Save)}
              disabled={!isProfileValid}
              description={t(i18Enum.Common_EditAccount)}
              buttonAction={() => SendEdit(req)}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}
