import { useQuery } from "@tanstack/react-query";
import { getColors } from "api/graphql/requests";

import Box from "components/primitives/Box";
import Grid from "components/primitives/Grid";

import { CustomThemeContext } from "context/ThemeContext";
import { useContext, useEffect, useState } from "react";

interface ColorGridProps {
  values?: string[];
  handleChange: (ids: string[]) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

function ColorGrid({ values, handleChange, onFocus, onBlur }: ColorGridProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const colorsTheme = customTheme.colors;
  const [colorsSelected, setColorsSelected] = useState<string[]>([]);

  const { data: colors } = useQuery({
    queryKey: ["userColors"],
    queryFn: () => getColors(),
  });

  console.log("colors", colors);

  useEffect(() => {
    handleChange(colorsSelected);
  }, [colorsSelected]);

  function addColor(value: string) {
    setColorsSelected((x) => [...x, value]);
  }

  function deleteColor(value: string) {
    const deletedColor = colorsSelected.filter((x) => x !== value);
    setColorsSelected(deletedColor);
  }

  return (
    <Grid
      gridTemplateColumns={"repeat(14,1fr)"}
      onFocus={() => (onFocus ? onFocus() : null)}
      onBlur={() => (onBlur ? onBlur() : null)}
    >
      {colors?.options &&
        colors.options.map((color) => (
          <Box
            key={color.id}
            width={[1]}
            background={color.value}
            borderColor={colorsTheme.primaryBase}
            borderStyle={"solid"}
            borderWidth={values?.includes(color.id) ? "2px" : "0"}
            onClick={() =>
              values?.includes(color.id)
                ? deleteColor(color.id)
                : addColor(color.id)
            }
            style={{ aspectRatio: "1 / 1 ", cursor: "pointer" }}
          ></Box>
        ))}
    </Grid>
  );
}

export default ColorGrid;
