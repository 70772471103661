import { Facebook, Instagram, Pinterest, Tiktok,  Youtube } from "react-bootstrap-icons";
import Flex from "../primitives/Flex";
import { SocialIconsSvg } from "./SocialIconSvg";

export interface ISocialIcon {
  variant: "facebook" | "instagram" | "tiktok" | "twitter" | "youtube" | "pinterest";
  url: string;
}

export const SocialIcon = ({ variant, url }: ISocialIcon) => {

 function goToLink(url:string){
  window.open(url)
 }
  return (
    <Flex style={{ cursor: "pointer" }} onClick={() => goToLink(url) }>
      {variant === "facebook" && <Facebook fontSize={"23px"} />}
      {variant === "instagram" && <Instagram fontSize={"23px"} />}
      {variant === "tiktok" && <Tiktok fontSize={"23px"} />}
      {variant === "twitter" &&  <SocialIconsSvg  height={"40px"} path="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />  }
      {variant === "youtube" && <Youtube fontSize={"23px"} />}
      {variant === "pinterest" && <Pinterest fontSize={"23px"} />}
    </Flex>
  );
};
