import { useContext, useEffect, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { OptionsValue } from "utils";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import Grid from "components/primitives/Grid";
import Button from "components/primitives/Button";
import { AddressCard, addressType } from "components/molecules/AddressCard";
import MenuNavigateProps from "components/organisms/MenuNavigate";
import { useNavigate } from "react-router-dom";
import { AppGeneralContext } from "context/AppGeneralContext";
import { BillingPageEdit } from "./BillingPageEdit";
import { PAGE } from "utils/const/pagePath";
import { useAppSelector } from "redux/app/hooks";
import { useDispatch } from "react-redux";
import { getOrdersAction } from "redux/features/user/userSlice";
import { Order } from "components/molecules/Order";
import { ErrorComponent } from "components/molecules";
import { OrderNoPayment } from "components/molecules/OrderNoPayment";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { OrderStatus } from "utils/graphql/generated/graphql";

function OrdersPage() {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { data: user } = useAppSelector((state) => state.user.user);
  const { data: billingAddress, isSuccess: billingIsSuccess } = useAppSelector(
    (state) => state.user.userBillingAddresses
  );

  const {
    data: ordersParent,
    isLoading,
    errors: ordersError,
  } = useAppSelector((state) => state.user.userOrderParent);
  const { isMobile, openAsideMenu, setIsLoading } =
    useContext(AppGeneralContext);
  const [statusSelected, setStatusSelected] = useState<OrderStatus | undefined>(
    undefined
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filter: OptionsValue<OrderStatus | undefined>[] = [
    {
      name: t(i18Enum.Orders_OrderStatus_Filter_1),
      value: undefined,
    },
    {
      name: t(i18Enum.Orders_OrderStatus_Filter_2),
      value: OrderStatus.Cancelled,
    },
    {
      name: t(i18Enum.Orders_OrderStatus_Filter_7),
      value: OrderStatus.CancelledByCustomer,
    },
    {
      name: t(i18Enum.Orders_OrderStatus_Filter_4),
      value: OrderStatus.Pending,
    },
    {
      name: t(i18Enum.Orders_OrderStatus_Filter_5),
      value: OrderStatus.WaitingCustomer,
    },
    {
      name: t(i18Enum.Orders_OrderStatus_Filter_6),
      value: OrderStatus.Shipped,
    },
  ];

  useEffect(() => {
    if (billingIsSuccess === null || isLoading) {
      return;
    }
    setIsLoading(false);
  }, [billingIsSuccess, isLoading]);

  function ReloadOrderPage() {
    dispatch(getOrdersAction());
  }

  const handleFilterOrder = (option: OptionsValue<OrderStatus | undefined>) => {
    setStatusSelected(option.value);
  };

  const handleAddEditBilling = (id: string | undefined): void => {
    if (isMobile) {
      navigate(PAGE.billing.menuPath, { state: { id: id ? id : undefined } });
    }
    openAsideMenu<string | undefined>(<BillingPageEdit />, id ? id : undefined);
  };

  return (
    <Flex padding={["5% 0", "5% 0", "0"]} width={[1]} justifyContent={"center"}>
      <Flex
        flexDirection={"column"}
        width={[1]}
        padding={["0 1em", "0 5em"]}
        $gap={1.2}
        maxWidth={["none", 1100]}
      >
        {user && <MenuNavigateProps userData={user} />}
        <>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={"1em"}
          >
            <Txt
              as={"h3"}
              variant="light"
              fontSize={[3]}
              color={colors.thirdText}
              textAlign={"left"}
            >
              {t(i18Enum.Orders_BillingAddress_Title)}
            </Txt>
            <Txt
              variant="linkLight"
              color={colors.primaryText}
              fontSize={[3]}
              $cursor="pointer"
              onClick={() => handleAddEditBilling(undefined)}
            >
              {t(i18Enum.Orders_BillingAddress_AddNow)}
            </Txt>
          </Flex>
          {billingAddress && billingAddress.length !== 0 ? (
            <Grid
              marginBottom={[5]}
              style={{ rowGap: "1em", columnGap: "1em" }}
              gridTemplateColumns={[
                "repeat(1, 1fr)",
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
              ]}
            >
              {billingAddress.map((billing, index) => (
                <AddressCard
                  id={billing.id}
                  key={index}
                  type={addressType.userBilling}
                  name={billing.name ?? ""}
                  city={billing.city ?? ""}
                  street={billing.street ?? ""}
                  streetNumber={billing.streetNumber ?? ""}
                  zipCode={billing.zipCode ?? ""}
                  countryId={billing.countryId ?? 110}
                  isDefault={billing.isDefault ?? false}
                  handleEdit={() => {
                    handleAddEditBilling(billing.id);
                  }}
                  isBilling={billing.isBilling ?? true}
                />
              ))}
            </Grid>
          ) : (
            <Txt
              marginTop={0}
              as={"p"}
              variant="light"
              color={colors.thirdText}
              textAlign={"left"}
              $textTransform="inherit"
              fontSize={[3]}
            >
              {t(i18Enum.Orders_BillingAddress_NoAddress)}
              <Txt
                paddingLeft={[3]}
                variant="linkLight"
                color={colors.primaryText}
                fontSize={[3]}
                onClick={() => handleAddEditBilling(undefined)}
              >
                {t(i18Enum.Orders_BillingAddress_AddNow)}
              </Txt>
            </Txt>
          )}

          {/* Sezione Ordini in attesa di pagamento */}
          {ordersParent &&
            ordersParent.filter((order) => order.status === OrderStatus.Pending)
              .length !== 0 && (
              <>
                <Txt
                  fontSize={[3]}
                  as={"h3"}
                  variant="light"
                  color={colors.thirdText}
                  textAlign={"left"}
                  marginBottom={0}
                >
                  {t(i18Enum.Orders_Order_Pending_Title)}
                </Txt>
                <Grid
                  width={"100%"}
                  marginTop={[3]}
                  gridTemplateColumns={[
                    "repeat(1, 1fr)",
                    "repeat(1, 1fr)",
                    "repeat(2, 1fr)",
                  ]}
                  style={{ rowGap: "1em", columnGap: "1em" }}
                >
                  {ordersParent
                    .filter((order) => order.status === OrderStatus.Pending)
                    .map((order) => (
                      <OrderNoPayment
                        key={order.id}
                        id={order.id}
                        shopId={order.shopId}
                        total={order.total}
                        items={order.items}
                        status={order.status}
                        itemsTotal={order.total}
                        number={order.number}
                        stripeCheckoutUrl={order.stripeCheckoutUrl ?? ""}
                        ParentNumber={order.ParentNumber}
                      />
                    ))}
                </Grid>
              </>
            )}
          <Txt
            fontSize={[3]}
            as={"h3"}
            variant="light"
            color={colors.thirdText}
            textAlign={"left"}
            marginBottom={0}
          >
            {t(i18Enum.Orders_Title)}
          </Txt>

          {/* Sezione Ordini "figlio" */}
          {ordersParent?.length !== 0 && (
            <Flex $gap={0.5} flexWrap={"wrap"}>
              {filter.map((button, index) => (
                <Button
                  key={index}
                  type="button"
                  variant={
                    button.value === statusSelected ? "option" : "switcherGenre"
                  }
                  padding={["0.5em 2em"]}
                  minWidth={"70px"}
                  width={"auto"}
                  onClick={() => handleFilterOrder(button)}
                >
                  <Txt $cursor="pointer" fontSize={["10px", "12px"]}>
                    {button.name}
                  </Txt>
                </Button>
              ))}
            </Flex>
          )}

          {ordersParent && ordersParent.length > 0 ? (
            <Grid
              width={[1]}
              marginTop={[3]}
              gridTemplateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
              style={{ rowGap: "1em", columnGap: "1em" }}
            >
              {ordersParent?.map((parentOrder) =>
                parentOrder?.orders
                  ?.filter((order) =>
                    statusSelected ? order.status === statusSelected : true
                  )
                  ?.map((order) => (
                    <Order
                      key={order.id}
                      id={order.id}
                      shopId={order.shopId}
                      shop={order.shop}
                      total={order.total}
                      items={order.items}
                      itemsTotal={order.itemsTotal}
                      number={order.number}
                      status={order.status}
                      ParentNumber={parentOrder.number}
                    />
                  ))
              )}
            </Grid>
          ) : (
            <Txt
              marginTop={0}
              as={"p"}
              variant="light"
              color={colors.thirdText}
              textAlign={"left"}
              $textTransform="inherit"
              fontSize={[3]}
            >
              {t(i18Enum.Orders_Message_NoOrders)}
            </Txt>
          )}
        </>

        {!isLoading && ordersError && (
          <ErrorComponent
            errors={ordersError}
            textToDisplay={t(i18Enum.ErrorPage500_ErrorLabel)}
            onClickHandler={ReloadOrderPage}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default OrdersPage;
