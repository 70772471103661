import { useContext, useEffect, useMemo, useState } from "react";
import { CustomThemeContext } from "context/ThemeContext";
import { priceNum /*, sortItemsByShop*/, toastType } from "utils";
import Flex from "components/primitives/Flex";
import Txt from "components/primitives/Txt";
import { AddressCard, addressType } from "components/molecules/AddressCard";
import { H6 } from "theme/theme.styled";
import Section from "components/primitives/Section";
import { OrderSentCard } from "components/molecules/OrderSentCard";
import { useLocation, useNavigate } from "react-router-dom";
import { AppGeneralContext } from "context/AppGeneralContext";
import Box from "components/primitives/Box";
import Button from "components/primitives/Button";
import ArrowSvg from "components/atoms/ArrowSvg";
import { ErrorNoItems } from "components/molecules/ErrorNoItems";
import { OrderDTO, UserShippingAddress } from "redux/features/user/UserTypes";
import { format } from "date-fns";
import { PAGE } from "utils/const/pagePath";
import { useDispatch } from "react-redux";
import { deleteOrderAction } from "redux/features/user/userSlice";
import { ChangeAddress } from "components/molecules/ChangeAddress";
import { updateOrderAddressCall } from "api/userCalls/userCalls";
import { UpdateOrderAddressReq } from "api/types/requests/shippingAddressRequest";
import CardWrapperWhite from "components/primitives/CardWrapperWhite";
import { DotsLoader } from "components/molecules/DotsLoader";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { OrderStatus } from "utils/graphql/generated/graphql";

export const OrdersDetailsPage = () => {
  const { customTheme } = useContext(CustomThemeContext);
  const { t } = useTranslation();
  const {
    setAsideIsOpen,
    isMobile,
    asideParams,
    // setIsLoading,
    // closeAndResetAside,
    addToast,
    setAsideParams,
  } = useContext(AppGeneralContext);
  const colors = customTheme.colors;
  const navigate = useNavigate();
  const orderDetail: OrderDTO | null = useLocation().state;
  const [routeParams, setRouteParams] = useState<OrderDTO | null>(orderDetail);
  const dispatch = useDispatch();
  const [SACardIsLoading, setSACardIsLoading] = useState<boolean>(false);
  const [BACardIsLoading, setBACardIsLoading] = useState<boolean>(false);

  const order: OrderDTO | null = useMemo(
    () => (isMobile ? routeParams : asideParams),
    [isMobile, routeParams, asideParams]
  );

  useEffect(() => {
    setRouteParams(orderDetail);
  }, [orderDetail, orderDetail?.status]);

  // const [counter, setCounter] = useState<number>(5)
  //const sortedItemsArray = sortItemsByShop(shops, orderData?.items);

  // const promoCode = 10;

  // useEffect(() => {
  //   if (sortedItemsArray.length !== 0) {
  //     return
  //   }

  //   if (counter > 0) {
  //     setTimeout(() => { setCounter(x => x - 1) }, 1000)
  //     return
  //   }

  //   if (isMobile) {
  //     navigate(-1)
  //   } else {
  //     setAsideIsOpen(false)
  //   }

  // }, [counter, sortedItemsArray.length])
  function changeAddress(
    address: UserShippingAddress | undefined,
    type: addressType
  ) {
    if (!order?.items?.[0].orderId) {
      return;
    }
    if (address === undefined) {
      addToast(toastType.error, t(i18Enum.Error_MissingAddress));
      return;
    }

    if (
      address.id === order?.shippingAddress?.id ||
      address.id === order?.billingAddress?.id
    ) {
      addToast(toastType.error, t(i18Enum.Error_SameAddress));
      return;
    }

    let req: UpdateOrderAddressReq | undefined;

    if (type === addressType.userAddress) {
      req = {
        ShippingAddressId: address.id,
        OrderId: order?.items?.[0].orderId,
      };
    } else if (type === addressType.userBilling) {
      req = {
        BillingAddressId: address.id,
        OrderId: order?.items?.[0].orderId,
      };
    } else {
      req = undefined;
    }

    if (!req) {
      addToast(toastType.error, t(i18Enum.ErrorPage500_ErrorLabel));
      return;
    }

    UpdateAddress(req);
  }

  async function UpdateAddress(req: UpdateOrderAddressReq) {
    req.BillingAddressId ? setBACardIsLoading(true) : setSACardIsLoading(true);
    const resp = await updateOrderAddressCall(req);

    if (!resp.isSuccess || !resp.response?.Data) {
      addToast(
        toastType.error,
        resp?.response?.ErrorMessage ?? t(i18Enum.ErrorPage500_ErrorLabel)
      );
      req.BillingAddressId
        ? setBACardIsLoading(false)
        : setSACardIsLoading(false);
      return;
    }

    addToast(toastType.success, t(i18Enum.Message_AddressUpdated));
    if (isMobile) {
      setRouteParams(resp.response.Data);
    }
    setAsideParams(resp.response.Data);
    req.BillingAddressId
      ? setBACardIsLoading(false)
      : setSACardIsLoading(false);
  }

  //TODO: guardare bene questo
  function orderAction(status: OrderStatus, orderId: string) {
    if (!orderId || orderId === "") {
      addToast(toastType.error, t(i18Enum.Error_MissingOrder));
      return;
    }
    if (status === OrderStatus.Shipped || status === OrderStatus.Cancelled) {
      setAsideIsOpen(false);
      navigate(PAGE.returnedGoods.menuPath);
      return;
    }

    if (status === OrderStatus.Pending) {
      //TODO delete order
      dispatch(deleteOrderAction(orderId));

      if (isMobile) {
        navigate(PAGE.orders.menuPath);
      }
      setAsideIsOpen(false);
      return;
    }

    return;
  }

  const renderButton = (status: OrderStatus, orderId: string) => {
    if (status === OrderStatus.Shipped || status === OrderStatus.Cancelled) {
      return (
        <Button
          variant="register"
          padding={"10px 20px"}
          onClick={() => {
            orderAction(status, orderId);
          }}
        >
          {t(i18Enum.Orders_Order_Button_AskForAReturn)}
        </Button>
      );
    }

    //TODO: riabilitare deleteOrder
    // if (status === OrderStatus.Pending) {
    //   return (
    //     <Button
    //       variant="danger"
    //       padding={"10px 20px"}
    //       onClick={() => {
    //         orderAction(status, orderId);
    //       }}
    //     >
    //       {t(i18Enum.Orders_Order_Button_DeleteOrder)}
    //     </Button>
    //   );
    // }

    return;
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      {isMobile && (
        <Section
          padding={["100px 5% 20px 5%"]}
          width={[1]}
          justifyContent={"space-between"}
        >
          <Flex flexDirection={"row"} onClick={() => navigate(-1)}>
            <ArrowSvg width={[40, 30]} />{" "}
            {!isMobile && (
              <Txt paddingLeft={10} variant="link" color={colors.primaryBase}>
                {t(i18Enum.Common_Back)}
              </Txt>
            )}
          </Flex>
        </Section>
      )}
      <Section
        padding={["10px 5%"]}
        width={[1]}
        justifyContent={"space-between"}
      >
        <Txt fontSize={H6} as={"h6"} fontFamily={"BauProMedium"}>
          {t(i18Enum.Orders_Order_OrderDate)}
        </Txt>
        <Txt
          fontSize={H6}
          as={"h6"}
          fontFamily={"BauPro"}
          fontWeight={"300"}
          color={colors.primaryBase}
        >
          {`${
            order?.createdAt ? format(new Date(order?.createdAt), "PPp") : ""
          }`}
        </Txt>
      </Section>
      <Section
        padding={["10px 5%"]}
        width={[1]}
        flexDirection={"column"}
        alignItems={"start"}
      >
        <Txt padding={"10px 0"} fontFamily={"BauPro"}>
          {t(i18Enum.Orders_Order_ShippingAddress)}
        </Txt>
        {order?.shippingAddress && (
          <>
            {SACardIsLoading ? (
              <CardWrapperWhite
                style={{ borderColor: colors.thirdBase }}
                minHeight={"70px"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <DotsLoader />
              </CardWrapperWhite>
            ) : (
              <AddressCard
                type={
                  order.status === OrderStatus.Pending
                    ? addressType.detail
                    : addressType.disabled
                }
                name={order?.shippingAddress.name ?? ""}
                street={order?.shippingAddress.street ?? ""}
                streetNumber={order?.shippingAddress.streetNumber ?? ""}
                zipCode={order?.shippingAddress.zipCode ?? ""}
                city={order?.shippingAddress.city ?? ""}
                countryId={order?.shippingAddress.countryId ?? 110}
                isDefault={order?.shippingAddress.isDefault ?? false}
                isBilling={!!order?.shippingAddress.isBilling}
                handleEdit={() => {}}
              />
            )}
            {/* {order.status === OrderStatus.Pending && (
              <ChangeAddress
                disabled={SACardIsLoading || BACardIsLoading}
                address={order?.shippingAddress}
                type={addressType.userAddress}
                changeAddress={changeAddress}
                addressesList={order?.ShippingAddressssList ?? []}
              />
            )} */}
          </>
        )}
      </Section>
      <Section
        padding={["10px 5%"]}
        width={[1]}
        flexDirection={"column"}
        alignItems={"start"}
      >
        <Txt padding={"10px 0"} fontFamily={"BauPro"}>
          {t(i18Enum.Orders_Order_BillingAddress)}
        </Txt>
        {order?.billingAddress && (
          <>
            {BACardIsLoading ? (
              <CardWrapperWhite
                style={{ borderColor: colors.thirdBase }}
                minHeight={"70px"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <DotsLoader />
              </CardWrapperWhite>
            ) : (
              <AddressCard
                type={
                  order.status === OrderStatus.Pending
                    ? addressType.detail
                    : addressType.disabled
                }
                name={order.billingAddress.name ?? ""}
                street={order.billingAddress.street ?? ""}
                streetNumber={order.billingAddress.streetNumber ?? ""}
                zipCode={order.billingAddress.zipCode ?? ""}
                city={order.billingAddress.city ?? ""}
                countryId={order.billingAddress.countryId ?? 110}
                isDefault={!!order.billingAddress.isDefault}
                isBilling={!!order.billingAddress.isBilling}
                handleEdit={() => {}}
              />
            )}
            {/* {order?.status === OrderStatus.Pending && (
              <ChangeAddress
                address={order?.billingAddress}
                disabled={SACardIsLoading || BACardIsLoading}
                type={addressType.userBilling}
                changeAddress={changeAddress}
                addressesList={order?.BillingAddressssList ?? []}
              />
            )} */}
          </>
        )}
      </Section>
      <Section
        padding={["10px 5%"]}
        width={[1]}
        flexDirection={"column"}
        alignItems={"start"}
      >
        {order?.items && order.items.length !== 0 ? (
          <Box width={[1]}>
            <Txt
              textAlign={"start"}
              as={"h6"}
              width={[1]}
              variant="medium"
              color={colors.thirdText}
              fontSize={H6}
              margin={"8% 0% 0% 0%"}
            >
              {`${t(i18Enum.Orders_OrderParent_Number)} ${
                order?.orderParent?.number
              }`}
            </Txt>
            <Txt
              textAlign={"start"}
              as={"h6"}
              width={[1]}
              variant="medium"
              color={colors.thirdText}
              fontSize={H6}
              margin={"2% 0% 5% 0%"}
            >
              {`${t(i18Enum.Orders_OrderShop_Number)} ${order?.number}`}
            </Txt>
            <OrderSentCard
              items={order?.items}
              order={order}
              variants={"order"}
            />
          </Box>
        ) : (
          <ErrorNoItems
            message={t(i18Enum.Orders_Order_ErrorNoItems)}
            countDownText={t(i18Enum.Orders_Order_OrderDetail)}
            arrayLenght={order?.items?.length}
            hasCountDown
          />
        )}
      </Section>
      <Section padding={["0 5% 25% 5%"]} width={[1]} flexDirection={"column"}>
        {/* <Flex justifyContent={"flex-end"} padding={"20px 0"} alignItems={"center"} width={[1]} borderBottom={`1px solid ${colors.thirdBase}`} $gap={2}>
          <Txt textAlign={"start"} as={"h6"} variant='medium' color={colors.thirdText} fontSize={H6}>
            Promo
          </Txt>
          <Txt textAlign={"start"} as={"h6"} variant='light' color={colors.primaryText} fontSize={H6}>
            {`${priceNum(order?.)}€`}
          </Txt>
        </Flex> */}
        {/* TODO: modificare con lo stato dell'ordine che arriva da back-end */}
        <Flex
          justifyContent={"flex-end"}
          padding={"20px 0"}
          borderBottom={"1px solid white"}
        >
          {renderButton(
            order?.status !== null && order?.status
              ? order.status
              : OrderStatus.Pending,
            order?.items?.[0].orderId ?? ""
          )}
        </Flex>
        <Flex
          justifyContent={"flex-end"}
          alignItems={"center"}
          width={[1]}
          padding={"20px 0"}
          borderBottom={`1px solid ${colors.thirdBase}`}
          $gap={2}
        >
          <Txt
            textAlign={"start"}
            as={"h6"}
            variant="medium"
            color={colors.thirdText}
            fontSize={H6}
          >
            {t(i18Enum.Common_Total)}
          </Txt>

          <Txt
            textAlign={"start"}
            as={"h6"}
            variant="light"
            color={colors.primaryText}
            fontSize={H6}
          >
            {`${priceNum(order?.total)}€`}
          </Txt>
        </Flex>
      </Section>
    </Flex>
  );
};
