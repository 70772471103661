import { WardrobeItem, WardrobeItemType } from "utils/generalTypes/wardrobe";
import { CartItemType, ShopGroupType } from "utils/generalTypes/cartItems";
import * as c from "utils/const";
import { UserShippingAddress } from "redux/features/user/UserTypes";
import { UserPreference, UserType } from "redux/features/user/UserTypes";

export const objectIsNotEmpty = <T>(par: T | undefined) => {
  if (par && Object.keys(par).length !== 0) {
    return true;
  } else {
    return false;
  }
};

export function removeDuplicates(
  items: { id: string; type: "COLOR" | "SIZE"; label: string }[]
) {
  const uniqueItems = new Map<
    string,
    { id: string; type: "COLOR" | "SIZE"; label: string }
  >();

  items.forEach((item) => {
    uniqueItems.set(item.id, item);
  });

  return Array.from(uniqueItems.values());
}

export const sortCards = (
  data: object[],
  cardsArray: object[][],
  numberOfItems: number
) => {
  for (let i = 0; i < data.length; i += numberOfItems) {
    cardsArray.push(data.slice(i, i + numberOfItems));
  }
};

export const UserToUserPreference = (user: UserType) => {
  const req: UserPreference = {
    firstName: user.firstName,
    lastName: user.lastName,
    birthdate: user.birthdate,
    isPrivate: user.isPrivate,
    gender: user.gender,
    Picture: user.Picture,
    height: user.height,
    weight: user.weight,
    favoriteColors: user.favoriteColors,
    favoriteSocials: user.favoriteSocials,
    favoriteSports: user.favoriteSports,
    //FavoriteVibes: user.FavoriteVibes,
    favoriteHobbies: user.favoriteHobbies,
  };
  return req;
};

export const waitNSeconds = (seconds: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(0);
    }, seconds * 1000);
  });

export const sortItemsByShop = (
  shops: ShopGroupType[],
  items?: CartItemType[]
) => {
  const sorted = shops.map((shop) => {
    if (items) {
      return items.filter((product) => product.shopId === shop.shopId);
    }
  });
  return sorted;
};

export function nullCheck(item?: any): boolean {
  return (
    typeof item !== "undefined" && typeof item !== "undefined" && item !== null
  );
}

export const parseWardrobeItems = (obj: WardrobeItem) => {
  return {
    id: obj.id,
    imageUrl: obj.variants?.[0]?.images?.[0]?.bucketUrl,
    nameProduct: obj.name,
    gender: undefined,
    categories: undefined,
    description: "",
    colors: [],
    isVisible: obj.isPublished,
  } as WardrobeItemType;
};
export const retrieveUrlCode = (url: string) => {
  const code = url.match(/(.*?)(?=\?)/gm)?.[0].split("/")[4];
  if (!code?.length) {
    return "";
  }
  return code;
};
export const retrieveShopId = (url: string) => {
  const shopId = url.match(/(\?shopId=)(.*)/)?.[2];
  if (!shopId?.length) {
    return "";
  }
  return shopId;
};

export const setShippingAddressCall = (req: UserShippingAddress) => {
  req.isBilling = false;
};
export const setBillingAddressCall = (req: UserShippingAddress) => {
  req.isBilling = true;
};

export const truncate = (par: string, lenght: number) => {
  if (par.length >= lenght) {
    return par.substring(0, lenght) + "...";
  }
  return par;
};

export const VariantColor = (key?: string) => {
  return c.colorsKeyEnum[key as keyof typeof c.colorsKeyEnum];
};

export const isDev = () => {
  return process.env.NODE_ENV === "development";
};
