import { useForm } from "react-hook-form";
import { SpaceProps, WidthProps } from "styled-system";
import { capitalize } from "../../utils";
import Flex from "../primitives/Flex";
import Toggle from "components/primitives/Toggle";
import Txt from "components/primitives/Txt";
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
export interface FormNotification {
  NotificationWishlistOnStockEnabled: boolean;
  NotificationOrderShippedEnabled: boolean;
  NotificationFollowingBirthdayEnabled: boolean;
}

interface FormField {
  id: keyof FormNotification;
  label: string;
}

interface NotificationsFormProps extends WidthProps, SpaceProps {
  onSubmit: (value: FormNotification) => void;
  data: FormNotification;
}

const initialNotification: FormNotification = {
  NotificationWishlistOnStockEnabled: false,
  NotificationOrderShippedEnabled: false,
  NotificationFollowingBirthdayEnabled: false,
};

type NotificationSchemaObject = {
  [key in keyof FormNotification]: Yup.Schema<any>;
};

const formNotoficationSchema = Yup.object().shape<NotificationSchemaObject>({
  NotificationWishlistOnStockEnabled: Yup.boolean().nullable(),
  NotificationOrderShippedEnabled: Yup.boolean().nullable(),
  NotificationFollowingBirthdayEnabled: Yup.boolean().nullable(),
});

function NotificationsForm({ onSubmit, data, ...props }: NotificationsFormProps) {
  const { handleSubmit, setValue, watch } = useForm<FormNotification>();
  const { t } = useTranslation();

  const formFields: FormField[] = [
    {
      id: "NotificationWishlistOnStockEnabled",
      label: t(i18Enum.User_NotificationSettings_Label_WishlistOnStock),
    },
    {
      id: "NotificationOrderShippedEnabled",
      label: t(i18Enum.User_NotificationSettings_Label_OrderShipped),
    },
    {
      id: "NotificationFollowingBirthdayEnabled",
      label: t(i18Enum.User_NotificationSettings_Label_FollowingBirthday),
    },
  ];

  const formik = useFormik<FormNotification>({
    initialValues: data ? data : initialNotification,
    validationSchema: formNotoficationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      onSubmit(values);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values.NotificationFollowingBirthdayEnabled, formik.values.NotificationOrderShippedEnabled, formik.values.NotificationWishlistOnStockEnabled]);

  return (
    <Flex as='form' width={[1]} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} onSubmit={handleSubmit(onSubmit)} {...props}>
      <Flex width={[1, 1, "500px"]} flexDirection={"column"} maxWidth={"500px"} $gap={1.5} marginBottom={[3]}>
        {formFields.map((field) => (
          <Flex key={field.id} alignItems={"center"} width={[1]}>
            <Toggle
              {...formik.getFieldProps(field.id)}
              handleChange={() => {
                formik.setFieldValue(field.id, !formik.values[field.id]);
                formik.setFieldTouched(field.id, true);
              }}
              checked={formik.values[field.id]}
            />

            <Txt fontSize={[2, 3]} lineHeight={"12px"} marginLeft={[3]} variant='light' $textTransform='inherit'>
              {capitalize(field.label)}
            </Txt>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

export default NotificationsForm;
