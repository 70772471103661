import { useCallback, useState, useContext, useEffect } from "react";
import ProductDetailMobile from "../components/organisms/ProductDetailMobile";
import Flex from "../components/primitives/Flex";
import VerticalImageSlider from "../components/molecules/VerticalImageSlider";
import { AppGeneralContext } from "../context/AppGeneralContext";
import Section from "../components/primitives/Section";
import GridCard from "../components/organisms/GridCard";
import Txt from "../components/primitives/Txt";
import { CustomThemeContext } from "../context/ThemeContext";
import { ProductDetailDesktop } from "../components/organisms";
import ShopDetailProduct from "../components/molecules/ShopDetailProduct";
import { ProductDetailDescription } from "../components/molecules";
import ArrowSvg from "../components/atoms/ArrowSvg";
import Skeleton from "../components/atoms/Skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { toastType } from "../utils";
import { ProductActions } from "redux/features/product/productSlice";
import { VariantDTO } from "redux/features/product/ProductsTypes";
import { cartActions } from "redux/features/cart/cartSlice";
import { SelectedProductVariant } from "utils/generalTypes/Frontend";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { CartShipping } from "pages";
import { PAGE } from "utils/const/pagePath";

export const SingleProduct = () => {
  const { isMobile, addToast, openAsideMenu } = useContext(AppGeneralContext);
  const [selected, setSelected] = useState<VariantDTO | undefined | null>(
    undefined
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [wishlisted, setWishlisted] = useState<boolean>(false);
  const [productToBasket, setProductToBasket] =
    useState<SelectedProductVariant | null>(null);
  const { customTheme } = useContext(CustomThemeContext);
  const { data: product, isLoading: isFetching } = useAppSelector(
    (state) => state.product.product
  );

  const productState: { id?: string; variant?: VariantDTO } | null =
    useLocation().state;

  const { data: similar } = useAppSelector(
    (state) => state.product.similarProducts
  );
  const { isLoading: isCartUpdating } = useAppSelector(
    (state) => state.cart.cart
  );
  const isBuyNow = useAppSelector((state) => state.cart.cart.data?.isBuyNow);
  const navigate = useNavigate();
  const colors = customTheme.colors;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userId = useAppSelector((state) => state.user.user.data?.id);
  const { code } = useParams();

  const isProductActive = product?.isPublished && !product?.isCancelled;

  const fetchProductId = async () => {
    if (code === null || code === undefined || !code) {
      navigate(PAGE.notFound.menuPath);
      addToast(toastType.error, "Si è verificato un errore, ritenta.");
      return;
    }
    dispatch(ProductActions.getProductByIdAction(code));

    //TODO: reenable
    // dispatch(ProductActions.getSimilarProductAction(code));
  };

  useEffect(() => {
    fetchProductId();
  }, []);

  useEffect(() => {
    if (!product || product === null) {
      return;
    } else {
      if (!isProductActive) {
        navigate(PAGE.notFound.menuPath);
      }
      if (product.variants && product.variants?.length > 0) {
        if (productState && productState.variant) {
          const preselectedProduct = productState.variant;
          setSelected(preselectedProduct);
        } else {
          const preselectedProduct = product.variants[0];
          setSelected(preselectedProduct);
        }
        setWishlisted(product.wishlistedByUser);
      } else {
        navigate(PAGE.notFound.menuPath);
      }
    }
  }, [product?.id, product, productState]);

  const handleSelection = useCallback(
    (product?: VariantDTO) => {
      if (!product) {
        return;
      }
      setSelected(product);
    },
    [selected]
  );

  const fastShoppingFlow = () => {
    if (isMobile) {
      navigate(PAGE.shipping.menuPath);
    }
    openAsideMenu<undefined>(<CartShipping />, undefined);
    return;
  };

  const handleAddToCart = useCallback(
    (isBuyNow: boolean) => {
      if (!userId) {
        navigate(PAGE.login.menuPath);
        return;
      }

      // console.log("productToBasket", productToBasket);

      if (!productToBasket || productToBasket.id === "") {
        addToast(toastType.error, t(i18Enum.Error_MissingSize));
        return;
      }
      if (!isBuyNow) {
        dispatch(cartActions.addItemAction(productToBasket));
        setProductToBasket(null);
        return;
      } else {
        if (!userId) {
          dispatch(
            cartActions.buyNowAction({
              item: productToBasket,
              action: () => navigate(PAGE.login.menuPath),
            })
          );
          setProductToBasket(null);
          return;
        }
        dispatch(
          cartActions.buyNowAction({
            item: productToBasket,
            action: fastShoppingFlow,
          })
        );
        setProductToBasket(null);
      }
    },
    [productToBasket, userId]
  );

  const handleWishlisted = () => {
    setWishlisted((x) => !x);
  };

  const handleShow = () => {
    setIsOpen(!isOpen);
  };

  if (isMobile) {
    const handleBack = () => {
      navigate(-1);
    };

    return (
      <Flex
        justifyContent={"center"}
        flexDirection={"column"}
        height={"100%"}
        width={[1]}
        position={"relative"}
      >
        {!isOpen && (
          <ArrowSvg
            widthCustom="s"
            position={"absolute"}
            top={["10vh"]}
            height={"20px"}
            left={[3]}
            zIndex={3}
            handleClick={handleBack}
          />
        )}
        <VerticalImageSlider
          isLoading={isFetching}
          isMobile={isMobile}
          urlImages={selected?.images?.map((el) => el.bucketUrl ?? "")}
        />
        <ProductDetailMobile
          isBuyNow={isBuyNow ?? false}
          cartIsUpdating={isCartUpdating}
          handleAddToCart={handleAddToCart}
          productVariants={product?.variants}
          isLoading={isFetching}
          product={product}
          handleSelection={handleSelection}
          selected={selected}
          handleShow={handleShow}
          handleWishlisted={handleWishlisted}
          wishlisted={wishlisted}
          isOpen={isOpen}
          similarProduct={similar ?? []}
          productToBasket={(product) => setProductToBasket(product)}
          selectedProductToBasket={productToBasket}
        />
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} width={"100vw"} maxWidth={"1100px"}>
      <Section
        width={"100%"}
        p={10}
        flexDirection={"row"}
        justifyContent={"center"}
        flexWrap={["wrap", "wrap", "nowrap"]}
      >
        <Flex
          width={["0%", "100%", "calc((100% / 12) * 8)"]}
          flexWrap={"nowrap"}
        >
          <Flex width={"60%"}>
            <VerticalImageSlider
              isLoading={isFetching}
              isMobile={isMobile}
              urlImages={selected?.images?.map((el) => el.bucketUrl ?? "")}
            />
          </Flex>
          <Flex
            $gap={2}
            width={"40%"}
            padding={"0 15px 1px 15px"}
            flexDirection={"column"}
            justifyContent={"end"}
          >
            {isFetching ? (
              <Skeleton height={"100%"} width={"100%"} />
            ) : (
              <>
                <ProductDetailDescription
                  wishlisted={wishlisted}
                  handleWishlisted={handleWishlisted}
                  product={product}
                  selectedVariant={selected}
                />
                <ShopDetailProduct
                  subtitle={
                    product?.shop?.location ?? "" //.negoziante.location
                  }
                  description={product?.shop.description}
                  img={product?.shop?.coverImage?.bucketUrl}
                  id={product?.shopId}
                  textButton={t(i18Enum.Common_ShowMore)}
                  title={product?.shop.name}
                  username={product?.shop.username}
                />
              </>
            )}
          </Flex>
        </Flex>
        <Flex
          flexDirection={"column"}
          width={["0%", "100%", "calc((100% / 12) * 4)"]}
        >
          <ProductDetailDesktop
            isBuyNow={isBuyNow ?? false}
            cartIsUpdating={isCartUpdating}
            productVariants={product?.variants}
            isLoading={isFetching}
            handleShow={() => {}}
            handleSelection={handleSelection}
            product={product}
            selected={selected}
            handleAddToCart={handleAddToCart}
            productToBasket={(product) => setProductToBasket(product)}
          />
        </Flex>
      </Section>
      {/* <Section
        p={3}
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Txt
          variant="light"
          fontSize={[1]}
          margin={"20px 0"}
          color={colors.thirdBase}
          width={[1]}
          textAlign={"left"}
        >
          {t(i18Enum.Product_YouMayAlsoLike)}
        </Txt>
        <GridCard products={similar ?? []} />
      </Section> */}
    </Flex>
  );
};
