import styled from "styled-components";
import {
  ColorProps,
  flexbox,
  LayoutProps,
  SpaceProps,
  layout,
  space,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  color,
} from "styled-system";

const ProductCardImage = styled.div<
  ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    PositionProps &
    BorderProps &
    {$gap?: number } & 
    {$backgroundImage?:string} &
    {$animated:boolean}&
    {$aspectRatio?:string}
>`
  display: flex;
  aspect-ratio: ${(props) => props.$aspectRatio ?? "9 / 18"};
  border:1px solid black;
  max-height:100%;
  background-color: ${(props) => props.theme.colors.gray12 };
  gap: ${(props) => props.$gap ? props.$gap + `em`:'unset'};
  transition: ${(props) => props.$animated ? "all 1s" :undefined};
  background-image: ${(props) => props.$backgroundImage ? `url(${props.$backgroundImage})`  : undefined};
  background-repeat: ${(props) => props.$backgroundImage ? "no-repeat" :undefined};
  background-position: ${(props) => props.$backgroundImage ? "center center" :undefined};
  background-size: ${(props) => props.$backgroundImage ? "auto 100% " :undefined};
  cursor:pointer;
  &:hover{
    background-size: ${(props) => (props.$backgroundImage && props.$animated )? " auto 110% " :undefined};
    transition: ${(props) => props.$animated ? "all 1s" :undefined};
  }

  ${space}
  ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
`;
export default ProductCardImage;
