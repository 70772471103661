import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Section from "components/primitives/Section";
import Box from "components/primitives/Box";
import ArrowSvg from "components/atoms/ArrowSvg";
import Txt from "components/primitives/Txt";
import Flex from "components/primitives/Flex";
import { MobileNavigationBottom } from "components/atoms/MobileNavigationBottom";
import { ShippingCard } from "components/organisms/ShippingCard";
import { priceNum } from "utils";
import { AppGeneralContext } from "context/AppGeneralContext";
import { Cart } from "./Cart";
import { PAGE } from "utils/const/pagePath";
import { CartCheckout } from "./CartCheckout";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import { useTheme } from "styled-components";
import {
  UpdateBasketRequest,
  UpdateShippingRequest,
} from "api/types/requests/cartRequest";
import { cartActions } from "redux/features/cart/cartSlice";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { filterItems } from "utils/functions/filterItems";

export const CartShipping = () => {
  //context data
  const { isMobile, openAsideMenu } = useContext(AppGeneralContext);
  //hook assignment
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const colors = useTheme().colors;
  //selectors
  const shoppingBasket = useAppSelector(
    (state) => state.cart.cart.data?.ShoppingBasket
  );
  const { isLoading } = useAppSelector((state) => state.cart.cart);
  const totalPrice = useAppSelector(
    (state) => state.cart.cart.data?.ShoppingBasket?.total
  );
  const { t } = useTranslation();

  const [shopsTotals, setShopsTotals] = useState<UpdateShippingRequest[]>([]);
  const [totalCartPrice, setTotalCartPrice] = useState<number>(totalPrice!);

  useEffect(() => {
    if (!shoppingBasket?.shops || !totalCartPrice) {
      return;
    }

    const beginCheckoutItems = filterItems(shoppingBasket?.shops);
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "EUR",
        value: totalCartPrice,
        items: beginCheckoutItems,
      },
    });

    console.log({ beginCheckout: beginCheckoutItems });
  }, [shoppingBasket?.shops, totalCartPrice]);

  const handleShopTotal = (req: UpdateShippingRequest) => {
    setShopsTotals((prev) => {
      const idx = prev?.findIndex((x) => x.ShopId === req.ShopId);
      if (idx !== -1) {
        prev.splice(idx, 1);
      }
      const newOpt = { ...req };
      return [...prev, newOpt];
    });
    return;
  };

  const retrieveTotalPrice = (arr: UpdateShippingRequest[]) => {
    const price = arr
      .map((el) => el.price)
      .reduce((acc, current) => acc + current, 0);
    return price;
  };

  useEffect(() => {
    if (shopsTotals.length <= 0) {
      return;
    }
    setTotalCartPrice(retrieveTotalPrice(shopsTotals));
  }, [shopsTotals]);

  const navigationText = (condition: boolean) => {
    if (condition) {
      return `*${t(i18Enum.Cart_Checkout_ShippingIncluded)}`;
    }

    return `*${t(i18Enum.Cart_Checkout_ShippingNotIncluded)}`;
  };

  //Navigation Function
  function back() {
    if (isMobile) {
      navigate(-1);
    }
    openAsideMenu<undefined>(<Cart />, undefined);
  }
  //function called in cartSaga, when setShippingOption is success
  function navigationAfterSuccess() {
    if (isMobile) {
      navigate(PAGE.checkout.menuPath);
      return;
    }
    openAsideMenu<undefined>(<CartCheckout />, undefined);
  }
  const next = useCallback(async () => {
    const shopOptionByShop: Record<string, string> = {};

    shopsTotals.forEach((shopTotalItem) => {
      shopOptionByShop[shopTotalItem.ShopId] = shopTotalItem.ShippingOptionId;
    });

    const updateShippingBasketRequests: UpdateBasketRequest[] = [];
    shoppingBasket?.shops?.forEach((shopsItem) => {
      shopsItem.items?.forEach((backetItem) => {
        updateShippingBasketRequests.push({
          shoppingBasketItemId: backetItem.id,
          shippingOptionId: shopOptionByShop[shopsItem.id],
          quantity: backetItem.quantity,
        });
      });
    });
    dispatch(
      cartActions.setShippingOptionAction({
        req: updateShippingBasketRequests,
        action: navigationAfterSuccess,
      })
    );
  }, [shoppingBasket?.id, shopsTotals]);

  //Almeno una opzione non ha la ShippingOptionId compilata
  const hasMissingShippingOption = shopsTotals.some(
    (shopItem) => !shopItem.ShippingOptionId
  );

  return (
    <>
      <Flex
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"start"}
        padding={["12% 0", "5% 0", "0"]}
      >
        <Box maxWidth={1100} width={"100%"}>
          <Section
            flexDirection={"column"}
            justifyContent={"center"}
            padding={"2em"}
            width={"100%"}
          >
            <ArrowSvg
              widthCustom="s"
              position={"absolute"}
              left={[3]}
              handleClick={() => back()}
            />
            <Txt
              fontSize={["12px", "14px"]}
              variant="medium"
              color={colors.primaryBase}
            >
              {t(i18Enum.Cart_ShippingMethod_Title)}
            </Txt>
          </Section>
          {/* {isLoading && <LoadingSpinner dimension="40px" />} */}
          {shoppingBasket?.shops?.length &&
          shoppingBasket?.shops?.length > 0 ? (
            shoppingBasket?.shops?.map((shop) => {
              const existingShopSelected = shoppingBasket?.shops?.find(
                (x) => x.id === shop.id
              );
              const shippingOptionSelected =
                existingShopSelected?.shippingOption;

              return (
                <ShippingCard
                  key={shop.id}
                  handleShopTotal={handleShopTotal}
                  shippingOptionsList={shop.shippingOptions}
                  shippingOptionSelect={shippingOptionSelected}
                  id={shop.id}
                  shopItems={shop.items!}
                  shop={shop}
                />
              );
            })
          ) : (
            // TODO: aggiungere il testo per indicare che il carrello è vuoto
            <Txt>{t(i18Enum.Cart_Checkout_NoItems)}</Txt>
          )}
        </Box>
      </Flex>

      <MobileNavigationBottom
        variant="cart"
        disabled={
          shopsTotals.length !== shoppingBasket?.shops?.length ||
          hasMissingShippingOption
        }
        isLoading={isLoading}
        cartShippingInfo={navigationText(
          shopsTotals.length === shoppingBasket?.shops?.length
        )}
        text={t(i18Enum.Common_Next)}
        description={`${t(i18Enum.Common_Total)}: ${priceNum(totalCartPrice)}€`}
        buttonAction={() => next()}
      />
    </>
  );
};
