import { Outlet, useNavigate } from "react-router-dom";
import Flex from "../components/primitives/Flex";
import { useEffect } from "react";
import { useAppSelector } from "redux/app/hooks";
import { PAGE } from "utils/const/pagePath";
import { getAuth } from "api/authHelpers";

export const ProtectedPage = () => {
  const { data: user, isLoading } = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    if ((!isLoading && user === null) || !getAuth()) {
      navigate(PAGE.home.menuPath);
    }
  }, [user]);

  return (
    <Flex height={"100%"} flexDirection={"column"} width={[1]}>
      <Outlet />
    </Flex>
  );
};
