interface PageDef {
  [key: string]: {
    title: string;
    path: string;
    menuPath: string;
    showInMenu: boolean;
    viewInDesktop: boolean;
  };
}

export const PAGE: PageDef = {
  home: {
    title: "home",
    path: "home",
    menuPath: "/home",
    showInMenu: true,
    viewInDesktop: true,
  },
  firstLogin: {
    title: "First login",
    path: "firstlogin",
    menuPath: "/user/firstLogin",
    showInMenu: false,
    viewInDesktop: true,
  },
  profilePreview: {
    title: "Profile Preview",
    path: "/:id",
    menuPath: "/",
    showInMenu: false,
    viewInDesktop: true,
  },
  wishlist: {
    title: "Wishlist",
    path: "wishlist",
    menuPath: "/user/wishlist",
    showInMenu: false,
    viewInDesktop: true,
  },
  address: {
    title: "Address",
    path: "",
    menuPath: "/user/address",
    showInMenu: false,
    viewInDesktop: true,
  },
  verify: {
    title: "Verify",
    path: "verify",
    menuPath: "/verify",
    showInMenu: false,
    viewInDesktop: true,
  },
  forgotPassword: {
    title: "forgotPassword",
    path: "forgotPassword",
    menuPath: "/forgot-password",
    showInMenu: false,
    viewInDesktop: true,
  },
  addressEdit: {
    title: "Address",
    path: "edit",
    menuPath: "/user/address/edit",
    showInMenu: false,
    viewInDesktop: false,
  },
  orders: {
    title: "Orders",
    path: "",
    menuPath: "/user/orders",
    showInMenu: false,
    viewInDesktop: true,
  },
  ordersParentDetail: {
    title: "Orders Parents",
    path: "paymentDetail",
    menuPath: "/user/orders/paymentDetail",
    showInMenu: false,
    viewInDesktop: false,
  },
  ordersDetail: {
    title: "Orders",
    path: "detail",
    menuPath: "/user/orders/detail",
    showInMenu: false,
    viewInDesktop: false,
  },
  billing: {
    title: "Billing Address",
    path: "billing",
    menuPath: "/user/orders/billing",
    showInMenu: false,
    viewInDesktop: false,
  },
  account: {
    title: "Account",
    path: "account",
    menuPath: "/user/account",
    showInMenu: false,
    viewInDesktop: true,
  },
  wardrobe: {
    title: "Wardrobe",
    path: "",
    menuPath: "/user/wardrobe",
    showInMenu: false,
    viewInDesktop: true,
  },
  wardrobeEdit: {
    title: "Edit Wear",
    path: "edit",
    menuPath: "/user/wardrobe/edit",
    showInMenu: false,
    viewInDesktop: false,
  },
  shopList: {
    title: "Shopping",
    path: "",
    menuPath: "/shop",
    showInMenu: true,
    viewInDesktop: true,
  },
  shopFilter: {
    title: "Filters",
    path: "filter",
    menuPath: "/shop/filter",
    showInMenu: false,
    viewInDesktop: false,
  },
  shopFiltered: {
    title: "My seletion",
    path: "pick",
    menuPath: "/shop/pick",
    showInMenu: false,
    viewInDesktop: true,
  },
  product: {
    title: "Product Detail",
    path: "product",
    menuPath: "/shop/product",
    showInMenu: false,
    viewInDesktop: true,
  },
  brand: {
    title: "Brand",
    path: "brand",
    menuPath: "/shop/brand",
    showInMenu: false,
    viewInDesktop: true,
  },
  brandsList: {
    title: "BrandsList",
    path: "brands",
    menuPath: "/shop/brands",
    showInMenu: false,
    viewInDesktop: true,
  },
  magazine: {
    title: "Magazine",
    path: "magazine",
    menuPath: "/magazine",
    showInMenu: true,
    viewInDesktop: true,
  },
  community: {
    title: "Community",
    path: "community",
    menuPath: "/community",
    showInMenu: true,
    viewInDesktop: true,
  },
  outfit: {
    title: "Outfit",
    path: "",
    menuPath: "/outfit",
    showInMenu: false,
    viewInDesktop: true,
  },
  buyOutfit: {
    title: "Buy Outfit",
    path: "buy",
    menuPath: "/outfit/buy",
    showInMenu: false,
    viewInDesktop: false,
  },
  profile: {
    title: "Account",
    path: "profile",
    menuPath: "/user/profile",
    showInMenu: false,
    viewInDesktop: true,
  },
  login: {
    title: "Login",
    path: "login",
    menuPath: "/auth/login",
    showInMenu: false,
    viewInDesktop: true,
  },
  register: {
    title: "Register",
    path: "register",
    menuPath: "/auth/register",
    showInMenu: false,
    viewInDesktop: true,
  },
  cart: {
    title: "Cart",
    path: "",
    menuPath: "/cart",
    showInMenu: false,
    viewInDesktop: false,
  },
  shipping: {
    title: "Cart Shipping",
    path: "shipping",
    menuPath: "/cart/shipping",
    showInMenu: false,
    viewInDesktop: false,
  },
  checkout: {
    title: "Shipping Address",
    path: "checkout",
    menuPath: "/cart/checkout",
    showInMenu: false,
    viewInDesktop: false,
  },
  pay: {
    title: "Payment",
    path: "",
    menuPath: "/payment",
    showInMenu: false,
    viewInDesktop: false,
  },
  paymentPending: {
    title: "Payment Pending",
    path: "",
    menuPath: "/payment/pending",
    showInMenu: false,
    viewInDesktop: false,
  },
  paymentSuccess: {
    title: "Payment Success",
    path: "",
    menuPath: "/payment/success",
    showInMenu: false,
    viewInDesktop: false,
  },
  paymentError: {
    title: "Payment Error",
    path: "",
    menuPath: "/payment/error",
    showInMenu: false,
    viewInDesktop: false,
  },
  termsAndConditions: {
    title: "Terms and Conditions",
    path: "terms-and-conditions",
    menuPath: "/terms-and-conditions",
    showInMenu: false,
    viewInDesktop: false,
  },
  privacyPolicy: {
    title: "Privacy Policy",
    path: "privacy-policy",
    menuPath: "/privacy-policy",
    showInMenu: false,
    viewInDesktop: false,
  },
  cookiePolicy: {
    title: "Cookie Policy",
    path: "cookie-policy",
    menuPath: "/cookie-policy",
    showInMenu: false,
    viewInDesktop: false,
  },
  payment: {
    title: "Payment",
    path: "payment",
    menuPath: "/payment",
    showInMenu: false,
    viewInDesktop: false,
  },
  shippings: {
    title: "Shippings",
    path: "shippings",
    menuPath: "/shippings",
    showInMenu: false,
    viewInDesktop: false,
  },
  returnedGoods: {
    title: "Returned Goods",
    path: "returned-goods",
    menuPath: "/returned-goods",
    showInMenu: false,
    viewInDesktop: false,
  },
  aboutUs: {
    title: "About Us",
    path: "about-us",
    menuPath: "/about-us",
    showInMenu: false,
    viewInDesktop: false,
  },
  affiliateProgram: {
    title: "Affiliate Program",
    path: "affiliate-program",
    menuPath: "/affiliate-program",
    showInMenu: false,
    viewInDesktop: false,
  },
  affiliatePolicy: {
    title: "Affiliate Policy",
    path: "affiliate-policy",
    menuPath: "/affiliate-policy",
    showInMenu: false,
    viewInDesktop: false,
  },
  auth: {
    title: "Auth",
    path: "",
    menuPath: "/",
    showInMenu: false,
    viewInDesktop: false,
  },
  code: {
    title: "Code",
    path: "code",
    menuPath: "/code",
    showInMenu: false,
    viewInDesktop: false,
  },
  notFound: {
    title: "Oooops... Something went wrong",
    path: "notFound",
    menuPath: "/notFound",
    showInMenu: false,
    viewInDesktop: true,
  },
};
