export type TranslationType = {
  [key in i18Enum]: string;
};

export enum i18Enum {
  HomeOutfitDesc = "HomeOutfitDesc",
  HomeOutfitTitle = "HomeOutfitTitle",
  AboutUs = "AboutUs",
  AffiliatePolicy = "AffiliatePolicy",
  CookiePolicy = "CookiePolicy",
  PaymentOptions = "PaymentOptions",
  PrivacyPolicy = "PrivacyPolicy",
  ReturnedGoods = "ReturnedGoods",
  Shipping = "Shipping",
  TermsAndConditions = "TermsAndConditions",
  Affiliate_Button_Send = "Affiliate_Button_Send",
  Affiliate_Contacts = "Affiliate_Contacts",
  Affiliate_Desc1 = "Affiliate_Desc1",
  Affiliate_Desc2 = "Affiliate_Desc2",
  Affiliate_Desc3 = "Affiliate_Desc3",
  Affiliate_Desc4 = "Affiliate_Desc4",
  Affiliate_Desc5 = "Affiliate_Desc5",
  Affiliate_Desc6 = "Affiliate_Desc6",
  Affiliate_Option_Yes = "Affiliate_Option_Yes",
  Affiliate_Option_No = "Affiliate_Option_No",
  Affiliate_HasECommerce = "Affiliate_HasECommerce",
  Affiliate_Label_Name = "Affiliate_Label_Name",
  Affiliate_Label_VATNumber = "Affiliate_Label_VATNumber",
  Affiliate_Label_TaxIdCode = "Affiliate_Label_TaxIdCode",
  Affiliate_Label_Address = "Affiliate_Label_Address",
  Affiliate_Label_Phone = "Affiliate_Label_Phone",
  Affiliate_Label_MobilePhone = "Affiliate_Label_MobilePhone",
  Affiliate_Label_Email = "Affiliate_Label_Email",
  Affiliate_Label_WebSiteUrl = "Affiliate_Label_WebSiteUrl",
  Affiliate_Label_EcommerceUrl = "Affiliate_Label_EcommerceUrl",
  Affiliate_Label_Sector = "Affiliate_Label_Sector",
  Affiliate_Title = "Affiliate_Title",
  CardsMenu_1 = "CardsMenu_1",
  CardsMenu_2 = "CardsMenu_2",
  CardsMenu_3 = "CardsMenu_3",
  Cart_Checkout_Button = "Cart_Checkout_Button",
  Cart_Checkout_Label = "Cart_Checkout_Label",
  Cart_Checkout_NoItems = "Cart_Checkout_NoItems",
  Cart_Checkout_ShippingIncluded = "Cart_Checkout_ShippingIncluded",
  Cart_Checkout_ShippingNotIncluded = "Cart_Checkout_ShippingNotIncluded",
  Cart_Checkout_Title = "Cart_Checkout_Title",
  Cart_Shop_Title = "Cart_Shop_Title",
  Cart_NothingInYourCart = "Cart_NothingInYourCart",
  Cart_Updated = "Cart_Updated",
  Cart_Shipping_AddNewAddress = "Cart_Shipping_AddNewAddress",
  Cart_Shipping_BillingAddress = "Cart_Shipping_BillingAddress",
  Cart_Shipping_FreeShipping_Desc = "Cart_Shipping_FreeShipping_Desc",
  Cart_Shipping_Label_FreeShipping = "Cart_Shipping_Label_FreeShipping",
  Cart_Shipping_NoAddresses = "Cart_Shipping_NoAddresses",
  Cart_Shipping_NoAddresses_Alert = "Cart_Shipping_NoAddresses_Alert",
  Cart_Shipping_OrderNow = "Cart_Shipping_OrderNow",
  Cart_Shipping_SameAddress = "Cart_Shipping_SameAddress",
  Cart_Shipping_PayWith = "Cart_Shipping_PayWith",
  Cart_Shipping_ShippingAddress = "Cart_Shipping_ShippingAddress",
  Cart_ShippingMethod_Title = "Cart_ShippingMethod_Title",
  Cart_ShippingMethod_Saved = "Cart_ShippingMethod_Saved",
  Cart_Shipping_Terms = "Cart_Shipping_Terms",
  Cart_Shipping_Total = "Cart_Shipping_Total",
  Cart_Shipping_TotalWithShipping = "Cart_Shipping_TotalWithShipping",
  Cart_ShoppingBag_Button_Checkout = "Cart_ShoppingBag_Button_Checkout",
  Cart_ShoppingBag_Button_ContinueShopping = "Cart_ShoppingBag_Button_ContinueShopping",
  Common_AccountPrivate = "Common_AccountPrivate",
  Common_AccountPublic = "Common_AccountPublic",
  Common_Back = "Common_Back",
  Common_Cancel = "Common_Cancel",
  Common_Delete = "Common_Delete",
  Common_Edit = "Common_Edit",
  Common_EditAccount = "Common_EditAccount",
  Common_Follow = "Common_Follow",
  Common_Followers = "Common_Followers",
  Common_Following = "Common_Following",
  Common_Next = "Common_Next",
  Common_Private = "Common_Private",
  Common_Public = "Common_Public",
  Common_Save = "Common_Save",
  Common_Search = "Common_Search",
  Common_Select = "Common_Select",
  Common_ShowMore = "Common_ShowMore",
  Common_Subtotal = "Common_Subtotal",
  Common_ThePage = "Common_ThePage",
  Common_Total = "Common_Total",
  Community_Button_Follow = "Community_Button_Follow",
  Community_Button_Followed = "Community_Button_Followed",
  Community_Button_Unfollow = "Community_Button_Unfollow",
  Community_Label_SearchUser = "Community_Label_SearchUser",
  Community_Search_Error = "Community_Search_Error",
  CropImageModal_Button_ChoosePhoto = "CropImageModal_Button_ChoosePhoto",
  CropImageModal_Button_DeletePhoto = "CropImageModal_Button_DeletePhoto",
  CropImageModal_Button_UploadPhoto = "CropImageModal_Button_UploadPhoto",
  CropImageModal_Error = "CropImageModal_Error",
  CropImageModal_FormatValidation = "CropImageModal_FormatValidation",
  CropImageModal_Title = "CropImageModal_Title",
  DV_Countries_1 = "DV_Countries_1",
  DV_Countries_10 = "DV_Countries_10",
  DV_Countries_100 = "DV_Countries_100",
  DV_Countries_101 = "DV_Countries_101",
  DV_Countries_102 = "DV_Countries_102",
  DV_Countries_103 = "DV_Countries_103",
  DV_Countries_104 = "DV_Countries_104",
  DV_Countries_105 = "DV_Countries_105",
  DV_Countries_106 = "DV_Countries_106",
  DV_Countries_107 = "DV_Countries_107",
  DV_Countries_108 = "DV_Countries_108",
  DV_Countries_109 = "DV_Countries_109",
  DV_Countries_11 = "DV_Countries_11",
  DV_Countries_110 = "DV_Countries_110",
  DV_Countries_111 = "DV_Countries_111",
  DV_Countries_112 = "DV_Countries_112",
  DV_Countries_113 = "DV_Countries_113",
  DV_Countries_114 = "DV_Countries_114",
  DV_Countries_115 = "DV_Countries_115",
  DV_Countries_116 = "DV_Countries_116",
  DV_Countries_117 = "DV_Countries_117",
  DV_Countries_118 = "DV_Countries_118",
  DV_Countries_119 = "DV_Countries_119",
  DV_Countries_12 = "DV_Countries_12",
  DV_Countries_120 = "DV_Countries_120",
  DV_Countries_121 = "DV_Countries_121",
  DV_Countries_122 = "DV_Countries_122",
  DV_Countries_123 = "DV_Countries_123",
  DV_Countries_124 = "DV_Countries_124",
  DV_Countries_125 = "DV_Countries_125",
  DV_Countries_126 = "DV_Countries_126",
  DV_Countries_127 = "DV_Countries_127",
  DV_Countries_128 = "DV_Countries_128",
  DV_Countries_129 = "DV_Countries_129",
  DV_Countries_13 = "DV_Countries_13",
  DV_Countries_130 = "DV_Countries_130",
  DV_Countries_131 = "DV_Countries_131",
  DV_Countries_132 = "DV_Countries_132",
  DV_Countries_133 = "DV_Countries_133",
  DV_Countries_134 = "DV_Countries_134",
  DV_Countries_135 = "DV_Countries_135",
  DV_Countries_136 = "DV_Countries_136",
  DV_Countries_137 = "DV_Countries_137",
  DV_Countries_138 = "DV_Countries_138",
  DV_Countries_139 = "DV_Countries_139",
  DV_Countries_14 = "DV_Countries_14",
  DV_Countries_140 = "DV_Countries_140",
  DV_Countries_141 = "DV_Countries_141",
  DV_Countries_142 = "DV_Countries_142",
  DV_Countries_143 = "DV_Countries_143",
  DV_Countries_144 = "DV_Countries_144",
  DV_Countries_145 = "DV_Countries_145",
  DV_Countries_146 = "DV_Countries_146",
  DV_Countries_147 = "DV_Countries_147",
  DV_Countries_148 = "DV_Countries_148",
  DV_Countries_149 = "DV_Countries_149",
  DV_Countries_15 = "DV_Countries_15",
  DV_Countries_150 = "DV_Countries_150",
  DV_Countries_151 = "DV_Countries_151",
  DV_Countries_152 = "DV_Countries_152",
  DV_Countries_153 = "DV_Countries_153",
  DV_Countries_154 = "DV_Countries_154",
  DV_Countries_155 = "DV_Countries_155",
  DV_Countries_156 = "DV_Countries_156",
  DV_Countries_157 = "DV_Countries_157",
  DV_Countries_158 = "DV_Countries_158",
  DV_Countries_159 = "DV_Countries_159",
  DV_Countries_16 = "DV_Countries_16",
  DV_Countries_160 = "DV_Countries_160",
  DV_Countries_161 = "DV_Countries_161",
  DV_Countries_162 = "DV_Countries_162",
  DV_Countries_163 = "DV_Countries_163",
  DV_Countries_164 = "DV_Countries_164",
  DV_Countries_165 = "DV_Countries_165",
  DV_Countries_166 = "DV_Countries_166",
  DV_Countries_167 = "DV_Countries_167",
  DV_Countries_168 = "DV_Countries_168",
  DV_Countries_169 = "DV_Countries_169",
  DV_Countries_17 = "DV_Countries_17",
  DV_Countries_170 = "DV_Countries_170",
  DV_Countries_171 = "DV_Countries_171",
  DV_Countries_172 = "DV_Countries_172",
  DV_Countries_173 = "DV_Countries_173",
  DV_Countries_174 = "DV_Countries_174",
  DV_Countries_175 = "DV_Countries_175",
  DV_Countries_176 = "DV_Countries_176",
  DV_Countries_177 = "DV_Countries_177",
  DV_Countries_178 = "DV_Countries_178",
  DV_Countries_179 = "DV_Countries_179",
  DV_Countries_18 = "DV_Countries_18",
  DV_Countries_180 = "DV_Countries_180",
  DV_Countries_181 = "DV_Countries_181",
  DV_Countries_182 = "DV_Countries_182",
  DV_Countries_183 = "DV_Countries_183",
  DV_Countries_184 = "DV_Countries_184",
  DV_Countries_185 = "DV_Countries_185",
  DV_Countries_186 = "DV_Countries_186",
  DV_Countries_187 = "DV_Countries_187",
  DV_Countries_188 = "DV_Countries_188",
  DV_Countries_189 = "DV_Countries_189",
  DV_Countries_19 = "DV_Countries_19",
  DV_Countries_190 = "DV_Countries_190",
  DV_Countries_191 = "DV_Countries_191",
  DV_Countries_192 = "DV_Countries_192",
  DV_Countries_193 = "DV_Countries_193",
  DV_Countries_194 = "DV_Countries_194",
  DV_Countries_195 = "DV_Countries_195",
  DV_Countries_196 = "DV_Countries_196",
  DV_Countries_197 = "DV_Countries_197",
  DV_Countries_198 = "DV_Countries_198",
  DV_Countries_199 = "DV_Countries_199",
  DV_Countries_2 = "DV_Countries_2",
  DV_Countries_20 = "DV_Countries_20",
  DV_Countries_200 = "DV_Countries_200",
  DV_Countries_201 = "DV_Countries_201",
  DV_Countries_202 = "DV_Countries_202",
  DV_Countries_203 = "DV_Countries_203",
  DV_Countries_204 = "DV_Countries_204",
  DV_Countries_205 = "DV_Countries_205",
  DV_Countries_206 = "DV_Countries_206",
  DV_Countries_207 = "DV_Countries_207",
  DV_Countries_208 = "DV_Countries_208",
  DV_Countries_209 = "DV_Countries_209",
  DV_Countries_21 = "DV_Countries_21",
  DV_Countries_210 = "DV_Countries_210",
  DV_Countries_211 = "DV_Countries_211",
  DV_Countries_212 = "DV_Countries_212",
  DV_Countries_213 = "DV_Countries_213",
  DV_Countries_214 = "DV_Countries_214",
  DV_Countries_215 = "DV_Countries_215",
  DV_Countries_216 = "DV_Countries_216",
  DV_Countries_217 = "DV_Countries_217",
  DV_Countries_218 = "DV_Countries_218",
  DV_Countries_219 = "DV_Countries_219",
  DV_Countries_22 = "DV_Countries_22",
  DV_Countries_220 = "DV_Countries_220",
  DV_Countries_221 = "DV_Countries_221",
  DV_Countries_222 = "DV_Countries_222",
  DV_Countries_223 = "DV_Countries_223",
  DV_Countries_224 = "DV_Countries_224",
  DV_Countries_225 = "DV_Countries_225",
  DV_Countries_226 = "DV_Countries_226",
  DV_Countries_227 = "DV_Countries_227",
  DV_Countries_228 = "DV_Countries_228",
  DV_Countries_229 = "DV_Countries_229",
  DV_Countries_23 = "DV_Countries_23",
  DV_Countries_230 = "DV_Countries_230",
  DV_Countries_231 = "DV_Countries_231",
  DV_Countries_232 = "DV_Countries_232",
  DV_Countries_233 = "DV_Countries_233",
  DV_Countries_234 = "DV_Countries_234",
  DV_Countries_235 = "DV_Countries_235",
  DV_Countries_236 = "DV_Countries_236",
  DV_Countries_237 = "DV_Countries_237",
  DV_Countries_238 = "DV_Countries_238",
  DV_Countries_239 = "DV_Countries_239",
  DV_Countries_24 = "DV_Countries_24",
  DV_Countries_240 = "DV_Countries_240",
  DV_Countries_241 = "DV_Countries_241",
  DV_Countries_242 = "DV_Countries_242",
  DV_Countries_243 = "DV_Countries_243",
  DV_Countries_244 = "DV_Countries_244",
  DV_Countries_245 = "DV_Countries_245",
  DV_Countries_246 = "DV_Countries_246",
  DV_Countries_247 = "DV_Countries_247",
  DV_Countries_248 = "DV_Countries_248",
  DV_Countries_249 = "DV_Countries_249",
  DV_Countries_25 = "DV_Countries_25",
  DV_Countries_26 = "DV_Countries_26",
  DV_Countries_27 = "DV_Countries_27",
  DV_Countries_28 = "DV_Countries_28",
  DV_Countries_29 = "DV_Countries_29",
  DV_Countries_3 = "DV_Countries_3",
  DV_Countries_30 = "DV_Countries_30",
  DV_Countries_31 = "DV_Countries_31",
  DV_Countries_32 = "DV_Countries_32",
  DV_Countries_33 = "DV_Countries_33",
  DV_Countries_34 = "DV_Countries_34",
  DV_Countries_35 = "DV_Countries_35",
  DV_Countries_36 = "DV_Countries_36",
  DV_Countries_37 = "DV_Countries_37",
  DV_Countries_38 = "DV_Countries_38",
  DV_Countries_39 = "DV_Countries_39",
  DV_Countries_4 = "DV_Countries_4",
  DV_Countries_40 = "DV_Countries_40",
  DV_Countries_41 = "DV_Countries_41",
  DV_Countries_42 = "DV_Countries_42",
  DV_Countries_43 = "DV_Countries_43",
  DV_Countries_44 = "DV_Countries_44",
  DV_Countries_45 = "DV_Countries_45",
  DV_Countries_46 = "DV_Countries_46",
  DV_Countries_47 = "DV_Countries_47",
  DV_Countries_48 = "DV_Countries_48",
  DV_Countries_49 = "DV_Countries_49",
  DV_Countries_5 = "DV_Countries_5",
  DV_Countries_50 = "DV_Countries_50",
  DV_Countries_51 = "DV_Countries_51",
  DV_Countries_52 = "DV_Countries_52",
  DV_Countries_53 = "DV_Countries_53",
  DV_Countries_54 = "DV_Countries_54",
  DV_Countries_55 = "DV_Countries_55",
  DV_Countries_56 = "DV_Countries_56",
  DV_Countries_57 = "DV_Countries_57",
  DV_Countries_58 = "DV_Countries_58",
  DV_Countries_59 = "DV_Countries_59",
  DV_Countries_6 = "DV_Countries_6",
  DV_Countries_60 = "DV_Countries_60",
  DV_Countries_61 = "DV_Countries_61",
  DV_Countries_62 = "DV_Countries_62",
  DV_Countries_63 = "DV_Countries_63",
  DV_Countries_64 = "DV_Countries_64",
  DV_Countries_65 = "DV_Countries_65",
  DV_Countries_66 = "DV_Countries_66",
  DV_Countries_67 = "DV_Countries_67",
  DV_Countries_68 = "DV_Countries_68",
  DV_Countries_69 = "DV_Countries_69",
  DV_Countries_7 = "DV_Countries_7",
  DV_Countries_70 = "DV_Countries_70",
  DV_Countries_71 = "DV_Countries_71",
  DV_Countries_72 = "DV_Countries_72",
  DV_Countries_73 = "DV_Countries_73",
  DV_Countries_74 = "DV_Countries_74",
  DV_Countries_75 = "DV_Countries_75",
  DV_Countries_76 = "DV_Countries_76",
  DV_Countries_77 = "DV_Countries_77",
  DV_Countries_78 = "DV_Countries_78",
  DV_Countries_79 = "DV_Countries_79",
  DV_Countries_8 = "DV_Countries_8",
  DV_Countries_80 = "DV_Countries_80",
  DV_Countries_81 = "DV_Countries_81",
  DV_Countries_82 = "DV_Countries_82",
  DV_Countries_83 = "DV_Countries_83",
  DV_Countries_84 = "DV_Countries_84",
  DV_Countries_85 = "DV_Countries_85",
  DV_Countries_86 = "DV_Countries_86",
  DV_Countries_87 = "DV_Countries_87",
  DV_Countries_88 = "DV_Countries_88",
  DV_Countries_89 = "DV_Countries_89",
  DV_Countries_9 = "DV_Countries_9",
  DV_Countries_90 = "DV_Countries_90",
  DV_Countries_91 = "DV_Countries_91",
  DV_Countries_92 = "DV_Countries_92",
  DV_Countries_93 = "DV_Countries_93",
  DV_Countries_94 = "DV_Countries_94",
  DV_Countries_95 = "DV_Countries_95",
  DV_Countries_96 = "DV_Countries_96",
  DV_Countries_97 = "DV_Countries_97",
  DV_Countries_98 = "DV_Countries_98",
  DV_Countries_99 = "DV_Countries_99",
  DV_Countries_EuropeanUnion = "DV_Countries_EuropeanUnion",
  DV_Countries_Worldwide = "DV_Countries_Worldwide",
  DV_Sports_1 = "DV_Sports_1",
  DV_Sports_2 = "DV_Sports_2",
  DV_Sports_3 = "DV_Sports_3",
  DV_Sports_4 = "DV_Sports_4",
  DV_Sports_5 = "DV_Sports_5",
  DV_Sports_6 = "DV_Sports_6",
  DV_Sports_7 = "DV_Sports_7",
  DV_Sports_8 = "DV_Sports_8",
  DV_Sports_9 = "DV_Sports_9",
  DV_Sports_10 = "DV_Sports_10",
  DV_Sports_11 = "DV_Sports_11",
  DV_Sports_12 = "DV_Sports_12",
  DV_Hobbies_1 = "DV_Hobbies_1",
  DV_Hobbies_2 = "DV_Hobbies_2",
  DV_Hobbies_3 = "DV_Hobbies_3",
  DV_Hobbies_4 = "DV_Hobbies_4",
  DV_Hobbies_5 = "DV_Hobbies_5",
  DV_Hobbies_6 = "DV_Hobbies_6",
  DV_Hobbies_7 = "DV_Hobbies_7",
  DV_Hobbies_8 = "DV_Hobbies_8",
  DV_Socials_1 = "DV_Socials_1",
  DV_Socials_2 = "DV_Socials_2",
  DV_Socials_3 = "DV_Socials_3",
  DV_Socials_4 = "DV_Socials_4",
  DV_Socials_5 = "DV_Socials_5",
  DV_Socials_6 = "DV_Socials_6",
  DV_Socials_7 = "DV_Socials_7",
  DV_Socials_8 = "DV_Socials_8",
  EmailConfirmation_SendEmail = "EmailConfirmation_SendEmail",
  Error_Links = "Error_Links",
  Error_MissingAddress = "Error_MissingAddress",
  Error_SameAddress = "Error_SameAddress",
  Error_MissingOrder = "Error_MissingOrder",
  Error_MissingSize = "Error_MissingSize",
  Error_MissingShop = "Error_MissingShop",
  Error_MissingUser = "Error_MissingUser",
  Error_MissingUsername = "Error_MissingUsername",
  Error_Occurred = "Error_Occurred",
  Error_YouMustBeLogged = "Error_YouMustBeLogged",
  ErrorPage404 = "ErrorPage404",
  ErrorPage404_ErrorLabel = "ErrorPage404_ErrorLabel",
  ErrorPage404_ErrorLabel2 = "ErrorPage404_ErrorLabel2",
  ErrorPage500_ErrorLabel = "ErrorPage500_ErrorLabel",
  ErrorPage_Back = "ErrorPage_Back",
  ErrorPage_ErrorLabel = "ErrorPage_ErrorLabel",
  ErrorPage_Help = "ErrorPage_Help",
  ErrorPage_PleaseRetry = "ErrorPage_PleaseRetry",
  ErrorPage_Retry = "ErrorPage_Retry",
  Error_No_Items_Countdown = "Error_No_Items_Countdown",
  Filters_Button_Filters = "Filters_Button_Filters",
  Filters_Label_Categories = "Filters_Label_Categories",
  Filters_Label_Price = "Filters_Label_Price",
  Filters_Label_Reset = "Filters_Label_Reset",
  Filters_Label_ShowPromo = "Filters_Label_ShowPromo",
  Filters_Label_ShowResults = "Filters_Label_ShowResults",
  Footer_AboutUs = "Footer_AboutUs",
  Footer_AffiliateProgram = "Footer_AffiliateProgram",
  Footer_Community = "Footer_Community",
  Footer_Contact = "Footer_Contact",
  Footer_CookiePolicy = "Footer_CookiePolicy",
  Footer_Copyright = "Footer_Copyright",
  Footer_DeliveryAndReturns = "Footer_DeliveryAndReturns",
  Footer_Explore = "Footer_Explore",
  Footer_FollowUs = "Footer_FollowUs",
  Footer_Information = "Footer_Information",
  Footer_Magazine = "Footer_Magazine",
  Footer_PaymentOptions = "Footer_PaymentOptions",
  Footer_PrivacyPolicy = "Footer_PrivacyPolicy",
  Footer_Rights = "Footer_Rights",
  Footer_TermsOfUse = "Footer_TermsOfUse",
  Footer_Shopping = "Footer_Shopping",
  Footer_Social = "Footer_Social",
  Footer_Shipping = "Footer_Shipping",
  Footer_TermsAndConditions = "Footer_TermsAndConditions",
  Footer_ContactUs = "Footer_ContactUs",
  Footer_WorkWithUs = "Footer_WorkWithUs",
  Home_Header_Desc = "Home_Header_Desc",
  Home_Header_Greetings = "Home_Header_Greetings",
  Home_Header_Greetings2 = "Home_Header_Greetings2",
  Home_Section_Magazine_Article = "Home_Section_Magazine_Article",
  Home_Section_Magazine_Button = "Home_Section_Magazine_Button",
  Home_Section_Magazine_Title = "Home_Section_Magazine_Title",
  Home_Section_Mood_Title = "Home_Section_Mood_Title",
  Home_Section_Outfit_Button = "Home_Section_Outfit_Button",
  Home_Section_Outfit_Title = "Home_Section_Outfit_Title",
  Home_Section_Shopping_Button = "Home_Section_Shopping_Button",
  Home_Section_Shopping_Title = "Home_Section_Shopping_Title",
  Home_Section_Shopping_Title2 = "Home_Section_Shopping_Title2",
  Home_Section_Wardrobe_Button = "Home_Section_Wardrobe_Button",
  Home_Section_Wardrobe_CTA = "Home_Section_Wardrobe_CTA",
  Home_Section_Wardrobe_Title = "Home_Section_Wardrobe_Title",
  Home_Section_AboutUs_Button = "Home_Section_AboutUs_Button",
  Home_Section_AboutUs_Desc1 = "Home_Section_AboutUs_Desc1",
  Home_Section_AboutUs_Desc2 = "Home_Section_AboutUs_Desc2",
  Home_Section_AboutUs_Desc3 = "Home_Section_AboutUs_Desc3",
  Home_Section_AboutUs_Desc4 = "Home_Section_AboutUs_Desc4",
  Home_Section_AboutUs_Desc5 = "Home_Section_AboutUs_Desc5",
  Home_Section_AboutUs_Title = "Home_Section_AboutUs_Title",
  IsTermsOfUseAgree = "IsTermsOfUseAgree",
  IsPrivacyPolicyAgree = "IsPrivacyPolicyAgree",
  IsMarektingPolicyAgree = "IsMarektingPolicyAgree",
  IsProfilingPolicyAgree = "IsProfilingPolicyAgree",
  Login_LoginTitle = "Login_LoginTitle",
  Login_CreateAccount = "Login_CreateAccount",
  Login_LoginButton = "Login_LoginButton",
  Login_ForgotPassword = "Login_ForgotPassword",
  Login_PasswordsMustMatch = "Login_PasswordsMustMatch",
  Login_PasswordConfirmation = "Login_PasswordConfirmation",
  Login_NoAccountCTO = "Login_NoAccountCTO",
  Login_RegisterButton = "Login_RegisterButton",
  Login_Password_ValidationFailed = "Login_Password_ValidationFailed",
  Message_AddressUpdated = "Message_AddressUpdated",
  Message_ItemRemoved = "Message_ItemRemoved",
  Message_ConfirmDeleteItem = "Message_ConfirmDeleteItem",
  Message_SelectAddress = "Message_SelectAddress",
  Navigation_Menu_Account = "Navigation_Menu_Account",
  Navigation_Menu_Community = "Navigation_Menu_Community",
  Navigation_Menu_Greeting = "Navigation_Menu_Greeting",
  Navigation_Menu_Home = "Navigation_Menu_Home",
  Navigation_Menu_Login = "Navigation_Menu_Login",
  Navigation_Menu_Logout = "Navigation_Menu_Logout",
  Navigation_Menu_Magazine = "Navigation_Menu_Magazine",
  Navigation_Menu_Shopping = "Navigation_Menu_Shopping",
  Navigation_UserMenu_AboutMe = "Navigation_UserMenu_AboutMe",
  Navigation_UserMenu_Address = "Navigation_UserMenu_Address",
  Navigation_UserMenu_MyProfile = "Navigation_UserMenu_MyProfile",
  Navigation_UserMenu_Orders = "Navigation_UserMenu_Orders",
  Navigation_UserMenu_Wardrobe = "Navigation_UserMenu_Wardrobe",
  Navigation_UserMenu_Wishlist = "Navigation_UserMenu_Wishlist",
  OnboardingQuiz_StepUserDetails_Label_Height = "OnboardingQuiz_StepUserDetails_Label_Height",
  OnboardingQuiz_StepUserDetails_Label_Weight = "OnboardingQuiz_StepUserDetails_Label_Weight",
  Orders_BillingAddress_AddNow = "Orders_BillingAddress_AddNow",
  Orders_BillingAddress_NoAddress = "Orders_BillingAddress_NoAddress",
  Orders_BillingAddress_Title = "Orders_BillingAddress_Title",
  Orders_Message_NoOrders = "Orders_Message_NoOrders",
  Orders_Order_Button_AskForAReturn = "Orders_Order_Button_AskForAReturn",
  Orders_Order_Confirm_DeleteOrder = "Orders_Order_Confirm_DeleteOrder",
  Orders_Order_Button_DeleteOrder = "Orders_Order_Button_DeleteOrder",
  Orders_Order_Button_GoBackHome = "Orders_Order_Button_GoBackHome",
  Orders_Order_ErrorNoItems = "Orders_Order_ErrorNoItems",
  Orders_Order_OrderDate = "Orders_Order_OrderDate",
  Orders_Order_OrderDetail = "Orders_Order_OrderDetail",
  Orders_Order_BillingAddress = "Orders_Order_BillingAddress",
  Orders_Order_ShippingAddress = "Orders_Order_ShippingAddress",
  Orders_Order_OrderSuccess_Email = "Orders_Order_OrderSuccess_Email",
  Orders_Order_OrderSuccess_Title = "Orders_Order_OrderSuccess_Title",
  Orders_Order_Others = "Orders_Order_Others",
  Orders_Order_Number = "Orders_Order_Number",
  Orders_Order_Pending_Title = "Orders_Order_Pending_Title",
  Orders_Order_PayNow = "Orders_Order_PayNow",
  Orders_Order_Shipping = "Orders_Order_Shipping",
  Orders_Order_Status = "Orders_Order_Status",
  Orders_Order_ViewDetail = "Orders_Order_ViewDetail",
  Orders_OrderStatus_Filter_1 = "Orders_OrderStatus_Filter_1",
  Orders_OrderStatus_Filter_2 = "Orders_OrderStatus_Filter_2",
  Orders_OrderStatus_Filter_3 = "Orders_OrderStatus_Filter_3",
  Orders_OrderStatus_Filter_4 = "Orders_OrderStatus_Filter_4",
  Orders_OrderStatus_Filter_5 = "Orders_OrderStatus_Filter_5",
  Orders_OrderStatus_Filter_6 = "Orders_OrderStatus_Filter_6",
  Orders_OrderStatus_Filter_7 = "Orders_OrderStatus_Filter_7",
  Orders_Title = "Orders_Title",
  OnboardingQuiz_Sports_Header = "OnboardingQuiz_Sports_Header",
  OnboardingQuiz_Hobbies_Header = "OnboardingQuiz_Hobbies_Header",
  OnboardingQuiz_Socials_Header = "OnboardingQuiz_Socials_Header",
  OnboardingQuiz_StepSocials_Title = "OnboardingQuiz_StepSocials_Title",
  OnboardingQuiz_Colors_Header = "OnboardingQuiz_Colors_Header",
  OnboardingQuiz_Vibes_Header = "OnboardingQuiz_Vibes_Header",
  Outfit_SelectedOutfit_Title = "Outfit_SelectedOutfit_Title",
  Outfit_SelectedOutfit_NoItems = "Outfit_SelectedOutfit_NoItems",
  OutfitBestSellers_Watch = "OutfitBestSellers_Watch",
  Payment_Message_Error = "Payment_Message_Error",
  Payment_Message_Error2 = "Payment_Message_Error2",
  Payment_GoBackToOrder = "Payment_GoBackToOrder",
  Payment_Message_Success = "Payment_Message_Success",
  Payment_Message_Success2 = "Payment_Message_Success2",
  Product_ProductCard_AddItemSuccess = "Product_ProductCard_AddItemSuccess",
  Product_ProductCard_Button_AddToCart = "Product_ProductCard_Button_AddToCart",
  Product_ProductCard_Button_Cancel = "Product_ProductCard_Button_Cancel",
  Product_ProductCard_ShippingInfo = "Product_ProductCard_ShippingInfo",
  Product_YouMayAlsoLike = "Product_YouMayAlsoLike",
  Product_Button_BuyNow = "Product_Button_BuyNow",
  Product_Label_Colors = "Product_Label_Colors",
  Product_Label_Color = "Product_Label_Color",
  Product_Label_Details = "Product_Label_Details",
  Product_Label_MaterialAndCare = "Product_Label_MaterialAndCare",
  Product_Label_ShippingInfo = "Product_Label_ShippingInfo",
  Product_Label_ShowMore = "Product_Label_ShowMore",
  Product_SelectColor = "Product_SelectColor",
  Product_SelectSize = "Product_SelectSize",
  Product_ShippingInfo = "Product_ShippingInfo",
  Product_Free_ShippingInfo = "Product_Free_ShippingInfo",
  Shopping_Page_ExploreByCategory = "Shopping_Page_ExploreByCategory",
  Shopping_Page_MostViewed = "Shopping_Page_MostViewed",
  Shopping_Page_NoResults = "Shopping_Page_NoResults",
  Shopping_Page_NoResultsFor = "Shopping_Page_NoResultsFor",
  Shopping_Page_Error_NoResults = "Shopping_Page_Error_NoResults",
  Shopping_Page_Promo = "Shopping_Page_Promo",
  Shopping_Page_PromoActive = "Shopping_Page_PromoActive",
  Shopping_Page_Results = "Shopping_Page_Results",
  Shopping_Page_ResultsFor = "Shopping_Page_ResultsFor",
  Shopping_Page_Title = "Shopping_Page_Title",
  Shopping_Page_ViewLess = "Shopping_Page_ViewLess",
  Shopping_Page_ViewMore = "Shopping_Page_ViewMore",
  Shopping_Page_ViewAllButton = "Shopping_Page_ViewAllButton",
  ToastCreateAddressError = "ToastCreateAddressError",
  Validation_Client_Field_AlphaNumeric = "Validation_Client_Field_AlphaNumeric",
  Validation_Client_Field_AlphaNumericAndMinusCharacter = "Validation_Client_Field_AlphaNumericAndMinusCharacter",
  Validation_Client_Field_Date = "Validation_Client_Field_Date",
  Validation_Client_Field_Email = "Validation_Client_Field_Email",
  Validation_Client_Field_Phone = "Validation_Client_Field_Phone",
  Validation_Client_Field_MaxLength = "Validation_Client_Field_MaxLength",
  Validation_Client_Field_MinLength = "Validation_Client_Field_MinLength",
  Validation_Client_Field_MinValue = "Validation_Client_Field_MinValue",
  Validation_Client_Field_NonWhitespace = "Validation_Client_Field_NonWhitespace",
  Validation_Client_Field_Number = "Validation_Client_Field_Number",
  Validation_Client_Field_Required = "Validation_Client_Field_Required",
  Validation_Client_Field_WholeNumber = "Validation_Client_Field_WholeNumber",
  Validation_Field_DataLength = "Validation_Field_DataLength",
  Validation_Field_InvalidEmail = "Validation_Field_InvalidEmail",
  Validation_Field_InvalidRange = "Validation_Field_InvalidRange",
  Validation_Field_Required = "Validation_Field_Required",
  Validation_Field_StringLength = "Validation_Field_StringLength",
  Validation_ImageUploadLimit = "Validation_ImageUploadLimit",
  User_Deactivate_DeactivateMyAccount = "User_Deactivate_DeactivateMyAccount",
  User_Deactivate_Skip = "User_Deactivate_Skip",
  User_Deactivate_Title_AreYouSure = "User_Deactivate_Title_AreYouSure",
  User_NotificationSettings_Label_FollowingBirthday = "User_NotificationSettings_Label_FollowingBirthday",
  User_NotificationSettings_Label_OrderShipped = "User_NotificationSettings_Label_OrderShipped",
  User_NotificationSettings_Label_WishlistOnStock = "User_NotificationSettings_Label_WishlistOnStock",
  User_Shipping_Label_Addresses = "User_Shipping_Label_Addresses",
  User_Shipping_Label_ShippingInfo = "User_Shipping_Label_ShippingInfo",
  User_Shipping_ManageShippingAddress_Label_AddAddress = "User_Shipping_ManageShippingAddress_Label_AddAddress",
  User_Shipping_ManageShippingAddress_Label_AddBillingAddress = "User_Shipping_ManageShippingAddress_Label_AddBillingAddress",
  User_Shipping_ManageShippingAddress_Label_AddNewAddress = "User_Shipping_ManageShippingAddress_Label_AddNewAddress",
  User_Shipping_ManageShippingAddress_Label_AddNewBillingAddress = "User_Shipping_ManageShippingAddress_Label_AddNewBillingAddress",
  User_Shipping_ManageShippingAddress_Label_ChangeAddress = "User_Shipping_ManageShippingAddress_Label_ChangeAddress",
  User_Shipping_ManageShippingAddress_Label_ChangeBillingAddress = "User_Shipping_ManageShippingAddress_Label_ChangeBillingAddress",
  User_Shipping_ManageShippingAddress_Label_ChooseAddress = "User_Shipping_ManageShippingAddress_Label_ChooseAddress",
  User_Shipping_ManageShippingAddress_Label_ChooseBillingAddress = "User_Shipping_ManageShippingAddress_Label_ChooseBillingAddress",
  User_Shipping_ManageShippingAddress_Label_AddressLine1 = "User_Shipping_ManageShippingAddress_Label_AddressLine1",
  User_Shipping_ManageShippingAddress_Label_AddressLine2 = "User_Shipping_ManageShippingAddress_Label_AddressLine2",
  User_Shipping_ManageShippingAddress_Label_City = "User_Shipping_ManageShippingAddress_Label_City",
  User_Shipping_ManageShippingAddress_Label_Country = "User_Shipping_ManageShippingAddress_Label_Country",
  User_Shipping_ManageShippingAddress_Label_EditAddress = "User_Shipping_ManageShippingAddress_Label_EditAddress",
  User_Shipping_ManageShippingAddress_Label_EditBillingAddress = "User_Shipping_ManageShippingAddress_Label_EditBillingAddress",
  User_Shipping_ManageShippingAddress_Label_Name = "User_Shipping_ManageShippingAddress_Label_Name",
  User_Shipping_ManageShippingAddress_Label_Note = "User_Shipping_ManageShippingAddress_Label_Note",
  User_Shipping_ManageShippingAddress_Label_PhoneNumber = "User_Shipping_ManageShippingAddress_Label_PhoneNumber",
  User_Shipping_ManageShippingAddress_Label_SelectCountry = "User_Shipping_ManageShippingAddress_Label_SelectCountry",
  User_Shipping_ManageShippingAddress_Label_State = "User_Shipping_ManageShippingAddress_Label_State",
  User_Shipping_ManageShippingAddress_Label_ZIPCode = "User_Shipping_ManageShippingAddress_Label_ZIPCode",
  User_Shipping_ManageShippingAddress_Label_SaveAddress = "User_Shipping_ManageShippingAddress_Label_SaveAddress",
  User_Shipping_ManageShippingAddress_Label_SaveBillingAddress = "User_Shipping_ManageShippingAddress_Label_SaveBillingAddress",
  User_Shipping_ShippingAddresses_Button_SetDefaultAddress = "User_Shipping_ShippingAddresses_Button_SetDefaultAddress",
  User_Shipping_ShippingAddresses_Label_DefaultAddress = "User_Shipping_ShippingAddresses_Label_DefaultAddress",
  User_Shipping_ShippingAddresses_AboutDelete = "User_Shipping_ShippingAddresses_AboutDelete",
  User_Shipping_ShippingAddresses_AlertDelete = "User_Shipping_ShippingAddresses_AlertDelete",
  User_Shipping_ShippingAddresses_Message_NoShippingAddresses = "User_Shipping_ShippingAddresses_Message_NoShippingAddresses",
  User_UserProfile_AboutYou = "User_UserProfile_AboutYou",
  User_UserProfile_AddBio = "User_UserProfile_AddBio",
  User_UserProfile_AddSocials = "User_UserProfile_AddSocials",
  User_UserProfile_ChangePassword = "User_UserProfile_ChangePassword",
  User_UserProfile_Change_Visibility = "User_UserProfile_Change_Visibility",
  User_UserProfile_Desc_About = "User_UserProfile_Desc_About",
  User_UserProfile_Desc_Personal = "User_UserProfile_Desc_Personal",
  User_UserProfile_Desc_Personal2 = "User_UserProfile_Desc_Personal2",
  User_UserProfile_Preview_PrivateText = "User_UserProfile_Preview_PrivateText",
  User_UserProfile_Preview_PublicText = "User_UserProfile_Preview_PublicText",
  User_UserProfile_Desc_Privacy = "User_UserProfile_Desc_Privacy",
  User_UserProfile_Desc_PrivacyLegend = "User_UserProfile_Desc_PrivacyLegend",
  User_UserProfile_EditProfile = "User_UserProfile_EditProfile",
  User_UserProfile_Greetings = "User_UserProfile_Greetings",
  User_UserProfile_Label_About = "User_UserProfile_Label_About",
  User_UserProfile_Label_BirthDate = "User_UserProfile_Label_BirthDate",
  User_UserProfile_Label_Birthday = "User_UserProfile_Label_Birthday",
  User_UserProfile_Label_CoverImage = "User_UserProfile_Label_CoverImage",
  User_UserProfile_Label_Email = "User_UserProfile_Label_Email",
  User_UserProfile_Label_Facebook = "User_UserProfile_Label_Facebook",
  User_UserProfile_Label_FamilyName = "User_UserProfile_Label_FamilyName",
  User_UserProfile_Label_FullName = "User_UserProfile_Label_FullName",
  User_UserProfile_Label_Gender = "User_UserProfile_Label_Gender",
  User_UserProfile_Label_Gender_Female = "User_UserProfile_Label_Gender_Female",
  User_UserProfile_Label_Gender_Male = "User_UserProfile_Label_Gender_Male",
  User_UserProfile_Label_Gender_Unspecified = "User_UserProfile_Label_Gender_Unspecified",
  User_UserProfile_Label_GivenName = "User_UserProfile_Label_GivenName",
  User_UserProfile_Label_Instagram = "User_UserProfile_Label_Instagram",
  User_UserProfile_Label_IsActive = "User_UserProfile_Label_IsActive",
  User_UserProfile_Label_LinkedIn = "User_UserProfile_Label_LinkedIn",
  User_UserProfile_Label_Location = "User_UserProfile_Label_Location",
  User_UserProfile_Label_Password = "User_UserProfile_Label_Password",
  User_UserProfile_Label_Pinterest = "User_UserProfile_Label_Pinterest",
  User_UserProfile_Label_ShortDescription = "User_UserProfile_Label_ShortDescription",
  User_UserProfile_Label_Twitter = "User_UserProfile_Label_Twitter",
  User_UserProfile_Label_Tiktok = "User_UserProfile_Label_Tiktok",
  User_UserProfile_Label_Username = "User_UserProfile_Label_Username",
  User_UserProfile_Label_Website = "User_UserProfile_Label_Website",
  User_UserProfile_Label_Youtube = "User_UserProfile_Label_Youtube",
  User_UserProfile_MyAccount = "User_UserProfile_MyAccount",
  User_NotificationSettings_Title = "User_NotificationSettings_Title",
  User_UserProfile_Repeat_Quiz = "User_UserProfile_Repeat_Quiz",
  User_UserProfile_Section_About = "User_UserProfile_Section_About",
  User_UserProfile_Section_AccountInfo = "User_UserProfile_Section_AccountInfo",
  User_UserProfile_Section_Personal = "User_UserProfile_Section_Personal",
  User_UserProfile_Section_Profile = "User_UserProfile_Section_Profile",
  User_UserProfile_Section_Preferences = "User_UserProfile_Section_Preferences",
  User_UserProfile_Section_SocialNetworks = "User_UserProfile_Section_SocialNetworks",
  User_UserProfile_Updated = "User_UserProfile_Updated",
  User_UserProfile_Deleted = "User_UserProfile_Deleted",
  User_UserProfile_PreferencesSaved = "User_UserProfile_PreferencesSaved",
  User_UserProfile_VisibilityMessage_Public = "User_UserProfile_VisibilityMessage_Public",
  User_UserProfile_VisibilityMessage_Private = "User_UserProfile_VisibilityMessage_Private",
  User_Shipping_ShippingAddress_Create = "User_Shipping_ShippingAddress_Create",
  User_Shipping_BillingAddress_Create = "User_Shipping_BillingAddress_Create",
  User_Shipping_ShippingAddress_Updated = "User_Shipping_ShippingAddress_Updated",
  User_Shipping_BillingAddress_Updated = "User_Shipping_BillingAddress_Updated",
  Wishlist_Desc = "Wishlist_Desc",
  Wishlist_Title = "Wishlist_Title",
  DV_Countries = "DV_Countries",
  Common_OneSize = "Common_OneSize",
  Register_Desc_VerifyAccount = "Register_Desc_VerifyAccount",
  Register_Error_InvalidCode = "Register_Error_InvalidCode",
  Register_ValidateButton = "Register_ValidateButton",
  Register_GetCodeButton = "Register_GetCodeButton",
  ForgotPsw_Desc_VerifyAccount = "ForgotPsw_Desc_VerifyAccount",
  ForgotPsw_ChangeButton = "ForgotPsw_ChangeButton",
  ForgotPsw_NewButton = "ForgotPsw_NewButton",
  ForgotPsw_SuccessMessage = "ForgotPsw_SuccessMessage",
  ForgotPsw_ErrorMessage = "ForgotPsw_ErrorMessage",
  ForgotPsw_Desc_ChangePassword = "ForgotPsw_Desc_ChangePassword",
  Login_Label_NewPassword = "Login_Label_NewPassword",
  Register_Label_ValidationCode = "Register_Label_ValidationCode",
  VerifyAccount_Desc = "VerifyAccount_Desc",
  Coming_Soon_Title = "Coming_Soon_Title",
  Coming_Soon_Text = "Coming_Soon_Text",
  Error_Email_InUse = "Error_Email_InUse",
  VerifyAccount_ErrorMessage_Wrong = "VerifyAccount_ErrorMessage_Wrong",
  VerifyAccount_ErrorMessage_Expired = "VerifyAccount_ErrorMessage_Expired",
  Community_ComingSoon_Desc1 = "Community_ComingSoon_Desc1",
  Community_ComingSoon_Desc2 = "Community_ComingSoon_Desc2",
  Community_ComingSoon_Desc3 = "Community_ComingSoon_Desc3",
  Community_ComingSoon_Button = "Community_ComingSoon_Button",
  DV_Vibes_1 = "DV_Vibes_1",
  DV_Vibes_2 = "DV_Vibes_2",
  DV_Vibes_3 = "DV_Vibes_3",
  DV_Vibes_4 = "DV_Vibes_4",
  DV_Vibes_5 = "DV_Vibes_5",
  DV_Vibes_6 = "DV_Vibes_6",
  DV_Vibes_7 = "DV_Vibes_7",
  DV_Vibes_8 = "DV_Vibes_8",
  DV_Vibes_9 = "DV_Vibes_9",
  DV_Vibes_10 = "DV_Vibes_10",
  DV_Vibes_11 = "DV_Vibes_11",
  DV_Vibes_12 = "DV_Vibes_12",
  DV_Vibes_13 = "DV_Vibes_13",
  DV_Vibes_14 = "DV_Vibes_14",
  DV_Vibes_15 = "DV_Vibes_15",
  DV_Vibes_16 = "DV_Vibes_16",
  DV_Vibes_17 = "DV_Vibes_17",
  DV_Vibes_18 = "DV_Vibes_18",
  Cookie_Consent_Desc = "Cookie_Consent_Desc",
  Cookie_Consent_Button = "Cookie_Consent_Button",
  Orders_OrderParent_Number = "Orders_OrderParent_Number",
  Orders_OrderShop_Number = "Orders_OrderShop_Number",
  Error_Login = "Error_Login",
  Brands_Title = "Brands_Title",
  Brands_Button = "Brands_Button",
  Brands_BrandsList_Button = "Brands_BrandsList_Button",
}
