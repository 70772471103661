//Define the modal Type

export enum ModalType {
  success = "success",
  error = "error",
  edit = "edit",
  loading = "loading",
  detail = "detail",
  message = "message",
  form = "form",
  uploadImage = "uploadImage",
}

// Define modal state Type
export type ModalStateType = {
  isOpen: boolean;
  type: ModalType;
  title: string;
  message: string;
  child?: React.ReactNode;
  callback?: () => void;
};

export const MODAL = "modal";
export type MODAL = typeof MODAL; //Typescript Line
//this is if u need a saga
export const OPEN_MODAL = "openModal";
export type OPEN_MODAL = typeof OPEN_MODAL; // Typescript Line
export const CLOSE_MODAL = "closeModal";
export type CLOSE_MODAL = typeof CLOSE_MODAL; // Typescript Line
