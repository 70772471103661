/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useContext } from "react";
import { PropsWithChildren } from "react";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import Flex from "../primitives/Flex";
import { DotsLoader } from "./DotsLoader";

interface IContentWithVisibleButton extends PropsWithChildren {
  idComponent: string | number;
  handleToggle?:(params:boolean)=>void
  checked?:boolean
}

interface IFieldData {
  id: number;
  isPublic: boolean;
}

export const ContentWithVisibleButton = ({
  children,
  idComponent,
  handleToggle,
  checked=false
}: IContentWithVisibleButton) => {
  const { addToast } = useContext(AppGeneralContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean>(true);


  //TODO riabilitare in V2
  // const mockApiCall = async (isVisible: boolean) => {
  //   setIsLoading(true);
  //   const response = await visibilityCall({ idComponent, isVisible });
  //   if (response) {
  //     setFieldData((prevFieldData) =>
  //       prevFieldData.map((field) =>
  //         field.id === idComponent
  //           ? { ...field, isPublic: !field.isPublic }
  //           : field
  //       )
  //     );
  //     setIsVisible((prevState) => !prevState);
  //     setIsLoading(false);
  //   } else {
  //     addToast(toastType.error, "Something went wrong!");
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   console.log(fieldData)
  // })

  return (
    <>
      {children && (
        <Flex
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {isLoading ? (
            <DotsLoader />
          ) : (
            <Flex opacity={isVisible ? 1 : 0.5}>{children}</Flex>
          )}

          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            height={"36px"}
            $aspectRatio="1/1"
          >
            {/* TODO: riattivare quando sarà implementata la funzionalità per cambiare la visibilità dei campi */}
            {/* <VisibleButton handleVisible={handleToggle} isVisible={checked} /> */}
          </Flex>
        </Flex>
      )}
    </>
  );
};
