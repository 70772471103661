import styled from "styled-components";
import {
  GridProps,
  HeightProps,
  SpaceProps,
  WidthProps,
  height,
} from "styled-system";
import Flex from "../primitives/Flex";
import { capitalize } from "../../utils";
import Txt from "../primitives/Txt";
import React from "react";

export interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
  spaceProp: WidthProps& HeightProps& SpaceProps& GridProps
  error?: string;
}

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })<any>`
  position: absolute;
  display: none;
`;

export const StyledCheckbox = styled.label<HeightProps & { checked: boolean }>`
  aspect-ratio: 1/1;
  border-color: ${({ theme }) => theme.colors.grayBase};
  border-style: solid;
  border-width: ${({ checked }) => (checked ? "1px" : "0px")};
  background-color: ${(props) =>
    props.checked ? props.theme.colors.primaryBase : props.theme.colors.grayBase};
  cursor: pointer;
  ${height}
`;

const Checkbox =React.forwardRef<HTMLInputElement,CheckboxProps>( ({ checked, children, id, error, spaceProp, ...rest},ref) => {
 return( <Flex
    as="ul"
    flexDirection={"column"}
    style={{  gridColumn: spaceProp.gridColumn ? spaceProp.gridColumn as string: "auto" }}
    {...spaceProp}
  >
    <Flex as="li" alignItems={"center"} $gap={0.6}>
      <HiddenCheckbox id={id} type="checkbox" checked={checked} {...rest} />
      <StyledCheckbox height={spaceProp.height || 14} htmlFor={id} checked={checked ?? false} />
      {children}
    </Flex>
    {error && (
      <Txt
        as="li"
        padding={"13px  0 0 18px"}
        variant="error"
        fontSize={[3]}
        textAlign={"left"}
      >
        {capitalize(error)}
      </Txt>
    )}
  </Flex>
)}
);

export default Checkbox;
