import { useCallback, useEffect, useMemo, useState } from "react";
import { SpaceProps, WidthProps } from "styled-system";
import Flex from "../primitives/Flex";
import Input from "../primitives/Input";
import Label from "../primitives/Label";
import Txt from "../primitives/Txt";
import Box from "../primitives/Box";
import CalendarSvg from "../atoms/CalendarSvg";
import ViewHideButton from "./ViewHideButton";
import Calendar, {formatDate} from "./Calendar";

interface InputFieldProps extends SpaceProps, WidthProps {
  id: string;
  error?: string;
  isPrivate?: boolean;
  placeholder?: string;
  value: Date | null;
  onChange: (value?:Date) => void;
  onBlur:()=>void
}
export type TypeCalendar = "default" | "date" | "month" | "year";

function CalendarField({ id, error, placeholder, value, isPrivate, onChange,onBlur, ...props }: InputFieldProps) {
  const [show, setShow] = useState<TypeCalendar>("default");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [privateType, setPrivateType] = useState<boolean | undefined>(isPrivate);
  const initialDate = useMemo(() => value ? new Date(value) : null, [value])

  useEffect(() => {
    setPrivateType(isPrivate);
  }, [isPrivate]);


  const handlePrivateType = () => setPrivateType(!privateType);

  const handleShow = useCallback(
    (initialDate: TypeCalendar) => {
      setShow(initialDate);
    },
    [show, setShow]
  );

  const handleFocused = useCallback(
    (initialDate: boolean) => {
      setIsFocused(initialDate);
      handleShow("date");
    },
    [isFocused]
  );

  const handleBlured = () => {setIsFocused(false); onBlur()}

  return (
    <Flex flexDirection={"column"} alignItems={"start"} minWidth={320} {...props}>
      <Box position={"relative"} width={[1]} marginTop={"20px"}>
        <Label
          fontSize={[2]}
          paddingLeft={["18px"]}
          htmlFor={id}
          position={"absolute"}
          top={isFocused || initialDate || show !== "default" ? "-25px" : "6px"}
          $transition={"top 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)"}
          style={{ display: "flex", gap: "0.5em", width: "max-content" }}
        >
          <CalendarSvg width={isFocused || initialDate ? [16] : [20]} variant="contrast" />
          {placeholder}
        </Label>

        <Input
          id={id}
          value={ initialDate?.toLocaleDateString("it-IT", { 
           day: "2-digit",
          month: "2-digit",
          year: "numeric",})
         }
          width={[1]}
          fontSize={[3]}
          variant="primary"
          padding={["0.48em 50px"]}
          color={privateType && initialDate ? "transparent" : "inherit"}
          private={privateType ? "date" : undefined}
          placeholder={""}
          readOnly
          onFocus={() => handleFocused(true)}
          onBlur={handleBlured}
        />
        {privateType && initialDate && (
          <Txt color={"#FFFFFF99"} as={"label"} htmlFor={id} fontSize={[3]} padding={["0.48em 50px"]} position={"absolute"} left={0}>
            { formatDate(initialDate)}
          </Txt>
        )}
        {initialDate && <CalendarSvg position={"absolute"} top={"0.48em"} left={3} width={[20]} height={[20]} variant="contrast" />}
        {isPrivate !== undefined && <ViewHideButton position={"absolute"} top={0} right={0} variant={privateType ? "hidden" : "see"} handleClick={handlePrivateType} />}
        {show !== "default" && <Calendar show={show} value={initialDate ? initialDate : null} handleShow={handleShow} onChange={onChange} />}
      </Box>
    </Flex>
  );
}

export default CalendarField;
