import { PayloadAction } from "@reduxjs/toolkit";
import { ApiSimpleResponse, PaginatedResponse } from "api/types/responses";
import {
  CommunitySearchRequest,
  CommunityUser,
  GET_COMMUNITY_USER_LIST,
} from "./CommunityTypes";
import { toast } from "react-toastify";
import { put, takeLatest } from "redux-saga/effects";
import { getCommunityUserListCall } from "api/userCalls/userCalls";
import { communityActions } from "./communitySlice";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";
import { toastErrorStyle } from "utils/const/const";

function* getCommunityUserListSaga({
  payload,
}: PayloadAction<CommunitySearchRequest>) {
  if (payload.isFiltering) {
    yield put(communityActions.communityResetData());
  }
  const res: ApiSimpleResponse<PaginatedResponse<CommunityUser>> =
    yield getCommunityUserListCall(payload);
  if (!res.isSuccess || !res.response) {
    yield put(
      communityActions.getCommunityUserListError(
        res.error ?? t(i18Enum.ErrorPage500_ErrorLabel)
      )
    );
    toast.error(
      res.error ?? t(i18Enum.ErrorPage500_ErrorLabel),
      toastErrorStyle
    );
    return;
  }
  yield put(communityActions.getCommunityUserListSuccess(res.response));
}

export function* watchCommunity() {
  yield takeLatest(GET_COMMUNITY_USER_LIST, getCommunityUserListSaga);
}
