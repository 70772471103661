import Stepper from 'components/organisms/Stepper'
import Flex from 'components/primitives/Flex'
// import { CustomThemeContext } from 'context/ThemeContext'
// import  { useContext } from 'react'

export const ProfilingPage = () => {
    // const { customTheme } =  useContext(CustomThemeContext)
  return (
    <Flex marginTop={["80px", "0"]}>
         <Stepper/>
    </Flex>
  )
}
