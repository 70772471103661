import { BasketShop } from "redux/features/cart/CartType";
import { ProductOptionType } from "redux/features/product/ProductsTypes";

export const filterItems = (
  shoppingBasketShops: BasketShop[] | undefined,
  newQuantity?: number,
  isQuantityIncreased?: boolean
) => {
  const items = shoppingBasketShops
    ?.map((shop) =>
      shop?.items?.map((product) => {
        const updatedQuantity =
          isQuantityIncreased === undefined
            ? product.quantity
            : isQuantityIncreased
            ? newQuantity
            : 1;
        return {
          item_brand: shop.name,
          item_id: product.productVariantId,
          item_name: product.productVariant?.product?.name,
          price:
            product.productVariant?.discountedPrice ??
            product.productVariant?.price,
          quantity: updatedQuantity,
        };
      })
    )
    .flat();

  return items;
};

export const reorderSizes = (options: ProductOptionType[]) => {
  const isNumeric = (label: string) => !isNaN(Number(label));

  const numericSizes: ProductOptionType[] = [];
  const standardSizes: ProductOptionType[] = [];

  options.forEach((option) => {
    if (isNumeric(option.label)) {
      numericSizes.push(option);
    } else {
      standardSizes.push(option);
    }
  });
  numericSizes.sort((a, b) => parseInt(a.label) - parseInt(b.label));
  standardSizes.sort((a, b) => a.index! - b.index!);

  const sortedLabels = [...standardSizes, ...numericSizes];

  return sortedLabels;
};
