interface IDashedRoundSvg {
  callback: () => void;
}

export const DashedRoundSvg = ({ callback }: IDashedRoundSvg) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 168 168' width='100%' height='100%' cursor="pointer" onClick={() => callback()}>
      <path fill='none' stroke='#00FF00' strokeDasharray='0 0 16 16' d='m84,.5h0c46.12,0,83.5,37.38,83.5,83.5h0c0,46.12-37.38,83.5-83.5,83.5h0C37.88,167.5.5,130.12.5,84h0C.5,37.88,37.88.5,84,.5Z' />
      <path fill='none' stroke='#00FF00' d='m70.22,84.64h28.5m-14.5-16v31' />
    </svg>
  );
};
