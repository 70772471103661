import  styled from 'styled-components' 
import {
 gridTemplateColumns,
 GridTemplateColumnsProps
} from "styled-system";


 const Row = styled.div<GridTemplateColumnsProps>`
    display: grid;
    grid-template-columns:1.5fr 1.5fr 0.3fr 2fr 0.3fr;
    
  & div:first-child {
    padding-left: 0;
  }
  & div:last-child {
    padding-right: 0;
  }
  ${gridTemplateColumns}
`

export default Row