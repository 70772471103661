import styled from "styled-components";
import {
  HeightProps,
  SpaceProps,
  TypographyProps,
  WidthProps,
  height,
  space,
  width,
} from "styled-system";
import Box from "../primitives/Box";
import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";

import { useContext } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import { AppGeneralContext } from "../../context/AppGeneralContext";
import Button from "../primitives/Button";
import { PAGE } from "utils/const/pagePath";
import { useNavigate } from "react-router-dom";
import { truncateString, useScrollBlock } from "utils";

interface ShopDetailProductProps {
  id?: string;
  img?: string;
  title?: string;
  username?: string;
  subtitle?: string;
  description?: string;
  textButton?: string;
}

function ShopDetailProduct({
  id,
  img,
  title,
  subtitle,
  description,
  textButton,
  username,
}: ShopDetailProductProps) {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;
  const { isMobile } = useContext(AppGeneralContext);
  const navigate = useNavigate();
  const [blockScroll, allowScroll] = useScrollBlock();

  return (
    <Box
      as="article"
      width={["100vw", "100%"]}
      height={["max-content"]}
      margin={["117px 0 24px", "0px"]}
      position={"relative"}
    >
      {isMobile ? (
        <CardBack height={["300px"]} width={[1]} img={img ?? ""} />
      ) : (
        <CardBackDesktop height={"330px"} img={img ?? ""} />
      )}
      <Flex
        as="ul"
        position={"absolute"}
        top={0}
        bottom={0}
        padding={["0 12% 0 9%", "5% 5%"]}
        flexDirection={"column"}
        justifyContent={["space-evenly", "end"]}
      >
        <Flex as="li" flexDirection={"column"}>
          <Txt
            as="h3"
            variant="medium"
            fontSize={["20px", "16px"]}
            color={colors.thirdText}
            textAlign={"left"}
          >
            {title}
          </Txt>
          <Txt
            as="h4"
            variant="light"
            fontSize={[3, 2]}
            color={colors.thirdText}
            textAlign={"left"}
          >
            {subtitle}
          </Txt>
          <Txt
            as="p"
            variant="light"
            fontSize={[3, 2]}
            color={colors.thirdText}
            textAlign={"left"}
            $textTransform="inherit"
            marginBottom={0}
          >
            {isMobile
              ? truncateString(description, 200)
              : truncateString(description, 200)}
          </Txt>
        </Flex>
        <Flex as="li" justifyContent={"right"}>
          <Button
            type="button"
            variant="secondary"
            padding={["12px 20px 9px"]}
            marginTop={[0, 2]}
            fontSize={[4, 2]}
            fontFamily={"BauPro"}
            // fontWeight={500}
            width={"max-content"}
            onClick={() => {
              navigate(`${PAGE.brand.menuPath}/${username}`, {
                state: { id: id },
              });
              allowScroll();
            }}
          >
            {textButton}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}

export const CardBackDesktop = styled.i<
  WidthProps & HeightProps & TypographyProps & SpaceProps & { img: string }
>`
  width: 100%;
  display: block;
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  height: 100%;

  cursor: pointer;
  ${height}
  ${width};
  ${space};

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100.2%;
    margin-bottom: 1px;
    background: linear-gradient(
      180deg,
      rgba(32, 32, 32, 0) 0%,
      rgba(32, 32, 32, 0.71) 39.58%,
      #202020 100%
    );
  }
`;
export const CardBack = styled.i<
  WidthProps & HeightProps & TypographyProps & SpaceProps & { img: string }
>`
  width: 100%;
  display: block;
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  transform: skewY(5.5deg);
  z-index: 1;
  cursor: pointer;
  ${height}
  ${width};
  ${space};

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100.2%;
    margin-bottom: 1px;
    background: linear-gradient(
      180deg,
      rgba(32, 32, 32, 0) 0%,
      rgba(32, 32, 32, 0.71) 39.58%,
      #202020 100%
    );
  }
`;
export default ShopDetailProduct;
