import styled from "styled-components";
import {
  HeightProps,
  WidthProps,
  space,
  SpaceProps,
  PositionProps,
  position,
  height,
  width,
  opacity,
  OpacityProps,
} from "styled-system";

interface SVGProps
  extends WidthProps,
    HeightProps,
    SpaceProps,
    PositionProps,
    OpacityProps {
  $transform?: string;
  $transition?: string;
  disabled?: boolean;
  widthCustom?: "s" | "m";
  fill?: string;
  handleClick?: () => void;
}

const StyledSVG = styled.svg<SVGProps>`
  width: ${(props) => props?.width || "50px"};
  cursor: pointer;
  transform: ${({ $transform }) => $transform || "none"};
  transition: ${({ $transition }) => $transition || "none"};
  cursor: pointer;
  ${space};
  ${position};
  ${height};
  ${width};
  ${opacity}
`;

const SVGPath = styled.path<SVGProps>`
  fill: ${(props) => props.fill || props.theme.colors.primaryBase};
`;

function ArrowSvg({ ...props }: SVGProps) {
  return (
    <StyledSVG
      width={"35px"}
      onClick={
        props.disabled
          ? () => {}
          : () => {
              props.handleClick?.();
            }
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 24"
      opacity={props.disabled ? 0 : 1}
      {...props}
    >
      <SVGPath
        {...props}
        d="M 0 12 L 20 23.547 V 0.453 L 0 12 Z M 62 10 L 18 10 V 14 L 62 14 V 10 Z"
      />
    </StyledSVG>
  );
}

export default ArrowSvg;
