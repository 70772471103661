import { useContext, useMemo, useState } from "react";
import { ModalTypeEnum, capitalize } from "utils";
import { Step } from "components/organisms";
import { useAppDispatch, useAppSelector } from "redux/app/hooks";
import StepLoginFirstForm, {
  FormStepLoginFirst,
} from "components/organisms/StepLoginFirstForm";

import { Avatar } from "components/atoms/Avatar";
import { VisibleButton } from "components/atoms/VisibleButton";
import StepperHeader from "components/molecules/StepperHeader";
import UploadImage from "components/molecules/UploadImage";
import Box from "components/primitives/Box";
import Button from "components/primitives/Button";
import Flex from "components/primitives/Flex";
import Toggle from "components/primitives/Toggle";
import Txt from "components/primitives/Txt";
import { AppGeneralContext } from "context/AppGeneralContext";
import { UserPreference } from "redux/features/user/UserTypes";
import { putUserPreferenceAction } from "redux/features/user/userSlice";
import { FileUploadDTO } from "utils/generalTypes/shared";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import { ModalContext } from "context/ModalContext";

interface StepperLoginFirsPageProps {
  handleCurrentStep: (step: Step) => void;
}

export function StepperLoginFirsPage({
  handleCurrentStep,
}: StepperLoginFirsPageProps) {
  const dispatch = useAppDispatch();
  const { openModalWithChildren, closeModal } = useContext(ModalContext);
  const [choosedImage, setChoosedImage] = useState<string | undefined>(
    undefined
  );
  const [imgVisible, setImageVisible] = useState<boolean>(false);
  const { isMobile, openAsideMenu, setAsideIsOpen, closeAndResetAside } =
    useContext(AppGeneralContext);
  const { t } = useTranslation();
  const paragraphOne = t(i18Enum.User_UserProfile_Desc_About);
  const paragraphTwo = t(i18Enum.User_UserProfile_Desc_Privacy);
  const legendIsPrivate = t(i18Enum.User_UserProfile_Desc_PrivacyLegend);
  const [avatar, setAvatar] = useState<FileUploadDTO | undefined>(undefined);
  const {
    isLoading: isLoadingUser,
    data: user,
    errors,
  } = useAppSelector((state) => state.user.user);
  const { data: userPreferences } = useAppSelector(
    (state) => state.user.userPreference
  );
  const isPrivate = useMemo(
    () => userPreferences?.isPrivate,
    [userPreferences?.isPrivate]
  );

  const handleIsPrivate = (value: boolean) => {
    dispatch(putUserPreferenceAction({ isPrivate: value }));
  };

  const handleImgVisible = () => {
    setImageVisible(!imgVisible);
  };
  const handleChooseImg = () => {
    if (isMobile) {
      openModalWithChildren(
        <UploadImage
          choosedImage={setChoosedImage}
          chooseItAction={setAvatar}
          onClose={() => closeModal()}
        />,
        ModalTypeEnum.uploadImage
      );
    } else {
      openAsideMenu<undefined>(
        <UploadImage
          choosedImage={setChoosedImage}
          chooseItAction={setAvatar}
          onClose={() => {
            setAsideIsOpen(false);
            closeAndResetAside();
          }}
        />,
        undefined
      );
    }
  };

  const onSubmit = async (
    value: FormStepLoginFirst,
    avatar?: FileUploadDTO
  ) => {
    const req: Partial<UserPreference> = {
      //TODO: add back avatar upload
      // Picture: avatar,
      // PictureUrl: avatar?.Base64 ? undefined : userPreferences?.PictureUrl,
      firstName: value.firstName,
      lastName: value.lastName,
      birthdate: value.birthdate,
      gender: value.gender,
    };
    dispatch(putUserPreferenceAction(req));
    handleCurrentStep(Step.second);
  };

  return (
    <>
      <Flex
        padding={"28px 5%"}
        flexDirection={"column"}
        alignItems={"center"}
        $gap={1.5}
        width={[1, 1, "900px"]}
      >
        <StepperHeader title="about you" />

        <Txt
          as="p"
          width={[1]}
          variant="light"
          $textTransform="inherit"
          textAlign={"left"}
          fontSize={[2, 3, 4]}
        >
          <Txt as={"span"} $textTransform="capitalize" marginRight="0.5em">
            {t(i18Enum.User_UserProfile_Greetings)}
          </Txt>
          {capitalize(paragraphOne)}
        </Txt>

        <Txt
          as="p"
          width={[1]}
          marginTop={0}
          variant="light"
          $textTransform="inherit"
          textAlign={"left"}
          fontSize={[2, 3, 4]}
        >
          {capitalize(paragraphTwo)}
        </Txt>

        <Flex alignItems={"center"} width={[1]}>
          <Toggle checked={isPrivate} handleChange={handleIsPrivate} />

          <Txt
            fontSize={[3]}
            lineHeight={"12px"}
            marginLeft={[3]}
            variant="light"
          >
            {isPrivate ? t(i18Enum.Common_Public) : t(i18Enum.Common_Private)}
          </Txt>
        </Flex>

        <Txt
          width={[1]}
          variant="light"
          $textTransform="inherit"
          textAlign={"left"}
          opacity={!isPrivate ? 0 : 1}
          maxHeight={!isPrivate ? 10 : 100}
          fontSize={[2, 3, 4]}
          style={{ transition: "all 0.5s ease-out" }}
        >
          {capitalize(legendIsPrivate)}
        </Txt>

        <Flex
          width={[1, 1, "500px"]}
          justifyContent={"center"}
          maxWidth={"500px"}
        >
          <Flex
            position={"relative"}
            borderRadius={"100%"}
            width={[0.5]}
            $aspectRatio="1/1"
            justifyContent={"center"}
          >
            <Avatar imageUrl={choosedImage ? choosedImage : user?.PictureUrl} />

            <Box position={"absolute"} bottom={0} width={[1]}>
              <Button
                variant="login"
                width={[1]}
                border={`2px solid ${"#000"}`}
                borderRadius={"50px"}
                onClick={handleChooseImg}
              >
                {t(i18Enum.CropImageModal_Button_UploadPhoto)}
              </Button>
            </Box>

            {isPrivate && (
              <Flex
                position={"absolute"}
                justifyContent={"center"}
                alignItems={"center"}
                right={0}
                width={"30%"}
                $aspectRatio="1/1"
              >
                <VisibleButton
                  handleVisible={() => handleImgVisible()}
                  isVisible={imgVisible}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <StepLoginFirstForm
        data={userPreferences as FormStepLoginFirst}
        isPrivate={isPrivate ? true : undefined}
        onSubmit={(value) => onSubmit(value, avatar)}
      />
    </>
  );
}
