import React, { useEffect, useRef, useState } from "react";
import { BorderRadiusProps, FontSizeProps, SpaceProps, WidthProps } from "styled-system";
import { capitalize, privateCharacters } from "../../utils";
import Flex from "../primitives/Flex";
import Textarea from "../primitives/Textarea";
import Label from "../primitives/Label";
import Txt from "../primitives/Txt";
import Box from "../primitives/Box";
import ViewHideButton from "./ViewHideButton";
import { variants } from "../primitives/Textarea";
import useAutosizeTextArea from "utils/hooks/useAutosizeTextArea";

interface TextareaFieldProps extends React.ComponentPropsWithoutRef<"textarea"> {
  id: string;
  label?: string;
  error?: string;
  variant: variants;
  spaceProp?: SpaceProps & WidthProps & BorderRadiusProps & FontSizeProps;
  isFocused?: boolean;
  isPrivate?: boolean;
}

const TextareaField = React.forwardRef<HTMLInputElement, TextareaFieldProps>(({ variant, isPrivate, isFocused, id, error, spaceProp, ...props }, ref) => {
  // const inputRef = ref;
  const [privateType, setPrivateType] = useState<boolean | undefined>(isPrivate);
  const handlePrivateType = () => setPrivateType(!privateType);
  const [focus, setFocus] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, props.value);

  useEffect(() => {
    setPrivateType(isPrivate);
  }, [isPrivate]);

  return (
    <Flex flexDirection={"column"} alignItems={"start"} {...spaceProp}>
      <Box position={"relative"} width={[1]}>
        <Label
          fontSize={focus || props.value ? 2 : 3}
          paddingLeft={["18px"]}
          htmlFor={id}
          position={"absolute"}
          top={focus || props.value ? "-23px" : "6px"}
          $transition={"all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1)"}
        >
          {props.placeholder}
        </Label>
        <Textarea
          {...props}
          ref={textAreaRef}
          rows={1}
          value={props.value}
          id='textarea'
          onFocus={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
            setFocus(true);
            props.onFocus?.(e);
          }}
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
            setFocus(false);
            props.onBlur?.(e);
          }}
          variant={variant}
          color={privateType && props.value ? "transparent" : ""}
          width={[1]}
          fontSize={[3]}
          padding={["16px"]}
          style={{ textTransform: "inherit" }}
          placeholder={""}
          autoComplete='off'
        />
        {privateType && props.value && (
          <Txt color={"#FFFFFF99"} as={"label"} htmlFor={id} fontSize={[3]} padding={["0.48em 20px"]} position={"absolute"} left={0} top={"0.3em"}>
            {props.value && privateCharacters(props.value as string)}
          </Txt>
        )}
        {privateType !== undefined && <ViewHideButton position={"absolute"} height={"30%"} top={0} right={0} variant={privateType ? "hidden" : "see"} handleClick={handlePrivateType} />}
      </Box>

      {error && !isFocused && (
        <Txt padding={"13px  0 0 18px"} variant='error' fontSize={[3]} textAlign={"left"}>
          {capitalize(error)}
        </Txt>
      )}
    </Flex>
  );
});

export default TextareaField;
