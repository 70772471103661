import { PayloadAction } from "@reduxjs/toolkit";
import {
  ApiSimpleResponse,
  BaseResponse,
  PaginatedResponse,
} from "api/types/responses";
import { BrandType, GET_BRANDS_LIST, GET_BRAND_BY_ID } from "./BrandTypes";
import {
  getBrandByShopIdCall,
  getBrandsListCall,
} from "api/brandCalls/brandCall";
import {
  getBrandByIdSuccessAction,
  manageBrandErrorAction,
  getBrandsListErrorAction,
  getBrandsListSuccessAction,
} from "./brandSlice";
import { put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { t } from "i18next";
import { i18Enum } from "i18n/types/translationType";
import { toastErrorStyle } from "utils/const/const";
import { BrandsListRequest } from "api/types/requests/brandRequest";
import { GetShopByUsernameQuery } from "utils/graphql/generated/graphql";

function* getBrandByIdSaga({ payload }: PayloadAction<string>) {
  const brandCallResponse: ApiSimpleResponse<
    BaseResponse<GetShopByUsernameQuery>
  > = yield getBrandByShopIdCall(payload);
  if (!brandCallResponse.isSuccess || !brandCallResponse.response?.shop) {
    toast.error(
      brandCallResponse.error ?? t(i18Enum.ErrorPage500_ErrorLabel),
      toastErrorStyle
    );
    yield put(manageBrandErrorAction(brandCallResponse.error ?? ""));
    return;
  }
  yield put(getBrandByIdSuccessAction(brandCallResponse.response.shop));
  // yield put(getProductListByBrandIdAction({ id: brandCallResponse.response.Id, params }))
}

function* getBrandsListSaga({ payload }: PayloadAction<BrandsListRequest>) {
  //TODO: manage pagination passing payload
  const res: ApiSimpleResponse<PaginatedResponse<BrandType>> =
    yield getBrandsListCall();
  if (!res.isSuccess || !res.response) {
    yield put(getBrandsListErrorAction(res.error ?? ""));
    return;
  }
  yield put(getBrandsListSuccessAction(res.response));
}

export function* watchGetBrand() {
  yield takeLatest(GET_BRAND_BY_ID, getBrandByIdSaga);
  yield takeLatest(GET_BRANDS_LIST, getBrandsListSaga);
}
