import Flex from "../primitives/Flex";
import Txt from "../primitives/Txt";
import { useContext } from "react";
import { CustomThemeContext } from "../../context/ThemeContext";
import Toggle from "../primitives/Toggle";

interface IToggleWithLabels {
  firstLabel?: string;
  firstIcon?: JSX.Element;
  secondLabel?: string;
  secondIcon?: JSX.Element;
  checked?: boolean;
  handleCheck?: (checked: boolean) => void;
  handleClick?: () => void;
}

export const ToggleWithLabels = ({
  firstLabel,
  firstIcon,
  secondLabel,
  secondIcon,
  handleCheck,
  handleClick,
  checked,
}: IToggleWithLabels) => {
  const { customTheme } = useContext(CustomThemeContext);
  const colors = customTheme.colors;

  return (
    <Flex justifyContent={"center"} $gap={1}>
      <Flex alignItems={"center"} $gap={1}>
        <Txt as={"h4"} variant="light" color={colors.thirdText}>
          {firstLabel}
        </Txt>
        {firstIcon ? firstIcon : undefined}
      </Flex>
      {handleClick ? (
        <Flex onClick={handleClick}>
          <Toggle checked={checked} handleChange={handleCheck} />
        </Flex>
      ) : (
        <Toggle checked={checked} handleChange={handleCheck} />
      )}
      <Flex alignItems={"center"} $gap={1}>
        {secondIcon ? secondIcon : undefined}
        <Txt as={"h4"} variant="light" color={colors.thirdText}>
          {secondLabel}
        </Txt>
      </Flex>
    </Flex>
  );
};
