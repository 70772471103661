import styled from "styled-components";
import {
  ColorProps,
  flexbox,
  LayoutProps,
  SpaceProps,
  layout,
  space,
  FlexboxProps,
  position,
  PositionProps,
  border,
  BorderProps,
  color
} from "styled-system";

const Flex = styled.section<
  ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    PositionProps &
    BorderProps & { $gap?: number } & { $aspectRatio?: string } & {
      $transition?: string;
    }
>`
  display: flex;
  aspect-ratio: ${(props) => props.$aspectRatio ?? undefined};
  gap: ${(props) => props.$gap}em;
  transition: ${(props) => props.$transition ?? undefined};

  ${space}
  ${layout}
    ${flexbox}
    ${position}
    ${border}
    ${color}
`;
export default Flex;
