import { useEffect } from "react";
import { SpaceProps, WidthProps } from "styled-system";

import Flex from "../primitives/Flex";
import SelectField from "components/molecules/SelectField";
import { OptionNumberType } from "components/primitives/Select";
import CalendarField from "components/molecules/CalendarField";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import { GenderEnum } from "utils";
import Txt from "components/primitives/Txt";
import { useAppSelector } from "redux/app/hooks";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";

export interface FormAccount {
  birthdate?: Date;
  gender?: string | undefined;
}

const initialAccount: FormAccount = {
  birthdate: undefined,
  gender: undefined,
};

interface AccountInfoFormProps extends WidthProps, SpaceProps {
  onSubmit: (value: FormAccount) => void;
  onFieldChange: () => void;
  data?: FormAccount;
  optionGenders: OptionNumberType[];
}

type FormAccountSchemaObject = {
  [key in keyof FormAccount]: Yup.Schema<any>;
};

function AccountInfoForm({
  onSubmit,
  onFieldChange,
  data,
  optionGenders,
  ...props
}: AccountInfoFormProps) {
  const {
    isLoading: isFectchingUser,
    errors,
    data: userData,
  } = useAppSelector((state) => state.user.user);
  const { t } = useTranslation();
  const formAccountSchema = Yup.object().shape<FormAccountSchemaObject>({
    gender: Yup.string(),
    birthdate: Yup.date().nullable(),
  });

  const formik = useFormik<FormAccount>({
    initialValues: data ? data : initialAccount,
    validationSchema: formAccountSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.birthdate && values.gender) {
        onSubmit(values);
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values.birthdate, formik.values.gender]);

  return (
    <Flex
      as="form"
      flex={1}
      width={[1]}
      flexDirection={"column"}
      alignItems={"center"}
      position={"relative"}
      {...props}
    >
      <Flex
        as="fieldset"
        flexDirection={"column"}
        width={[1, 1, "500px"]}
        maxWidth={"500px"}
        padding={0}
        margin={0}
        $gap={0.5}
        border={"none"}
      >
        <InputFieldWardrobe
          name={"Email"}
          id={"Email"}
          type={"email"}
          placeholder={t(i18Enum.User_UserProfile_Label_Email)}
          readOnly
          label={"Email"}
          spaceProp={{ width: [1], marginTop: [4], padding: ["10px 0"] }}
          value={userData?.email}
        />
        <CalendarField
          id="birthdate"
          paddingTop={2}
          paddingBottom={2}
          onBlur={() => formik.setFieldTouched("birthdate", true)}
          placeholder={t(i18Enum.User_UserProfile_Label_BirthDate)}
          width={[1]}
          // value={formik.values?.date ? new Date(formik.values.date) : today}
          value={
            formik.values.birthdate ? new Date(formik.values.birthdate) : null
          }
          onChange={(date?: Date) => {
            formik.setFieldValue("birthdate", date?.toISOString());
            onFieldChange();
          }}
        />
        {formik.errors.birthdate && (
          <Txt
            padding={"13px 0 0 18px"}
            variant="error"
            fontSize={[3]}
            textAlign={"left"}
          >
            {String(formik.errors.birthdate)}
          </Txt>
        )}
        <SelectField<OptionNumberType>
          zIndex={[0, 0, 1]}
          width={[1]}
          marginTop={"1.2em"}
          id="gender"
          placeholder={t(i18Enum.User_UserProfile_Label_Gender)}
          option={optionGenders}
          value={optionGenders.find((x) => x.value === formik.values.gender)}
          handleChange={(opt) => {
            formik.setFieldValue("gender", opt.value);
            onFieldChange();
          }}
          handleBlur={() => {
            formik.setFieldTouched("gender", true);
          }}
          labelExtractor={(x) => x.label}
          valueExtractor={(x) => GenderEnum[x.value]}
        />
        {formik.errors.gender && (
          <Txt
            padding={"13px 0 0 18px"}
            variant="error"
            fontSize={[3]}
            textAlign={"left"}
          >
            {formik.errors.gender}
          </Txt>
        )}
      </Flex>
    </Flex>
  );
}

export default AccountInfoForm;
