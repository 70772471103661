import styled from "styled-components";
import { SpaceProps, WidthProps, compose, space, variant, width } from "styled-system";

export interface WardrobeImageProps {
  variant?: "circle" | "oval" | "loading";
}

const WardrobeImage = styled.img<WardrobeImageProps & SpaceProps & WidthProps>`
  flex-grow: 1;
  object-fit: cover;
  object-position: center top;
  ${() =>
    compose(
      variant({
        variants: {
          circle: {
            borderRadius: "50%",
            aspectRatio: "1/1",
          },
          oval: {
            borderRadius: "100% / 55%",
            aspectRatio: "9/16"
          },
          loading: {
            borderRadius: "50%",
            aspectRatio: "1/1",
          },
        },
      }),
      width,
      space
      
    )};
`;

export default WardrobeImage;
