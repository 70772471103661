import Txt from "../primitives/Txt";
import Button from "../primitives/Button";
import { SpaceProps, WidthProps } from "styled-system";
import { useFormik } from "formik";
import * as Yup from "yup";

import InputFieldWardrobe from "components/molecules/InputFieldWardrobe";
import { i18Enum } from "i18n/types/translationType";
import { useTranslation } from "react-i18next";
import { LoginRequest } from "api/types/requests";
import { AUTH_API } from "api/graphql/requests";
import { isDev } from "utils";

type LoginSchemaObject = {
  [key in keyof FormLogin]: Yup.Schema<any>;
};
export type FormLogin = {
  email: string;
};

const loginInitialValue: FormLogin = {
  email: "",
};
type LoginProps = WidthProps & SpaceProps;

function LoginForm({ ...props }: LoginProps) {
  const { t } = useTranslation();

  const loginSchema = Yup.object().shape<LoginSchemaObject>({
    email: Yup.string()
      .matches(
        /^[\w\-.]+@([\w-]+\.)+[a-zA-Z]{2,10}$/i,
        t(i18Enum.Validation_Client_Field_Email)
      )
      .required(t(i18Enum.Validation_Client_Field_Required)),
  });

  const formik = useFormik<FormLogin>({
    initialValues: loginInitialValue,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      //loading
      setSubmitting(true);
      const newReq: LoginRequest = {
        email: values.email,
      };

      const publicDomain = isDev()
        ? "http://localhost:5173"
        : "https://mooore.com";

      const loginRequest = new URLSearchParams({
        client_id: "local",
        redirect_uri: `${publicDomain}`,
        response_type: "token",
        provider: "code",
        tenant: "mooore",
        email: newReq.email || "",
      });
      location.href = `${AUTH_API}/code/authorize?${loginRequest}`;
      formik.resetForm();
    },
  });

  return (
    <div>
      <Txt
        as="legend"
        width={[1]}
        fontSize={[4]}
        variant="light"
        textAlign={"left"}
      >
        {t(i18Enum.Login_LoginTitle)}
      </Txt>
      <InputFieldWardrobe
        {...formik.getFieldProps("email")}
        name={"email"}
        id={"email"}
        type={"email"}
        placeholder={t(i18Enum.User_UserProfile_Label_Email)}
        label={"email"}
        spaceProp={{ width: [1], marginTop: [4], padding: ["10px 0"] }}
        onChange={(e) => {
          formik.handleChange(e);
        }}
        onBlur={() => formik.setFieldTouched("email", true)}
        value={formik.values.email}
        error={
          formik.errors.email && formik.touched.email ? formik.errors.email : ""
        }
        autoComplete={"email"}
      />

      <Button
        variant="login"
        type="button"
        marginTop={[4]}
        disabled={!formik.isValid || formik.errors.email ? true : false}
        onClick={() => formik.handleSubmit()}
      >
        {t(i18Enum.Login_LoginButton)}
      </Button>
    </div>
  );
}

export default LoginForm;
