import { useContext, useEffect } from "react";
import { PAGE } from "../../../utils/const/pagePath";
import { useLocation, useNavigate } from "react-router-dom";
import Flex from "../../primitives/Flex";
import Ul from "../../primitives/Ul";
import { CustomThemeContext } from "../../../context/ThemeContext";
import Txt from "../../primitives/Txt";
import SearchSvg from "../../atoms/SearchSvg";
import icon from "../../../assets/icons/logoPrincipal.svg";
import Image from "../../primitives/Image";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import CrossSvg from "components/atoms/CrossSvg";
import SearchPage from "pages/SearchPage";
import { Cart } from "pages";
import { AppGeneralContext } from "context/AppGeneralContext";
import CartSvg from "components/atoms/CartSvg";
import { getWardrobeAction } from "redux/features/wardrobe/wardrobeSlice";
import { SearchKey } from "utils";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
// import { SelectLanguageField } from "components/molecules/SelectLanguageField";

export const DesktopNavbar = () => {
  const dispatch = useAppDispatch();
  const { customTheme } = useContext(CustomThemeContext);
  const { openAsideMenu, isMobile, setSearchKeyword } =
    useContext(AppGeneralContext);
  const numberOfCartItem = useAppSelector(
    (state) => state.cart.cart.data?.ShoppingBasket?.itemsCount
  );
  const colors = customTheme.colors;
  const navigate = useNavigate();
  const path = useLocation();
  const user = useAppSelector((state) => state.user.user.data);
  const { data: userPref } = useAppSelector(
    (state) => state.user.userPreference
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (
      path.pathname === PAGE.shopList.menuPath ||
      path.pathname === PAGE.shopFiltered.menuPath
    ) {
      return;
    } else {
      setSearchKeyword("");
    }
  }, [path]);

  useEffect(() => {
    if (
      user?.id &&
      userPref &&
      (!userPref.favoriteColors ||
        !userPref.favoriteHobbies ||
        !userPref.favoriteSports)
    ) {
      navigate(PAGE.firstLogin.menuPath);
    }
  }, [user]);

  const retrieveAllUserInfo = () => {
    if (!user?.id) {
      navigate(PAGE.login.menuPath);
      return;
    }
    dispatch(
      getWardrobeAction({
        isFirstLoad: true,
        isRefinement: false,
        usage: SearchKey.WardrobeItemsUserCard,
      })
    );

    if (user?.favoriteColors || user?.favoriteHobbies) {
      navigate(PAGE.account.menuPath);
    } else {
      navigate(PAGE.firstLogin.menuPath);
    }
  };
  const renderMenuList = () => {
    return Object.entries(PAGE)
      .filter(([key, value]) => value.showInMenu)
      .map(([key, value]) => {
        return (
          <Txt
            as={"li"}
            variant="linkLightSimple"
            color={
              path.pathname === value.menuPath
                ? colors.primaryBase
                : colors.thirdBase
            }
            key={key}
            onClick={() => {
              navigate(value.menuPath);
            }}
          >
            {value.title}
          </Txt>
        );
      });
  };

  function filter() {
    openAsideMenu<null>(<SearchPage />, null);
    if (path.pathname.toLocaleLowerCase().includes("pick")) {
      navigate(PAGE.shopList.menuPath);
    }
  }

  function OpenCart() {
    if (isMobile) {
      navigate(PAGE.cart.menuPath);
    }
    openAsideMenu<undefined>(<Cart />, undefined);
  }

  return (
    <Flex
      as={"nav"}
      width={"100%"}
      height={"80px"}
      position={"sticky"}
      zIndex={100}
      top={"0"}
      backgroundColor={colors.secondaryBg}
      flexDirection={"row"}
      justifyContent={"center"}
    >
      <Flex
        as={"nav"}
        width={"100%"}
        maxWidth={"1280px"}
        height={"100%"}
        backgroundColor={colors.secondaryBg}
        flexDirection={"row"}
        justifyContent={"center"}
      >
        <Flex
          flex={3}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ cursor: "pointer" }}
        >
          <Image
            src={icon}
            alt="icon"
            width="50%"
            onClick={() => navigate(PAGE.home.menuPath)}
          />
        </Flex>
        <Flex flex={6} justifyContent={"center"} alignItems={"center"}>
          <Ul
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-around"}
          >
            {renderMenuList()}
          </Ul>
        </Flex>
        <Flex flex={3.5} justifyContent={"space-evenly"} alignItems={"center"}>
          <SearchSvg handleClick={filter} />
          {/* Checkout flow  */}
          {/* <CartSvg number={numberOfCartItem} handleClick={OpenCart} /> */}

          {path.pathname.includes("user") ? (
            <CrossSvg
              handleClick={() => navigate(PAGE.home.menuPath)}
              width={"40px"}
              height={"30px"}
              display={"block"}
              stroke={colors.thirdBase}
            />
          ) : (
            <Txt
              as={"a"}
              variant="linkLightSimple"
              color={colors.primaryBase}
              $hoverColor={colors.thirdBase}
              fontWeight={300}
              onClick={() => retrieveAllUserInfo()}
            >
              {" "}
              {user?.id
                ? t(i18Enum.Navigation_Menu_Account)
                : t(i18Enum.Navigation_Menu_Login)}
            </Txt>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
