import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import { CustomThemeContext } from "../../../context/ThemeContext";
import Flex from "../../primitives/Flex";
import Box from "../../primitives/Box";
import Txt from "../../primitives/Txt";
import SelectFilter from "../../molecules/SelectFilter";
import Search from "../../molecules/Search";
import ListCTACardButton from "../ListCTACardButton";
import { PAGE } from "../../../utils/const/pagePath";
import { Tags } from "redux/features/product/ProductsTypes";
import { AppGeneralContext } from "context/AppGeneralContext";
import { ProductActions } from "redux/features/product/productSlice";
import { ProductsListRequest } from "api/types/requests/productRequest";
import Button from "components/primitives/Button";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { i18Enum } from "i18n/types/translationType";
import SearchPage from "pages/SearchPage";

interface MobileNavBarProps {
  searching: boolean;
  handleClick: () => void;
}

function MobileNavBar({ handleClick, searching }: MobileNavBarProps) {
  //hook assignment
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, watch, setValue } = useForm<{ search: "" }>();

  //context data
  const { filterList, cardsMenu } = useContext(AppGeneralContext);
  const colors = useContext(CustomThemeContext).customTheme.colors;
  //redux selectors data
  const { data: user } = useAppSelector((store) => store.user.user);
  const { data: userPref } = useAppSelector(
    (state) => state.user.userPreference
  );
  //useState data
  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    filterList?.OnHeader?.[0]?.id ? [filterList?.OnHeader?.[0]?.id] : []
  );
  const [isPromo, setIsPromo] = useState<boolean>(false);
  const { t } = useTranslation();

  //set filtersList
  const exploreCategoriesList: Tags[] | undefined = useMemo(() => {
    return filterList?.ExploreByCategory;
  }, [filterList?.ExploreByCategory?.length]);
  const headerFilterList: Tags[] | undefined = useMemo(() => {
    return filterList?.OnHeader;
  }, [filterList?.ExploreByCategory?.length]);

  useEffect(() => {
    if (
      user?.id &&
      userPref &&
      (!userPref.favoriteColors || !userPref.favoriteHobbies)
    ) {
      navigate(PAGE.firstLogin.menuPath);
    }
  }, [user]);

  //navigation Function
  function closeMenu() {
    handleClick();
  }

  const fnNavigate = (link: string): void => {
    navigate(link);
    handleClick();
  };

  const retrieveAllUserInfo = useCallback(() => {
    if (!user?.id) {
      navigate(PAGE.login.menuPath);
      return;
    }
    if (user?.favoriteColors || user?.favoriteHobbies) {
      navigate(PAGE.account.menuPath);
    } else {
      navigate(PAGE.firstLogin.menuPath);
    }
  }, [user?.id]);

  //handler function
  const handleToggleIds = useCallback(
    (id?: string) => {
      if (!id) {
        return;
      }
      if (selectedFilters?.includes(id)) {
        const index = selectedFilters.indexOf(id);
        selectedFilters?.splice(index, 1);
        setSelectedFilters([...selectedFilters]);
        return;
      }
      setSelectedFilters([...selectedFilters, id]);
      return;
    },
    [selectedFilters]
  );

  const handleSearching = (
    keyword?: string,
    tagIds?: string[],
    isPromo?: boolean
  ) => {
    const req: ProductsListRequest = {
      Name: keyword ? keyword : undefined,
      IsFiltering: true,
      // MinPrice: 0,
      // MaxPrice: filterList?.MaxPrice,
      PageNum: 1,
      PageSize: 12,
      IsPromo: isPromo,
      TagsIds: tagIds,
    };
    dispatch(ProductActions.searchProductAction(req));
    navigate(PAGE.shopFiltered.menuPath, {
      state: { ...req, IsFiltering: false },
    });
    closeMenu();
    return;
  };

  const resetFilter = () => {
    setSelectedFilters([]);
    setValue("search", "");
    setIsPromo(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearching(watch().search, selectedFilters, isPromo);
      return;
    }
    if (e.ctrlKey && e.key === "a") {
      e.preventDefault();
      const inputElement =
        document.querySelector<HTMLInputElement>("#searchInput");
      if (inputElement) {
        inputElement.setSelectionRange(0, inputElement.value.length);
      }
      return;
    }
  };

  return (
    <Flex
      width={[1]}
      height={["calc(100vh - 80px)"]}
      flexDirection={"column"}
      overflowY={"scroll"}
      justifyContent={"space-between"}
      backgroundColor={colors.secondaryBgActive}
    >
      {searching && (
        <Box
          position={"absolute"}
          zIndex={1000}
          height={"100vh"}
          width={"100vw"}
          top={"80px"}
          left={0}
        >
          <SearchPage
            close={handleClick}
            handleBack={() => handleSearching(false)}
          />
        </Box>
      )}
      <Flex flexDirection={"column"} alignItems={"end"}>
        <Box
          as={"hr"}
          backgroundColor={colors.primaryBg}
          width={[1]}
          height={"100%"}
          border="none"
          margin={0}
        />
        <Flex
          width={"100%"}
          justifyContent={"end"}
          $gap={1.2}
          paddingRight={[3]}
          marginTop={[3]}
        >
          {headerFilterList &&
            headerFilterList.map((genre) => (
              <Button
                minWidth={"80px"}
                key={genre.id}
                variant={
                  selectedFilters.includes(genre.id ?? "")
                    ? "switcherGenre"
                    : "switcher"
                }
                padding={"5px 20px"}
                onClick={() => handleToggleIds(genre.id)}
              >
                {genre.label}
              </Button>
            ))}
        </Flex>
        <Search
          paddingRight={[3]}
          margin={["12px 0 5px"]}
          width={[0.9]}
          isButton={true}
          short={true}
          control={register("search")}
          value={watch("search")}
          launchSearch={() =>
            handleSearching(watch().search, selectedFilters, isPromo)
          }
          onKeyDown={(e) => handleKeyDown(e)}
          id="searchInput"
        />
        {selectedFilters.length > 0 && (
          <Txt
            variant="linkDanger"
            color={colors.red10}
            as={"span"}
            fontFamily={"BauPro"}
            fontWeight={"400"}
            fontSize={[3]}
            onClick={resetFilter}
            paddingRight={[3, 5]}
            mb={2}
          >
            {t(i18Enum.Filters_Label_Reset)}
          </Txt>
        )}
        <SelectFilter
          paddingRight={[3]}
          filters={exploreCategoriesList ?? []}
          selected={selectedFilters}
          handleClick={(value: string) => handleToggleIds(value)}
        />
        {filterList?.IsPromo && (
          <Button
            selected={isPromo}
            padding={["3px 16px"]}
            mt={"5px"}
            mr={"16px"}
            variant={"promo"}
            onClick={() => {
              setIsPromo((x) => !x);
            }}
          >
            {t(i18Enum.Shopping_Page_Promo)}
          </Button>
        )}
      </Flex>
      <Flex width={"100%"} justifyContent={"center"} padding={"30px 0"}>
        <Button
          variant={"success"}
          fontSize={[3]}
          fontWeight={500}
          width={["150px"]}
          height={["40px"]}
          onClick={() =>
            handleSearching(watch().search, selectedFilters, isPromo)
          }
        >
          {t(i18Enum.Common_Search)}
        </Button>
      </Flex>
      <Flex flexDirection={"column"}>
        <ListCTACardButton
          cards={cardsMenu}
          handleClick={(link) => fnNavigate(link)}
        />
        <Box
          as={"hr"}
          backgroundColor={colors.primaryBg}
          width={[1]}
          height={"100%"}
          border="none"
          margin={0}
        />
        {/* MENU-LIST */}
        <Flex
          marginBottom={["80px", "20px"]}
          paddingX={[3]}
          justifyContent={"right"}
          alignItems={"center"}
          $gap={1}
          maxWidth={"100vw"}
        >
          <Txt
            variant="linkLight"
            fontSize={[2]}
            fontFamily={"BauPro"}
            fontWeight={"400"}
            onClick={() => {
              navigate(PAGE.home.menuPath);
              closeMenu();
            }}
          >
            {t(i18Enum.Navigation_Menu_Home)}
          </Txt>
          {user?.id ? (
            <Txt
              variant="linkLight"
              fontSize={[2]}
              fontFamily={"BauPro"}
              fontWeight={"400"}
              onClick={() => {
                retrieveAllUserInfo();
                closeMenu();
              }}
            >
              {t(i18Enum.Navigation_Menu_Account)}
            </Txt>
          ) : (
            <Txt
              variant="linkLight"
              fontSize={[2]}
              fontFamily={"BauPro"}
              fontWeight={"400"}
              onClick={() => {
                retrieveAllUserInfo();
                closeMenu();
              }}
            >
              {t(i18Enum.Navigation_Menu_Login)}
            </Txt>
          )}
          {/* TODO: riattivare la tendina per selezionare la lingua */}
          {/* <Flex width={"14%"}>
            <SelectLanguageField width='100%' />
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default MobileNavBar;
