import {
  ProductType,
  ShopDetailInProductDTO,
} from "redux/features/product/ProductsTypes";
import { Pager } from "../../api/types/responses";

export const PagerInitialState: Pager = {
  PageNum: 1,
  PageSize: 0,
  TotalItems: 0,
  TotalPages: 0,
  Links: [],
};

export const shopInitialState: ShopDetailInProductDTO = {
  id: "",
  description: "",
  name: "",
  Picture: "",
  about: "",
  coverImage: { bucketUrl: "" },
  location: "",
  username: "",
};

export const productInitialState: ProductType = {
  id: "",
  isPublished: false,
  LikesCount: 0,
  name: "",
  shopId: "",
  AddedDate: new Date(),
  handle: "",
  description: "",
  metatagKeywords: "",
  wishlistedByUser: false,
  Tags: [],
  AddedBy: "",
  composition: "",
  Delivery: "",
  details: "",
  Order: 0,
  shop: shopInitialState,
  variants: [],
};
