import {
  addToShoppingBasket,
  createOrder,
  createStripeSession,
  getShoppingBasket,
  removeFromShoppingBasket,
  updateShoppingBasket,
} from "api/graphql/requests";
import { OrderRequest } from "api/types/requests/cartRequest";
import { queryClient } from "App";

import {
  CreateCheckoutSessionMutationVariables,
  UpdateShoppingBasketItemInput,
} from "utils/graphql/generated/graphql";

export const getShoppingBasketCall = async () => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["getShoppingBasket"],
      queryFn: () => getShoppingBasket(),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const addItemToShoppingBasketCall = async (variantIds: string[]) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["addProductToShoppingBasket"],
      queryFn: () => addToShoppingBasket(variantIds),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const updateShoppingBasketCall = async (
  id: string,
  shoppingBasketItem: UpdateShoppingBasketItemInput
) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["updateShoppingBasket"],
      queryFn: () => updateShoppingBasket(id, shoppingBasketItem),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const removeFromShoppingBasketCall = async (
  shoppingBasketItemId: string
) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["removeFromShoppingBasket"],
      queryFn: () => removeFromShoppingBasket(shoppingBasketItemId),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const createOrderCall = async (req: OrderRequest) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["createOrder"],
      queryFn: () => createOrder(req),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};

export const stripeCall = async (
  createSessionParams: CreateCheckoutSessionMutationVariables
) => {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ["createStripeSession"],
      queryFn: () => createStripeSession(createSessionParams),
      staleTime: 0,
      gcTime: 0,
    });

    return { response: data, isSuccess: true, error: false };
  } catch (error) {
    console.error(error);
    return { response: null, isSuccess: false, error };
  }
};
