export const affiliateProgramCall = async (
  req: Record<string, string | boolean>
) => {
  const res = await fetch("https://slu.fabricode.it/sendEmail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: "c.controguerra@mooore.com",
      replyTo: "c.controguerra@mooore.com",
      domain: "mooore.com",
      template: "website-contact-form",
      data: req,
    }),
  });
  return res;
};
