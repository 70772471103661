import styled from "styled-components";
import { BorderProps, ColorProps, FlexboxProps, LayoutProps, PositionProps, SpaceProps, TypographyProps, compose, variant } from "styled-system";

interface ContainerImageProps {
  variant?: "popup";
}

const ContainerImage = styled.div<
  ContainerImageProps &
    ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    PositionProps &
    BorderProps &
    TypographyProps & { $gap?: number } & { $aspectRatio?: string } & { $transition?: string } & { $backgroundImage?: string } & { $blur?: boolean } & { $aspectRatio?: string }
>`
  height: 80%;
  width: 92.5%;
  margin-left: 3%;
  margin-top: 2.5%;
  background-color: "transparent";
  aspect-ratio: ${(props) => (props.$aspectRatio ? props.$aspectRatio : undefined)};
  transform: rotate(3deg);
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  position: relative;

  ${() =>
    compose(
      variant({
        variants: {
          popup: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",
            margin: "1% 0 0 1%",
            transform: "rotate(2deg)",
          },
        },
      })
    )}
`;

export default ContainerImage;
